import { createSlice } from "@reduxjs/toolkit";


export interface OpenSideBar{
    Opened:boolean;
}



const initialState:OpenSideBar = {
    Opened:false,
}

export const OpenSideBarSlice = createSlice({
    name: 'OpenSideBar',
    initialState,
    reducers:{
        setOpenOrCloseSideBar(state,action){
            state.Opened = action.payload;
        }
    }

});


export const {setOpenOrCloseSideBar} = OpenSideBarSlice.actions;
export default OpenSideBarSlice.reducer;