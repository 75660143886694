import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../../hooks/components/common/Button/Button'
import ContentCard from '../../hooks/components/common/ContentCard/ContentCard'
import ContentCardHeading from '../../hooks/components/common/ContentCard/ContentCardHeading'
import ContentCardInner from '../../hooks/components/common/ContentCard/ContentCardInner'
import ContentCardWrapper from '../../hooks/components/common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../hooks/components/common/ContentMainTheme'
import InformationCard from '../../hooks/components/common/InformationCard/InformationCard'
import {
	FAQItem,
	setCurrentPage,
	setPopularQuestions,
} from '../../redux/slices/helpSlice'
import { RootState } from '../../redux/store'
import { APPLICATION_MICROSERVICE_URL } from '../../utils/team'
import { checkIsTokenExpired, getTokens, isTokenInLS } from '../../utils/token'
import Pagination from './Pagination'

const PopularQuestion = () => {
	//This hook is needed to pass the props from the "QuestionSetActive"
	//file (for the message that one of the faq questions has been deleted)
	//{
	const location = useLocation()
	const { que } = location.state || {}
	//}

	//The role is defined here: admin/student
	//{
	let admin: boolean = false
	let student: boolean = false

	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		const isAdmin = roles.indexOf('client-admin') > -1
		if (isAdmin) admin = isAdmin
		const isStudent = roles.indexOf('client-student') > -1
		const isTeamLead = roles.indexOf('client-teamlead') > -1
	}
	//}

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const popularQuestions = useSelector(
		(state: RootState) => state.help.popularQuestions
	)
	const currentPage = useSelector((state: RootState) => state.help.currentPage)
	const questionsPerPage = useSelector(
		(state: RootState) => state.help.questionsPerPage
	)

	const totalPages = Math.ceil(popularQuestions.length / questionsPerPage)
	const startIndex = currentPage * questionsPerPage
	const endIndex = startIndex + questionsPerPage

	const fetchPopularQuestions = async (page: number, pageSize: number) => {
		const tokens = JSON.parse(window.localStorage.getItem('token') || '{}')
		if (checkIsTokenExpired(tokens.access_token)) {
			if (checkIsTokenExpired(tokens.refresh_token)) {
				window.localStorage.removeItem('token')
				navigate('/')
			}
		}
		try {
			//get questions faq
			const response = await axios.get(
				`${APPLICATION_MICROSERVICE_URL}/help/faq/${page}/${pageSize}`,
				{
					headers: {
						Authorization: `Bearer ${getTokens().access_token}`,
					},
				}
			)
			const popularQuestionsArray = response.data.data.content
			if (popularQuestionsArray && popularQuestionsArray.length > 0) {
				dispatch(setPopularQuestions(popularQuestionsArray))
			}
		} catch (error) {
			console.error('Error fetching popular questions:', error)
		}
	}

	const handlePageChange = (newPage: number) => {
		dispatch(setCurrentPage(newPage))
	}

	useEffect(() => {
		//update every time variables "currentPage" and "questionsPerPage" change
		fetchPopularQuestions(currentPage, questionsPerPage)
	}, [currentPage, questionsPerPage])

	return (
		<>
			<ContentMainTheme>
				Появился вопрос? Напишите нам и мы поможем!
			</ContentMainTheme>
			<ContentCardWrapper>
				<ContentCard transparent low>
					<div className='text-mobile'>
						За всё время обучения у студентов появлялось немало вопросов,
						поэтому специально для вас мы решили сделать отдельный раздел с
						самыми часто задаваемыми вопросами. Если вы не нашли ответа на свой
						вопрос, вы всегда можете задать его сами:
					</div>
					<div className='text-desktop'>
						За всё время обучения у студентов появлялось немало вопросов,
						поэтому специально для вас мы решили сделать отдельный раздел с
						самыми часто задаваемыми вопросами. Если вы не нашли ответа на свой
						вопрос, вы всегда можете задать его сами:
					</div>

					<div className='help-common-information-button-wrapper'>
						{admin ? (
							<Button action={'link'} to='../ask-question-form'>
								Создать вопрос
							</Button>
						) : (
							<Button action={'link'} to='../ask-question-form'>
								Задать вопрос
							</Button>
						)}
						<div className='help-button2'>
							<Button action={'link'} to='../question-history'>
								История вопросов
							</Button>
						</div>
					</div>
				</ContentCard>
				<ContentCard transparent>
					<ContentCardHeading topic='Популярные вопросы' />
					<div className='faq-delete'>
						{que !== undefined
							? 'Вопрос: "' + que + '" был успешно удалён'
							: ''}
					</div>
					<ContentCardInner>
						{popularQuestions
							.slice(startIndex, endIndex)
							.map((item: FAQItem) => (
								<InformationCard
									id={item.id}
									question={item.content}
									answer={item.feedback}
									initiator={''}
								/>
							))}
					</ContentCardInner>
					<Pagination
						currentPage={currentPage}
						totalPages={totalPages}
						onPageChange={handlePageChange}
					/>
				</ContentCard>
			</ContentCardWrapper>
		</>
	)
}

export default PopularQuestion
