import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Company } from '../../../../@types/entities/Company'
import ErrorHandler from '../../../../utils/ErrorHandler'
import {
	MODERATION_MICROSERVICE_URL,
	sendAxiosWithTokens,
} from '../../../../utils/team'
import SingleSelect from '../../SingleSelect'
import Button from '../../common/Button/Button'
import ButtonWrapper from '../../common/Button/ButtonWrapper'
import ContainerMain from '../../common/ContainerMain/ContainerMain'
import ContentCard from '../../common/ContentCard/ContentCard'
import ContentCardWrapper from '../../common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../common/ContentMainTheme'
import DoubleList from '../../common/DoubleList/DoubleList'
import SeparateLine from '../../common/SeparateLine/SeparateLine'
import FormLabel from '../AdminQuestionnaire/FormLabel'

type SelectType = { valueName: number; valueId: number; label: string }

export const CreateCurator = () => {
	const [actualCompanyId, setActualCompanyId] = useState<number>(-1)
	const [companies, setCompanies] = useState<SelectType[]>()
	const navigate = useNavigate()

	//запрос на список компаний
	const GetCompanyListFromServer = async () => {
		await sendAxiosWithTokens(
			`get`,
			`${MODERATION_MICROSERVICE_URL}/api/v1/company`
		)
			.then(r => {
				const dataToSaveInState: SelectType[] = []
				r.data.data.forEach((item: Company) => {
					dataToSaveInState.push({
						valueName: item.id,
						label: item.title,
						valueId: item.id,
					} as SelectType)
				})
				setCompanies(dataToSaveInState)
			})
			.catch(error => {
				console.log(error)
			})
	}

	useEffect(() => {
		GetCompanyListFromServer()
	}, [])

	interface CreateCuratorTypes {
		fio: string
		username: string
		telegram_link: string
		email: string
		phone_number: string
		password: string
		role: string
	}

	const {
		register,
		getValues,
		formState: { errors, isValid },
	} = useForm<CreateCuratorTypes>({
		mode: 'onTouched',
	})

	const PutNewCurator = () => {
		const value = getValues()

		//отправка запроса на создание юзера
		const CreateNewCurator = async () => {
			await sendAxiosWithTokens(
				`put`,
				`${MODERATION_MICROSERVICE_URL}/api/v1/admin/users/`,
				{
					username: value['username'],
					fio: value['fio'],
					telegram_link: value['telegram_link'],
					email: value['email'],
					phone_number: value['phone_number'],
					password: value['password'],
					roles: [value['role']],
					studentProfile: null,
					adminProfile: null,
					companyUserProfile: {
						companyId: actualCompanyId,
					},
				}
			)
				.then(r => {
					console.log(r)
				})
				.catch(error => {
					console.log(error)
					ErrorHandler(error, navigate)
				})
		}
		CreateNewCurator()
	}

	// @ts-ignore
	return (
		<ContainerMain size='medium'>
			<ContentMainTheme>
				Создание куратора/заказчика от компании
			</ContentMainTheme>
			<ContentCardWrapper>
				<ContentCard transparent low>
					Чтобы создать профиль куратора/заказчика от компании необходимо
					заполнить следующие поля
				</ContentCard>
				<ContentCard blured rounded>
					<FormLabel required>Компания:</FormLabel>
					<SingleSelect
						options={companies}
						infobefore=''
						onChangeValue={(valueName: string, valueId: number) => {
							setActualCompanyId(valueId)
						}}
					/>
					<SeparateLine />
					{actualCompanyId === -1 ? (
						<p className='form-field-input--error-message'>
							Необходимо выбрать компанию
						</p>
					) : (
						<DoubleList
							className='initials'
							nonUnderLine
							listData={[
								{
									identifier: 'ФИО:',
									content: (
										<>
											{' '}
											<input
												className='company-info-input-forEdit'
												placeholder='ФИО'
												{...register('fio', {
													required: 'Небходимо заполнить',
												})}
											></input>
											{errors.fio && (
												<p className='form-field-input--error-message'>
													{errors.fio.message}
												</p>
											)}
										</>
									),
								},
								{
									identifier: 'Имя пользователя:',
									content: (
										<>
											<input
												className='company-info-input-forEdit'
												placeholder='Имя пользователя'
												{...register('username', {
													required: 'Небходимо заполнить',
												})}
											></input>
											{errors.username && (
												<p className='form-field-input--error-message'>
													{errors.username.message}
												</p>
											)}
										</>
									),
								},
								{
									identifier: 'VK:',
									content: (
										<>
											<input
												className='company-info-input-forEdit'
												placeholder='VKID'
												{...register('telegram_link', {
													required: 'Небходимо заполнить',
												})}
											></input>
											{errors.telegram_link && (
												<p className='form-field-input--error-message'>
													{errors.telegram_link.message}
												</p>
											)}
										</>
									),
								},
								{
									identifier: 'Личная почта:',
									content: (
										<>
											<input
												className='company-info-input-forEdit'
												placeholder='Личная почта'
												{...register('email', {
													required: 'Небходимо заполнить',
												})}
											></input>
											{errors.email && (
												<p className='form-field-input--error-message'>
													{errors.email.message}
												</p>
											)}
										</>
									),
								},
								{
									identifier: 'Номер телефона:',
									content: (
										<>
											<input
												className='company-info-input-forEdit'
												placeholder='Номер телефона'
												{...register('phone_number', {
													required: 'Небходимо заполнить',
												})}
											></input>
											{errors.phone_number && (
												<p className='form-field-input--error-message'>
													{errors.phone_number.message}
												</p>
											)}
										</>
									),
								},
								{
									identifier: 'Пароль:',
									content: (
										<>
											<input
												className='company-info-input-forEdit'
												placeholder='Пароль'
												{...register('password', {
													required: 'Небходимо заполнить',
												})}
											></input>
											{errors.password && (
												<p className='form-field-input--error-message'>
													{errors.password.message}
												</p>
											)}
										</>
									),
								},
								{
									identifier: 'Роль:',
									content: (
										<>
											<select
												{...register('role', {
													required: 'Небходимо заполнить',
												})}
											>
												<option value='CURATOR'>Куратор</option>
												<option value='CUSTOMER'>Заказчик</option>
											</select>

											{errors.role && (
												<p className='form-field-input--error-message'>
													{errors.role.message}
												</p>
											)}
										</>
									),
								},
							]}
						/>
					)}

					<div className='company-Go-To-Back-Button-In-Info'>
						<ButtonWrapper>
							<Button
								action='link'
								to='/company'
								onClick={PutNewCurator}
								disabled={!isValid || actualCompanyId === -1}
							>
								Создать
							</Button>
							<Button
								action='link'
								to='/company'
								onClick={() => console.log(actualCompanyId)}
							>
								Назад
							</Button>
						</ButtonWrapper>
					</div>
				</ContentCard>
			</ContentCardWrapper>
		</ContainerMain>
	)
}

export default CreateCurator
