import { Company } from '../../../../../@types/entities/Company'
import {
	CREATE_COMMAND_MICROSERVICE_URL,
	MODERATION_MICROSERVICE_URL,
	sendAxiosWithTokens,
} from '../../../../../utils/team'

export type SelectType = { valueName: number; valueId: number; label: string }

export const facultyList = [
	{
		valueName: 1,
		label: '1 Факультет',
	},
	{
		valueName: 2,
		label: '2 Факультет',
	},
	{
		valueName: 3,
		label: '3 Факультет',
	},
	{
		valueName: 4,
		label: '4 Факультет',
	},
	{
		valueName: 5,
		label: '5 Факультет',
	},
	{
		valueName: 6,
		label: '6 Факультет',
	},
	{
		valueName: 7,
		label: '7 Факультет',
	},
	{
		valueName: 8,
		label: '8 Факультет',
	},
	{
		valueName: 9,
		label: '9 Факультет',
	},
	{
		valueName: 10,
		label: '10 Факультет',
	},
	{
		valueName: 11,
		label: '11 Факультет',
	},
	{
		valueName: 12,
		label: '12 Факультет',
	},
]
export const degrees = [
	{
		valueName: 'Б',
		label: 'Бакалавриат',
	},
	{
		valueName: 'C',
		label: 'Специалитет',
	},
	{
		valueName: 'М',
		label: 'Магистратура',
	},
]
export const rawSkills = [
	'AirFlow',
	'Atlassian stack',
	'Back-end разработка',
	'С',
	'С#',
	'С++',
	'СNN',
	'CSS',
	'Canva',
	'Computer Vision',
	'Confluence',
	'Data Engineering',
	'Data Science',
	'Deep Learning',
	'DevOps',
	'Django',
	'Docker',
	'ETL',
	'Excel',
	'Figma',
	'Front-end разработка',
	'Git',
	'Go',
	'HTML',
	'Hadoop',
	'Jira',
	'Jupyter',
	'Kafka',
	'Keras',
	'Kotlin',
	'Kubernetes',
	'Linux',
	'Machine Learning',
	'Marketing',
	'Matplotlib',
	'Microsoft Power BI',
	'NumPy',
	'ONNX Runtime',
	'OpenCV',
	'Pandas',
	'Photoshop',
	'PowerPoint',
	'PyTorch',
	'Python',
	'R',
	'React',
	'SMM',
	'SQL',
	'Scikit-Learn',
	'Seaborn',
	'TensorFlow',
	'Использование принципов Rest',
	'Математическая статистика',
	'Построение Rest API',
	'Работа с JSON',
	'Работа с XML',
	'Работа с YAML',
	'Разработка моделей данных',
	'СУБД GreenPulm',
	'СУБД MS SQL Server',
	'СУБД Oracle',
	'СУБД PostgreSQL',
	'Умение работать с API',
	'Управление проектами',
]
export const getCompanies = async () => {
	const url = `${MODERATION_MICROSERVICE_URL}/api/v1/company`
	return sendAxiosWithTokens(`get`, url).then(res => {
		const dataToSaveInState: SelectType[] = []
		res.data.data.forEach((item: Company) => {
			dataToSaveInState.push({
				valueName: item.id,
				valueId: item.id,
				label: item.title,
			} as SelectType)
		})
		return dataToSaveInState
	})
}
type group = 'dpp' | 'academic'
export const getGroups = async (typeGroup: group) => {
	const typesUrl = {
		dpp: `${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/group-selector?is_dpp=true`,
		academic: `${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/group-selector?is_academic=true`,
	} as Record<string, string>
	return sendAxiosWithTokens(`get`, typesUrl[typeGroup]).then(res => {
		const dataToSaveInState: SelectType[] = []
		res.data.data.forEach((item: Company) => {
			dataToSaveInState.push({
				valueName: item.id,
				valueId: item.id,
				label: item.title,
			} as SelectType)
		})
		return dataToSaveInState
	})
}

export const getPrograms = async () => {
	const url = `${MODERATION_MICROSERVICE_URL}/api/v1/program/`
	return await sendAxiosWithTokens(`get`, url).then(res => {
		const dataToSaveInState: SelectType[] = []
		res.data.data.forEach((item: Company) => {
			dataToSaveInState.push({
				valueName: item.id,
				valueId: item.id,
				label: item.title,
			} as SelectType)
		})
		return dataToSaveInState
	})
}
