import React from "react";

export const ColumnFilter = ({ column }: any) => {
	// console.log(column);
	const { filterValue, setFilter } = column;
	const [visible, setVisible] = React.useState(false);

	return (
		<span
			className={`table__column-filter-wrapper ${
				visible ? "table__column-filter-wrapper--visible" : ""
			}`}
		>
			<button
				type="button"
				className="table__column-filter-button"
				onClick={() => setVisible(!visible)}
			>
				&equiv;
			</button>
			{visible && (
				<span className="table__column-filter-input">
					<input
						type="text"
						value={filterValue || ""}
						onChange={(e) => setFilter(e.target.value || undefined)}
						placeholder="Фильтровать"
					/>
				</span>
			)}
		</span>
	);
};
