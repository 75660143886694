import jwt_decode from 'jwt-decode'
import { useState } from 'react'
import { useMatch } from 'react-router-dom'
import { getTokens, isTokenInLS } from '../../../../utils/token'
import InformationCardAction from './InformationCardAction'

const InformationCard = ({ question, answer, id, initiator }) => {
	const [isAnswerShown, setAnswerShown] = useState(true)
	const toggleIndex = () => {
		setAnswerShown(!isAnswerShown)
	}

	let admin = false
	let student = false

	if (isTokenInLS()) {
		const token = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		const isAdmin = roles.indexOf('client-admin') > -1
		if (isAdmin) admin = isAdmin
		const isStudent = roles.indexOf('client-student') > -1
		if (isStudent) student = isStudent
	}

	return (
		<div
			className={isAnswerShown ? 'information-card' : 'information-card-active'}
		>
			{/*faq card design: expand/collapse*/}
			{useMatch('help/question-history') ? (
				<div className='card-initiator'>
					{admin ? initiator + ' спрашивает:' : ''}
				</div>
			) : (
				''
			)}
			{isAnswerShown ? (
				<p className='information-card-text'> {question}</p>
			) : (
				<p className='information-card-text'> {answer}</p>
			)}
			<InformationCardAction
				isButtonClicked={isAnswerShown === true}
				onShow={() => toggleIndex()}
				answerItem={question}
				queItem={answer}
				id={id}
			/>
		</div>
	)
}

export default InformationCard
