import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { TeamDTO } from '../../@types/createCommand'
import { Topic } from '../../@types/createTopic'
import { UserInTeamDTO } from '../../@types/dtos/UserInTeamDTO'
import { RoleInProject } from '../../@types/entities/RoleInProject'

interface MyTeamSlice {
	teamStatus: 'SELECTED' | 'NOT_SELECTED'
	teamLeadStatus: 'NOT_SENT' | 'REJECTED' | 'ACCEPTED' | 'IN_REVIWEVING'
	teamStorageStatus: 'FETCHED' | 'NOT_FETCHED'
	squadStorageStatus: 'FETCHED' | 'NOT_FETCHED'
	team: TeamDTO
	squad: UserInTeamDTO[]
}

type TeamKeys = keyof TeamDTO
// | "id"
// | "company"
// | "title"
// | "topic"
// | "isApprovedByAdmin"
// | "isApprovedByCompany"
// | "feedback"
// | "description"
// | "supervisor"
// | "reviewer"
// | "adminApplication"
// | "companyApplication"
// | "consultant"
// | "program"
// | "teamlead";

const initialState: MyTeamSlice = {
	teamStatus: 'NOT_SELECTED',
	teamLeadStatus: 'NOT_SENT',
	teamStorageStatus: 'NOT_FETCHED',
	squadStorageStatus: 'NOT_FETCHED',
	team: {
		id: 0,
		company: {},
		title: '',
		topic: {},
		// isApprovedByAdmin: false,
		// isApprovedByCompany: false,
		feedback: '',
		description: '',
		studentsCount: 0,
		supervisor: {},
		reviewer: {},
		adminApplication: {},
		companyApplication: {},
		consultant: {},
		program: {},
		teamlead: {},
	},
	squad: [],
}

export const myTeamSlice = createSlice({
	name: 'myTeam',
	initialState,
	reducers: {
		initTeam(state) {
			state.team = {
				id: 0,
				company: {},
				title: '',
				topic: {},
				studentsCount: 0,
				// isApprovedByAdmin: false,
				// isApprovedByCompany: false,
				feedback: '',
				description: '',
				supervisor: {},
				reviewer: {},
				adminApplication: {},
				companyApplication: {},
				consultant: {},
				program: {},
				teamlead: {},
			}
		},

		// setTopic(state, action: PayloadAction<{topic:string}>) {
		//     if (state.team?.topic)
		//     state.team.topic = action.payload.topic

		// }

		setTeamLeadStatus(
			state,
			action: PayloadAction<{ status: typeof state.teamLeadStatus }>
		) {
			state.teamLeadStatus = action.payload.status
		},
		setTeamStatus(
			state,
			action: PayloadAction<{ status: typeof state.teamStatus }>
		) {
			state.teamStatus = action.payload.status
		},
		setTeamInfo(state, action: PayloadAction<{ name: TeamKeys; value: any }>) {
			//@ts-ignore
			if (action.payload.name in state?.team)
				//@ts-ignore
				state.team[action.payload.name] = action.payload.value
		},
		setEntireTeam(state, action: PayloadAction<{ team: TeamDTO }>) {
			// console.log(action.payload.team);
			for (let key of Object.keys(action.payload.team)) {
				// @ts-ignore
				state.team[key] = action.payload.team[key]
			}
			state.teamStorageStatus = 'FETCHED'
			state.teamStatus = 'SELECTED'
		},

		setTopic(state, action: PayloadAction<{ topic: Topic }>) {
			state.team.topic = action.payload.topic
		},

		setSquadList(state, action: PayloadAction<{ squad: any }>) {
			state.squad = action.payload.squad
			state.squadStorageStatus = 'FETCHED'

			// console.log('squad: ', state.MySquadInfo.squad);
		},
		setSquadMemberRole(
			state,
			action: PayloadAction<{ userId: number; role: RoleInProject }>
		) {
			state.squad = state.squad.map(user => {
				if (user.member.id === action.payload.userId) {
					user.role = action.payload.role
				}
				return user
			})
		},
		updateTeamInfo(state, action) {
			state.team = action.payload
			// console.log(state.MyTeamInfo);
		},
		setDeputyTeamLead(state, action: PayloadAction<{ id: number }>) {
			state.squad = state.squad.map(user => {
				user.isDeputy = user.member.id === action.payload.id
				return user
			})
		},

		deleteUserFromSquad(state, action: PayloadAction<{ userId: number }>) {
			state.squad = state.squad.filter(
				user => user.member.id !== action.payload.userId
			)
		},

		leaveTeam(state) {
			state.team = initialState.team
			state.teamStatus = 'NOT_SELECTED'
			state.teamLeadStatus = 'NOT_SENT'
			state.teamStorageStatus = 'NOT_FETCHED'
			state.squadStorageStatus = 'NOT_FETCHED'

			console.log('Вы вышли из команды')
		},
	},
})

export const {
	initTeam,
	setTeamInfo,
	setSquadList,
	setEntireTeam,
	setTeamStatus,
	setTeamLeadStatus,
	setTopic,
	setSquadMemberRole,
	setDeputyTeamLead,
	deleteUserFromSquad,
	leaveTeam,
} = myTeamSlice.actions
export default myTeamSlice.reducer
