import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { setTeamInfo } from '../../../redux/slices/myTeamSlice'
import { RootState } from '../../../redux/store'
import {
	clientIsTeamLead,
	sendTopicTitleAndDescriptionChange,
	updateTeamData,
} from '../../../utils/team'
import Button from '../common/Button/Button'
import DoubleList from '../common/DoubleList/DoubleList'

const TeamCommonInformationEditing = () => {
	const dispatch = useDispatch()
	const { team } = useSelector((state: RootState) => state.myTeam)

	let teamlead = clientIsTeamLead()

	interface ITeamInfoEditing {
		title: string
		description: string
	}

	const {
		register,
		getValues,
		resetField,
		formState: { errors, isValid },
	} = useForm<ITeamInfoEditing>({
		mode: 'onTouched',
		defaultValues: {
			title: team.title,
			description: team.description,
		},
	})

	useEffect(() => {
		;(async () => {
			await updateTeamData()
		})()

		return () => {
			const values = getValues()
			dispatch(setTeamInfo({ name: 'title', value: values['title'] }))
			dispatch(
				setTeamInfo({ name: 'description', value: values['description'] })
			)
			sendTopicTitleAndDescriptionChange(
				team.id,
				values['title'],
				values['description']
			)
			resetField('title')
			resetField('description')
			console.log('Редактирование команды отработало')
		}
	}, [])

	return (
		<DoubleList
			className={'common'}
			listData={[
				{
					identifier: 'Наименование команды:',
					content: (
						<input
							className={
								'form-field-input ' +
								(errors.title ? 'form-field-input--not-valid ' : '')
							}
							{...register('title', { required: true })}
						/>
					),
				},
				{
					identifier: 'Описание команды:',
					content: (
						<textarea
							className='form-textarea'
							rows={5}
							{...register('description')}
						/>
					),
				},
				{
					identifier: 'Наименование кейса:',
					content: (
						<div className='team-editing__select-case'>
							{team.topic?.title}
							<Button styling={'text'} action='link' to='../select-case'>
								изменить кейс
							</Button>
						</div>
					),
				},
				{
					identifier: 'Представитель кейса:',
					content: team?.supervisor
						? [
								team.supervisor.fio.split(' ')[0],
								team.supervisor.fio.split(' ')[1],
								team.supervisor.fio.split(' ')[2],
						  ].join(' ')
						: 'Имя отсутствует',
				},
			]}
		/>
	)
}

export default TeamCommonInformationEditing
