import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Response from "../../@types/entities/Response";
import QuestionnaireCreateDTO from "../../@types/dtos/QuestionnaireCreateDTO";
import { PERSONAL_ACCOUNT_MICROSERVICE } from "../../utils/team";

export const api = createApi({
    reducerPath: "questionnareApi",
    baseQuery: fetchBaseQuery({
        baseUrl: "/",
        headers: {
            Authorization:
                "Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJSQ0J2U3VyNFhWYS1EXzhTSHNNemw1T3o1MENqRkE3VnlqQnRqNUc0T1dZIn0.eyJleHAiOjE2OTA0NzU3OTMsImlhdCI6MTY5MDQ3NTQ5MywianRpIjoiMmZhNDViNmItODQ1Ny00ZTNiLTlhZDgtM2Y0OTcxNGZhMzQwIiwiaXNzIjoiaHR0cHM6Ly8xNzk1Mzk3LWNmMDA4ODMudHdjMS5uZXQvcmVhbG1zL2RwcC1wbGF0Zm9ybSIsImF1ZCI6ImFjY291bnQiLCJzdWIiOiIzNTMxMDcyMS03MGNhLTQ5OWEtYTU5Yy0wMDQzOWExODVjMTciLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJzcHJpbmctY2xpZW50Iiwic2Vzc2lvbl9zdGF0ZSI6IjFkNDI0ODQyLTJlNjUtNGNlYi04ZWFmLWFkY2VmNDg3M2FiOSIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsic3R1ZGVudCIsIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iLCJkZWZhdWx0LXJvbGVzLWRwcC1wbGF0Zm9ybSIsImNsaWVudC1zdHVkZW50Il19LCJyZXNvdXJjZV9hY2Nlc3MiOnsic3ByaW5nLWNsaWVudCI6eyJyb2xlcyI6WyJjbGllbnQtc3R1ZGVudCJdfSwiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJwcm9maWxlIGVtYWlsIiwic2lkIjoiMWQ0MjQ4NDItMmU2NS00Y2ViLThlYWYtYWRjZWY0ODczYWI5IiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJpaXZhbm92IiwiZ2l2ZW5fbmFtZSI6IiIsImZhbWlseV9uYW1lIjoiIn0.b7Zzyo7MmnwCSHwduHTskIM8P0Zw-zVRj6AyVYj83-VkWnil4q3r-0hBgIVlXzmZqwJH0NSnzuVaBSf1LIy2LOmvLLWRLfpM6-imHdCflCHpS2w-z96XxaSwPEPAKXIEdtmgVgLc3hIdMGQB9aaVxlpTp9OJwsOl8F2sYs1dA7OaL_Ajjk8jtPzm6sPRd4PXkkzyDDtLgJiNYsrtqOTB87_jJzNVVPo_f2SwK9KJrFF0QqdBoHSVFa7zBTebkEMs2FOydtBI00FWCyIQgqCmKAfANVoGnGg64fPiAmNjyHGhfcxSYvG29skq2ec__WLnbTEMi7S7c2hF-rzcA2CLXA",
        },
    }),
    endpoints: (build) => ({
        checkIsFirstLogin: build.query<Response<Boolean>, void>({
            query: () => ({
                url: `${PERSONAL_ACCOUNT_MICROSERVICE}/api/v1/personal_account`,
            }),
        }),
        fillQuestionnare: build.mutation<
            Response<String>,
            QuestionnaireCreateDTO
        >({
            query: (post) => ({
                url: `http://194.67.105.79:8083/api/v1/questionnare/`,
                method: "POST",
                body: post,
            }),
        }),
    }),
});
