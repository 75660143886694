import { useSelector } from 'react-redux'
import { UserRole } from '../../../../../@types/entities/UserRole'
import { RootState } from '../../../../../redux/store'
import Button from '../../../common/Button/Button'
import ButtonWrapper from '../../../common/Button/ButtonWrapper'
import ContainerMain from '../../../common/ContainerMain/ContainerMain'
import ContentCard from '../../../common/ContentCard/ContentCard'
import ContentCardFooter from '../../../common/ContentCard/ContentCardFooter'
import ContentCardHeading from '../../../common/ContentCard/ContentCardHeading'
import ContentCardInner from '../../../common/ContentCard/ContentCardInner'
import ContentCardWrapper from '../../../common/ContentCard/ContentCardWrapper'
import AllAdminsTable from './AllAdminsTable'

const AllUsers = () => {
	const { profile } = useSelector((state: RootState) => state.personalAccount)
	return (
		<ContainerMain>
			<ContentCardWrapper>
				<ContentCard low>
					<div className='text-mobile'>
						Для облегчения поиска мы решили сделать список рекомендаций, в
						котором каждый из вас сможет найти себе команду в зависимости от
						вашей предпочтительной роли, указанной в личном кабинете. Вы также
						можете воспользоваться расширенным поиском, который поможет найти
						любого пользователя и команду по названию или фильтрам
					</div>
					<div className='desktop-mobile'>
						Для облегчения поиска мы решили сделать список рекомендаций, в
						котором каждый из вас сможет найти себе команду в зависимости от
						вашей предпочтительной роли, указанной в личном кабинете. Вы также
						можете воспользоваться расширенным поиском, который поможет найти
						любого пользователя и команду по названию или фильтрам
					</div>
				</ContentCard>
				<ContentCard>
					<ContentCardHeading topic='Список администраторов'></ContentCardHeading>
					<ContentCardInner>
						<div className='table-wrapper'>
							<AllAdminsTable />
						</div>
					</ContentCardInner>
					<ContentCardFooter>
						{profile?.Mydata?.role !== UserRole.CUSTOMER ? (
							<ButtonWrapper>
								<Button action={'link'} to='/admin/users'>
									Студенты
								</Button>
								<Button action={'link'} to='/admin/companies'>
									Партнеры
								</Button>
								<Button action={'link'} to='/admin/admins'>
									Админы
								</Button>
							</ButtonWrapper>
						) : null}
					</ContentCardFooter>
				</ContentCard>
			</ContentCardWrapper>
		</ContainerMain>
	)
}

export default AllUsers
