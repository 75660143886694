import React from "react";

const GlobalFilter = ({ filter = "", setFilter }: { filter: string; setFilter: any }) => {
	return (
		<div className="table__global-filter-input">
			<label>
				<div className="table-flex-block">
					<div className="search-table-teams">Поиск:</div>
					<input
						type="text"
						value={filter}
						onChange={(e) => {
							setFilter(e.target.value);
						}}
					/>
				</div>
			</label>
		</div>
	);
};

export default GlobalFilter;
