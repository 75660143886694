import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	setUserAnotherSkills,
	setUserInfo,
	toggleUserSkills,
} from '../../../../redux/slices/userQuestionnaireSlice'
import { RootState } from '../../../../redux/store'
import Button from '../../common/Button/Button'
import ButtonWrapper from '../../common/Button/ButtonWrapper'
import FormMultipleDropdownItem from '../../common/Form/FormMultipleDropdownItem'
import FormNavigateButtons from '../../common/Form/FormNavigateButtons'

const AnotherSkillsWrapper = () => {
	const dispatch = useDispatch()

	const [anotherSkillsString, setAnotherSkillsString] = useState<string>('')

	const { user, skills, allSkills, anotherSkills } = useSelector(
		(state: RootState) => state.userQuestionnaire
	)

	const handleOnClick = ({ target }: any) => {
		dispatch(toggleUserSkills({ skill: target?.value }))
	}

	const toggleDropdown = () => {
		document
			.querySelector('.input-multiple-dropdown-list')
			?.classList.toggle('input-multiple-dropdown-list--opened')
	}

	const getSkillsList = (skillsString: string) =>
		skillsString
			.trim()
			.split(',')
			.map((skill: string) => skill.trim())

	const handleAnotherSkillsEditing = () => {
		dispatch(
			setUserAnotherSkills({
				anotherSkills: getSkillsList(anotherSkillsString),
			})
		)
	}

	const makeSkillsString = () => {
		const newSkills = [...skills, ...anotherSkills]
		dispatch(setUserInfo({ name: 'skills', value: newSkills.join(';') }))
	}

	const canGoNext = () => {
		return skills.length !== 0 || anotherSkills.length !== 0
	}

	return (
		<>
			{/* Тут надо навести порядок, я надеюсь, что успею, если нет прошу нижайше прощения */}
			<div className='input-multiple-dropdown-wrapper'>
				<div className='input-multiple-dropdown-field-wrapper'>
					<ul className='input-multiple-dropdown-list' onClick={toggleDropdown}>
						<div className='input-multiple-dropdown-field'>
							<span className='input-multiple-dropdown-field__placeholder'>
								Выберите Навык
							</span>
							<span className='input-multiple-dropdown-field__arrow'>
								{' '}
								&#8250;{' '}
							</span>
						</div>
						<div className='input-multiple-dropdown-list-wrapper'>
							{allSkills.map(skill => {
								if (!skills.includes(skill))
									return (
										<data
											key={skill}
											className='input-multiple-dropdown-list__item'
											value={skill}
											onClick={handleOnClick}
										>
											{skill}
										</data>
									)
							})}
						</div>
					</ul>
				</div>
				<div className='input-multiple-dropdown-selected-container'>
					{skills.map(skill => {
						return (
							<FormMultipleDropdownItem
								key={skill}
								onClick={handleOnClick}
								value={skill}
							/>
						)
					})}
				</div>
			</div>
			<textarea
				className='another-skills-input'
				rows={6}
				placeholder='Другой навык 1, Другой навык 2 '
				name='anotherSkills'
				value={anotherSkillsString}
				onChange={event => setAnotherSkillsString(event.target.value)}
				onBlur={handleAnotherSkillsEditing}
			></textarea>
			<FormNavigateButtons>
				<ButtonWrapper>
					<Button action={'link'} to='../skills'>
						Назад
					</Button>

					<Button
						action={'link'}
						to='../survey'
						disabled={!canGoNext()}
						onClick={makeSkillsString}
					>
						Далее
					</Button>
				</ButtonWrapper>
			</FormNavigateButtons>
		</>
	)
}

export default AnotherSkillsWrapper
