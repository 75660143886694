import { Link } from 'react-router-dom'

// import { COLUMNS } from "./allStudentColumns";
import axios from 'axios'
import { useEffect, useState } from 'react'
import { MODERATION_MICROSERVICE_URL } from '../../../../../utils/team'
import { getTokens } from '../../../../../utils/token'
import Table from '../../../common/Tables/TableFilterAndPagination'

const AllAdminsTable = () => {
	const [data, setData] = useState([])
	const [isVisible, setVisibility] = useState(false)
	const [recipientId, setId] = useState<number>()
	const [inviteRoleId, setRoleId] = useState<number>()

	const adminRoles: { PROGRAM_CURATOR: string; MAIN_ADMIN: string } = {
		PROGRAM_CURATOR: 'Руководитель программы',
		MAIN_ADMIN: 'Главный администратор',
	}

	useEffect(() => {
		const getData = async () => {
			const tokens = getTokens()
			await axios
				.get(`${MODERATION_MICROSERVICE_URL}/api/v1/admin/users/admins/`, {
					headers: {
						Authorization: `Bearer ${tokens.access_token}`,
					},
				})
				.then(result => {
					console.log(result)
					setData(result.data.data)
				})
				.catch(error => {
					console.error(error)
				})
		}
		getData()
	}, [])

	const COLUMNS = [
		{
			Header: '№',
			Cell: ({ row }: { row: any }) => <span>{row.index + 1}</span>,
		},
		{
			Header: 'ФИО пользователя',
			Cell: ({ row }: { row: any }) => <>{row.original.fio}</>,
		},
		{
			Header: 'Должность',
			Cell: ({ row }: { row: any }) => {
				// @ts-ignore
				const userRole = adminRoles[row.original.role]
				return <>{userRole || ''}</>
			},
		},
		{
			Header: 'ВК',
			accessor: 'telegram_link',
		},
		{
			Header: 'Профиль',
			accessor: 'username',
			Cell: ({ value }: { value: any }) => (
				<Link className='profile-link' to={`/profile/${value}/data`}>
					Перейти в профиль
				</Link>
			),
		},
	]

	return (
		<>
			{data.length === 0 ? (
				'Loading...'
			) : (
				<>
					{' '}
					<Table
						columns={COLUMNS}
						data={data}
						itemsOnPage={10}
						visiblePaginationRange={2}
						className='all-user-table'
					/>
				</>
			)}
		</>
	)
}

export default AllAdminsTable
