import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RoleInProject } from '../../../@types/entities/RoleInProject'
import {
	deleteUserFromSquad,
	setSquadMemberRole,
} from '../../../redux/slices/myTeamSlice'
import { RootState } from '../../../redux/store'
import {
	fetchRolesList,
	sendDeleteUserFromTeam,
	sendSquadMemberRoleChange,
	updateSquadList,
} from '../../../utils/team'
import Button from '../common/Button/Button'
import ButtonWrapper from '../common/Button/ButtonWrapper'
import ContentCardFooter from '../common/ContentCard/ContentCardFooter'
import ContentCardInner from '../common/ContentCard/ContentCardInner'
import LoadingText from '../common/Loaders/LoadingText'
import ModalWindow from '../common/ModalWindow/ModalWindow'
import SeparateLine from '../common/SeparateLine/SeparateLine'
import UserListItem from '../common/UserList/UserListItem'
import { getRoleDescription } from '../common/Form/FormInputRadio'

const SquadAboutEditing = () => {
	const dispatch = useDispatch()
	const { squad, team } = useSelector((state: RootState) => state.myTeam)
	const [roleEditing, setRoleEditing] = useState<{
		userId: number
		roleId: number
	} | null>(null)
	const [rolesInProjectList, setRolesInProjectList] = useState<RoleInProject[]>(
		[]
	)
	const [modalWindowContent, setModalWindowContent] =
		useState<React.ReactNode>(null)

	useEffect(() => {
		;(async () => {
			try {
				await updateSquadList()
				const roles = await fetchRolesList()
				setRolesInProjectList(roles)
			} catch (err) {
				console.error(err)
			}
		})()
	}, [])

	const deleteMemberFromSquad = (userId: number) => {
		sendDeleteUserFromTeam(team.id, userId).catch(console.error)
		dispatch(deleteUserFromSquad({ userId: userId }))
	}

	const onEditStart = (userId: number) => {
		const user = squad.find(user => user.member.id === userId)
		if (user) {
			setRoleEditing({
				userId: userId,
				roleId: user.role?.id || 2,
			})
		}
	}

	const onEditEnd = () => {
		const role = rolesInProjectList.find(
			role => role.id === roleEditing?.roleId
		)
		const userId = roleEditing?.userId
		if (role && userId) {
			dispatch(
				setSquadMemberRole({
					userId,
					role,
				})
			)
			sendSquadMemberRoleChange(team.id, userId, role.id)
		}
		setRoleEditing(null)
	}

	const teamLead = squad.find(user => {
		return user?.role?.title === 'Team Lead'
	})!

	const confirmDeletingMember = (userId: number) => (
		<>
			<p>Вы уверены, что хотите удалить пользователя из команды?</p>
			<ContentCardFooter>
				<ButtonWrapper>
					<Button
						onClick={() => {
							deleteMemberFromSquad(userId)
							setModalWindowContent(null)
						}}
					>
						Да
					</Button>
					<Button onClick={() => setModalWindowContent(null)}>
						Нет, я передумал
					</Button>
				</ButtonWrapper>
			</ContentCardFooter>
		</>
	)

	const editingUserList = squad
		.filter(user => user?.role?.id !== 1)
		.map((user, i) => (
			<UserListItem key={i} user={user}>
				<div className='select-member-role__wrapper'>
					{roleEditing?.userId === user.member.id ? (
						<>
							<select
								name='select-member-role'
								id='select-member-role'
								value={roleEditing.roleId}
								onChange={(e: any) => {
									setRoleEditing({
										userId: user.member.id,
										roleId: Number(e.target.value),
									})
								}}
							>
								{rolesInProjectList.map(
									role =>
										role.title !== 'Team Lead' && (
											<option value={role.id} key={role.id} title={getRoleDescription(role.title)}>
												{role.title}
											</option>
										)
								)}
							</select>
							<Button
								styling='text'
								onClick={() => {
									onEditEnd()
								}}
							>
								сохранить
							</Button>
						</>
					) : (
						<>
							<span>{user.role?.title || 'Роль не выбрана'}</span>
							<Button
								styling='text'
								onClick={() => {
									onEditStart(user.member.id)
								}}
							>
								изменить роль
							</Button>
						</>
					)}
				</div>

				<Button
					styling={'text'}
					onClick={() => {
						setModalWindowContent(confirmDeletingMember(user.member.id))
					}}
				>
					исключить
				</Button>
			</UserListItem>
		))

	return (
		<>
			<ModalWindow visibility={!!modalWindowContent}>
				{modalWindowContent}
			</ModalWindow>
			<ContentCardInner>
				{squad.length === 0 ? (
					<LoadingText />
				) : (
					<div className='user-list'>
						<UserListItem user={teamLead!} highlighted>
							<div className='teamlead-change__wrapper'>
								<strong>{teamLead?.role?.title}</strong>
								<Button
									styling='text'
									action='link'
									to='../select-new-teamlead'
								>
									переназначить
								</Button>
							</div>
						</UserListItem>
						{!squad.find(user => user.isDeputy) && (
							<ButtonWrapper center>
								<Button
									styling={'text'}
									action={'link'}
									to='../select-deputy-teamlead'
								>
									Выбрать заместителя Team Lead
								</Button>
							</ButtonWrapper>
						)}
						<SeparateLine />

						{editingUserList}
					</div>
				)}
			</ContentCardInner>
		</>
	)
}

export default SquadAboutEditing
