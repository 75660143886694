import placeholderImg from '../../../../assets/img/profile-img-placeholder.png'

interface ProfileImageProps {
	imageURL?: string
	size?: 'small' | 'medium' | 'large'
}

const ProfileImage = ({
	imageURL = '',
	size = 'medium',
}: ProfileImageProps) => {
	console.log(imageURL)
	return (
		<div className='profile-image-wrapper'>
			<img
				src={Boolean(imageURL) ? imageURL : placeholderImg}
				alt='Profile Photo.'
				className={'profile-image' + ` profile-image--${size}`}
			/>
		</div>
	)
}

export default ProfileImage
