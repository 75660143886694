import React, { useEffect, useState } from 'react'
import ContentCard from '../../../common/ContentCard/ContentCard'
import ContentCardWrapper from '../../../common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../../common/ContentMainTheme'

import jwt_decode from 'jwt-decode'
import { useNavigate, useParams } from 'react-router-dom'
import ErrorHandler from '../../../../../utils/ErrorHandler'
import {
	PERSONAL_ACCOUNT_MICROSERVICE,
	sendAxiosWithTokens,
} from '../../../../../utils/team'
import { getTokens, isTokenInLS } from '../../../../../utils/token'
import AboutMe from './AboutMe'
import AboutMeEditing from './AboutMeEditing'
import Role from './Role'
import RoleEditing from './RoleEditing'

type About = {
	isEasyGoing?: string
	isSolid?: string
	behaviourInTeam?: string
	doesLikeSurprises?: string
	fears?: string
	doesLikePlanning?: string
	isCreative?: string
}
type SkillsRole = {
	preferedRole: {
		title: string
	}
	skills: string
}

type SkillsType = About & SkillsRole
const ViewedUserSkills = () => {
	const [skills, setSkills] = useState<SkillsType>({} as SkillsType)
	const [isLoading, setIsLoading] = useState(true)
	const navigate = useNavigate()
	const { username } = useParams()
	const [isRoleEditing, setIsRoleEditing] = React.useState(false)
	const [isAboutMeEditing, setIsAboutMeEditing] = React.useState(false)

	const AboutMePropsEdit = {
		status: isAboutMeEditing,
		toggle: () => setIsAboutMeEditing(!isAboutMeEditing),
		content: <AboutMeEditing About={skills} />,
	}
	const SkillsProps = {
		status: isRoleEditing,
		toggle: () => setIsRoleEditing(!isRoleEditing),
		content: <RoleEditing SkillsRole={skills} />,
	}

	let admin: boolean = false
	let student: boolean = false
	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		const isAdmin = roles.indexOf('client-main-admin') > -1
		if (isAdmin) admin = isAdmin
		const isStudent = roles.indexOf('client-student') > -1
		if (isStudent) student = isStudent
	}
	const getSkills = async () => {
		await sendAxiosWithTokens(
			'get',
			`${PERSONAL_ACCOUNT_MICROSERVICE}/api/v1/personal_account/getAboutAndSkillsInfo/${username}`
		)
			.then(r => {
				setIsLoading(false)
				setSkills(r.data.data)
			})
			.catch(error => {
				ErrorHandler(error, navigate)
			})
	}

	useEffect(() => {
		getSkills()
	}, [])
	return (
		<div className='personal-account-skills'>
			<ContentMainTheme>Навыки</ContentMainTheme>
			<ContentCardWrapper>
				<ContentCard topic='Роль и навыки'>
					<Role role={skills} />
				</ContentCard>

				<ContentCard topic='О себе'>
					<AboutMe about={skills} />
				</ContentCard>
			</ContentCardWrapper>
		</div>
	)
}
export default ViewedUserSkills
