import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import GetUserMainInformationDTO from '../../../../@types/dtos/GetUserMainInformationDTO'
import ErrorHandler from '../../../../utils/ErrorHandler'
import {
	PERSONAL_ACCOUNT_MICROSERVICE,
	sendAxiosWithTokens,
} from '../../../../utils/team'
import ContentCardWrapper from '../../common/ContentCard/ContentCardWrapper'
import { AdditionalInfo } from './AdditionalInfo'
import MainInfo from './MainInfo'

const UserProfile = () => {
	const { username } = useParams()
	const [visibility, setVisibility] = useState(false)
	const navigate = useNavigate()
	const [userData, setUserData] = useState<GetUserMainInformationDTO>()

	const saveData = async () => {
		await sendAxiosWithTokens(
			'get',
			`${PERSONAL_ACCOUNT_MICROSERVICE}/api/v1/personal_account/${username}`
		)
			.then(r => {
				const updateData: GetUserMainInformationDTO = {
					...r.data.data,
				}
				const date = updateData.birthDate
				const Rusdate = new Date(date)

				const day = String(Rusdate.getDate()).padStart(2, '0')
				const month = String(Rusdate.getMonth() + 1).padStart(2, '0')
				const year = Rusdate.getFullYear()

				updateData.birthDate = `${day}.${month}.${year}`
				setUserData(updateData)
				console.log(',fsdfds', r.data.data)
			})
			.catch(error => {
				ErrorHandler(error, navigate)
			})
	}
	useEffect(() => {
		saveData()
	}, [])

	return (
		<ContentCardWrapper>
			<MainInfo userData={userData} />
			<AdditionalInfo userData={userData} />
		</ContentCardWrapper>
	)
}

export default UserProfile
