import React from "react";
import { Link } from "react-router-dom";
import button from "./button.module.scss";

interface ButtonProps {
  label?: string;
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  spacing?: "none" | "4" | "8" | "12" | "16";
  action?: "formButton" | "toCopy" | "link" | "none";
  type?: "button" | "submit" | "reset" | undefined;
  onClick?: Function;
  value?: string;
  to?: string;
  target?: string;
  copyText?: string;
  copyId?: string;
  styling?: "classic" | "text";
}

const Button = ({
  children = "—",
  className = "",
  disabled,
  action = "none",
  type = "button",
  to = "",
  copyText = "",
  copyId,
  onClick,
  value = "",
  spacing = "none",
  styling = "classic",
}: ButtonProps) => {
  let resultBtn;

  const buttonProps: any = {
    className: [button[styling], disabled && button["disabled"]].join(" "),
    disabled,
    children,
    value,
    type: "button",
  };

  const copyToCipboard = window.navigator.clipboard
    ? () => {
        window.navigator.clipboard
          .writeText(copyText)
          .then(() => {
            if (onClick) onClick();
          })
          .catch((err) => {
            console.log("Something went wrong", err);
          });
      }
    : () => {
        let copyTextElement = document.createElement("input");
        copyTextElement.value = copyText;
        document.body.appendChild(copyTextElement);
        copyTextElement.select();
        document.execCommand("copy");
        document.body.removeChild(copyTextElement);
        //  }
        // 	// Выборка ссылки с электронной почтой
        // 	let elementToCopy = copyId ? document.querySelector(copyId) : null;
        // 	let range = document.createRange();
        // 	if (elementToCopy) range.selectNode(elementToCopy);
        // 	window.getSelection()?.addRange(range);

        // 	try {
        // 		// Теперь, когда мы выбрали текст ссылки, выполним команду копирования
        // 		let successful = document.execCommand("copy");
        // 		let msg = successful ? "successful" : "unsuccessful";
        console.log("Copy email command was successful");
        // 	} catch (err) {
        // 		console.log("Oops, unable to copy");
        // 	}

        // 	// Снятие выделения - ВНИМАНИЕ: вы должны использовать
        // 	// removeRange(range) когда это возможно
        // 	window.getSelection()?.removeRange(range);
      };

  if (onClick) buttonProps["onClick"] = onClick;

  if (action === "toCopy") buttonProps["onClick"] = copyToCipboard;

  if (action === "formButton") buttonProps["type"] = type;

  if (action === "link" && !disabled) {
    resultBtn = (
      <Link to={to} className={button["spacing--" + spacing] + ` ${className}`}>
        <button {...buttonProps} />
      </Link>
    );
  } else {
    buttonProps["className"] +=
      ` ${button["spacing--" + spacing]}` + ` ${className}`;
    resultBtn = <button {...buttonProps} />;
  }

  return resultBtn;
};

export default Button;
