import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { APPLICATION_MICROSERVICE_URL } from '../../../utils/team'
import { getTokens } from '../../../utils/token'
import Button from '../common/Button/Button'
import ContainerMain from '../common/ContainerMain/ContainerMain'
import ContentCard from '../common/ContentCard/ContentCard'
import ContentCardFooter from '../common/ContentCard/ContentCardFooter'
import ContentCardHeading from '../common/ContentCard/ContentCardHeading'
import ContentCardInner from '../common/ContentCard/ContentCardInner'
import ContentMainTheme from '../common/ContentMainTheme'

const Disclaimer = () => {
	const navigate = useNavigate()
	const teamCreateRequest = async () => {
		const tokens = getTokens()
		try {
			let res = await axios.post(
				`${APPLICATION_MICROSERVICE_URL}/api/v1/requestsToAdmin/createTeamLeadRequest`,
				{},
				{
					headers: {
						Authorization: `Bearer ${tokens.access_token}`,
					},
				}
			)
			navigate('/PersonalAccount/MyData')
		} catch (err) {
			console.error(err)
			navigate('/AnyError')
		}
	}

	return (
		<ContainerMain>
			<ContentMainTheme>Моя команда</ContentMainTheme>
			<ContentCard>
				<ContentCardHeading></ContentCardHeading>
				<ContentCardInner>
					<p>
						ВНИМАНИЕ! Создавая команду, вы берёте на себя ответственность за
						неё. Team Lead - это важная роль, относитесь к ней с полной
						серьёзностью. При желании вы можете поменяться ролями с кем-нибудь
						из участников команды, если вы не уверены в своих силах.{' '}
					</p>
				</ContentCardInner>
				<ContentCardFooter>
					<div className='button-block'>
						<Button action='link' to='../../'>
							Назад
						</Button>

						<Button onClick={teamCreateRequest}>Стать тимлидом</Button>
					</div>
				</ContentCardFooter>
			</ContentCard>
		</ContainerMain>
	)
}

export default Disclaimer
