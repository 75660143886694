import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TopicDTO } from '../../@types/createTopic'
import Button from '../../components/common/Button/Button'

import ButtonWrapper from '../../components/common/Button/ButtonWrapper'
import DoubleList from '../../components/common/DoubleList/DoubleList'
import { setCaseList } from '../../redux/slices/caseSlice'
import {
	MODERATION_MICROSERVICE_URL,
	sendAxiosWithTokens,
} from '../../utils/team'
import Logo from './Logo'

interface props {
	CaseItem: TopicDTO
	editing?: boolean
	role: boolean
	topicStatus: string
}

const CaseFrame = ({ CaseItem, editing, role, topicStatus }: props) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [toggle, setToggle] = useState<boolean>(false)

	const handleButtonClick = () => {
		navigate(`Info/${CaseItem.id}`, { state: { editing } })
	}
	const softDeleteOrShowing = async () => {
		const currentUrl = {
			HIDDEN: `/api/v1/admin/topic/showTopic/${CaseItem.id}`,
			DISPLAYED: `/api/v1/admin/topic/hideTopic/${CaseItem.id}`,
		} as Record<string, string>

		await sendAxiosWithTokens(
			`put`,
			`${MODERATION_MICROSERVICE_URL}${currentUrl[topicStatus]}`
		)
			.then(r => console.log(r.data.data))
			.catch(e => console.log(e))

		const body = `{
      "topicStatus": "${topicStatus}",
      "page": 1,
      "pageSize": 8
    }`
		const obj = JSON.parse(body)
		const url = `${MODERATION_MICROSERVICE_URL}/api/v1/admin/topic/getTopics`
		await sendAxiosWithTokens(
			`post`,
			`${MODERATION_MICROSERVICE_URL}/api/v1/admin/topic/filterTopics`,
			obj
		)
			.then(r => {
				dispatch(setCaseList(r.data.data?.content))
			})
			.catch(e => console.log(e))
	}

	return (
		<>
			<div className='infonum2'>
				<div className='blocks'>
					<div className='case-content-card'>
						<Logo></Logo>
					</div>
					<div className='blocks-1st'>
						<DoubleList
							nonUnderLine={true}
							className='initials'
							listData={[
								{
									identifier: 'Компания:',
									content: CaseItem.company?.title,
								},
								{
									identifier: 'Название кейса:',
									content: CaseItem.title,
								},
								{
									identifier: role ? 'Программы(а)' : null,
									content: role
										? CaseItem.programs?.map((item, i) => {
												return <p key={i}>-{item.title}</p>
										  })
										: null,
								},
							]}
						/>
					</div>
					<div className='blocks-2nd'>
						<DoubleList
							nonUnderLine={true}
							className='initials'
							listData={[
								{
									identifier: 'Куратор кейса:',
									content: CaseItem.delegate?.owner?.fio || 'куратор кейса',
								},
								{
									identifier: 'Заказчик кейса:',
									content: CaseItem.customer?.owner?.fio || 'заказчик кейса',
								},
							]}
						/>
					</div>
				</div>
			</div>

			<ButtonWrapper>
				<Button styling='text' onClick={handleButtonClick}>
					{editing ? 'Внести изменения' : 'Читать подробнее'}
				</Button>
				{role && (
					<Button styling='text' onClick={softDeleteOrShowing}>
						{topicStatus != 'All'
							? topicStatus === 'HIDDEN'
								? 'Показать кейс'
								: 'Скрыть кейс'
							: ''}
					</Button>
				)}
			</ButtonWrapper>
		</>
	)
}

export default CaseFrame
