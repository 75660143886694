import { ContentCardEditingProp } from "./ContentCard";
import ContentCardEditButton from "./ContentCardEditButton";
import ContentCardSubtopic from "./ContentCardSubtopic";
import ContentCardTopic from "./ContentCardTopic";
import styles from "./content-card.module.scss";

interface ContentCardHeadingProps {
	topic?: React.ReactNode;
	subtopic?: string;
	children?: React.ReactNode;
	editing?: ContentCardEditingProp;
}

const ContentCardHeading = ({
	topic = "",
	subtopic = "",
	children,
	editing,
}: ContentCardHeadingProps) => {
	return (
		<>
			{(topic || children) && (
				<div className={styles["heading"]}>
					{topic && <ContentCardTopic>{topic}</ContentCardTopic>}
					{children}
					{editing && !editing?.status && (
						<ContentCardEditButton toggle={editing?.toggle} />
					)}
					{editing?.status && <span>Редактирование</span>}
				</div>
			)}

			{subtopic && <ContentCardSubtopic>{subtopic}</ContentCardSubtopic>}
		</>
	);
};

export default ContentCardHeading;
