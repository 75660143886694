import { createSlice } from "@reduxjs/toolkit";
interface cases {
    AllCases: {
        CaseList: any[];
    };
    ActiveCase: {
        id: number;
        Description: string;
        title: string;
        maxAmountOfTeams: number;
    };
    editing:boolean;
    activeFrame:number;
}

const initialState: cases = {
    AllCases: {
        CaseList: [],
    },
    ActiveCase: {
        id: 1,
        Description: "",
        title: "",
        maxAmountOfTeams: 1,
    },
    editing:false,
    activeFrame:0
};

export const caseSlice = createSlice({
    name: "case",
    initialState,
    reducers: {
        setCaseList: (state, action) => {
            state.AllCases.CaseList = action.payload;
        },
        setActiveCase(state, action) {
            state.ActiveCase = action.payload;
        },
        setEditStatus(state,action) { 
            state.editing = action.payload;
        },
        setActiveFrame(state,action) {
            state.activeFrame = action.payload
        }
    },
});

export const { setCaseList, setActiveCase,setEditStatus ,setActiveFrame} = caseSlice.actions;
export default caseSlice.reducer;
