import React, { useEffect, useState } from 'react'

// import "bootstrap/dist/css/bootstrap.min.css";
import '../src/assets/styles/main.scss'
import Navbar from './hooks/components/Navbar'
import MyData from './hooks/components/PersonalAccount/MyData/MyData'
import Skills from './hooks/components/PersonalAccount/Skills/Skills'
import Sidebar from './hooks/components/Sidebar'
import SignIn from './hooks/components/SignIn'

// === team pages ===
import SelectNewTeamLeadPage from './Pages/Team/SelectNewTeamLeadPage'
import CreateTeamForm from './hooks/components/CreateTeam/CreateTeamForm'
import CreationFinish from './hooks/components/CreateTeam/CreationFinish'
import Disclaimer from './hooks/components/CreateTeam/Disclaimer'
import TeamCreateRequest from './hooks/components/CreateTeam/Request'
import Search from './hooks/components/CreateTeam/Search'
import SelectTeam from './hooks/components/CreateTeam/SelectTeam'
import LayoutTeam from './hooks/components/LayoutTeam'
import AllTeams from './hooks/components/Team/AllTeams'

import NotFoundPage from './hooks/components/NotFoundPage'

import AnotherSkills from './Pages/Questionnaire/User/AnotherSkills'
import FinishQuestionnaire from './Pages/Questionnaire/User/FinishQuestionnaire'
import UserMainInfo from './Pages/Questionnaire/User/MainInfo'
import PreferredRole from './Pages/Questionnaire/User/PreferredRole'
import RelevantSkills from './Pages/Questionnaire/User/RelevantSkills'
import Survey from './Pages/Questionnaire/User/Survey'
import UserForm from './Pages/Questionnaire/User/UserForm'
import Questionnaire from './hooks/components/Questionnaire/Questionnaire'

import AuthorisationPage from './Pages/Authorisation/AuthorisationPage'
import Redirect from './Pages/Authorisation/Redirect'
import Caseoutlet from './Pages/Case/Caseoutlet'
import Help from './Pages/Help/Help'
import Team from './Pages/Team/Team'
import Examples from './hooks/components/common/Button/Examples'
import NotificationsAll from './hooks/components/notifications&applications/All'
import NotifWelcome from './hooks/components/notifications&applications/NotifWelcome'
import NotificationsRead from './hooks/components/notifications&applications/Read'
import NotificationsUnread from './hooks/components/notifications&applications/Unread'

import Infolet from './Pages/Info/Infolet'

import AskQuestionForm from './Pages/Help/AskQuestionForm'
import PopularQuestion from './Pages/Help/PopularQuestion'
import QuestionHistory from './Pages/Help/QuestionHistory'
import SignOut from './hooks/components/SignOut'
import AllStudents from './hooks/components/Team/AllStudents'
import { isTokenInLS } from './utils/token'

import { useDispatch, useSelector } from 'react-redux'
import { Outlet, Route, Routes, useLocation } from 'react-router-dom'
import CaseCreate from './Pages/Case/CaseCreate'
import Company from './Pages/Company/Company'
import AnswerUser from './Pages/Help/AnswerUser'
import QuestionSet from './Pages/Help/QuestionSet'
import SelectCasePage from './Pages/Team/SelectCasePage'
import SelectDeputyTeamLeadPage from './Pages/Team/SelectDeputyTeamLeadPage'
import TeamPage from './Pages/Team/TeamPage'
import Connection from './Pages/Сonnection/Connection'
import UploadTable from './UploadTable'
import AnyErrorsPage from './hooks/components/AnyErrorsPage/index'
import PersonalAccount from './hooks/components/PersonalAccount/PersonalAccount'
import ViewedUserSkills from './hooks/components/PersonalAccount/UserProfile/Skills/ViewedUserSkills'
import UserProfile from './hooks/components/PersonalAccount/UserProfile/UserProfile'
import UserProfileOutlet from './hooks/components/PersonalAccount/UserProfile/UserProfileOutlet'
import { ExtraSearchStudents } from './hooks/components/Team/ExtraSearchStudents'
import { ExtraSearchTeams } from './hooks/components/Team/ExtraSearchTeams'
import AdminForm from './hooks/components/admin/AdminQuestionnaire/UserForm/AdminForm'
import AdminMainWrapper from './hooks/components/admin/AdminQuestionnaire/UserForm/AdminMainWrapper'
import { AdminCreateTeam } from './hooks/components/admin/createTeam/AdminCreateTeam'
import AllAdmins from './hooks/components/admin/tables/admin/AllAdmins'
import AllCompanies from './hooks/components/admin/tables/companies/AllCompanies'
import AllAdminTeams from './hooks/components/admin/tables/teams/AllAdminTeams'
import AllUsers from './hooks/components/admin/tables/user/AllUsers'
import CustomerContact from './hooks/components/customer/CustomerContact'
import CustomerFinish from './hooks/components/customer/CustomerFinish'
import CustomerForm from './hooks/components/customer/CustomerForm'
import CustomerInfo from './hooks/components/customer/CustomerInfo'
import paths from './paths'
import { setAmountNotifications } from './redux/slices/notificationSlice'
import ProtectedElementForRoute from './utils/ProtectedRoute'
import { useGetApplications } from './utils/useGetApplications'
import { useGetNotifications } from './utils/useGetNotifications'

import InfoAboutCompany from './Pages/Company/AboutCompany'
import CreateCompanyCard from './Pages/Company/CreateCompanyCard'
import { RootState } from './redux/store'

import useSound from 'use-sound'
import CreatePasport from './Pages/Info/FramesCasePasport/CreatePasport/CreatePasport'
import CheckPassportForAdmin from './Pages/Team/passportProject/checkPassportForAdmin/checkPassportForAdmin'
import CreatePassport from './Pages/Team/passportProject/createPassport/createPassport'
import PassportProjectMainBoard from './Pages/Team/passportProject/passportMainBoard'
import CreateCurator from './hooks/components/admin/CreateCurator/CreateCurator'
import PassportList from './hooks/components/admin/PassportList/PassportList'

const App: React.FC = () => {
	// const navigate = useNavigate();
	const dispatch = useDispatch()
	const [isLogin, setIsLogin] = useState<boolean>()

	const GetApplications = useGetApplications()
	const GetNotifications = useGetNotifications()

	const [play] = useSound('/sound/notificationFromTg.mp3')

	const [dataLoaded, setDataLoaded] = useState(false)

	useEffect(() => {
		setIsLogin(isTokenInLS())
		const updateNotifations = async () => {
			await GetApplications()
			await GetNotifications()
			dispatch(setAmountNotifications())
			setDataLoaded(true)
		}

		updateNotifations()

		const interval = setInterval(() => updateNotifations(), 20000)
		return () => clearInterval(interval)
	}, [])

	const { amountUnreadApplication } = useSelector(
		(state: RootState) => state.NotifcationReducer
	)
	const { amountUnreadNotification } = useSelector(
		(state: RootState) => state.NotifcationReducer
	)

	const [prevUnreadNotif, setunreadNotif] = useState<number>(
		amountUnreadNotification
	)
	const [prevUnreadApp, setunreadApp] = useState<number>(
		amountUnreadApplication
	)

	useEffect(() => {
		if (!dataLoaded) return

		if (
			(prevUnreadNotif !== amountUnreadNotification &&
				amountUnreadNotification > prevUnreadNotif) ||
			(prevUnreadApp !== amountUnreadApplication &&
				amountUnreadApplication > prevUnreadApp)
		) {
			play()
		}
		setunreadNotif(amountUnreadNotification)
		setunreadApp(amountUnreadApplication)
	}, [amountUnreadNotification, amountUnreadApplication])

	const location = useLocation()
	// Определение текущего маршрута для скрытия Navbar
	const currentPath = window.location.pathname
	const isOnAuthorizationPage = currentPath === '/' && !isLogin

	const hideNavbarPaths = [
		'/questionnaire/user/main',
		'/questionnaire/user/role',
		'/questionnaire/user/skills',
		'/questionnaire/user/survey',
	]
	const hideSidebarPaths = [
		'/questionnaire/user/main',
		'/questionnaire/user/role',
		'/questionnaire/user/skills',
		'/questionnaire/user/survey',
	]

	const shouldHideNavbar =
		isOnAuthorizationPage || hideNavbarPaths.includes(currentPath)

	return (
		<>
			{!shouldHideNavbar && (
				<div className='header header_shift'>
					<Navbar />
					{!isLogin ? <SignIn /> : <SignOut />}
				</div>
			)}
			{!hideSidebarPaths.includes(location.pathname) && <Sidebar />}

			<Routes>
				<Route path={paths.base} element={<AuthorisationPage />} />
				<Route path='test/authorisation' element={<AuthorisationPage />} />
				<Route path='test/redirect' element={<Redirect />} />
				<Route path='upload' element={<UploadTable />} />
				{/* <<< === Team === >>> */}

				<Route path={paths.team.base} element={<Outlet />}>
					<Route
						index
						element={<ProtectedElementForRoute component={LayoutTeam} />}
					/>
					<Route
						path='/team/admin/passportProject'
						element={<CheckPassportForAdmin />}
					/>
					<Route
						path='/team/passportProject'
						element={<PassportProjectMainBoard />}
					/>
					<Route
						path='/team/passportProject/createPassport'
						element={<CreatePassport />}
					/>
					<Route path={paths.team.select} element={<SelectTeam />} />
					<Route path={paths.team.extraSearch} element={<ExtraSearchTeams />} />
					<Route
						path={paths.team.extraSearchStudents}
						//@ts-ignore
						element={
							<ExtraSearchStudents
								//@ts-ignore
								studentsFromExtendedSearch={[]}
							/>
						}
					/>
					<Route
						path={paths.team.selectSearch}
						element={<AllTeams teamsFromExtendedSearch={[]} />}
					/>

					<Route
						path={paths.team.my}
						element={<ProtectedElementForRoute component={Team} />}
					/>

					<Route
						path={paths.team.teamId}
						element={<ProtectedElementForRoute component={TeamPage} />}
					/>

					<Route
						path={paths.team.selectNewTeamLead}
						element={<SelectNewTeamLeadPage />}
					/>
					<Route
						path={paths.team.selectDeputyTeamLead}
						element={<SelectDeputyTeamLeadPage />}
					/>
					<Route path={paths.team.selectCase} element={<SelectCasePage />} />
					<Route path={paths.team.create.base} element={<Outlet />}>
						<Route
							path={paths.team.create.disclaimer}
							element={<Disclaimer />}
						/>
						<Route
							path={paths.team.create.request}
							element={<TeamCreateRequest />}
						/>
						<Route path={paths.team.create.form} element={<CreateTeamForm />} />
						<Route
							path={paths.team.create.formFinish}
							element={<CreationFinish />}
						/>
					</Route>
					<Route path={paths.team.search} element={<Search />} />
					<Route path={paths.team.studentList} element={<AllStudents />} />
				</Route>

				{/* <<< === Team === >>> */}

				<Route path={paths.questionnaire.base} element={<Questionnaire />}>
					<Route path={paths.questionnaire.user.base} element={<UserForm />}>
						<Route
							path={paths.questionnaire.user.main}
							element={<UserMainInfo />}
						/>
						<Route
							path={paths.questionnaire.user.role}
							element={<PreferredRole />}
						/>
						<Route
							path={paths.questionnaire.user.skills}
							element={<RelevantSkills />}
						/>
						<Route
							path={paths.questionnaire.user.anotherSkills}
							element={<AnotherSkills />}
						/>
						<Route
							path={paths.questionnaire.user.survey}
							element={<Survey />}
						/>
						<Route
							path={paths.questionnaire.user.finish}
							element={<FinishQuestionnaire />}
						/>
					</Route>
				</Route>
				<Route
					path={paths.help.base}
					element={<ProtectedElementForRoute component={Help} />}
				>
					<Route
						path={paths.help.popularQuestion}
						element={<PopularQuestion />}
					/>
					<Route
						path={paths.help.askQuestionForm}
						element={<AskQuestionForm />}
					/>
					<Route
						path={paths.help.questionHistory}
						element={<QuestionHistory />}
					></Route>
					<Route path={paths.help.questionSet} element={<QuestionSet />} />
					<Route path={paths.help.answerUser} element={<AnswerUser />} />
				</Route>
				<Route
					path='/Company'
					element={<ProtectedElementForRoute component={Company} />}
				/>
				<Route path='/InfoAboutCompany' element={<InfoAboutCompany />} />
				<Route path='/CreateCompany' element={<CreateCompanyCard />} />
				<Route path={paths.admin.base} element={<Outlet />}>
					<Route
						path={paths.admin.users}
						element={<AllUsers studentsFromExtendedSearch={[]} />}
					/>
					<Route path={paths.admin.companies} element={<AllCompanies />} />
					<Route path={paths.admin.admins} element={<AllAdmins />} />
					<Route path={paths.admin.teams} element={<AllAdminTeams />} />
					<Route path={paths.admin.createTeam} element={<AdminCreateTeam />} />
					<Route path={paths.admin.passportList} element={<PassportList />} />
				</Route>

				{/* Мой лк */}
				<Route
					path={paths.personalAccount.base}
					element={<ProtectedElementForRoute component={PersonalAccount} />}
				>
					<Route path={paths.personalAccount.myData} element={<MyData />} />
					<Route path={paths.personalAccount.skills} element={<Skills />} />
					{/* <Route path={paths.personalAccount.rating} element={<Raiting />} /> */}
				</Route>

				{/* Лк другого юзера */}
				{/* <Route path=":userId" element={<UserProfile />}></Route> */}
				<Route path={paths.profile.base} element={<UserProfileOutlet />}>
					<Route path={paths.profile.data} element={<UserProfile />} />
					<Route path={paths.profile.skills} element={<ViewedUserSkills />} />
				</Route>

				<Route
					path={paths.notifications.base}
					element={<ProtectedElementForRoute component={NotifWelcome} />}
				>
					<Route
						path={paths.notifications.all}
						element={<NotificationsAll />}
					/>
					<Route
						path={paths.notifications.read}
						element={<NotificationsRead />}
					/>
					<Route
						path={paths.notifications.unread}
						element={<NotificationsUnread />}
					/>
				</Route>
				<Route path={paths.help.base} element={<Help />}></Route>
				<Route
					path='MyTeamTest'
					element={<ProtectedElementForRoute component={Team} />}
				></Route>
				<Route path='/createprojectpassport' element={<CreatePasport />} />
				<Route
					path={paths.case.base}
					element={<ProtectedElementForRoute component={Caseoutlet} />}
				>
					<Route path={paths.case.create} element={<CaseCreate />} />

					<Route path={paths.case.info.base} element={<Infolet />}>
						{/* <Route
              path={paths.case.info.passportProject}
              element={<ProjectPasport />}
            /> */}
						{/* <Route
              path={paths.case.info.projectDescription}
              element={<ProjectDescription />}
            />
            <Route
              path={paths.case.info.exampleProject}
              element={<ExampleProject />}
            /> */}
					</Route>
				</Route>
				<Route path={paths.examples} element={<Examples />} />
				<Route path={paths.adminQuestionnaire.base} element={<AdminForm />}>
					<Route
						path={paths.adminQuestionnaire.info}
						element={<AdminMainWrapper />}
					/>
					<Route
						path={paths.adminQuestionnaire.role}
						element={<PreferredRole />}
					/>
					<Route
						path={paths.adminQuestionnaire.skills}
						element={<RelevantSkills />}
					/>
					<Route
						path={paths.adminQuestionnaire.anotherSkills}
						element={<AnotherSkills />}
					/>
					<Route path={paths.adminQuestionnaire.survey} element={<Survey />} />
					<Route
						path={paths.adminQuestionnaire.finish}
						element={<FinishQuestionnaire />}
					/>
				</Route>

				<Route path={paths.customer.base} element={<CustomerForm />}>
					<Route path={paths.customer.info} element={<CustomerInfo />} />
					<Route path={paths.customer.finish} element={<CustomerFinish />} />
					<Route path={paths.customer.contact} element={<CustomerContact />} />
				</Route>

				<Route path={paths.connection.base} element={<Connection />} />

				<Route path={'*'} element={<NotFoundPage />} />

				<Route path={paths.anyError} element={<AnyErrorsPage />} />
				<Route path={paths.CreateCurator} element={<CreateCurator />} />
			</Routes>
		</>
	)
}
export default App
