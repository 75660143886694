import React, { MouseEventHandler } from "react";

interface FormMultipleDropdownItemProps {
    children?: React.ReactNode;
    value?: string;
    name?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

const FormMultipleDropdownItem = ({ value, onClick }: FormMultipleDropdownItemProps) => {
    return (
        <div className="input-multiple-dropdown-selected-item-wrapper">
            <div className="input-multiple-dropdown-selected-item">{value}</div>

            <button type="button" className="deselect-button" value={value} onClick={onClick}>
                &times;
            </button>
        </div>
    );
};

export default FormMultipleDropdownItem;
