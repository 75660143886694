import { useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import GetUserMainInformationDTO from '../../../../@types/dtos/GetUserMainInformationDTO'
import { UserRole } from '../../../../@types/entities/UserRole'
import ErrorHandler from '../../../../utils/ErrorHandler'
import {
	MODERATION_MICROSERVICE_URL,
	PERSONAL_ACCOUNT_MICROSERVICE,
	sendAxiosWithTokens,
} from '../../../../utils/team'
import Button from '../../common/Button/Button'
import ContainerMain from '../../common/ContainerMain/ContainerMain'
import ContentCardWrapper from '../../common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../common/ContentMainTheme'
import LoadingText from '../../common/Loaders/LoadingText'
import ViewedUserRightSidebar from './ViewedUserRightSidebar'

const UserProfileOutlet = () => {
	const { username } = useParams()
	const [visibility, setVisibility] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [userData, setUserData] = useState<GetUserMainInformationDTO>()
	const navigate = useNavigate()
	const deleteUser = () => {
		;(async () => {
			await sendAxiosWithTokens(
				'delete',
				`${MODERATION_MICROSERVICE_URL}/api/v1/admin/users/${username}`
			)
		})()
	}

	const saveData = async () => {
		await sendAxiosWithTokens(
			'get',
			`${PERSONAL_ACCOUNT_MICROSERVICE}/api/v1/personal_account/${username}`
		)
			.then(r => {
				const updateData: GetUserMainInformationDTO = {
					...r.data.data,
				}
				const date = updateData.birthDate
				const Rusdate = new Date(date)

				const day = String(Rusdate.getDate()).padStart(2, '0')
				const month = String(Rusdate.getMonth() + 1).padStart(2, '0')
				const year = Rusdate.getFullYear()

				updateData.birthDate = `${day}.${month}.${year}`
				setUserData(updateData)
				setIsLoading(false)
			})
			.catch(error => {
				ErrorHandler(error, navigate)
			})
	}

	useEffect(() => {
		saveData()
	}, [])
	return (
		<>
			{isLoading ? (
				<LoadingText />
			) : (
				<ContainerMain relative size='small'>
					<ViewedUserRightSidebar
						setvisibility={setVisibility}
						username={username}
						userData={userData}
					/>
					<ContentCardWrapper>
						<ContentMainTheme>
							Данные пользователя{' '}
							{(userData?.role == UserRole.CUSTOMER ||
								userData?.role == UserRole.CURATOR) ?? (
								<Button type={'button'} onClick={deleteUser} styling={'text'}>
									Удалить аккаунт
								</Button>
							)}
						</ContentMainTheme>
						<Outlet />
					</ContentCardWrapper>
				</ContainerMain>
			)}
		</>
	)
}

export default UserProfileOutlet
