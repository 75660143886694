import styles from "./content-card.module.scss";
import React from "react";

interface ContentCardTopicProps {
  children?: React.ReactNode;
}
const ContentCardTopic: React.FC<ContentCardTopicProps> = ({ children }) => {
  return <h2 className={styles["heading__topic"]}>{children}</h2>;
};

export default ContentCardTopic;
