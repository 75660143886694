import DoubleList from "../../common/DoubleList/DoubleList";

function parseAdditionals(About) {
    return [
        {
            identifier: "Легко ли вам общаться с людьми?",
            content: About?.isEasyGoing,
        },
        {
            identifier: "Вы предпочитаете работать в команде или в одиночку?",
            content: About?.isSolid,
        },
        {
            identifier: "Как вы ведете себя при работе в команде?",
            content: About?.behaviourInTeam,
        },
        {
            identifier: "Вам нравятся сюрпризы?",
            content: About?.doesLikeSurprises,
        },
        {
            identifier: "Что вас может напугать/оттолкнуть от задачи?",
            content: About?.fears,
        },
        {
            identifier: "Как вы относитесь к планированию?",
            content: About?.doesLikePlanning,
        },
        {
            identifier: "Можете ли вы назвать себя креативной личностью?",
            content: About?.isCreative,
        },
    ];
}

export default function AboutMe({ about }) {
    return <DoubleList className={"additionals"} listData={parseAdditionals(about)} />;
}
