import RolesWrapper from '../../../hooks/components/Questionnaire/UserForm/RolesWrapper'
import ContentCard from '../../../hooks/components/common/ContentCard/ContentCard'
import ContentCardInner from '../../../hooks/components/common/ContentCard/ContentCardInner'
import ContentCardWrapper from '../../../hooks/components/common/ContentCard/ContentCardWrapper'
import FormLabel from '../../../hooks/components/common/Form/FormLabel'
import FormPanel from '../../../hooks/components/common/Form/FormPanel'

const PreferredRole = () => {
	return (
		<div className='questionnaire-preffered-role'>
			<div className='forms-page-counter'>2/3</div>
			<ContentCardWrapper>
				<ContentCard>
					<div className='questionnaire-topic'>Укажите свои данные</div>
					<ContentCardInner>
						<FormPanel>
							<FormLabel required className='user-form-relevant-role'>
								Как вы считаете, какая роль в команде вам подошла бы лучше
								всего?
							</FormLabel>
							<RolesWrapper />
						</FormPanel>
					</ContentCardInner>
				</ContentCard>
			</ContentCardWrapper>
		</div>
	)
}

export default PreferredRole
