import React, { MouseEventHandler } from "react";

interface FormRadioItemProps {
    children?: React.ReactNode;
    selected?: boolean;
    value?: string;
    onClick?: MouseEventHandler<HTMLDataElement>;
}

const FormRadioItem = ({ children, selected, onClick, value }: FormRadioItemProps) => {
    return (
        <data
            className={"input-radio-item " + (selected ? "input-radio-item--selected" : "")}
            onClick={onClick}
            value={value}
        >
            {children}
        </data>
    );
};

export default FormRadioItem;
