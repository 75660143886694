import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
	CreatePassportMyTeamDTO,
	PassportMyTeamDTO,
	createMember,
	problem,
	task,
} from '../../../../@types/dtos/PassportMyTeamDTO'
import { UserInTeamDTO } from '../../../../@types/dtos/UserInTeamDTO'
import Button from '../../../../components/common/Button/Button'
import ButtonWrapper from '../../../../components/common/Button/ButtonWrapper'
import ContainerMain from '../../../../components/common/ContainerMain/ContainerMain'
import ContentCard from '../../../../components/common/ContentCard/ContentCard'
import ContentCardWrapper from '../../../../components/common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../../../components/common/ContentMainTheme'
import DoubleList from '../../../../components/common/DoubleList/DoubleList'
import {
	PASSPORT_MICROSERVICE,
	fetchSquadList,
	sendAxiosWithTokens,
} from '../../../../utils/team'

const CreatePassport = () => {
	const [membersFetch, setMemberFetch] = useState<UserInTeamDTO[] | null>(null)
	const location = useLocation()
	const teamId: number = location.state.teamId
	const actualPassport: PassportMyTeamDTO = location.state.actualPassport
	const [amountTasks, setAmountTasks] = useState<number[]>([1])
	const [amountProblems, setAmountProblems] = useState<number[]>([1])
	const [slide, setSlide] = useState<number>(1)
	const [team, setTeam] = useState<createMember[]>([])
	const [passportData, setPassportData] = useState<CreatePassportMyTeamDTO>({
		dppTitle: actualPassport?.dppTitle || '',
		name: actualPassport?.name || '',
		shifr: actualPassport?.shifr || '',
		customer: actualPassport?.customer || '',
		consultant: actualPassport?.consultant || '',
		supervisor: actualPassport?.supervisor || '',
		reviewer: actualPassport?.reviewer || '',
		endUser: actualPassport?.endUser,
		duration: actualPassport?.duration || '',
		github: actualPassport?.github || '',
		trello: actualPassport?.trello || '',
		miro: actualPassport?.miro || '',
		others: 'other',
		image: actualPassport?.image || '',
		goal: actualPassport?.goal || '',
		result: actualPassport?.result || '',
		restrictions: actualPassport?.restrictions || '',
		resources: actualPassport?.resources || '',
		risks: actualPassport?.risks || '',
		smart: actualPassport?.smart || '',
		members: [],
		tasks: [],
		problems: [],
		mvp: actualPassport?.mvp || '',
	})
	useEffect(() => {
		if (teamId) {
			fetchSquadList(teamId).then(setMemberFetch).catch(console.error)
		}
	}, [])

	useEffect(() => {
		if (actualPassport) {
			const tempTeam: createMember[] = []
			actualPassport?.members?.forEach((member, index) => {
				// чтобы при наличии паспорта заполнялся объект участников
				tempTeam.push({
					userId: 0,
					tasks: member?.task,
					role: member?.role,
				})
			})

			const tempAmountTasks: number[] = []
			actualPassport?.tasks?.forEach((task, index) => {
				tempAmountTasks.push(index + 1)
			})
			setAmountTasks(tempAmountTasks)

			const tempTasks: task[] = []
			actualPassport?.tasks?.map((task, index) => {
				tempTasks.push({
					condition: task?.condition,
					subtask: task?.subtask,
					time: task?.time,
				})
			})

			const tempAmountProblems: number[] = []
			actualPassport?.problems?.map((problem, index) => {
				tempAmountProblems.push(index + 1)
			})
			setAmountProblems(tempAmountProblems)
			const tempProblems: problem[] = []
			actualPassport?.problems?.forEach((problem, index) => {
				tempProblems.push({
					number: problem?.number,
					condition: problem?.condition,
				})
			})
			setPassportData({
				...passportData,
				problems: tempProblems,
				tasks: tempTasks,
				members: tempTeam,
			})
		}
	}, [actualPassport])

	// useEffect(() => {
	// 	console.log('team', team)
	// 	console.log('passportdata.members', passportData?.members)
	// 	console.log('members from fetch', members) //чек че у нас вообще есть
	// }, [team, passportData.members, members])

	useEffect(() => {
		if (membersFetch) {
			const teamOfProject: createMember[] = []
			membersFetch?.map((member, index) => {
				teamOfProject.push({
					userId: member?.member?.id,
					role: actualPassport?.members[index]?.role || '',
					tasks: actualPassport?.members[index]?.task || '', //чтобы в любом случае заполнялся массив участниками
				})
			})
			setPassportData({
				...passportData,
				members: teamOfProject,
			})
			setTeam(teamOfProject)
		}
	}, [membersFetch])
	useEffect(() => {
		console.log(passportData)
	}, [passportData])
	const navigate = useNavigate()
	const endClick = () => {
		const CreatePassportMyTeam = async () => {
			if (teamId) {
				await sendAxiosWithTokens(
					`post`,
					`${PASSPORT_MICROSERVICE}/passport/command/${teamId}`,
					passportData
				)
					.then(r => navigate('/team/passportProject'))
					.catch(error => console.error(error))
			}
		}
		CreatePassportMyTeam()
	}

	return (
		<ContainerMain>
			<ContentMainTheme>Создание паспорта проекта</ContentMainTheme>
			<ContentCardWrapper>
				<ContentCard>
					<div
						style={{
							fontSize: '20px',
						}}
					>
						Страница {slide}
					</div>
					<br />
					<hr
						style={{
							border: '1px black solid',
						}}
					/>
					{slide == 1 ? (
						<DoubleList
							listData={[
								{
									identifier:
										'Наименование дополнительной профессиональной программы профессиональной переподготовки',
									content: (
										<textarea
											defaultValue={passportData.dppTitle}
											className='info-block-textareaForEdit'
											onChange={e =>
												setPassportData({
													...passportData,
													dppTitle: e.target.value,
												})
											}
										/>
									),
								},
								{
									identifier: 'Наименование проекта',
									content: (
										<textarea
											defaultValue={passportData.name}
											className='info-block-textareaForEdit'
											onChange={e =>
												setPassportData({
													...passportData,
													name: e.target.value,
												})
											}
										/>
									),
								},
								{
									identifier: 'Шифр проекта (команды)',
									content: (
										<textarea
											defaultValue={passportData.shifr}
											className='info-block-textareaForEdit'
											onChange={e =>
												setPassportData({
													...passportData,
													shifr: e.target.value,
												})
											}
										/>
									),
								},
								{
									identifier: 'Заказчик проекта',
									content: (
										<textarea
											defaultValue={passportData.customer}
											className='info-block-textareaForEdit'
											onChange={e =>
												setPassportData({
													...passportData,
													customer: e.target.value,
												})
											}
										/>
									),
								},
								{
									identifier: 'Руководитель темы от МАИ',
									content: (
										<textarea
											defaultValue={passportData.supervisor}
											className='info-block-textareaForEdit'
											onChange={e =>
												setPassportData({
													...passportData,
													supervisor: e.target.value,
												})
											}
										/>
									),
								},
								{
									identifier: 'Консультант',
									content: (
										<textarea
											defaultValue={passportData.consultant}
											className='info-block-textareaForEdit'
											onChange={e =>
												setPassportData({
													...passportData,
													consultant: e.target.value,
												})
											}
										/>
									),
								},
								{
									identifier: 'Рецензент темы',
									content: (
										<textarea
											defaultValue={passportData.reviewer}
											className='info-block-textareaForEdit'
											onChange={e =>
												setPassportData({
													...passportData,
													reviewer: e.target.value,
												})
											}
										/>
									),
								},
								{
									identifier:
										'Целевая аудитория результата проекта (кто потребитель результата проекта)',
									content: (
										<textarea
											defaultValue={passportData.endUser}
											className='info-block-textareaForEdit'
											onChange={e =>
												setPassportData({
													...passportData,
													endUser: e.target.value,
												})
											}
										/>
									),
								},
								{
									identifier: 'Длительность проекта (даты начала и окончания)',
									content: (
										<textarea
											defaultValue={passportData.duration}
											className='info-block-textareaForEdit'
											onChange={e =>
												setPassportData({
													...passportData,
													duration: e.target.value,
												})
											}
										/>
									),
								},
							]}
						/>
					) : (
						''
					)}
					{slide == 2 ? (
						<>
							<DoubleList
								listData={[
									{
										identifier: 'Ссылка на гитхаб',
										content: (
											<textarea
												defaultValue={passportData.github}
												className='info-block-textareaForEdit'
												onChange={e =>
													setPassportData({
														...passportData,
														github: e.target.value,
													})
												}
											/>
										),
									},
									{
										identifier: 'Сcылка на MIRO',
										content: (
											<textarea
												defaultValue={passportData.miro}
												className='info-block-textareaForEdit'
												onChange={e =>
													setPassportData({
														...passportData,
														miro: e.target.value,
													})
												}
											/>
										),
									},
									{
										identifier: 'Ссылка на трекер',
										content: (
											<textarea
												defaultValue={passportData.trello}
												className='info-block-textareaForEdit'
												onChange={e =>
													setPassportData({
														...passportData,
														trello: e.target.value,
													})
												}
											/>
										),
									},
									{
										identifier: 'Образ результата проекта',
										content: (
											<textarea
												defaultValue={passportData.image}
												className='info-block-textareaForEdit'
												onChange={e =>
													setPassportData({
														...passportData,
														image: e.target.value,
													})
												}
											/>
										),
									},
									{
										identifier: 'Цель проекта',
										content: (
											<textarea
												defaultValue={passportData.goal}
												className='info-block-textareaForEdit'
												onChange={e =>
													setPassportData({
														...passportData,
														goal: e.target.value,
													})
												}
											/>
										),
									},
								]}
							/>
							<div>
								<h2
									style={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									Задачи проекта
								</h2>
								{amountTasks.map((number, index) => {
									const updatedTasks = [...passportData.tasks]

									return (
										<div
											key={index}
											style={{
												marginBottom: '8px',
											}}
										>
											<div
												style={{
													display: 'flex',
													gap: '4px',
												}}
											>
												<div>
													Задача:{' '}
													<textarea
														defaultValue={
															passportData?.tasks[index]?.condition || ''
														}
														style={{
															maxWidth: '250px',
														}}
														className='info-block-textareaForEdit'
														onChange={e => {
															updatedTasks[index] = {
																...updatedTasks[index],
																condition: e.target.value,
															}
															setPassportData({
																...passportData,
																tasks: updatedTasks,
															})
														}}
													/>
												</div>
												<div>
													Подзадача:{' '}
													<textarea
														defaultValue={
															passportData?.tasks[index]?.subtask || ''
														}
														style={{
															maxWidth: '250px',
														}}
														className='info-block-textareaForEdit'
														onChange={e => {
															updatedTasks[index] = {
																...updatedTasks[index],
																subtask: e.target.value,
															}
															setPassportData({
																...passportData,
																tasks: updatedTasks,
															})
														}}
													/>
												</div>
												<div>
													Время на задачу в часах:
													<textarea
														defaultValue={
															passportData?.tasks[index]?.time || ''
														}
														style={{
															maxWidth: '250px',
														}}
														className='info-block-textareaForEdit'
														onChange={e => {
															updatedTasks[index] = {
																...updatedTasks[index],
																time: Number(e.target.value),
															}
															setPassportData({
																...passportData,
																tasks: updatedTasks,
															})
														}}
													/>
												</div>
											</div>
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
												}}
											></div>
										</div>
									)
								})}
								<div>
									<Button
										onClick={() =>
											setAmountTasks([
												...amountTasks,
												amountTasks[amountTasks.length - 1] + 1,
											])
										}
									>
										Добавить задачу
									</Button>
								</div>
								<hr
									style={{
										marginTop: '8px',
										border: '1px solid gray',
									}}
								/>
							</div>
							<div>
								<h2
									style={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									Проблемы проекта
								</h2>
								{amountProblems.map((number, index) => {
									const updatedProblems = [...passportData.problems]
									return (
										<>
											<div
												key={index}
												style={{
													marginBottom: '8px',
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'center',
												}}
											>
												Проблема: <br />
												<textarea
													defaultValue={
														passportData?.problems[index]?.condition
													}
													style={{
														marginBottom: '8px',
													}}
													className='info-block-textareaForEdit'
													onChange={e => {
														updatedProblems[index] = {
															...updatedProblems[index],
															number: index + 1,
															condition: e.target.value,
														}
														setPassportData({
															...passportData,
															problems: updatedProblems,
														})
													}}
												/>
											</div>
										</>
									)
								})}
								<Button
									onClick={() =>
										setAmountProblems([
											...amountProblems,
											amountProblems[amountProblems.length - 1] + 1,
										])
									}
								>
									Добавить проблему
								</Button>
							</div>
							<hr
								style={{
									marginTop: '8px',
									border: '1px solid gray',
								}}
							/>
						</>
					) : (
						''
					)}
					{slide == 3 ? (
						<>
							<div>
								<h2
									style={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									Команда проекта
								</h2>
								{membersFetch?.map((member, index) => {
									const updatedMembers = [...passportData.members]

									return (
										<div>
											<p
												style={{
													fontSize: '18px',
													marginBottom: '8px',
													display: 'flex',
													justifyContent: 'center',
												}}
											>
												{member.member.fio}
											</p>
											<div>
												Роль в проекте: <br />{' '}
												<textarea
													defaultValue={
														passportData?.members[index]?.role ||
														team[index]?.role ||
														''
													}
													className='info-block-textareaForEdit'
													onChange={e => {
														updatedMembers[index] = {
															...updatedMembers[index],
															role: e.target.value,
														}
														setPassportData({
															...passportData,
															members: updatedMembers,
														})
													}}
												/>
											</div>

											<div>
												Задачи в проекте: <br />
												<textarea
													defaultValue={
														passportData?.members[index]?.tasks ||
														team[index]?.tasks ||
														''
													}
													className='info-block-textareaForEdit'
													onChange={e => {
														updatedMembers[index] = {
															...updatedMembers[index],
															tasks: e.target.value,
														}
														setPassportData({
															...passportData,
															members: updatedMembers,
														})
													}}
												/>
											</div>
											<hr
												style={{
													marginTop: '8px',
													border: '1px solid #edededcf',
												}}
											/>
										</div>
									)
								})}
								<hr
									style={{
										marginTop: '8px',
										border: '1px solid gray',
									}}
								/>
							</div>
							<DoubleList
								listData={[
									{
										identifier: 'Результат проекта',
										content: (
											<textarea
												defaultValue={passportData.result}
												className='info-block-textareaForEdit'
												onChange={e =>
													setPassportData({
														...passportData,
														result: e.target.value,
													})
												}
											/>
										),
									},
									{
										identifier:
											'Ограничения и допущения, которые имеют или могут оказать существенное влияние на результат проекта',
										content: (
											<textarea
												defaultValue={passportData.restrictions}
												className='info-block-textareaForEdit'
												onChange={e =>
													setPassportData({
														...passportData,
														restrictions: e.target.value,
													})
												}
											/>
										),
									},
									{
										identifier: 'S.M.A.R.T',
										content: (
											<textarea
												defaultValue={passportData.smart}
												className='info-block-textareaForEdit'
												onChange={e =>
													setPassportData({
														...passportData,
														smart: e.target.value,
													})
												}
											/>
										),
									},
									{
										identifier:
											'Необходимые ресурсы для выполнения проекта (компетенции исполнителей, материальные ресурсы и др.)',
										content: (
											<textarea
												defaultValue={passportData.resources}
												className='info-block-textareaForEdit'
												onChange={e =>
													setPassportData({
														...passportData,
														resources: e.target.value,
													})
												}
											/>
										),
									},
									{
										identifier: 'Риски проекта',
										content: (
											<textarea
												defaultValue={passportData.risks}
												className='info-block-textareaForEdit'
												onChange={e =>
													setPassportData({
														...passportData,
														risks: e.target.value,
													})
												}
											/>
										),
									},

									{
										identifier: 'MVP',
										content: (
											<textarea
												defaultValue={passportData.mvp}
												className='info-block-textareaForEdit'
												onChange={e =>
													setPassportData({
														...passportData,
														mvp: e.target.value,
													})
												}
											/>
										),
									},
								]}
							/>
							<br />
						</>
					) : (
						''
					)}
					<br />
					<br />
					<ButtonWrapper>
						{(slide == 2 || slide == 3) && (
							<Button onClick={() => setSlide(slide - 1)}>Назад</Button>
						)}
						{(slide == 1 || slide == 2) && (
							<Button onClick={() => setSlide(slide + 1)}>Далее</Button>
						)}

						{slide == 3 && (
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
								}}
							>
								<Button onClick={endClick}>Создать</Button>{' '}
							</div>
						)}
					</ButtonWrapper>
				</ContentCard>
			</ContentCardWrapper>
		</ContainerMain>
	)
}

export default CreatePassport
