import axios, { AxiosResponse } from 'axios'
import { CreateInvitationDTO } from '../@types/JoinToCommand'
import { TeamDTO, UpdateRoleDTO, UpdateTeamDTO } from '../@types/createCommand'
import { TopicDTO } from '../@types/createTopic'
import { GetImportantInfoDTO } from '../@types/dtos/GetImportantInfoDTO'
import { UserInTeamDTO } from '../@types/dtos/UserInTeamDTO'
import { RoleInProject } from '../@types/entities/RoleInProject'
import { CreateRequestToTransferTeamLeadDTO } from '../@types/teamLeadTransferDTO'
import { setEntireTeam, setSquadList } from '../redux/slices/myTeamSlice'
import { store } from '../redux/store'
import {
	TokenType,
	checkIsTokenExpired,
	getClientRoles,
	getDecodedToken,
	getOrCreateTokens,
	isTokenInLS,
	updateToken,
} from './token'

const dispatch = store.dispatch

export const SERVER_HOST =
	process.env.REACT_APP_SERVER_HOST || window.location.host

// const CREATE_COMMAND_MICROSERVICE_PORT = process.env.REACT_APP_CREATE_COMMAND_PORT || 9093;
// const APPLICATION_MICROSERVIC_PORT = process.env.REACT_APP_APPLICATION_PORT || 9087;
// const MODERATION_MICROSERVICE_PORT = process.env.REACT_APP_MODERATION_PORT || 9090;
// const PASSPORT_PORT = process.env.REACT_APP_PASSPORT_PORT || 9085;
// const PERSONAL_ACCOUNT_PORT = process.env.REACT_APP_PERSONAL_ACCOUNT_PORT || 9086;
export const CREATE_COMMAND_MICROSERVICE_URL = `https://api.${SERVER_HOST}/create_command`
export const APPLICATION_MICROSERVICE_URL = `https://api.${SERVER_HOST}/application`
export const MODERATION_MICROSERVICE_URL = `https://api.${SERVER_HOST}/moderation`
export const PERSONAL_ACCOUNT_MICROSERVICE = `https://api.${SERVER_HOST}/personal_account`
export const PASSPORT_MICROSERVICE = `https://api.${SERVER_HOST}/passport`
export const FILES_MICROSERVICE = `https://api.${SERVER_HOST}/file`
// export const FILES_MICROSERVICE = `http://localhost:9094`

export const sendAxiosWithTokens = async <T = any>(
	method: 'post' | 'get' | 'put' | 'delete',
	url: string,
	body?: any
): Promise<AxiosResponse<{ data: T }, any>> => {
	let tokens: TokenType | null = getOrCreateTokens()

	if (tokens != null) {
		if (checkIsTokenExpired(tokens?.access_token)) {
			if (checkIsTokenExpired(tokens?.refresh_token)) {
				window.localStorage.removeItem('token')
				window.location.replace('/')
			} else {
				await updateToken()
				tokens = getOrCreateTokens()
			}
		}
	}
	const sendData: {
		method: 'post' | 'get' | 'put' | 'delete'
		url: string
		data?: any
	} = {
		method,
		url,
	}
	if (method === 'post') {
		if (!body) return Promise.reject('Метод POST должен содержать тело запроса')
		sendData.data = body
	}
	sendData.method = method
	if (tokens) {
		return axios.request<{ data: T }>({
			method,
			url: url ? url : `${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/${method}`,
			data: body,
			headers: {
				Authorization: `Bearer ${tokens.access_token}`,
			},
		})
	} else {
		return Promise.reject('Токены отсутствуют в локальном хранилище')
	}
}
export const sendAxiosWithTokensAndCustomHeaders = async <T = any>(
	method: 'post' | 'get' | 'put' | 'delete',
	url: string,
	body?: any,
	headers?: {
		[key: string]: string
	}
): Promise<AxiosResponse<{ data: T }, any>> => {
	let tokens: TokenType | null = getOrCreateTokens()

	if (tokens != null) {
		if (checkIsTokenExpired(tokens?.access_token)) {
			if (checkIsTokenExpired(tokens?.refresh_token)) {
				window.localStorage.removeItem('token')
				window.location.replace('/')
			} else {
				await updateToken()
				tokens = getOrCreateTokens()
			}
		}
	}
	const sendData: {
		method: 'post' | 'get' | 'put' | 'delete'
		url: string
		data?: any
	} = {
		method,
		url,
	}
	if (method === 'post') {
		if (!body) return Promise.reject('Метод POST должен содержать тело запроса')
		sendData.data = body
	}
	sendData.method = method
	if (tokens) {
		return axios.request<{ data: T }>({
			method,
			url: url ? url : `${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/${method}`,
			data: body,
			headers: {
				Authorization: `Bearer ${tokens.access_token}`,
				...headers,
			},
		})
	} else {
		return Promise.reject('Токены отсутствуют в локальном хранилище')
	}
}

export const clientIsTeamLead = (): boolean => {
	if (isTokenInLS()) {
		const roles = getClientRoles()
		return roles.includes('client-teamlead')
	}
	return false
}

export const fetchTeamInfo = async (teamId?: number): Promise<TeamDTO> => {
	try {
		const teamResponse = await sendAxiosWithTokens<TeamDTO>(
			'get',
			`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/team${
				teamId ? `/${teamId}` : ''
			}`
		)

		return teamResponse.data.data
	} catch (error) {
		return Promise.reject(error)
		// console.error(error);
	}
}

export const updateTeamData = async () => {
	const { teamStorageStatus } = store.getState().myTeam
	if (teamStorageStatus === 'NOT_FETCHED') {
		const teamData = await fetchTeamInfo()
		dispatch(setEntireTeam({ team: teamData }))
	}
	return store.getState().myTeam.team
}

export const isInTeam = () => {
	return store.getState().myTeam.teamStatus === 'SELECTED'
}

export const fetchSquadList = async (
	teamId?: number
): Promise<UserInTeamDTO[]> => {
	try {
		const squadListResponse = await sendAxiosWithTokens<UserInTeamDTO[]>(
			'get',
			`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/students${
				teamId ? `/${teamId}` : ''
			}`
		)

		return squadListResponse.data.data
	} catch (err) {
		return Promise.reject(err)
	}
}

export const undoApproveTeam = async (teamId?: number) => {
	try {
		const undoApproveTeamResponse = await sendAxiosWithTokens(
			`put`,
			`${APPLICATION_MICROSERVICE_URL}/api/v1/requestsToAdmin/undoTeamApprove`,
			{
				teamId: teamId,
				feedback: 'Отменил одобрение команды',
			}
		)
		return undoApproveTeamResponse.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const updateSquadList = async () => {
	const { squadStorageStatus, teamStorageStatus } = store.getState().myTeam
	if (teamStorageStatus === 'NOT_FETCHED') {
		await updateTeamData()
	}
	const { team } = store.getState().myTeam
	if (squadStorageStatus === 'NOT_FETCHED') {
		const squadList = await fetchSquadList(team.id)
		dispatch(setSquadList({ squad: squadList }))
	}
	return store.getState().myTeam.squad
}

export const fetchCaseTopicList = async (): Promise<TopicDTO[]> => {
	const tokens = getOrCreateTokens()
	if (tokens) {
		try {
			const caseListResponse = await axios.get(
				`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/case`,
				{
					headers: {
						Authorization: `Bearer ${tokens.access_token}`,
					},
				}
			)
			return caseListResponse.data.data
		} catch (err) {
			return Promise.reject(err)
		}
	} else {
		return Promise.reject('Токены отсутствую в локальном хранилище')
	}
}

export const sendChoosenTopic = async (teamId: number, topicId: number) => {
	if (teamId !== 0 && topicId !== 0) {
		try {
			console.log('first')
			const response = await sendAxiosWithTokens(
				'post',
				`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/case/choose`,
				{
					teamId: teamId,
					topicId: topicId,
				}
			)
		} catch (err) {
			return Promise.reject(err)
		}
	} else {
		return Promise.reject('Неправильные teamId и topicId')
	}
}

export const sendUpdateTeam = async (
	teamId: number,
	updateInfo: {
		team?: UpdateTeamDTO
		role?: UpdateRoleDTO
	}
) => {
	try {
		if (updateInfo?.team) {
			const teamUpdataResponse = await sendAxiosWithTokens(
				'put',
				`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/team/${teamId}`,
				updateInfo.team
			)
		}
		if (updateInfo?.role) {
			const rolesUpdateResponse = await sendAxiosWithTokens(
				'put',
				`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/team/roles/${teamId}`,
				updateInfo?.role
			)
		}
	} catch (err) {
		return Promise.reject(err)
	}
}

export const sendTopicTitleAndDescriptionChange = async (
	teamId: number,
	newTitle?: string,
	newDescription?: string
) => {
	const updateInfo: { team: UpdateTeamDTO } = { team: {} }
	if (newTitle) {
		updateInfo.team['title'] = newTitle
	}
	if (newDescription) {
		updateInfo.team['description'] = newDescription
	}
	try {
		await sendUpdateTeam(teamId, updateInfo)
	} catch (err) {
		return Promise.reject(err)
	}
}

export const sendDeputyTeamLeadChange = async (
	teamId: number,
	deputyId: number,
	roleId: number
) => {
	const updateInfo: { role: UpdateRoleDTO } = {
		role: { userId: deputyId, roleId, deputyId: deputyId },
	}
	try {
		await sendUpdateTeam(teamId, updateInfo)
	} catch (err) {
		return Promise.reject(err)
	}
}

export const sendSquadMemberRoleChange = async (
	teamId: number,
	userId: number,
	roleId: number
) => {
	const updateInfo: { role: UpdateRoleDTO } = { role: { userId, roleId } }

	try {
		await sendUpdateTeam(teamId, updateInfo)
	} catch (err) {
		return Promise.reject(err)
	}
}

export const sendTeamLeadTransferRequest = async (userId: number) => {
	const transferData: CreateRequestToTransferTeamLeadDTO = {
		recipientId: userId,
		roleId: 1,
	}

	try {
		const transferRequest = await sendAxiosWithTokens(
			'post',
			`${APPLICATION_MICROSERVICE_URL}/api/v1/requestsToAdmin/createRequestToTransferTeamLead`,
			transferData
		)
	} catch (err) {
		return Promise.reject(err)
	}
}

export const fetchRolesList = async (): Promise<RoleInProject[]> => {
	try {
		const rolesResponse = await sendAxiosWithTokens<RoleInProject[]>(
			'get',
			`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/user/roles`
		)
		// console.log(rolesResponse.data.data);
		return rolesResponse.data.data
	} catch (err) {
		return Promise.reject(err)
	}
}
export const fetchFreeStudentsList = async (): Promise<
	GetImportantInfoDTO[]
> => {
	try {
		const freeStudentsResponse = await sendAxiosWithTokens<
			GetImportantInfoDTO[]
		>(
			'get',
			`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/user/freeStudentsInProgram`
		)
		return freeStudentsResponse.data.data
	} catch (err) {
		return Promise.reject(err)
	}
}

export const fetchAllUserList = async (): Promise<GetImportantInfoDTO[]> => {
	try {
		const allUserListResponse = await sendAxiosWithTokens(
			'get',
			`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/user/all`
		)
		// console.log(rolesResponse.data.data);
		return allUserListResponse.data.data
	} catch (err) {
		return Promise.reject(err)
	}
}

export const sendInviteToTheTeam = async (userId: number, roleId: number) => {
	const inviteData: CreateInvitationDTO = {
		recipientId: userId,
		roleId: roleId,
	}
	try {
		const inviteResponse = await sendAxiosWithTokens(
			'post',
			`${APPLICATION_MICROSERVICE_URL}/api/v1/joinToCommand/invitation`,
			inviteData
		)
		// console.log(inviteResponse);
		return inviteResponse
	} catch (err) {
		return Promise.reject(err)
		// console.error(err);
	}
}

export const sendDeleteUserFromTeam = async (
	teamId: number,
	userId: number
) => {
	try {
		const deleteResponse = await sendAxiosWithTokens(
			'delete',
			`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/team/${teamId}/${userId}`
		)
	} catch (err) {
		// return Promise.reject(err);
		console.error(err)
	}
}

export const sendLeaveTeam = async (userName: string) => {
	try {
		const leaveResponse = await sendAxiosWithTokens(
			'get',
			`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/team/leave/${userName}`
		)
	} catch (err) {
		//@ts-ignore
		// alert(err?.response?.data?.message);
		// return Promise.reject(err);
		console.error(err)
	}
}

export const sendJoinToTeamRequest = async (teamLeadId: number) => {
	try {
		const joinResponse = await sendAxiosWithTokens(
			'post',
			`${APPLICATION_MICROSERVICE_URL}/api/v1/joinToCommand/request`,
			{ recipientId: teamLeadId }
		)
		// console.log(joinResponse);
		return joinResponse
	} catch (err) {
		return Promise.reject(err)
		// console.error(err);
	}
}

export const sendRequestToApproveTeam = async () => {
	try {
		const approuveResponse = await sendAxiosWithTokens(
			'post',
			`${APPLICATION_MICROSERVICE_URL}/api/v1/requestsToAdmin/createRequestToApproveTeam`,
			{}
		)
		return approuveResponse
	} catch (err) {
		return Promise.reject(err)
	}
}

export const getTeamList = async () => {
	try {
		const username = getDecodedToken().preferred_username

		const getProgramId = await sendAxiosWithTokens(
			'get',
			`${PERSONAL_ACCOUNT_MICROSERVICE}/api/v1/personal_account/program/${username}`
		)
		const programId = getProgramId.data.data

		const teamsGetResponse = await sendAxiosWithTokens(
			'get',
			`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/team/all/${programId}`
		)
		return teamsGetResponse.data.data
	} catch (err) {
		console.error(err)
	}
}
