import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Application } from '../../@types/entities/Application'
import notifStyle from '../../assets/styles/components/notifStyle.module.scss'
import PaginationBar from '../../hooks/components/common/Tables/PaginationBar'
import { RootState } from '../../redux/store'
import ViewSendedNotifications from './ViewSendedNotifications'

const SendedNotificationsPage: React.FC = () => {
	const ActiveIndex = useSelector(
		(state: RootState) => state.activeIndexReducer.activeIndex
	)
	const SendedAppsList = useSelector(
		(state: RootState) => state.NotifcationReducer.sendedApplications
	)
	const SendedAppsListReverse = [...SendedAppsList].reverse()
	const [searchValue, setSearchValue] = useState('')

	let currentList: any[] = []
	switch (ActiveIndex) {
		case 1:
			currentList = SendedAppsListReverse.filter(
				item => item.status === 'ACCEPTED' || item.status === 'REJECTED'
			)
			break
		case 2:
			currentList = SendedAppsListReverse.filter(
				item => item.status === 'IN_REVIWEVING'
			)
			break
		default:
			currentList = SendedAppsListReverse
			break
	}

	const [optionPage, setOptionPage] = useState<number[]>([])
	const [currentPage, setCurrentPage] = useState<number>(0)

	let totalPages: number =
		Math.floor(currentList.length / 15) + (currentList.length % 15 ? 1 : 0)

	useEffect(() => {
		const pages = Array.from({ length: totalPages }, (_, i) => i)
		setOptionPage(pages)
	}, [totalPages, currentPage, ActiveIndex])

	const currentListToShow = (list: any[]) => {
		const startIndex = currentPage * 15
		const endIndex = startIndex + 15
		return list.slice(startIndex, endIndex)
	}

	const ListToShow = currentListToShow(currentList)
	function handleChangeVal(e: {
		target: { value: React.SetStateAction<string> }
	}) {
		setSearchValue(e.target.value)
	}
	const [FilteredList, setFilteredList] = useState<Application[]>(ListToShow)
	useEffect(() => {
		if (searchValue) {
			setFilteredList(
				ListToShow.filter(
					item =>
						item?.recipient?.fio
							.toLowerCase()
							.includes(searchValue.toLowerCase()) ||
						item?.responser?.fio
							.toLowerCase()
							.includes(searchValue.toLowerCase())
				)
			)
		} else {
			setFilteredList(ListToShow)
		}
	}, [searchValue, ListToShow])
	return (
		<>
			<div className={notifStyle.mainSection}>
				<ViewSendedNotifications
					currentList={currentList}
					ListToShow={FilteredList}
				/>
			</div>
			<div className={notifStyle.footerSection}>
				<PaginationBar
					pageIndex={currentPage}
					visiblePaginationRange={2}
					pageOptions={optionPage}
					pageCount={totalPages}
					previousPage={() => setCurrentPage(old => Math.max(old - 1, 0))}
					nextPage={() =>
						setCurrentPage(old => Math.min(old + 1, totalPages - 1))
					}
					gotoPage={(page: number) => setCurrentPage(page)}
					canPreviousPage={currentPage > 0}
					canNextPage={currentPage < totalPages - 1}
				/>
				<input
					type='text'
					className='case-input'
					onChange={handleChangeVal}
					placeholder='Поиск по ФИО'
				/>
			</div>
		</>
	)
}
export default SendedNotificationsPage
