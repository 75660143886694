import React from "react";
import styles from "./content-card.module.scss";
import ContentCardHeading from "./ContentCardHeading";
import ContentCardFooter from "./ContentCardFooter";
import Button from "../Button/Button";
import ContentCardInner from "./ContentCardInner";
import { useMatch } from "react-router-dom";

export interface ContentCardEditingProp {
	status: boolean;
	toggle?: () => void;
	content: React.ReactNode;
}
interface ContentCardPrompt {
	children?: React.ReactNode;
	low?: boolean;
	blured?: boolean;
	rounded?: boolean;
	transparent?: boolean;
	editing?: ContentCardEditingProp;
	topic?: React.ReactNode;
	myEditButton?:boolean;
}

const ContentCard = ({
	children,
	low,
	blured,
	rounded,
	transparent,
	editing,
	topic,
	myEditButton
}: ContentCardPrompt) => {
	return (
		<div
			className={
				styles.card +
				(low ? ` ${styles.low}` : "") +
				(blured ? ` ${styles.blured}` : "") +
				(rounded ? ` ${styles.rounded}` : "") +
				(transparent ? ` ${styles.transparent}` : "")
			}
		>
			{topic ? <ContentCardHeading topic={topic} editing={editing} /> : null}

			{useMatch("help/question-set")? 
			((editing?.status) ? (
				<>
					<ContentCardInner>{editing.content}</ContentCardInner>
					{editing.toggle ? (
						<ContentCardFooter>
							<div className="button-block">
								<Button onClick={editing?.toggle} action={"link"} to="/help/popular-question">Сохранить изменения</Button>
							</div>
						</ContentCardFooter>
					) : null}
				</>
			) : (
				children
			)):
			((editing?.status) ? (
				<>
					<ContentCardInner>{editing.content}</ContentCardInner>
					{editing.toggle && !myEditButton ? (
						<ContentCardFooter>
							<div className="button-block">
								<Button onClick={editing?.toggle}>Сохранить изменения</Button>
							</div>
						</ContentCardFooter>
					) : null}
				</>
			) : (
				children
			))
			}
			
			
			
		</div>
	);
};
export default ContentCard;
