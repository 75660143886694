import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getTokens, isTokenInLS } from '../../../../utils/token'
import Button from '../../common/Button/Button'
import PACategories from './PACategories'
import RightSidebarTopic from './RightSidebarTopic'

import jwt_decode from 'jwt-decode'
import { RootState } from '../../../../redux/store'
import ButtonWrapper from '../../common/Button/ButtonWrapper'

export default function PARightSidebar({
	setvisibility,
}: {
	setvisibility: Function
}) {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { profile }: any = useSelector(
		(state: RootState) => state.personalAccount
	)

	const Links = {
		'Цифровые технологии в социально-гуманитарной сфере':
			'https://vk.me/join/Zren9lWoRGZdGqYW3zRWA1oX5P0/KeP/fzI=',
		'Цифровое моделирование и суперкомпьютерные технологии':
			'https://vk.me/join/KMK7dyisS4X449mQoSCvWHHblUuMOt/YPJc=',
		'Методы искусственного интеллекта для дистанционного зондирования Земли':
			'https://vk.me/join/WXIKiAEvrktBtEVRX6DxSV9oIGbk0njoztU=',
		'Методы искусственного интеллекта и предиктивная аналитика в проектах дефектоскопии':
			'https://vk.me/join/dF0Byd74X1U20dzT48JuOKSO3XrtkFe_Ysc=',
		'Прикладные системы инженерных расчетов':
			'https://vk.me/join/Z6RRjwdpBRni1OsWcSX5bgOpYRrBSU_hfXI=',
		'Интеллектуальные технические системы':
			'https://vk.me/join/VlLdHtN2ck6hZqzRoIkhTlvquLEJ4vXGcEI=',
		'Прикладные задачи машинного обучения и анализа больших данных':
			'https://vk.me/join/UgSamHrlfAm63VPMQpCxttqDNsO9o/TkVMk=',
	}
	let admin: boolean = false
	let student: boolean = false
	let teamlead: boolean = false

	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		admin = roles.indexOf('client-main-admin') > -1
		student = roles.indexOf('client-student') > -1
		teamlead = roles.indexOf('client-teamlead') > -1
	}

	return (
		<div className='personal-account-right-sidebar'>
			<div className='personal-account-right-sidebar__categories'>
				<RightSidebarTopic>Личный кабинет</RightSidebarTopic>
				<PACategories />
			</div>
			<RightSidebarTopic>Полезные cсылки</RightSidebarTopic>
			<ButtonWrapper wide>
				<Button
					className='p-a-button'
					type='button'
					to='https://lms.mai.ru'
					action={'link'}
					label='Ссылка на курс в ЛМС'
				>
					Ссылка на курс в ЛМС
				</Button>
				<Button
					className='p-a-button'
					type='button'
					//@ts-ignore
					to={Links[profile.Mydata.programTitle]}
					action={'link'}
					label='Ссылка на чат в Telegram'
				>
					Ссылка на чат в Telegram
				</Button>
				{!teamlead ? (
					<Button
						className='p-a-button'
						type='button'
						label='Получить линк на вступление в команду'
						onClick={() => setvisibility(true)}
					>
						Ввести ссылку для вступления в команду
					</Button>
				) : (
					''
				)}
			</ButtonWrapper>
		</div>
	)
}
