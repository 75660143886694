import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import forInfo from '../../assets/img/icons/forInfo.svg'
import forInvite from '../../assets/img/icons/forInvite.svg'
import notifStyle from '../../assets/styles/components/notifStyle.module.scss'
import Button from '../../hooks/components/common/Button/Button'
import {
	setAmountNotifications,
	setCurrentListsInWindow,
	setOpenWindow,
} from '../../redux/slices/notificationSlice'
import { RootState } from '../../redux/store'
import { CREATE_COMMAND_MICROSERVICE_URL } from '../../utils/team'
import {
	TokenType,
	checkIsTokenExpired,
	getTokens,
	isTokenInLS,
} from '../../utils/token'
import { useGetNotifications } from '../../utils/useGetNotifications'
import { useResponseOnApplication } from '../../utils/useResponseOnApplication'

import { forwardRef } from 'react'
import { ApplicationType } from '../../@types/entities/ApplicationType'
import { ModalContent } from './ModalContent'
interface NotifWindowProps {
	setWindowContent: Function
}
const NotifWindow = forwardRef<HTMLDivElement, NotifWindowProps>(
	({ setWindowContent }, ref) => {
		let teamlead: boolean = false
		let Mainadmin: boolean = false
		let student: boolean = false
		let ProgrammCurator: boolean = false

		const dispatch = useDispatch()

		if (isTokenInLS()) {
			const token: any = jwt_decode(getTokens().access_token)

			const roles = token.resource_access['spring-client'].roles
			Mainadmin = roles.indexOf('client-main-admin') > -1
			teamlead = roles.indexOf('client-teamlead') > -1
			student = roles.indexOf('client-student') > -1
			ProgrammCurator = roles.indexOf('client-program-curator') > -1
		}
		const notificationsList = useSelector(
			(state: RootState) => state.NotifcationReducer.notifications
		)
		//фильтр увед по прочитанности
		const CurrentNotificationList = notificationsList.filter(
			item => !item.wasRead
		)
		const ReversedNotificationList = [...CurrentNotificationList].reverse()

		const applicationsList = useSelector(
			(state: RootState) => state.NotifcationReducer.applications
		)
		const ReversedApplicationList = [...applicationsList].reverse()
		//Фильтрация по ролям заявок
		const MainAdminList = ReversedApplicationList.filter(
			item =>
				item.type === 'QUESTION_FOR_MAIN_ADMIN' ||
				item.type === 'QUESTION_FOR_SUPPORT' ||
				item.type === 'TEAMLEAD_APPLICATION' ||
				item.type === 'MEMBERS_APPLICATION'
		)
		const ProgrammCuratorList = ReversedApplicationList.filter(
			item =>
				item.type === 'QUESTION_FOR_CURATOR' ||
				item.type === 'MEMBERS_APPLICATION' ||
				item.type === 'TEAMLEAD_APPLICATION'
		)
		const StudentTeamLeadList = ReversedApplicationList.filter(
			item => item.type === 'REQUEST_TO_THE_TEAM'
		)
		const StudentOnlyList = ReversedApplicationList.filter(
			item =>
				item.type === 'INVITE_TO_THE_TEAM' ||
				(item.type === 'TEAMLEAD_APPLICATION' && item.subject?.id)
		)

		let currentList: any[] = []
		switch (true) {
			case Mainadmin:
				currentList = MainAdminList.filter(
					item => item.status === 'IN_REVIWEVING'
				)
				break
			case student:
				if (teamlead) {
					currentList = StudentTeamLeadList.filter(
						item => item.status === 'IN_REVIWEVING'
					)
				} else {
					currentList = StudentOnlyList.filter(
						item => item.status === 'IN_REVIWEVING'
					)
				}
				break
			case ProgrammCurator:
				currentList = ProgrammCuratorList.filter(
					item => item.status === 'IN_REVIWEVING'
				)
				break
		}
		const { ResponseOnApplications } = useResponseOnApplication()
		const navigate = useNavigate()
		const GetNotifications = useGetNotifications()
		const { openwindow } = useSelector(
			(state: RootState) => state.NotifcationReducer
		)

		const ClickShowAll = () => {
			dispatch(setOpenWindow(!openwindow))
		}

		const onClickAccept = (
			applicationId: number | undefined,
			type?: string,
			teamId?: number
		) => {
			const action = 'ACCEPTED'
			ResponseOnApplications(applicationId, action, type, teamId)
		}

		const onClickReject = (
			applicationId: number | undefined,
			type?: string
		) => {
			const action = 'REJECTED'
			ResponseOnApplications(applicationId, action, type)
		}

		const PutRequestForReadMessage = async (
			notificationId: number | undefined
		) => {
			const tokens: TokenType = JSON.parse(
				window.localStorage.getItem('token')!
			)
			if (checkIsTokenExpired(tokens.access_token)) {
				if (checkIsTokenExpired(tokens.refresh_token)) {
					window.localStorage.removeItem('token')
					navigate('/')
				}
			}
			if (tokens?.access_token) {
				await axios
					.put(
						`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/notification/${notificationId}`,
						{},
						{
							headers: {
								Authorization: `Bearer ${tokens.access_token}`,
							},
						}
					)
					.then(response => {
						console.log(response)
					})
				await GetNotifications()
				dispatch(setAmountNotifications())
			}
		}

		const ClickOnMessageForRead = (notificationId: number | undefined) => {
			PutRequestForReadMessage(notificationId)
		}

		const GoToQuestion = () => {
			navigate('/help/question-history')
		}

		const Swap = (i: number) => {
			dispatch(setCurrentListsInWindow(i))
		}
		const activeListInWindow = useSelector(
			(state: RootState) => state.NotifcationReducer.SwapListsInWindow
		)

		const addition = (content: string) => {
			return content === 'Заявка на становление тимлидом одобрена'
				? ' Теперь вы можете создать свою команду'
				: ''
		}
		return (
			<div ref={ref} className={notifStyle.windowWrapperIn}>
				<div className={notifStyle.titlewindow}>Уведомления и заявки</div>

				<div className={notifStyle.WindowheaderSwapLists}>
					<button
						onClick={() => Swap(1)}
						className={
							activeListInWindow === 1
								? `${notifStyle.headerButtoniInWindow} ${notifStyle.active}`
								: notifStyle.headerButtoniInWindow
						}
					>
						Уведомления
					</button>
					<button
						onClick={() => Swap(0)}
						className={
							activeListInWindow === 0
								? `${notifStyle.headerButtoniInWindow} ${notifStyle.active}`
								: notifStyle.headerButtoniInWindow
						}
					>
						Заявки
					</button>
				</div>
				<div className={notifStyle.mainwindow}>
					{activeListInWindow === 0 &&
						(currentList.length == 0 ? null : (
							<ul>
								{currentList.map((item, i) => {
									if (item.type === 'INVITE_TO_THE_TEAM') {
										return (
											<li key={i} className={notifStyle.NewAppWindow}>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.WindowText}>
														<span className={notifStyle.LinkToUserByQuestion}>
															<Link
																to={`/profile/${item.initiator?.username}/data`}
															>{`${item.initiator?.fio}`}</Link>
														</span>
														{` пригласил вас в команду "${
															item.subject?.title || 'Названия команды нет'
														}" ${
															item.role?.title
																? `на роль ${item.role?.title}`
																: ''
														}`}
													</span>
												</div>
												<div className={notifStyle.TwoButton}>
													<button
														onClick={() =>
															setWindowContent(
																<ModalContent
																	action='ACCEPTED'
																	setWindowContent={setWindowContent}
																	applicationType={
																		ApplicationType.INVITE_TO_THE_TEAM
																	}
																	teamTitle={item.subject?.title}
																	applicationid={item.id}
																/>
															)
														}
														className={notifStyle.Accept}
													>
														Принять
													</button>
													<button
														onClick={() =>
															setWindowContent(
																<ModalContent
																	action='REJECTED'
																	setWindowContent={setWindowContent}
																	applicationType={
																		ApplicationType.INVITE_TO_THE_TEAM
																	}
																	teamTitle={item.subject?.title}
																	applicationid={item.id}
																/>
															)
														}
														className={notifStyle.Reject}
													>
														Отклонить
													</button>
												</div>
											</li>
										)
									} else if (item.type === 'REQUEST_TO_THE_TEAM') {
										return (
											<li key={i} className={notifStyle.NewAppWindow}>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.WindowText}>
														<span className={notifStyle.LinkToUserByQuestion}>
															<Link
																to={`/profile/${item.initiator?.username}/data`}
															>{`${item.initiator?.fio}`}</Link>
														</span>{' '}
														{` хочет вступить в вашу команду "${
															item.subject?.title
														}" ${
															item.role?.title
																? `на роль ${item.role?.title}`
																: ''
														} `}
													</span>
												</div>
												<div className={notifStyle.TwoButton}>
													<button
														onClick={() =>
															setWindowContent(
																<ModalContent
																	action='ACCEPTED'
																	setWindowContent={setWindowContent}
																	applicationType={
																		ApplicationType.REQUEST_TO_THE_TEAM
																	}
																	fio={item.initiator?.fio}
																	teamTitle={item.subject?.title}
																	applicationid={item.id}
																/>
															)
														}
														className={notifStyle.Accept}
													>
														Принять
													</button>
													<button
														onClick={() =>
															setWindowContent(
																<ModalContent
																	action='REJECTED'
																	setWindowContent={setWindowContent}
																	applicationType={
																		ApplicationType.REQUEST_TO_THE_TEAM
																	}
																	fio={item.initiator?.fio}
																	teamTitle={item.subject?.title}
																	applicationid={item.id}
																/>
															)
														}
														className={notifStyle.Reject}
													>
														Отклонить
													</button>
												</div>
											</li>
										)
									} else if (item.type === 'MEMBERS_APPLICATION') {
										return (
											<li key={i} className={notifStyle.NewAppWindow}>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.WindowText}>
														{`Команда "${item.subject?.title}" хочет подтвердить список своей команды `}
														<br />
														<Link
															className='profile-link'
															to={`/team/${item.subject?.id}`}
														>
															Посмотреть состав команды
														</Link>{' '}
														<br />
														{`Кейс: ${
															item.subject?.topic?.title || 'еще не выбран'
														} `}
													</span>
												</div>
												<div className={notifStyle.TwoButton}>
													<button
														onClick={() =>
															setWindowContent(
																<ModalContent
																	admin
																	action='ACCEPTED'
																	setWindowContent={setWindowContent}
																	applicationType={
																		ApplicationType.MEMBERS_APPLICATION
																	}
																	teamTitle={item.subject?.title}
																	applicationid={item.id}
																	teamId={item.subject?.id}
																/>
															)
														}
														className={notifStyle.Accept}
													>
														Принять
													</button>
													<button
														onClick={() =>
															setWindowContent(
																<ModalContent
																	admin
																	action='REJECTED'
																	setWindowContent={setWindowContent}
																	applicationType={
																		ApplicationType.MEMBERS_APPLICATION
																	}
																	teamTitle={item.subject?.title}
																	applicationid={item.id}
																	teamId={item.subject?.id}
																/>
															)
														}
														className={notifStyle.Reject}
													>
														Отклонить
													</button>
												</div>
											</li>
										)
									} else if (item.type === 'TEAMLEAD_APPLICATION') {
										if (item?.subject?.id && item?.recipient) {
											return (
												<li
													key={i}
													className={
														item.status === 'ACCEPTED' ||
														item.status === 'REJECTED'
															? `${notifStyle.oldApp}`
															: `${notifStyle.NewAppWindow}`
													}
												>
													<div className={notifStyle.item}>
														<img src={forInvite} alt='fds' />
														<span className={notifStyle.WindowText}>
															<span className={notifStyle.LinkToUserByQuestion}>
																<Link
																	to={`/profile/${item.initiator?.username}/data`}
																>{`${item.initiator?.fio}`}</Link>
															</span>{' '}
															{`хочет передать вам роль Team lead'а`}
														</span>
													</div>
													<div className={notifStyle.TwoButton}>
														<button
															onClick={() =>
																setWindowContent(
																	<ModalContent
																		action='ACCEPTED'
																		setWindowContent={setWindowContent}
																		applicationType={
																			ApplicationType.TEAMLEAD_APPLICATION
																		}
																		fio={item.initiator?.fio}
																		applicationid={item.id}
																		hackForTeamLeadApplication=''
																	/>
																)
															}
															className={notifStyle.Accept}
														>
															Принять
														</button>
														<button
															onClick={() =>
																setWindowContent(
																	<ModalContent
																		action='REJECTED'
																		setWindowContent={setWindowContent}
																		applicationType={
																			ApplicationType.TEAMLEAD_APPLICATION
																		}
																		fio={item.initiator?.fio}
																		applicationid={item.id}
																		hackForTeamLeadApplication=''
																	/>
																)
															}
															className={notifStyle.Reject}
														>
															Отклонить
														</button>
													</div>
												</li>
											)
										} else if (!item?.subject) {
											return (
												<li
													key={i}
													className={
														item.status === 'ACCEPTED' ||
														item.status === 'REJECTED'
															? `${notifStyle.oldApp}`
															: `${notifStyle.NewAppWindow}`
													}
												>
													<div className={notifStyle.item}>
														<img src={forInvite} alt='fds' />
														<span className={notifStyle.WindowText}>
															<span className={notifStyle.LinkToUserByQuestion}>
																<Link
																	to={`/profile/${item.initiator?.username}/data`}
																>{`${item.initiator?.fio}`}</Link>
															</span>{' '}
															{`хочет стать тимлидом команды.`}
															<br />
															{`Программа: ${
																item?.program?.title ||
																'программа пока не известна'
															}`}
														</span>
													</div>
													<div className={notifStyle.TwoButton}>
														<button
															onClick={() =>
																setWindowContent(
																	<ModalContent
																		admin
																		action='ACCEPTED'
																		setWindowContent={setWindowContent}
																		applicationType={
																			ApplicationType.TEAMLEAD_APPLICATION
																		}
																		fio={item.initiator?.fio}
																		hackForTeamLeadApplication='approve'
																		applicationid={item.id}
																	/>
																)
															}
															className={notifStyle.Accept}
														>
															Принять
														</button>
														<button
															onClick={() =>
																setWindowContent(
																	<ModalContent
																		admin
																		action='REJECTED'
																		setWindowContent={setWindowContent}
																		applicationType={
																			ApplicationType.TEAMLEAD_APPLICATION
																		}
																		fio={item.initiator?.fio}
																		hackForTeamLeadApplication='approve'
																		applicationid={item.id}
																	/>
																)
															}
															className={notifStyle.Reject}
														>
															Отклонить
														</button>
													</div>
												</li>
											)
										} else if (item?.subject && !item?.recipient) {
											return (
												<li
													key={i}
													className={
														item.status === 'ACCEPTED' ||
														item.status === 'REJECTED'
															? `${notifStyle.oldApp}`
															: `${notifStyle.NewAppWindow}`
													}
												>
													<div className={notifStyle.item}>
														<img src={forInvite} alt='fds' />
														<span className={notifStyle.WindowText}>
															<span className={notifStyle.LinkToUserByQuestion}>
																<Link
																	to={`/profile/${item.initiator?.username}/data`}
																>{`${item.initiator?.fio}`}</Link>
															</span>{' '}
															{`хочет передать роль Team Lead'а .`}
														</span>
													</div>
													<div className={notifStyle.TwoButton}>
														<button
															onClick={() =>
																setWindowContent(
																	<ModalContent
																		admin
																		action='ACCEPTED'
																		setWindowContent={setWindowContent}
																		applicationType={
																			ApplicationType.TEAMLEAD_APPLICATION
																		}
																		fio={item.initiator?.fio}
																		hackForTeamLeadApplication='transfer'
																		applicationid={item.id}
																	/>
																)
															}
															className={notifStyle.Accept}
														>
															Принять
														</button>
														<button
															onClick={() =>
																setWindowContent(
																	<ModalContent
																		admin
																		action='REJECTED'
																		setWindowContent={setWindowContent}
																		applicationType={
																			ApplicationType.TEAMLEAD_APPLICATION
																		}
																		fio={item.initiator?.fio}
																		hackForTeamLeadApplication='transfer'
																		applicationid={item.id}
																	/>
																)
															}
															className={notifStyle.Reject}
														>
															Отклонить
														</button>
													</div>
												</li>
											)
										}
									} else if (item.type === 'QUESTION_FOR_CURATOR') {
										return (
											<li key={i} className={notifStyle.NewAppWindow}>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.WindowText}>
														{' '}
														{`От `}
														<span className={notifStyle.LinkToUserByQuestion}>
															<Link
																to={`/profile/${item.initiator?.username}/data`}
															>{`${item.initiator?.fio}`}</Link>
														</span>
														{` поступил вопрос для куратора`}
													</span>
												</div>
												<div className={notifStyle.GoToQuestion}>
													<Button
														styling='text'
														onClick={() => {
															GoToQuestion()
															ClickShowAll()
														}}
													>
														Перейти к вопросу
													</Button>
												</div>
											</li>
										)
									} else if (item.type === 'QUESTION_FOR_SUPPORT') {
										return (
											<li key={i} className={notifStyle.NewAppWindow}>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.WindowText}>
														{' '}
														{`От `}
														<span className={notifStyle.LinkToUserByQuestion}>
															<Link
																to={`/profile/${item.initiator?.username}/data`}
															>{`${item.initiator?.fio}`}</Link>
														</span>
														{` поступил вопрос для тех. поддержки`}
													</span>
												</div>
												<div className={notifStyle.GoToQuestion}>
													<Button
														styling='text'
														onClick={() => {
															GoToQuestion()
															ClickShowAll()
														}}
													>
														Перейти к вопросу
													</Button>
												</div>
											</li>
										)
									} else if (item.type === 'QUESTION_FOR_MAIN_ADMIN') {
										return (
											<li key={i} className={notifStyle.NewAppWindow}>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.WindowText}>
														{' '}
														{`От `}
														<span className={notifStyle.LinkToUserByQuestion}>
															<Link
																to={`/profile/${item.initiator?.username}/data`}
															>{`${item.initiator?.fio}`}</Link>
														</span>
														{` поступил вопрос для администрации`}
													</span>
												</div>
												<div className={notifStyle.GoToQuestion}>
													<Button
														styling='text'
														onClick={() => {
															GoToQuestion()
															ClickShowAll()
														}}
													>
														Перейти к вопросу
													</Button>
												</div>
											</li>
										)
									}
								})}
							</ul>
						))}
					{activeListInWindow === 1 &&
						(ReversedNotificationList.length == 0 ? null : (
							<ul>
								{ReversedNotificationList.map((text, i) =>
									!text.wasRead ? (
										<li
											key={i}
											className={notifStyle.NewNotif}
											onClick={() => ClickOnMessageForRead(text.id)}
										>
											<div className={notifStyle.item}>
												<img src={forInfo} alt='fds' />
												<span className={notifStyle.Infotext}>
													{`${text.content}.${addition(
														text.content as string
													)}`}
												</span>
											</div>
										</li>
									) : (
										''
									)
								)}
							</ul>
						))}
				</div>
				<div className={notifStyle.Windowfooter}>
					<Link to='/notifications/all'>
						<button
							onClick={() => ClickShowAll()}
							className={notifStyle.button}
						>
							Показать всё
						</button>
					</Link>
				</div>
			</div>
		)
	}
)

export default NotifWindow
