import { AxiosError } from "axios";

const ErrorHandler = (error: AxiosError, navigate: (path: string) => void) => {
  switch (error.response?.status) {
    case 404:
      navigate("/*");
      console.error(error);
      break;
    default:
      if (
        //@ts-ignore
        error.response?.data?.type === "business" ||
        //@ts-ignore
        error.response?.data?.type === "system"
      )
        //@ts-ignore
        navigate("/AnyError", { message: error.response.data.message });
      console.error(error);
  }
};

export default ErrorHandler;
