import { PASSPORT_MICROSERVICE, sendAxiosWithTokens } from '../../../utils/team'

export const fetchPassportMyTeam = async (teamId: number) => {
	try {
		return await sendAxiosWithTokens(
			`get`,
			`${PASSPORT_MICROSERVICE}/passport/command/${teamId}`
		).then(res => {
			console.log(res.data.data)
			return res.data.data
		})
	} catch (error) {
		console.log(error)
	}
}
