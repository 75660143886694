import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../hooks/components/common/Button/Button'
import ContentCard from '../../hooks/components/common/ContentCard/ContentCard'
import ContentCardInner from '../../hooks/components/common/ContentCard/ContentCardInner'
import ContentCardWrapper from '../../hooks/components/common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../hooks/components/common/ContentMainTheme'
import InformationCard from '../../hooks/components/common/InformationCard/InformationCard'
import {
	questionHistoryItem,
	setQuestionHistory,
} from '../../redux/slices/helpSlice'
import { RootState } from '../../redux/store' // Путь к вашему store.ts
import { checkIsTokenExpired, getTokens, isTokenInLS } from '../../utils/token'

import jwt_decode from 'jwt-decode'
import { useLocation, useNavigate } from 'react-router-dom'
import { APPLICATION_MICROSERVICE_URL } from '../../utils/team'

const QuestionHistory = () => {
	let admin: boolean = false
	let student: boolean = false

	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		const isAdmin = roles.indexOf('client-admin') > -1
		if (isAdmin) admin = isAdmin
		const isStudent = roles.indexOf('client-student') > -1
		if (isStudent) student = isStudent
	}
	const [accepted, setAccepted] = useState(0)
	const [accepted2, setAccepted2] = useState('ACCEPTED')

	function handleChangeAccepted1() {
		return admin ? setAccepted(1) : setAccepted2('ACCEPTED')
	}
	function handleChangeAccepted2() {
		return admin ? setAccepted(0) : setAccepted2('IN_REVIWEVING')
	}

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const questionHistory = useSelector(
		(state: RootState) => state.help.questionHistory
	)

	const fetchQuestionHistory = async (page: number, pageSize: number) => {
		const tokens = JSON.parse(window.localStorage.getItem('token') || '{}')
		if (checkIsTokenExpired(tokens.access_token)) {
			if (checkIsTokenExpired(tokens.refresh_token)) {
				window.localStorage.removeItem('token')
				navigate('/')
			}
		}

		// const queryParams = new URLSearchParams(location.search);

		// const page = queryParams.get("page") || "1";
		// const pageSize = queryParams.get("pageSize") || "10";
		// const status = queryParams.get("status") || "";
		// const type = queryParams.get("type") || "";
		// const sendDirection = queryParams.get("send_direction") || "";

		try {
			const response = await axios.get(
				`${APPLICATION_MICROSERVICE_URL}/help/questions/${page}/${pageSize}?status=IN_REVIWEVING,ACCEPTED&type=QUESTION_FOR_MAIN_ADMIN,QUESTION_FOR_SUPPORT,QUESTION_FOR_CURATOR&send_direction=ANY`,
				{
					headers: {
						Authorization: `Bearer ${getTokens().access_token}`,
					},
				}
			)
			const questionHistoryArray = response.data.data.content.map(
				(item: any) => ({
					id: item.id,
					initiator: item.initiator,
					content: item.content,
					type: item.type,
					status: item.status,
					feedback: item.feedback,
				})
			)
			console.log(questionHistoryArray)
			if (questionHistoryArray && questionHistoryArray.length > 0) {
				dispatch(setQuestionHistory(questionHistoryArray))
			}
		} catch (error) {
			console.error('Error fetching question history:', error)
		}
	}
	useEffect(() => {
		fetchQuestionHistory(0, 100)
	}, [])
	return (
		<>
			<ContentMainTheme>
				История вопросов
				<Button action={'link'} to='../popular-question' spacing='16'>
					Назад
				</Button>
			</ContentMainTheme>
			<ContentCardWrapper>
				<ContentCard transparent>
					<ContentCardInner>
						<div className='block-history-help'>
							<div className='help-history'>
								{admin
									? questionHistory
											.map((item: questionHistoryItem) =>
												(
													accepted
														? item.feedback != null
														: item.feedback === null
												) ? (
													<InformationCard
														id={item.id}
														question={item.content}
														answer={item.feedback}
														initiator={item.initiator}
													/>
												) : (
													''
												)
											)
											.reverse()
									: questionHistory
											.map((item: questionHistoryItem) =>
												item.status === accepted2 ? (
													<InformationCard
														id={item.id}
														question={item.content}
														answer={item.feedback}
														initiator={''}
													/>
												) : (
													''
												)
											)
											.reverse()}
							</div>

							<div className='column-button-help'>
								<Button
									onClick={handleChangeAccepted1}
									to='../popular-question'
									spacing='16'
								>
									Отвеченные вопросы
								</Button>
								<div className='two-history-button'>
									<Button
										onClick={handleChangeAccepted2}
										to='../popular-question'
										spacing='16'
									>
										Неотвеченные вопросы
									</Button>
								</div>
							</div>
						</div>
					</ContentCardInner>
				</ContentCard>
			</ContentCardWrapper>
		</>
	)
}

export default QuestionHistory
