import { sendAxiosWithTokens } from '../../../utils/team' // Путь к вашей функции sendAxiosWithTokens

class FileUploader {
	async uploadFile(url, file) {
		try {
			const formData = new FormData()
			formData.append('file', file)

			const response = await sendAxiosWithTokens('post', url, formData)
			return response
		} catch (error) {
			console.error('Ошибка при загрузке файла:', error)
			// Для дальнейшей обработки
		}
	}

	base64ToBlob(base64) {
		const binaryString = window.atob(base64)
		const binaryLen = binaryString.length
		const bytes = new Uint8Array(binaryLen)
		for (let i = 0; i < binaryLen; i++) {
			bytes[i] = binaryString.charCodeAt(i)
		}
		return new Blob([bytes], { type: 'image/jpeg' })
	}

	async downloadPhoto(url) {
		try {
			const response = await sendAxiosWithTokens('get', url)
			if (response.data.data !== null && response.data.data !== undefined) {
				return this.base64ToBlob(response.data.data) // Возвращает Blob объект
			}
		} catch (error) {
			console.error('Ошибка при загрузке фото:', error)
		}
	}

	async downloadFile(url) {
		try {
			const response = await sendAxiosWithTokens('get', url)
			return URL.createObjectURL(response.data)
		} catch (error) {
			console.error('Ошибка при загрузке файла:', error)
		}
	}
}

export default FileUploader
