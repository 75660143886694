
import isEmptyImg from "../../assets/img/icons/человечек в уведах.svg"
import notifStyles from "../../assets/styles/components/notifStyle.module.scss"
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Notification } from "../../@types/dtos/Notification";
import forInfo from "../../assets/img/icons/forInfo.svg";
interface ViewNotificationsProps {
    currentList?: Notification[];
    ListToShow: Notification[];
    ClickOnMessageForRead: Function;
}

const ViewNotification: React.FC<ViewNotificationsProps> = ({ currentList, ListToShow, ClickOnMessageForRead }) => {

    const activeIndex = useSelector((state: RootState) => state.activeIndexReducer.activeIndex);
    const addition = (content: string) => {
        return content === 'Заявка на становление тимлидом одобрена' ? ' Теперь вы можете создать свою команду' : ''
    }

    return (
        <>
            {currentList?.length === 0 ?
                <div className={notifStyles.isEmptyWindow}>
                    <img src={isEmptyImg} alt="" />
                    <h2>В {/*{activeIndex == 1 ? 'прочитанных' : activeIndex == 2 ? 'непрочитанных' : ''}*/} уведомлениях пусто</h2>
                </div> :
                <div>
                    {activeIndex === 0 ?
                        <ul>
                            {ListToShow.map((text, i) => (

                                <li key={i} className={text.wasRead ? notifStyles.OldNotif : notifStyles.NewNotif} onClick={() => ClickOnMessageForRead(text.id)}>
                                    <div className={notifStyles.item}>
                                        <img src={forInfo} alt="fds" />
                                        <span className={notifStyles.Infotext}>{`${text.content}.${addition(text.content as string)}`}</span>
                                    </div>
                                </li>
                            ))}
                        </ul> : ''}
                    {activeIndex === 1 ?
                        <ul>
                            {ListToShow.map((text, i) => (
                                <li key={i} className={notifStyles.OldNotif}>
                                    <div className={notifStyles.item}>
                                        <img src={forInfo} alt="fds" />
                                        <span className={notifStyles.Infotext}>{`${text.content}.${addition(text.content as string)}`}</span>
                                    </div>
                                </li>
                            ))}
                        </ul> : ''}
                    {activeIndex === 2 ?
                        <ul>
                            {ListToShow.map((text, i) => (
                                <li key={i} className={notifStyles.NewNotif} onClick={() => ClickOnMessageForRead(text.id)}>
                                    <div className={notifStyles.item}>
                                        <img src={forInfo} alt="fds" />
                                        <span className={notifStyles.Infotext}>{`${text.content}.${addition(text.content as string)}`}</span>
                                    </div>
                                </li>
                            ))}
                        </ul> : ''}
                </div>}
        </>
    );
}
export default ViewNotification;