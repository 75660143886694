import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../../hooks/components/common/Button/Button'
import ButtonWrapper from '../../hooks/components/common/Button/ButtonWrapper'
import ContainerMain from '../../hooks/components/common/ContainerMain/ContainerMain'
import ContentCard from '../../hooks/components/common/ContentCard/ContentCard'
import ContentCardFooter from '../../hooks/components/common/ContentCard/ContentCardFooter'
import ContentCardHeading from '../../hooks/components/common/ContentCard/ContentCardHeading'
import ContentCardInner from '../../hooks/components/common/ContentCard/ContentCardInner'
import ContentCardWrapper from '../../hooks/components/common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../hooks/components/common/ContentMainTheme'
import LoadingText from '../../hooks/components/common/Loaders/LoadingText'
import ModalWindow from '../../hooks/components/common/ModalWindow/ModalWindow'
import UserListItem from '../../hooks/components/common/UserList/UserListItem'
import { setDeputyTeamLead } from '../../redux/slices/myTeamSlice'
import { RootState } from '../../redux/store'
import { sendDeputyTeamLeadChange, updateSquadList } from '../../utils/team'

const SelectDeputyTeamLeadPage = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { team, squad } = useSelector((state: RootState) => state.myTeam)
	const [visibility, setVisibility] = React.useState(false)

	useEffect(() => {
		updateSquadList()
	}, [])

	const userList = squad
		.filter(user => user.role?.title !== 'Team Lead')
		.map((user, i) => (
			<UserListItem key={i} user={user}>
				{user.role?.title ? (
					user.isDeputy ? (
						<strong>Заместитель</strong>
					) : (
						<Button
							onClick={() => {
								console.log(user)
								dispatch(
									setDeputyTeamLead({
										id: user.member.id,
									})
								)
								sendDeputyTeamLeadChange(team.id, user.member.id, user.role.id)
								setVisibility(true)
							}}
							styling='text'
						>
							Назначить
						</Button>
					)
				) : (
					<strong>Выберете роль!</strong>
				)}
			</UserListItem>
		))

	return (
		<>
			<ModalWindow visibility={visibility}>
				<p>
					Вы назначили {squad.find(user => user.isDeputy)?.member.surname} вашим
					заместителем
				</p>
				<ButtonWrapper center>
					<Button
						onClick={() => {
							setVisibility(false)
							navigate('../')
						}}
					>
						Закрыть
					</Button>
				</ButtonWrapper>
			</ModalWindow>
			<ContainerMain>
				<ContentMainTheme>Заместитель Team Lead</ContentMainTheme>
				<ContentCardWrapper>
					<ContentCard low>
						Выберите заместителя Team Lead из списка команды
					</ContentCard>
					<ContentCard>
						<ContentCardHeading topic='Список команды'></ContentCardHeading>
						<ContentCardInner>
							<div className='user-list'>
								{squad.length === 0 ? <LoadingText center /> : userList}
							</div>
						</ContentCardInner>
						<ContentCardFooter>
							<ButtonWrapper>
								<Button action='link' to='../'>
									Назад
								</Button>
							</ButtonWrapper>
						</ContentCardFooter>
					</ContentCard>
				</ContentCardWrapper>
			</ContainerMain>
		</>
	)
}

export default SelectDeputyTeamLeadPage
