import { PassportMyTeamDTO } from '../../../@types/dtos/PassportMyTeamDTO'

export const ExamplePassport: PassportMyTeamDTO = {
	dppTitle: 'Организация процесса разработки программного обеспечения',
	name: 'Предиктивная аналитика отклонений сердечного ритма по объективным данным методами машинного обучения',
	shifr: 'МАИ.2023.МX0-XXXБ.ЭКГ',
	customer:
		'Московский авиационный институт (национальный исследовательский университет)',
	consultant: 'Не нашел/не понял где это',
	supervisor: 'Лунева Светлана Юрьевна',
	reviewer: 'Сошников Дмитрий Валерьевич',
	endUser:
		'Проект ориентирован на медицинских специалистов, осуществляющих анализ электрокардиограмм, а также на осведомлённых пользователей, стремящихся контролировать своё здоровье.',
	duration: '01.09.2023 - 31.12.2023',
	comandName: 'NAME_COMMAND',
	roles: 'TeamLead, Backend-разработчик, Тестировщик, ML – engineer',
	github: 'LINK1',
	trello: 'LINK2',
	miro: 'LINK3',
	others: 'НЕ ПОНЯЛ ЧТО ЭТО',
	image: 'Сервис',
	goal: 'Создание веб-сервиса, направленного на оптимизацию и ускорение процесса интерпретации электрокардиограмм',
	result: `Web-сервис, способный анализировать электрокардиограммы (ЭКГ) в формате WFDB. 
	Ключевые функции сервиса включают:
	Преобразование сырых данных ЭКГ в визуальные графики, доступные для просмотра через веб-браузер;
	Анализ аномалий сердечного ритма с использованием данных MIT-BIH Database;
	Предоставление текстовой интерпретации результатов анализа с помощью технологии Large Language Model (LLM).`,
	restrictions:
		'Бюджетные ограничения: проект финансируется исключительно за счет средств студентов, что накладывает ограничения на доступные ресурсы и возможности.',
	resources: `Финансовые и технические требования:
	Бюджет для запуска и поддержки проекта;
	VPN и иностранные платёжные средства для использования API OpenAI.
	Технологический стек:
	Программирование: Python, DynamoDB;
	Фреймворки и библиотеки: TensorFlow, Flask, Matplotlib, Scikit-learn, WFDB, Pyplot, Numpy, Boto3;
	Базы данных: DynamoDB, S3 
	Инфраструктура для облачных технологий:
	Использование облачных сервисов, таких как Yandex Object Storage (S3) и Yandex Message Queue, Yandx DynamoDB`,
	risks: `Технический риск:
	Ограниченные вычислительные мощности для обучения моделей машинного обучения, что может привести к проблемам с обобщаемостью и эффективностью моделей.`,
	smart: 'НЕ ПОНЯЛ чтО ЭТО',
	totalTime: '105',
	members: [
		{
			fio: 'Иванов Иван Иванович1',
			role: 'TeamLead',
			competence:
				'Python, Flask, Miro, Notion, TensorFlow, Numpy, Pandas, Seaborn, Matplotlib, Jupyter Notebook, Yandex Cloud',
			task: `Создание системного проектного документа (System Design Document):
			Создание и настройка рабочего пространства в Notion, Miro;
			Координация и руководство процессом создания документа.
			Разработка проектного документа системы машинного обучения (Machine Learning System Design Document):
			Проверка и одобрение документа;
			Интеграция с общей системой проекта.
			Интеграция логики Backend и ML-компонентов:
			Координация интеграции и убеждение, что все части работают совместно и корректно.`,
		},
		{
			fio: 'Иванов Иван Иванович2',
			role: 'Backend-разработчик 1',
			competence:
				'Python, Flask, Miro, Notion, TensorFlow, Numpy, Pandas, Seaborn, Matplotlib, Jupyter Notebook, Yandex Cloud',
			task: `Реализация Backend-части проекта:
			Разработка приложения на Flask с единой точкой входа;
			Подключение облачных сервисов для реализации корректной работы с ML частью;
			Разработка личного кабинета пользователя.
			Интеграция логики Backend и ML-компонентов:
			Совместная работа над интеграцией системы.`,
		},
		{
			fio: 'Иванов Иван Иванович3',
			role: 'Тестировщик',
			competence: 'Python, Jupyter Notebook',
			task: `Создание системного проектного документа (System Design Document):
			Проведение Research доступных инструментов;
			Выбор лучшего инструмента в качестве фундамента для Backend части.
			Интеграция логики Backend и ML-компонентов:
			Совместная работа над интеграцией системы.
			Верификация результатов работы системы:
			Проведение анализа полученных измеренных метрик;
			Prompt-engineering ChatGPT для текстовой интерпретации ЭКГ`,
		},
		{
			fio: 'Иванов Иван Иванович4',
			role: 'Backend-разработчик 2',
			competence: 'Python, Flask',
			task: `Cоздание системного проектного документа (System Design Document):
			Разработка архитектуры проекта согласно Research документу.
			Реализация Backend-части проекта:
			Поддержка в разработке и тестировании backend.
			Интеграция логики Backend и ML-компонентов:
			Совместная работа над интеграцией системы.`,
		},
		{
			fio: 'Иванов Иван Иванович5',
			role: 'ML-engineer',
			competence:
				'TensorFlow, Classic ML, Numpy, Pandas, Jupyter Notebook, Yandex Cloud, Seaborn, Matplotlib',
			task: `Разработка проектного документа системы машинного обучения (Machine Learning System Design Document):
			Research имеющихся датасетов;
			Выбор лучшего датасета;
			Формулирование целей, функций;
			Формулирование метрик; 
			Обзор данных.
			Реализация ML-компонента проекта:
			Проведение Exploratory Data Analysis;
			Создание авторских DataLoader для входных данных в формате WFDB;
			Разработка baseline модели;
			Разработка модели на основе нейронных сетей;
			Разработка необходимых инструментов для конкатенации с Backend частью.
			Интеграция логики Backend и ML-компонентов:
			Совместная работа над интеграцией системы.`,
		},
	],
	tasks: [
		{
			condition:
				'Создание системного проектного документа (System Design Document). Результатом будет страница в рабочем пространстве Notion',
			subtask: 'Subtask',
			time: 56,
		},
		{
			condition:
				'Создание системного проектного документа (System Design Document). Результатом будет страница в рабочем пространстве Notion',
			subtask: 'Subtask',
			time: 770,
		},
		{
			condition:
				'Создание системного проектного документа (System Design Document). Результатом будет страница в рабочем пространстве Notion',
			subtask: 'Subtask',
			time: 88,
		},
	],
	role: [
		{
			role: 'TeamLead',
			owner: 'Иванов Иван Иванович1',
		},
		{
			role: 'Backend-разработчик 1',
			owner: 'Иванов Иван Иванович2',
		},
		{
			role: 'Тестировщик',
			owner: 'Иванов Иван Иванович3',
		},
		{
			role: 'Backend-разработчик 2',
			owner: 'Иванов Иван Иванович4',
		},
		{
			role: 'ML-Engineer',
			owner: 'Иванов Иван Иванович5',
		},
	],
	problems: [
		{
			number: 0,
			condition: 'Какая-то проблема, я не нашел в примере паспорта',
		},
	],
	mvp: `MVP`,
}
