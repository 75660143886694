import React from "react";
import { Link } from "react-router-dom";
import Button from "../common/Button/Button";

const CreationFinish: React.FC = () => {
    return (
        <div className="wrapper-ct--small">
            <div className="title">
                <h1>Заявка на тимлидерство</h1>
            </div>
            <div className="content-block">
                <p>
                    Ваша заявка отправлена администрации на согласование. Ожидайте. Руководитель
                    программы свяжется с вами.
                </p>
                <div className="buttons-block">
                    <Button action="link" to={"/"}>
                        Ок
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default CreationFinish;
