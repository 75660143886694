import { useDispatch, useSelector } from 'react-redux'
import { setUserInfo } from '../../../../redux/slices/userQuestionnaireSlice'
import { RootState } from '../../../../redux/store'
import Button from '../../common/Button/Button'
import FormInputRadio from '../../common/Form/FormInputRadio'
import FormNavigateButtons from '../../common/Form/FormNavigateButtons'

const RolesWrapper = () => {
	const dispatch = useDispatch()

	const { user, roles } = useSelector(
		(state: RootState) => state.userQuestionnaire
	)

	const selectRole = ({ target }: any) => {
		dispatch(
			setUserInfo({ name: 'role', value: target?.value || target?.textContent })
		)
	}

	const canGoNext = () => {
		return user.role !== ''
	}

	return (
		<>
			<FormInputRadio
				extra
				itemList={roles}
				selected={user.role}
				onClick={selectRole}
				name='role'
			/>
			<FormNavigateButtons>
				<Button action='link' to='../main' spacing='8'>
					Назад
				</Button>

				<Button
					action='link'
					to='../skills'
					disabled={!canGoNext()}
					spacing='8'
				>
					Далее
				</Button>
			</FormNavigateButtons>
		</>
	)
}

export default RolesWrapper
