import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'

// import { COLUMNS } from "./allStudentColumns";
import { useEffect, useState } from 'react'
import { Cell } from 'react-table'
import { GetImportantInfoDTO } from '../../../@types/dtos/GetImportantInfoDTO'
import { RoleInProject } from '../../../@types/entities/RoleInProject'
import {
	fetchFreeStudentsList,
	fetchRolesList,
	sendInviteToTheTeam,
	updateSquadList,
	updateTeamData,
} from '../../../utils/team'
import Button from '../common/Button/Button'
import ButtonWrapper from '../common/Button/ButtonWrapper'
import ContentCardFooter from '../common/ContentCard/ContentCardFooter'
import FormInputRadio from '../common/Form/FormInputRadio'
import LoadingText from '../common/Loaders/LoadingText'
import ModalWindow from '../common/ModalWindow/ModalWindow'
import { ColumnFilter } from '../common/Tables/ColumnFilter'
import Table from '../common/Tables/TableFilterAndPagination'
import styles from "./scroll.module.scss"

const AllStudentsTable = ({
	students = [],
}: {
	students: GetImportantInfoDTO[]
}) => {
	const { team, squad } = useSelector((state: RootState) => state.myTeam)
	const { applications } = useSelector(
		(state: RootState) => state.NotifcationReducer
	)
	const [isVisible, setVisibility] = useState(false)
	const [isVisibleSend, setVisibilitySend] = useState(false)
	const [inviteUserAndRole, setInviteUserAndRole] = useState<{
		userId: number | null
		roleId: number | null
	}>({ userId: null, roleId: null })
	const [allUsersList, setAllUsersList] = useState<GetImportantInfoDTO[]>([])
	const [rolesInProjectList, setRolesInProjectList] = useState<RoleInProject[]>(
		[]
	)
	const [inviteUserResult, setInviteUserResult] = useState<string | null>(null)

	useEffect(() => {
		updateTeamData().catch(console.error)
		updateSquadList().catch(console.error)
		fetchFreeStudentsList().then(setAllUsersList).catch(console.error)
		fetchRolesList().then(setRolesInProjectList).catch(console.error)
	}, [])

	const COLUMNS = [
		{
			Header: '№',
			Cell: ({ row }: { row: any }) => <span>{row.index + 1}</span>,
			Filter: ColumnFilter,
		},
		{
			Header: 'ФИО пользователя',
			accessor: 'fio',
			Filter: ColumnFilter,
		},
		{
			Header: 'Предпочтительная роль',
			accessor: 'preferedRole.title',
			Filter: ColumnFilter,
		},
		{
			Header: 'Профиль',
			// accessor: "username",
			accessor: ({ username }: GetImportantInfoDTO) => (
				<Button styling='text' action='link' to={`/profile/${username}/data`}>
					Перейти
				</Button>
			),
			Filter: ColumnFilter,
			disableFilters: true,
		},
		{
			Header: 'Запрос',
			Cell: ({ row }: Cell<GetImportantInfoDTO>) => {
				return applications.find(
					application =>
						application.type === 'INVITE_TO_THE_TEAM' &&
						application?.recipient?.id === row.original.userId
				) ? (
					'запрос отправлен'
				) : (
					<Button
						styling='text'
						onClick={() => {
							setInviteUserAndRole({
								...inviteUserAndRole,
								userId: row.original.userId!,
							})
							setVisibility(true)
						}}
					>
						Отправить
					</Button>
				)
			},
			Filter: ColumnFilter,
			disableFilters: true,
		},
	]

	const selectRole = (roleId?: string) => {
		setInviteUserAndRole({ ...inviteUserAndRole, roleId: Number(roleId) })
	}

	const handleInviteSubmit = () => {
		sendInviteToTheTeam(inviteUserAndRole.userId!, inviteUserAndRole.roleId!)
			.then(() => {
				// console.log("Роль назначена");
				const invitedUser = allUsersList.find(
					user => user?.userId === inviteUserAndRole.userId
				)
				if (invitedUser)
					setInviteUserResult(
						`Вы пригласили пользователя ${invitedUser.fio} в свою команду.`
					)
			})
			.catch(res => {
				console.error(res)
				setInviteUserResult(
					res?.response?.data?.message === 'Такая заявка уже есть.'
						? `Вы уже отправляли заявку этому пользователю`
						: `Произошла ошибка`
				)
			})
	}

	return (
		<>
			<ModalWindow visibility={isVisible}>
				<div className={styles.scroll}>
					{inviteUserResult ? (
						<>
							<p>{inviteUserResult}</p>
							<ContentCardFooter>
								<ButtonWrapper center>
									<Button
										onClick={() => {
											setVisibility(false)
											setInviteUserResult(null)
											setInviteUserAndRole({ userId: null, roleId: null })
										}}
									>
										Продолжить
									</Button>
								</ButtonWrapper>
							</ContentCardFooter>
						</>
					) : (
						<>
							<strong>
								Для продолжения выберите роль, на которую вы хотите пригласить
								пользователя в команду:
							</strong>

							<FormInputRadio
								itemList={rolesInProjectList.map(role => role.title)}
								valueList={rolesInProjectList.map(role => role.id).map(String)}
								onSelect={selectRole}
								selected={String(inviteUserAndRole.roleId || '')}
							/>

							<ButtonWrapper>
								<Button
									onClick={() => {
										setVisibility(false)
										setInviteUserAndRole({ userId: null, roleId: null })
									}}
								>
									Отмена
								</Button>

								<Button
									onClick={() => {
										handleInviteSubmit()
										setVisibilitySend(true)
									}}
									disabled={
										inviteUserAndRole.userId === null ||
										inviteUserAndRole.roleId === null
									}
								>
									Продолжить
								</Button>
							</ButtonWrapper>
						</>
					)}
				</div>
			</ModalWindow>

			{allUsersList.length === 0 ? (
				<LoadingText center />
			) : (
				<Table
					columns={COLUMNS}
					data={students.length === 0 ? allUsersList : students}
					itemsOnPage={10}
					visiblePaginationRange={2}
					className='all-user-table'
				/>
			)}
		</>
	)
}

export default AllStudentsTable
