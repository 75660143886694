import React from "react";

interface FormPanelProps {
    children?: React.ReactNode;
    className?: string;
    method?: "POST" | "GET";
}

const FormPanel = ({ children, className = "", method = "POST" }: FormPanelProps) => {
    return (
        <form method={method} className={"form-pannel " + className}>
            {children}
        </form>
    );
};

export default FormPanel;
