import jwt_decode from 'jwt-decode'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import BurgerIcon from '../../assets/img/icons/burger-icon.svg'
import paths from '../../paths'
import { setOpenOrCloseSideBar } from '../../redux/slices/OpenSideBarSlice'
import { RootState } from '../../redux/store'
import { getTokens, isTokenInLS } from '../../utils/token'

function Sidebar() {
	const { Opened } = useSelector((state: RootState) => state.OpenSideBar)
	const dispatch = useDispatch()
	const location = useLocation()
	useEffect(() => {
		const shouldCloseSidebar = location.pathname.split('/').length > 1
		dispatch(setOpenOrCloseSideBar(!shouldCloseSidebar))
	}, [dispatch, location])

	const toggleSidebar = () => {
		dispatch(setOpenOrCloseSideBar(!Opened))
	}

	let notLogin: boolean = false
	let admin: boolean = false
	let student: boolean = false
	let customer: boolean = false
	let curator: boolean = false
	let programCurator: boolean = false
	let pervak: boolean = false
	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		const isAdmin = roles.indexOf('client-main-admin') > -1
		if (isAdmin) admin = isAdmin
		const isCurator = roles.indexOf('client-curator') > -1
		if (isCurator) curator = isCurator
		const isStudent = roles.indexOf('client-student') > -1
		if (isStudent) student = isStudent
		const isCustomer = roles.indexOf('client-customer') > -1
		if (isCustomer) customer = isCustomer
		const isProgramCurator = roles.indexOf('client-program-curator') > -1
		if (isProgramCurator) programCurator = isProgramCurator
		pervak = roles.indexOf('client-pervak') > -1
	} else {
		notLogin = true
	}

	return (
		<div className={Opened ? 'sidebar-active' : 'sidebar'}>
			{isTokenInLS() ? (
				<div className='sidebar-up'>
					<div className='sidebar-menu__icon'>
						<img src={BurgerIcon} alt='' onClick={() => toggleSidebar()} />
					</div>
					<span>Меню</span>
				</div>
			) : (
				''
			)}

			{Opened &&
				(admin || curator || programCurator ? (
					<ul className='sidebar-menu__list'>
						<Link to='/PersonalAccount/MyData'>
							<li className='sidebar-menu__item'>Личный кабинет</li>
						</Link>
						<Link to='/admin/users'>
							<li className='sidebar-menu__item'>Списки пользователей</li>
						</Link>
						<Link to='/admin/teams'>
							<li className='sidebar-menu__item'>Списки команд</li>
						</Link>
						<Link to='/admin/passportList'>
							<li className='sidebar-menu__item'>Списки паспортов</li>
						</Link>
						<div className='mobile-button-sidebar'>
							<Link to='/company'>
								<li className='sidebar-menu__item'>Компании</li>
							</Link>
							<Link to='/Case'>
								<li className='sidebar-menu__item'>Кейсы</li>
							</Link>
						</div>

						<Link to='/notifications/all'>
							<li className='sidebar-menu__item'>Уведомления и заявки</li>
						</Link>
						<div className='mobile-button-sidebar'>
							<Link to={paths.help.popularQuestion}>
								<li className='sidebar-menu__item'>Помощь</li>
							</Link>
						</div>
					</ul>
				) : student || notLogin ? (
					<ul className='sidebar-menu__list'>
						<Link to='/PersonalAccount/MyData'>
							<li className='sidebar-menu__item'>Личный кабинет</li>
						</Link>
						<Link to='/team'>
							<li className='sidebar-menu__item'>Моя команда</li>
						</Link>
						<Link to='/team/select/search'>
							<li className='sidebar-menu__item'>Поиск команды</li>
						</Link>
						<Link to='/team/passportProject'>
							<li className='sidebar-menu__item'>Паспорт проекта</li>
						</Link>
						<div className='mobile-button-sidebar'>
							<Link to='/company'>
								<li className='sidebar-menu__item'>Компании</li>
							</Link>
							<Link to='/Case'>
								<li className='sidebar-menu__item'>Кейсы</li>
							</Link>
						</div>
						<div className='mobile-button-sidebar'>
							<Link to={paths.help.popularQuestion}>
								<li className='sidebar-menu__item'>Помощь</li>
							</Link>
						</div>

						{!pervak ? (
							<Link to='help/ask-question-form'>
								<li className='sidebar-menu__item'>Задать вопрос</li>
							</Link>
						) : (
							''
						)}
						<Link to='/notifications/all'>
							<li className='sidebar-menu__item'>Уведомления и заявки</li>
						</Link>
					</ul>
				) : customer ? (
					<ul className='sidebar-menu__list'>
						<Link to='/PersonalAccount/MyData'>
							<li className='sidebar-menu__item'>Личный кабинет</li>
						</Link>
						<Link to='*'>
							<li className='sidebar-menu__item'>Команды компании</li>
						</Link>
						<div className='mobile-button-sidebar'>
							<Link to='/company'>
								<li className='sidebar-menu__item'>Компании</li>
							</Link>
							<Link to='/Case'>
								<li className='sidebar-menu__item'>Кейсы</li>
							</Link>
						</div>

						<Link to='/notifications/all'>
							<li className='sidebar-menu__item'>Уведомления и заявки</li>
						</Link>
						<Link to='/connection'>
							<li className='sidebar-menu__item'>Связь с администрацией</li>
						</Link>
					</ul>
				) : (
					''
				))}
		</div>
	)
}

export default Sidebar
