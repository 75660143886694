import axios from 'axios'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import { setEntireTeam, setTeamStatus } from '../../redux/slices/myTeamSlice'
import { RootState } from '../../redux/store'
import { CREATE_COMMAND_MICROSERVICE_URL } from '../../utils/team'
import { getTokens } from '../../utils/token'

const LayoutTeam = () => {
	const { teamStatus } = useSelector((state: RootState) => state.myTeam)

	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		;(async () => {
			const tokens = getTokens()
			if (teamStatus === 'NOT_SELECTED') {
				try {
					const res = await axios.get(
						`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/team`,
						{
							headers: {
								Authorization: `Bearer ${tokens.access_token}`,
							},
						}
					)
					dispatch(setTeamStatus({ status: 'SELECTED' }))
					dispatch(setEntireTeam({ team: res.data.data }))

					navigate('my')
					// navigate("select");
				} catch (error: any) {
					if (error.response.status === 404) {
						console.error(error)
						dispatch(setTeamStatus({ status: 'NOT_SELECTED' }))

						navigate('select')
					} else {
						console.error(error)
						navigate('/AnyError')
					}
				}
			} else {
				navigate('my')
			}
			// navigate("/PersonalAccount");
		})()
	}, [])

	return (
		<>
			<Outlet />
		</>
	)
}

export default LayoutTeam
