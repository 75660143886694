import styles from "./case-content-card.module.scss";

interface CaseContentCardPrompt {
    children?: React.ReactNode;
    low?: boolean;
    blured?: boolean;
    rounded?: boolean;
    isRedactable?: boolean;
}

const CaseContentCard: React.FC<CaseContentCardPrompt> = ({
    children,
    low,
    blured,
    rounded,
    isRedactable = false,
}) => {
    return (
        <div
            className={
                styles.card +
                (low ? ` ${styles.low}` : "") +
                (blured ? ` ${styles.blured}` : "") +
                (rounded ? ` ${styles.rounded}` : "")
            }
        >
            {children}
        </div>
    );
};
export default CaseContentCard;
