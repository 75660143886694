import { useDispatch } from 'react-redux'
import { setSkills } from '../../../../../redux/slices/paSlice'
import MultiSelect from '../../../MultiSelect'
import SingleSelect from '../../../SingleSelect'
import DoubleList from '../../../common/DoubleList/DoubleList'

const MyRole = [
	{ value: `Team Lead`, label: `Team Lead` },
	{ value: `ML Engineer`, label: `ML Engineer` },
	{ value: `Front-end developer`, label: `Front-end developer` },
	{ value: `Back-end developer`, label: `Back-end developer` },
	{ value: `Data Scientist`, label: `Data Scientist` },
	{ value: `Тестировщик (QA)`, label: `Тестировщик (QA)` },
	{ value: `DevOps Engineer`, label: `DevOps Engineer` },
	{ value: `Designer`, label: `Designer` },
	{ value: `Математик`, label: `Математик` },
]

const MySkills = [
	{ value: `C++`, label: `C++` },
	{ value: `Git`, label: `Git` },
	{ value: `Python`, label: `Python` },
	{ value: `Figma`, label: `Figma` },
	{ value: `HTML`, label: `HTNL` },
	{ value: `CSS`, label: `CSS` },
	{ value: `React`, label: `React` },
	{ value: `Machine Learning`, label: `Machine Learning` },
	{ value: `Miro`, label: `Miro` },
	{ value: `C`, label: `C` },
	{ value: `C#`, label: `C#` },
	{ value: `Photoshop`, label: `Photoshop` },
]
interface skillsProps {
	SkillsRole: {
		preferedRole: {
			title: string
		}
		skills: string
	}
}
const RoleEditing: React.FC<skillsProps> = ({ SkillsRole }) => {
	const dispatch = useDispatch()
	const ChangeValuesSkills = (name: any, value: string) => {
		dispatch(setSkills({ name: name, value: value }))
	}
	const handleSelectedSkillsChange = (selectedValues: any) => {
		// Преобразование массива выбранных значений в строку
		const skillsString = selectedValues.join(', ')
		dispatch(setSkills({ name: 'skills', value: skillsString }))
	}

	const parseAdditionals = [
		{
			identifier: 'Предпочтительная роль',
			content: (
				<SingleSelect
					options={MyRole}
					infobefore={SkillsRole.preferedRole?.title}
					onChangeValue={(value: string) => {
						ChangeValuesSkills('preferedRole.title', value)
					}}
				/>
			),
		},
		{
			identifier: 'Навыки',
			content: (
				<MultiSelect
					options={MySkills}
					infobefore={SkillsRole.skills}
					onSelectedValuesChange={handleSelectedSkillsChange}
				/>
			),
		},
	]
	return <DoubleList className={'role'} listData={parseAdditionals} />
}
export default RoleEditing
