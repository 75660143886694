import { createSlice } from "@reduxjs/toolkit";
import { Topic } from "../../@types/entities/Topic";
import { StudentProfile } from "../../@types/entities/StudentProfile";
import { TeamDTO } from "../../@types/dtos/TeamDTO";

// сделать типы

interface TableData {
    value: number;
    currentPage: number;
    totalPages: number;
    searchTerm: string;
    data: Topic[];
}

interface TableSliceState {
    createTeamDataTable: {
        value: number;
        currentPage: number;
        totalPages: number;
        searchTerm: string;
        data: Topic[];
    };
    userListData: {
        value: number;
        currentPage: number;
        totalPages: number;
        searchTerm: string;
        data: StudentProfile[];
    };
    teamListData: {
        value: number;
        currentPage: number;
        totalPages: number;
        searchTerm: string;
        data: TeamDTO[];
    };
}

const initialState: TableSliceState = {
    createTeamDataTable: {
        value: 0,
        currentPage: 1,
        totalPages: 3,
        searchTerm: "",
        data: [
            {
                id: 1,
                title: "Lorem ipsum",
                description:
                    "111Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            },
            {
                id: 2,
                title: "frontend",
                description:
                    "22Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            },
            {
                id: 3,
                title: "backend",
                description:
                    "3333Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            },
            {
                id: 4,
                title: "Lorem ipsum",
                description:
                    "4111Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            },
            {
                id: 5,
                title: "frontend",
                description:
                    "522Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            },
            {
                id: 6,
                title: "backend",
                description:
                    "63333Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            },
            {
                id: 7,
                title: "Lorem ipsum",
                description:
                    "7111Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            },
            {
                id: 8,
                title: "frontend",
                description:
                    "822Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            },
            {
                id: 9,
                title: "backend",
                description:
                    "93333Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            },
        ],
    },
    userListData: {
        value: 0,
        currentPage: 1,
        totalPages: 3,
        searchTerm: "",
        data: [],
    },
    teamListData: {
        value: 0,
        currentPage: 1,
        totalPages: 3,
        searchTerm: "",
        data: [],
    }
};

export const tableSlice = createSlice({
    name: "table",
    initialState: initialState,
    reducers: {
        updateCaseList(state, action) {
            state.createTeamDataTable.data = action.payload;
        },
        setValue: (state, action) => {
            state.createTeamDataTable.value = action.payload;
        },

        setCurrentPage: (state, action) => {
            state.createTeamDataTable.currentPage = action.payload;
        },

        setTotalPages: (state, action) => {
            state.createTeamDataTable.totalPages = action.payload;
        },

        handleSearch: (state, action) => {
            state.createTeamDataTable.searchTerm = action.payload;
            state.createTeamDataTable.currentPage = 1;
        },

        // User List AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAaaaaaaaaaaaaaAAAAAAaAA

        setUserListValue: (state, action) => {
            state.userListData.value = action.payload;
        },

        setUserListCurrentPage: (state, action) => {
            state.userListData.currentPage = action.payload;
        },

        setUserListTotalPages: (state, action) => {
            state.userListData.totalPages = action.payload;
        },

        handleUserListSearch: (state, action) => {
            state.userListData.searchTerm = action.payload;
            state.userListData.currentPage = 1;
        },
        setUserListData: (state, action) => {
            state.userListData.data = action.payload;
            // console.log(state.userListData.data);
        },
        setTeamListData: (state, action) => {
            state.teamListData.data = action.payload.list;
            // console.log(state.userListData.data);
        },
        setAllTeamsListData: (state,action) =>{
            state.createTeamDataTable.data = action.payload
        }
    },
});


export const {
    setValue,
    setCurrentPage,
    setTotalPages,
    handleSearch,
    setUserListValue,
    updateCaseList,
    setUserListCurrentPage,
    setUserListTotalPages,
    handleUserListSearch,
    setUserListData,
    setTeamListData,
} = tableSlice.actions;


export default tableSlice.reducer;
