import { getDecodedToken } from '../../../../../utils/token'
import DoubleList from '../../../common/DoubleList/DoubleList'

function parseAdditionals(About) {
	const otherAnswers = [
		['Да', 'Нет', 'Когда как', 'Затрудняюсь ответить'],
		[
			'В команде',
			'В одиночку',
			'Мне комфортно в любом случае',
			'Затрудняюсь ответить',
		],
		[
			'Делаю работу за всех, самый активный участник',
			'Коммуницирую с командой, выполняю свои задачи во время и качественно',
			'Не против, чтобы мою работу сделали за меня',
			'Затрудняюсь ответить',
		],
		['Да', 'Нет', 'Затрудняюсь ответить'],
		[
			'Рутина',
			'Сложность, непонимание как решать',
			'Необходимость обращаться за консультацией к другим людям',
			'Необходимость контакта с другими членами команды',
			'Ничего из перечисленного',
			'Затрудняюсь ответить',
		],
		[
			'Веду календарь/планер, фиксирую что и когда буду делать',
			'Веду список задач и выполняю их, когда появляется время',
			'Держу всё в голове',
			'Затрудняюсь ответить',
		],
		['Да', 'Нет', 'Затрудняюсь ответить'],
	]

	const CheckRole = () => {
		const roles = getDecodedToken()?.resource_access['spring-client'].roles
		return roles?.includes('client-main-admin')
	}
	return [
		{
			identifier: 'Легко ли вам общаться с людьми?',
			content: (
				<>
					{About?.isEasyGoing}{' '}
					{CheckRole() &&
						otherAnswers[0].map((item, i) => (
							<div className='otherAnswerOfAboutMe'>-{item}</div>
						))}
				</>
			),
		},
		{
			identifier: 'Вы предпочитаете работать в команде или в одиночку?',
			content: (
				<>
					{About?.isSolid}{' '}
					{CheckRole() &&
						otherAnswers[1].map((item, i) => (
							<div className='otherAnswerOfAboutMe'>-{item}</div>
						))}
				</>
			),
		},
		{
			identifier: 'Как вы ведете себя при работе в команде?',
			content: (
				<>
					{About?.behaviourInTeam}{' '}
					{CheckRole() &&
						otherAnswers[2].map((item, i) => (
							<div className='otherAnswerOfAboutMe'>-{item}</div>
						))}
				</>
			),
		},
		{
			identifier: 'Вам нравятся сюрпризы?',
			content: (
				<>
					{About?.doesLikeSurprises}{' '}
					{CheckRole() &&
						otherAnswers[3].map((item, i) => (
							<div className='otherAnswerOfAboutMe'>-{item}</div>
						))}
				</>
			),
		},
		{
			identifier: 'Что вас может напугать/оттолкнуть от задачи?',
			content: (
				<>
					{About?.fears}{' '}
					{CheckRole() &&
						otherAnswers[4].map((item, i) => (
							<div className='otherAnswerOfAboutMe'>-{item}</div>
						))}
				</>
			),
		},
		{
			identifier: 'Как вы относитесь к планированию?',
			content: (
				<>
					{About?.doesLikePlanning}{' '}
					{CheckRole() &&
						otherAnswers[5].map((item, i) => (
							<div className='otherAnswerOfAboutMe'>-{item}</div>
						))}
				</>
			),
		},
		{
			identifier: 'Можете ли вы назвать себя креативной личностью?',
			content: (
				<>
					{About?.isCreative}{' '}
					{CheckRole() &&
						otherAnswers[6].map((item, i) => (
							<div className='otherAnswerOfAboutMe'>-{item}</div>
						))}
				</>
			),
		},
	]
}

export default function AboutMe({ about }) {
	return (
		<DoubleList className={'additionals'} listData={parseAdditionals(about)} />
	)
}
