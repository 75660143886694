import jwt_decode from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Button from '../../hooks/components/common/Button/Button'
import ContainerMain from '../../hooks/components/common/ContainerMain/ContainerMain'
import CaseContentCard from '../../hooks/components/common/ContentCard/CaseContentCard'
import ContentCardWrapper from '../../hooks/components/common/ContentCard/ContentCardWrapper'
import LoadingText from '../../hooks/components/common/Loaders/LoadingText'
import {
	setCompanyList,
	setEdittingStatus,
} from '../../redux/slices/companySlice'
import { RootState } from '../../redux/store'
import { FILES_MICROSERVICE, sendAxiosWithTokens } from '../../utils/team'
import { getTokens, isTokenInLS } from '../../utils/token'
import CompanyCard from './CompanyCard'

const Company = () => {
	const [isLoading, setLoading] = useState<boolean>(true)
	let admin: boolean = false
	let student: boolean = false
	let programmCurator: boolean = false
	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)
		const roles = token.resource_access['spring-client'].roles
		const isAdmin = roles.indexOf('client-main-admin') > -1
		if (isAdmin) admin = isAdmin
		const isStudent = roles.indexOf('client-student') > -1
		const isTeamLead = roles.indexOf('client-teamlead') > -1
		programmCurator = roles.indexOf('client-program-curator') > -1
	}
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { companyList } = useSelector((state: RootState) => state.company)

	useEffect(() => {
		const getCompanys = async () => {
			await sendAxiosWithTokens('get', `${FILES_MICROSERVICE}/api/v1/company`)
				.then(response => {
					dispatch(setCompanyList(response.data.data))
					console.log(response.data.data)
					setLoading(false)
				})
				.catch(error => {
					console.log(error)
				})
		}
		getCompanys()
	}, [])
	const isEditting = useSelector((state: RootState) => state.company.isEditting)
	const ChangeEdittingStatus = () => {
		dispatch(setEdittingStatus(!isEditting))
	}

	const [filteredList, setFilteredList] = useState(companyList)
	const [textval, setTextval] = useState('')

	function handleChangeVal(e: {
		target: { value: React.SetStateAction<string> }
	}) {
		setTextval(e.target.value)
	}
	useEffect(() => {
		if (textval) {
		  setFilteredList(
			companyList
			  .filter((item: any) =>
				item?.company?.title
				  ?.toLowerCase()
				  .includes(textval.toLowerCase())
			  )
			  .slice()
			  .sort((a: any, b: any) =>
				a?.company?.title?.localeCompare(b?.company?.title)
			  )
		  );
		} else {
		  setFilteredList(
			companyList
			  .slice()
			  .sort((a: any, b: any) =>
				a?.company?.title?.localeCompare(b?.company?.title)
			  )
		  );
		}
	}, [textval, companyList]);
	  
	  

	const GoToCreateCompany = () => {
		navigate('/CreateCompany')
	}

	const CreateCuratorFromCompany = () => {
		navigate('/create-curator')
	}

	return (
		<>
			<div className='company-case-help-container'>
				<ContainerMain size='large'>
					<div className='content-main-theme-case'>
						Здесь представлен список всех компаний, с кем мы сотрудничаем
					</div>
					<ContentCardWrapper>
						<CaseContentCard>
							<div className='text-mobile'>
								"IT-компании" в данном контексте можно определить как
								технологические организации различного масштаба и специализации,
								которые вступают в сотрудничество с Цифровой кафедрой
								Московского авиационного института, чтобы предоставить студентам
								возможность работать над реальными проектами. Эти компании,
								включая такие узнаваемые названия как Сбер, С7 ТехЛаб, ООО
								"АБК", ООО "Геоскан", Иркут, ЦАГИ, предлагают практический опыт,
								который помогает студентам глубже понять и применять
								классические теории и методы, а также развивать ценные навыки в
								решении актуальных задач отрасли.
							</div>
							<div className='text-desktop'>
								"IT-компании" в данном контексте можно определить как
								технологические организации различного масштаба и специализации,
								которые вступают в сотрудничество с Цифровой кафедрой
								Московского авиационного института, чтобы предоставить студентам
								возможность работать над реальными проектами. Эти компании,
								включая такие узнаваемые названия как Сбер, С7 ТехЛаб, ООО
								"АБК", ООО "Геоскан", Иркут, ЦАГИ, предлагают практический опыт,
								который помогает студентам глубже понять и применять
								классические теории и методы, а также развивать ценные навыки в
								решении актуальных задач отрасли.
							</div>
						</CaseContentCard>

						<div className='company-button-for-edditing'>
							<div className='case-wrapper-search'>
								Поиск
								<input
									className='case-input'
									type='text'
									onChange={handleChangeVal}
								></input>
							</div>
							<i>
								{admin || programmCurator ? (
									<Button
										styling={isEditting ? 'classic' : 'text'}
										onClick={ChangeEdittingStatus}
									>
										{isEditting ? 'Завершить редактирование' : 'Редактировать'}
									</Button>
								) : null}
								{admin || programmCurator ? (
									<Button styling='text' onClick={GoToCreateCompany}>
										Добавить карточку компании
									</Button>
								) : null}
								{admin || programmCurator ? (
									<Button styling='text' onClick={CreateCuratorFromCompany}>
										Создать куратора/заказчика от&nbsp;компании
									</Button>
								) : null}
							</i>
						</div>
						{isLoading ? <LoadingText /> : ''}
						{filteredList
							.map((CompanyItem: any, i: number) => (
								<CompanyCard
									key={i}
									Company={CompanyItem.company}
									idCompany={CompanyItem.company.id}
									editting={isEditting}
									icon={CompanyItem.icon}
								></CompanyCard>
							))
							}
					</ContentCardWrapper>
				</ContainerMain>
			</div>
		</>
	)
}

export default Company
