import ContentCard from '../../hooks/components/common/ContentCard/ContentCard'

import { getTokens } from './AuthorisationLogic'

const Redirect = () => {
	getTokens()
	return (
		<div className='questionnare-content-wrapper'>
			<ContentCard>
				<h1>Загрузка...</h1>
			</ContentCard>
		</div>
	)
}

export default Redirect
