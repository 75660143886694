import Categories from "./Categories";
import ActiveList from "./ActiveList";

export default function PACategories() {
    return (
        <Categories>
            <ActiveList />
        </Categories>
    );
}
