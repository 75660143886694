import { createSlice } from '@reduxjs/toolkit'
import { Company } from '../../@types/entities/Company'
export interface companySliceState {
	isEditting: boolean
	companyList: Company[]
	activeCompany: {
		title: string
		id?: number
		shortTitle: string
		contactPerson: string
		contactPersonContact: string
	}
}

const initialState: companySliceState = {
	isEditting: false,
	companyList: [],
	activeCompany: {
		title: '',
		id: 0,
		shortTitle: '',
		contactPerson: '',
		contactPersonContact: '',
	},
}

export const companySlice = createSlice({
	name: 'company',
	initialState,
	reducers: {
		setEdittingStatus(state, action) {
			state.isEditting = action.payload
		},
		setActiveCompany(state, action) {
			state.activeCompany = action.payload
		},
		setCompanyList(state, action) {
			state.companyList = action.payload
		},
	},
})

export const { setEdittingStatus, setActiveCompany, setCompanyList } =
	companySlice.actions
export default companySlice.reducer
