import NotifCategories from "./notifCategoties";

export default function NotifRightSB({}) {
    return (
        <div className="personal-account-right-sidebar">
            <div className="personal-account-right-sidebar__categories">
                <NotifCategories />
            </div>
        </div>
    );
}
