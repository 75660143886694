import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TokenType, checkIsTokenExpired, getDecodedToken, getTokens, updateToken } from "../../utils/token";
import axios from "axios";
import { useEffect, useState } from "react";
import { APPLICATION_MICROSERVICE_URL } from "../../utils/team";

interface QuestionSetActivePrompt {
    question:string;
    answer:string;
    id:any;
}

const QuestionSetActiveEdit: React.FC<QuestionSetActivePrompt>= ({ question,answer, id }) => {
    const dispatch = useDispatch();
    let tokens: TokenType = getTokens();
    const navigate = useNavigate();
    

    const PutNewInfo = async (ans: string | undefined ,que: string | undefined, questionId:any) => {
        if (tokens != null) {
            const data_from_token = getDecodedToken();

            if (checkIsTokenExpired(tokens.access_token)) {
                if (checkIsTokenExpired(tokens.refresh_token)) {
                    window.localStorage.removeItem("token");
                    navigate("/");
                } else {
                    await updateToken();
                    tokens = getTokens();
                }
            }
            axios.post(`${APPLICATION_MICROSERVICE_URL}/help/faq/${questionId}`,
            {
                content: ans,
                feedback: que,

            },
            {
                headers: {
                    Authorization: `Bearer ${getTokens().access_token}`,
                },
            }
        ).then((response) => {
            console.log(response)   
        })
        .catch((error) =>{
            console.log(error)
        })
        }
    }


    const [questionContent, setQuestionContent] = useState<string | undefined>(question);
    const [answerContent, setAnswerContent] = useState<string | undefined>(answer);

    useEffect(() => {

        return () => {
            PutNewInfo(questionContent, answerContent,id);
        }
    }, [questionContent, answerContent])

    return (
        <>
           <div className="ask-question-textarea-wrapper">
                        Вопрос:
                        <textarea
                            rows={10}
                            cols={36}
                            value={questionContent}
                            onChange={(e) => setQuestionContent(e.target.value)}
                            className="ask-question-textarea-input12"
                        
                            
                        />
                    </div>
                    <div className="ask-question-textarea-wrapper">
                        Ответ:
                        <textarea
                            rows={10}
                            cols={36}
                            value={answerContent}
                            onChange={(e) => setAnswerContent(e.target.value)}
                            className="ask-question-textarea-input22"
                        />
                        <div className="ask-question-button-wrapper">
                            <Link
                                to="../question-history"
                                className="ask-question-link-wrapper"
                            >
                            </Link>
                        </div>
                    </div>
        </>
    );
};

export default QuestionSetActiveEdit;