import { Link, useNavigate } from 'react-router-dom'

// import { COLUMNS } from "./allStudentColumns";
import { useEffect, useState } from 'react'
import {
	MODERATION_MICROSERVICE_URL,
	sendAxiosWithTokens,
} from '../../../../../utils/team'
import Table from '../../../common/Tables/TableFilterAndPagination'

const AllCompaniesTable = () => {
	const [data, setData] = useState([])
	const navigate = useNavigate()
	const adminRoles: { CUSTOMER: string; CURATOR: string } = {
		CUSTOMER: 'Заказчик',
		CURATOR: 'Куратор',
	}

	useEffect(() => {
		const getData = async () => {
			await sendAxiosWithTokens(
				'get',
				`${MODERATION_MICROSERVICE_URL}/api/v1/admin/users/company_users/`
			)
				.then(r => setData(r.data.data))
				.catch(err => {
					// ErrorHandler(error, navigate)
					console.log(err)
				})
		}
		getData()
	}, [])

	const COLUMNS = [
		{
			Header: '№',
			Cell: ({ row }: { row: any }) => <span>{row.index + 1}</span>,
		},
		{
			Header: 'ФИО пользователя',
			Cell: ({ row }: { row: any }) => <>{row.original.fio || ''}</>,
		},
		{
			Header: 'Компания',
			Cell: ({ row }: { row: any }) => {
				return <>{row.original.company.title || ''}</>
			},
		},
		{
			Header: 'Должность',
			Cell: ({ row }: { row: any }) => {
				// @ts-ignore
				const userRole = adminRoles[row.original.role]
				return <>{userRole || ''}</>
			},
		},
		{
			Header: 'Телеграм',
			accessor: 'telegram_link',
		},
		{
			Header: 'Профиль',
			accessor: 'username',
			Cell: ({ value }: { value: any }) => (
				<Link className='profile-link' to={`/profile/${value}/data`}>
					Перейти
				</Link>
			),
		},
	]

	return (
		<>
			{data.length === 0 ? (
				<span
					style={{
						display: 'flex',
						justifyContent: 'center',
						fontSize: '18px',
					}}
				>
					Список пуст
				</span>
			) : (
				<>
					{' '}
					<Table
						columns={COLUMNS}
						data={data}
						itemsOnPage={10}
						visiblePaginationRange={2}
						className='all-company-table'
					/>
				</>
			)}
		</>
	)
}

export default AllCompaniesTable
