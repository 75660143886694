import jwt_decode from 'jwt-decode'
import { ReactComponent as YourQuestionSend1 } from '../../assets/img/icons/ura.svg'
import { ReactComponent as YourQuestionSend2 } from '../../assets/img/icons/your-question-send.svg'
import Button from '../../hooks/components/common/Button/Button'
import { getTokens, isTokenInLS } from '../../utils/token'

let Modal = ({ active, setActive }) => {
	let admin = false
	let student = false

	if (isTokenInLS()) {
		const token = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		const isAdmin = roles.indexOf('client-admin') > -1
		if (isAdmin) admin = isAdmin
		const isStudent = roles.indexOf('client-student') > -1
		const isTeamLead = roles.indexOf('client-teamlead') > -1
	}
	return (
		<div className={active ? 'modal-active' : 'modal'}>
			<div className='modal-content'>
				<h1 className='modal-content-title'>
					{admin ? 'Ваш вопрос был создан' : 'Ваш вопрос был отправлен'}
				</h1>
				<h4 className='modal-content-description'>
					{admin
						? 'Ура-а-а, меньше вопросов!'
						: 'Ожидайте, скоро на него ответят!'}
				</h4>
				{admin ? <YourQuestionSend1 /> : <YourQuestionSend2 />}
				<Button
					onClick={() => setActive(false)}
					action='link'
					spacing='16'
					to='../question-history'
				>
					Хорошо
				</Button>
			</div>
		</div>
	)
}

export default Modal
