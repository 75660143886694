import AirPlan from '../../../assets/img/AirPlan.svg'
import styles from './index.module.scss'

const AnyErrorsPage = ({ message = '' }: { message?: string }) => {
	return (
		<div className={styles['Terror']}>
			<img src={AirPlan} className={styles['samoletic']} />
			<div className={styles['errorBlock']}>
				<h1 className={styles['errorTitle']}>{message}</h1>
			</div>
			<h2 className={styles['errorTitle2']}>Упс, что-то пошло не так..</h2>
			<div>
				<a href='https://digital.mai.ru/' className={styles['buttonText']}>
					Перейти на главную страницу
				</a>
			</div>
		</div>
	)
}

export default AnyErrorsPage
