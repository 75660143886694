import Categories from "../RightSidebar/Categories";
import ViewedUserActiveList from "./ViewedUserActiveList";

export default function ViewedUserCategories() {
    return (
        <Categories>
            <ViewedUserActiveList />
        </Categories>
    );
}
