import card from "./content-card.module.scss";
import React from "react";

interface ContentCardFooterProps {
    children: React.ReactNode;
}

const ContentCardFooter = ({ children }: ContentCardFooterProps) => {
    return <footer className={card["footer"]}>{children}</footer>;
};

export default ContentCardFooter;
