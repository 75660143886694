import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import { checkIsTokenExpired, getTokens, updateToken } from "../../../utils/token";
import { CREATE_COMMAND_MICROSERVICE_URL } from "../../../utils/team";

const UserForm = () => {
    // @ts-ignore
    const navigate = useNavigate();

    useEffect(() => {
        const fn = async () => {
            let token = getTokens();

            if (checkIsTokenExpired(token.access_token)) {
                if (checkIsTokenExpired(token.refresh_token)) navigate("/");
                console.log(token);
                updateToken();
                console.log(token);
            }
            token = getTokens();

            await axios
                .get(`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/user`, {
                    headers: {
                        Authorization: `Bearer ${token.access_token}`,
                    },
                })
                .then((resp) => {
                    if (resp.data.data === false) navigate("/PersonalAccount/MyData");
                })
                .catch((error) => {
                    if (error.status === 404) {
                        // navigate('/not-found')
                    }
                });
        };
        fn();
    }, []);

    return (
        <div className="user-form-wrapper">
            <Outlet />
        </div>
    );
};

export default UserForm;
