import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Cell } from 'react-table'
import { TopicDTO } from '../../../@types/createTopic'
import { setTeamInfo } from '../../../redux/slices/myTeamSlice'
import { RootState } from '../../../redux/store'
import {
	fetchCaseTopicList,
	sendChoosenTopic,
	updateTeamData,
} from '../../../utils/team'
import Button from '../common/Button/Button'
import ButtonWrapper from '../common/Button/ButtonWrapper'
import FoldedText from '../common/FoldedText/FoldedText'
import CheckBox from '../common/Form/CheckBox'
import LoadingText from '../common/Loaders/LoadingText'
import Table from '../common/Tables/TableFilterAndPagination'

const SelectCaseWrapper = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [caseTopicList, setCaseTopicList] = useState<TopicDTO[]>([])
	const { team } = useSelector((state: RootState) => state.myTeam)
	const [selectedTopicId, setSelectedTopicId] = useState<number | null>(
		team?.topic?.id || null
	)

	useEffect(() => {
		;(async () => {
			await updateTeamData()
			const allTopics = await fetchCaseTopicList()
			setCaseTopicList(allTopics)
		})()
	}, [])

	useEffect(() => {
		setSelectedTopicId(team?.topic?.id || null)
	}, [team])

	const COLUMNS = [
		{
			Header: '№',
			Cell: ({ row }: { row: any }) => <span>{row.index + 1}</span>,
		},
		{
			Header: 'Заказчик',
			accessor: 'company',
			Cell: ({ value }: Cell) => {
				return <span>{value?.title}</span>
			},
		},
		{
			Header: 'Название кейса',
			accessor: 'title',
		},
		{
			Header: 'Описание кейса',
			accessor: 'description',
			Cell: ({ value }: Cell) => {
				return (
					<FoldedText buttonCenter textCenter>
						{value}
					</FoldedText>
				)
			},
		},
		{
			Header: 'Выбрать',
			accessor: 'id',
			Cell: ({ value }: Cell) => {
				return (
					<CheckBox
						round
						checked={selectedTopicId ? selectedTopicId === value : false}
						onClick={() => {
							setSelectedTopicId(value)
						}}
					/>
				)
			},
		},
	]

	return (
		<>
			<div className='table-wrapper'>
				{caseTopicList.length === 0 ? (
					<LoadingText center />
				) : (
					<Table
						className='select-case-table'
						columns={COLUMNS}
						data={caseTopicList}
						itemsOnPage={10}
					/>
				)}
			</div>
			<ButtonWrapper spacing>
				<Button
					action='link'
					to='../'
					disabled={selectedTopicId == null}
					onClick={() => {
						dispatch(
							setTeamInfo({
								name: 'topic',
								value: caseTopicList.find(
									topic => topic?.id === selectedTopicId
								)!,
							})
						)
						sendChoosenTopic(team?.id, selectedTopicId!)
							.then(console.log)
							.catch(console.error)
					}}
				>
					Сохранить
				</Button>
			</ButtonWrapper>
		</>
	)
}

export default SelectCaseWrapper
