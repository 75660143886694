import React from "react";
import placeholderImg from "../../../src/assets/img/icons/Logo.png";

interface LogoProps {
    imageURL?: string;
}

const Logo = ({ imageURL = "" }: LogoProps) => {
    return (
        <div className="case-profile-image-wrapper">
            <img
                src={Boolean(imageURL) ? imageURL : placeholderImg}
                alt="Logo Photo."
                className="logo-image"
            />
        </div>
    );
};

export default Logo;
