import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { useNavigate } from 'react-router-dom'
import { Notification } from '../../@types/dtos/Notifiation'
import notifStyles from '../../assets/styles/components/notifStyle.module.scss'
import PaginationBar from '../../hooks/components/common/Tables/PaginationBar'
import { RootState } from '../../redux/store'
import { useGetNotifications } from '../../utils/useGetNotifications'
import { useResponseOnApplication } from '../../utils/useResponseOnApplication'
import ViewNotification from './ViewNotifications'

const NotifcationPage: React.FC = () => {
	const [currentPage, setCurrentPage] = useState(0)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [searchValue, setSearchValue] = useState('')

	const notificationsListAll = useSelector(
		(state: RootState) => state.NotifcationReducer.notifications
	)
	const ReversedNotificationListAll = [...notificationsListAll].reverse()
	const readNotificationsList = ReversedNotificationListAll.filter(
		notif => notif.wasRead
	)
	const unreadNotificationsList = ReversedNotificationListAll.filter(
		notif => !notif.wasRead
	)

	const activeIndex = useSelector(
		(state: RootState) => state.activeIndexReducer.activeIndex
	)

	const getNotifications = useGetNotifications()

	const [pageOption, setPageOption] = useState<number[]>([])
	let currentList: any[] = []
	switch (activeIndex) {
		case 0:
			currentList = ReversedNotificationListAll
			break
		case 1:
			currentList = readNotificationsList
			break
		case 2:
			currentList = unreadNotificationsList
			break
		default:
			currentList = []
	}
	let totalpages: number =
		Math.floor(currentList.length / 20) +
		(currentList.length % 20 !== 0 ? 1 : 0)

	const CurrentListToShow = (list: any[]) => {
		const startIndex = currentPage * 20
		const endIndex = startIndex + 20
		return list.slice(startIndex, endIndex)
	}

	const ListToShow = CurrentListToShow(currentList)

	useEffect(() => {
		const pages = Array.from({ length: totalpages }, (_, i) => i)
		setPageOption(pages)
	}, [totalpages, currentPage, activeIndex])

	const { PutRequestForReadMessage } = useResponseOnApplication()

	const ClickOnMessageForRead = (notificationId: number | undefined) => {
		PutRequestForReadMessage(notificationId)
	}
	function handleChangeVal(e: {
		target: { value: React.SetStateAction<string> }
	}) {
		setSearchValue(e.target.value)
	}
	const [FilteredList, setFilteredList] = useState<Notification[]>(ListToShow)
	useEffect(() => {
		if (searchValue) {
			setFilteredList(
				ListToShow.filter(item =>
					item.content.toLowerCase().includes(searchValue.toLowerCase())
				)
			)
		} else {
			setFilteredList(ListToShow)
		}
	}, [searchValue])

	return (
		<>
			<div className={notifStyles.mainSection}>
				<ViewNotification
					currentList={currentList}
					ListToShow={FilteredList}
					ClickOnMessageForRead={ClickOnMessageForRead}
				/>
			</div>
			<div className={notifStyles.footerSection}>
				<PaginationBar
					pageIndex={currentPage}
					visiblePaginationRange={2}
					pageOptions={pageOption}
					pageCount={totalpages}
					previousPage={() => setCurrentPage(old => Math.max(old - 1, 0))}
					nextPage={() =>
						setCurrentPage(old => Math.min(old + 1, totalpages - 1))
					}
					gotoPage={(page: number) => setCurrentPage(page)}
					canPreviousPage={currentPage > 0}
					canNextPage={currentPage < totalpages - 1}
				/>

				<input
					type='text'
					className='case-input'
					onChange={handleChangeVal}
					placeholder='Поиск'
				/>
			</div>
		</>
	)
}
export default NotifcationPage
