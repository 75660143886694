import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import UpdatePassportDTO from '../../../../@types/dtos/UpdatePassportDTO'
import Button from '../../../../hooks/components/common/Button/Button'
import ContainerMain from '../../../../hooks/components/common/ContainerMain/ContainerMain'
import ContentCard from '../../../../hooks/components/common/ContentCard/ContentCard'
import ContentCardWrapper from '../../../../hooks/components/common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../../../hooks/components/common/ContentMainTheme'
import DoubleList from '../../../../hooks/components/common/DoubleList/DoubleList'
import { setPasportCase } from '../../../../redux/slices/companyPasportSlice'
import {
	PASSPORT_MICROSERVICE,
	sendAxiosWithTokens,
} from '../../../../utils/team'

const CreatePasport = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const {
		register,
		getValues,
		formState: { errors, isValid },
	} = useForm<UpdatePassportDTO>({
		mode: 'onTouched',
	})

	const CreateNewPasport = async (data: UpdatePassportDTO) => {
		await sendAxiosWithTokens(
			`post`,
			`${PASSPORT_MICROSERVICE}/api/v1/passport/${location.state.caseId}`,
			data
		)
			.then(r => {
				console.log(r)
			})
			.catch(error => {
				console.error(error)
			})

		;(async () => {
			await sendAxiosWithTokens(
				`get`,
				`${PASSPORT_MICROSERVICE}/api/v1/passport/${location.state.caseId}`
			)
				.then(resp => {
					dispatch(setPasportCase(resp.data.data))
				})
				.catch(error => {
					// console.log(error)
				})
		})()
	}

	const onCreateNewPasport = () => {
		const value = getValues()
		const data: UpdatePassportDTO = {
			endUser: value['endUser'],
			projectDuration: value['projectDuration'],
			projectType: value['projectType'],
			projectGoal: value['projectGoal'],
			projectTasks: value['projectTasks'],
			projectResult: value['projectResult'],
			projectRestrictions: value['projectRestrictions'],
			projectResources: value['projectResources'],
			projectTaskTracker: value['projectTaskTracker'],
			projectCompitentions: value['projectCompitentions'],
			additionalInformation: value['additionalInformation'],
			projectMentor: value['projectMentor'],
			projectSupervisor: value['projectSupervisor'],
			example: value['example'],
		}

		CreateNewPasport(data)
		navigate(`/Case/Info/${location.state.caseId}`, { state: 1 })
	}

	return (
		<ContainerMain size='medium'>
			<ContentMainTheme>
				Создание паспорта проекта для кейса "{location?.state?.caseTitle}"
			</ContentMainTheme>
			<ContentCardWrapper>
				<ContentCard low blured>
					<DoubleList
						className='initials'
						listData={[
							{
								identifier: 'Конечный пользователь',
								content: (
									<>
										<textarea
											className='info-block-textareaForEdit'
											{...register('endUser', {
												required: 'Необходимо заполнить',
											})}
										/>
										{errors.endUser && (
											<p className='form-field-input--error-message'>
												{errors.endUser.message}
											</p>
										)}
									</>
								),
							},
							{
								identifier: 'Продолжительность проекта',
								content: (
									<>
										<textarea
											className='info-block-textareaForEdit'
											{...register('projectDuration', {
												required: 'Необходимо заполнить',
											})}
										/>
										{errors.projectDuration && (
											<p className='form-field-input--error-message'>
												{errors.projectDuration.message}
											</p>
										)}
									</>
								),
							},
							{
								identifier:
									'Тип продукта (сервис, продукт, алгоритм, модель и т.п.',
								content: (
									<>
										<textarea
											className='info-block-textareaForEdit'
											{...register('projectType', {
												required: 'Необходимо заполнить',
											})}
										/>
										{errors.projectType && (
											<p className='form-field-input--error-message'>
												{errors.projectType.message}
											</p>
										)}
									</>
								),
							},
							{
								identifier: 'Цель проекта',
								content: (
									<>
										<textarea
											className='info-block-textareaForEdit'
											{...register('projectGoal', {
												required: 'Необходимо заполнить',
											})}
										/>
										{errors.projectGoal && (
											<p className='form-field-input--error-message'>
												{errors.projectGoal.message}
											</p>
										)}
									</>
								),
							},
							{
								identifier: 'Задачи проекта',
								content: (
									<>
										<textarea
											rows={10}
											cols={40}
											className='info-block-textareaForEdit'
											{...register('projectTasks', {
												required: 'Необходимо заполнить',
											})}
										/>
										{errors.projectTasks && (
											<p className='form-field-input--error-message'>
												{errors.projectTasks.message}
											</p>
										)}
									</>
								),
							},
							{
								identifier: 'Результат проекта',
								content: (
									<>
										<textarea
											rows={10}
											cols={40}
											className='info-block-textareaForEdit'
											{...register('projectResult', {
												required: 'Необходимо заполнить',
											})}
										/>
										{errors.projectResult && (
											<p className='form-field-input--error-message'>
												{errors.projectResult.message}
											</p>
										)}
									</>
								),
							},
							{
								identifier: 'Ограничения проекта',
								content: (
									<>
										<textarea
											rows={10}
											cols={40}
											className='info-block-textareaForEdit'
											{...register('projectRestrictions', {
												required: 'Необходимо заполнить',
											})}
										/>
										{errors.projectRestrictions && (
											<p className='form-field-input--error-message'>
												{errors.projectRestrictions.message}
											</p>
										)}
									</>
								),
							},
							{
								identifier:
									'Необходимые ресурсы для выполнения проекта(источник данных)',
								content: (
									<>
										<textarea
											rows={10}
											cols={40}
											className='info-block-textareaForEdit'
											{...register('projectResources', {
												required: 'Необходимо заполнить',
											})}
										/>
										{errors.projectResources && (
											<p className='form-field-input--error-message'>
												{errors.projectResources.message}
											</p>
										)}
									</>
								),
							},
							{
								identifier: 'Трекер задач (Miro, Trello и т.п.)',
								content: (
									<>
										<textarea
											className='info-block-textareaForEdit'
											{...register('projectTaskTracker', {
												required: 'Необходимо заполнить',
											})}
										/>
										{errors.projectTaskTracker && (
											<p className='form-field-input--error-message'>
												{errors.projectTaskTracker.message}
											</p>
										)}
									</>
								),
							},
							{
								identifier:
									'Пререквизиты проекта(компетенции участников команды)',
								content: (
									<>
										<textarea
											rows={10}
											cols={40}
											className='info-block-textareaForEdit'
											{...register('projectCompitentions', {
												required: 'Необходимо заполнить',
											})}
										/>
										{errors.projectCompitentions && (
											<p className='form-field-input--error-message'>
												{errors.projectCompitentions.message}
											</p>
										)}
									</>
								),
							},
							{
								identifier: 'Дополнительная информация',
								content: (
									<>
										<textarea
											className='info-block-textareaForEdit'
											{...register('additionalInformation', {
												required: 'Необходимо заполнить',
											})}
										/>
										{errors.additionalInformation && (
											<p className='form-field-input--error-message'>
												{errors.additionalInformation.message}
											</p>
										)}
									</>
								),
							},
							{
								identifier: 'Ментор проекта',
								content: (
									<>
										<textarea
											className='info-block-textareaForEdit'
											{...register('projectMentor', {
												required: 'Необходимо заполнить',
											})}
										/>
										{errors.projectMentor && (
											<p className='form-field-input--error-message'>
												{errors.projectMentor.message}
											</p>
										)}
									</>
								),
							},
							{
								identifier: 'Руководитель проекта',
								content: (
									<>
										<textarea
											className='info-block-textareaForEdit'
											{...register('projectSupervisor', {
												required: 'Необходимо заполнить',
											})}
										/>
										{errors.projectSupervisor && (
											<p className='form-field-input--error-message'>
												{errors.projectSupervisor.message}
											</p>
										)}
									</>
								),
							},
							{
								identifier: 'Пример',
								content: (
									<>
										<textarea
											rows={10}
											cols={40}
											className='info-block-textareaForEdit'
											{...register('example', {
												required: 'Необходимо заполнить',
											})}
										/>
										{errors.example && (
											<p className='form-field-input--error-message'>
												{errors.example.message}
											</p>
										)}
									</>
								),
							},
						]}
					/>
					<div className='company-Go-To-Back-Button-In-Info'>
						<Button onClick={onCreateNewPasport} disabled={!isValid}>
							Создать
						</Button>
					</div>
				</ContentCard>
			</ContentCardWrapper>
		</ContainerMain>
	)
}

export default CreatePasport
