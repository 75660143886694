import { PayloadAction, createSlice } from '@reduxjs/toolkit'

const initialState = {
	profile: {
		//======================My data=============================
		Mydata: {
			isInfoHidden: true,
			maiemail: ``,
			phoneNumber: ``,
			username: ``,
			surname: ``,
			name: ``,
			fio: '',
			patronymic: ``,
			telegram_link: ``,
			email: ``,
			programTitle: ``,
			dppgroup: ``,
			birthDate: ``,
			degree: ``,
			programCurator: '',
			teamTitle: '',
			teamId: 0,
			academicGroup: '',
			companyTitle: '',
			role: '',
		},
		//=======================Skills==============================
		Skills: {
			preferedRole: {
				title: `Team Lead`,
				id: 1,
			},
			skills: ``,
			isEasyGoing: ``,
			isSolid: ``,
			behaviourInTeam: ``,
			doesLikeSurprises: ``,
			fears: ``,
			doesLikePlanning: ``,
			isCreative: ``,
		},
	},
}
type Skills = {
	preferedRole: {
		title: string
		id: number
	}
	skills: string
}
type skillsKeys = keyof Skills

type aboutME = {
	isEasyGoing: string
	isSolid: string
	behaviourInTeam: string
	doesLikeSurprises: string
	fears: string
	doesLikePlanning: string
	isCreative: string
}
type aboutmeKeys = keyof aboutME

type user = {
	maiemail: string
	phoneNumber: string
	username: string
	surname: string
	name: string
	patronymic: string
	telegram_link: string
	email: string
	programTitle: string
	dppgroup: string
	birthDate: string
	degree: string
	programCurator: string
	teamTitle: string
	academicGroup: string
	companyTitle: string
	role: string
	teamId: number
}
type userKeys = keyof user

export const paSlice = createSlice({
	name: 'pa',
	initialState,
	reducers: {
		updateMydata: (state, action) => {
			state.profile.Mydata = action.payload
		},
		updateSkills: (state, action) => {
			state.profile.Skills = action.payload
		},
		setMainInfo(state, action: PayloadAction<{ name: userKeys; value: any }>) {
			if (action.payload.name in state?.profile?.Mydata) {
				;(state.profile.Mydata as any)[action.payload.name] =
					action.payload.value
			}
		},
		setAboutMe(
			state,
			action: PayloadAction<{ name: aboutmeKeys; value: string }>
		) {
			if (action.payload.name in state?.profile?.Skills) {
				;(state.profile.Skills as any)[action.payload.name] =
					action.payload.value
			}
		},
		setSkills(
			state,
			action: PayloadAction<{
				name: skillsKeys | 'preferedRole.title' | 'preferedRole.id'
				value: string
				id?: number
			}>
		) {
			if (action.payload.name === 'preferedRole.title') {
				if (!state.profile.Skills.preferedRole) {
					state.profile.Skills.preferedRole = { title: 'Team Lead', id: 1 }
				}
				state.profile.Skills.preferedRole.title = action.payload.value
			} else if (
				action.payload.name === 'preferedRole.id' &&
				action.payload.id
			) {
				state.profile.Skills.preferedRole.id = action.payload.id
			} else if (action.payload.name in state?.profile?.Skills) {
				;(state.profile.Skills as any)[action.payload.name] =
					action.payload.value
			}
		},
	},
})

export const {
	updateMydata,
	updateSkills,
	setMainInfo,
	setAboutMe,
	setSkills,
} = paSlice.actions
export default paSlice.reducer
