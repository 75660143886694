import styles from "./container.module.scss";
import React from "react";

interface ContainerMainPrompt {
	children?: React.ReactNode;
	size?: "large" | "medium" | "small";
	relative?: boolean;
}

const ContainerMain = ({ children, size = "medium", relative }: ContainerMainPrompt) => {
	return (
		<div
			className={[
				styles["main"],
				styles[`main--${size}`],
				relative && styles["relative"],
			].join(" ")}
		>
			{children}
		</div>
	);
};

export default ContainerMain;
