import { Link } from 'react-router-dom'

// import { COLUMNS } from "./allStudentColumns";
import axios from 'axios'
import { useEffect, useState } from 'react'
import { Cell } from 'react-table'
import { TeamDTO } from '../../../../../@types/createCommand'
import { CREATE_COMMAND_MICROSERVICE_URL } from '../../../../../utils/team'
import { getTokens } from '../../../../../utils/token'
import FoldedText from '../../../common/FoldedText/FoldedText'
import Table from '../../../common/Tables/TableFilterAndPagination'

const AllAdminsTeamsTable = ({
	teamsFromExtendedSearch = [],
}: {
	teamsFromExtendedSearch?: TeamDTO[]
}) => {
	const [data, setData] = useState<TeamDTO[]>([])
	const [isVisible, setVisibility] = useState(false)
	const [isLoading, setIsLoading] = useState<boolean>(true)
	useEffect(() => {
		const getData = async () => {
			const tokens = getTokens()
			await axios
				.get(`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/team/all`, {
					headers: {
						Authorization: `Bearer ${tokens.access_token}`,
					},
				})
				.then(result => {
					console.log(result.data.data)
					setData(result.data.data)
					setIsLoading(false)
				})
				.catch(error => {
					console.error(error)
				})
		}
		getData()
	}, [])

	const COLUMNS = [
		{
			Header: '№',
			Cell: ({ row }: { row: any }) => <span>{row.index + 1}</span>,
		},
		{
			Header: 'Название команды',
			accessor: 'title',
		},
		{
			Header: 'Количество человек',
			accessor: 'studentsCount',
		},
		{
			Header: 'Описание команды',
			accessor: 'description',
			Cell: ({ value }: { value: any }) => (
				<>
					<FoldedText buttonCenter textCenter>
						{value}
					</FoldedText>
				</>
			),
		},
		{
			Header: 'Просмотр',
			Cell: ({ row }: Cell<TeamDTO>) => (
				<Link className='profile-link' to={`/team/${row?.original.id}`}>
					перейти
				</Link>
			),
		},
	]

	return (
		<>
			{isLoading ? (
				'Loading...'
			) : (
				<>
					{data.length == 0 ? (
						'Команд пока нет'
					) : (
						<Table
							columns={COLUMNS}
							data={
								teamsFromExtendedSearch.length == 0
									? data
									: teamsFromExtendedSearch
							}
							itemsOnPage={10}
							visiblePaginationRange={2}
							className='all-user-table'
						/>
					)}
				</>
			)}
		</>
	)
}

export default AllAdminsTeamsTable
