import { useState } from 'react'
import { useSelector } from 'react-redux'
import ContentCard from '../../../../hooks/components/common/ContentCard/ContentCard'
import { RootState } from '../../../../redux/store'
import ExampleProjEdit from './ExampleProjEdit'

const ExampleProject = ({
	caseId,
	admin,
}: {
	caseId?: number
	admin: boolean
}) => {
	const { PasportCase } = useSelector(
		(state: RootState) => state.companyPasportSlice
	)

	const [pasportExampleEdit, setpasportExampleEdit] = useState<boolean>(false)

	const ExampleProjectEdit = {
		status: pasportExampleEdit,
		toggle: () => setpasportExampleEdit(!pasportExampleEdit),
		content: (
			<ExampleProjEdit
				caseId={caseId}
				toggle={() => setpasportExampleEdit(!pasportExampleEdit)}
			/>
		),
	}

	return (
		<ContentCard
			topic={!admin ? null : ' '}
			blured
			myEditButton
			editing={admin ? ExampleProjectEdit : undefined}
		>
			{PasportCase?.example}
		</ContentCard>
	)
}

export default ExampleProject
