import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { PASSPORT_MICROSERVICE } from '../../../../utils/team'
import { TokenType, getTokens } from '../../../../utils/token'
import ContainerMain from '../../common/ContainerMain/ContainerMain'
import ContentCard from '../../common/ContentCard/ContentCard'
import ContentCardHeading from '../../common/ContentCard/ContentCardHeading'
import ContentCardInner from '../../common/ContentCard/ContentCardInner'
import ContentCardWrapper from '../../common/ContentCard/ContentCardWrapper'
import Table from '../../common/Tables/TableFilterAndPagination'
import ModalContentPassport from './ModalContentPassport'

interface TableDTO {
	message?: null
	error?: null
	data: getData[]
}

interface getData {
	feedback?: string
	teamTitle?: string
	programTitle?: string
	filename?: string
}

const PassportList = () => {
	const [data, setData] = useState<TableDTO[]>([])
	const [showContent, setShowContent] = useState<boolean>(false)
	const [teamid, setTeamid] = useState<number>(0)
	const [info, setInfo] = useState<string>('')

	useEffect(() => {
		const getData = async () => {
			const tokens = getTokens()
			await axios
				.get(`${PASSPORT_MICROSERVICE}/passport/command/getTableData`, {
					headers: {
						Authorization: `Bearer ${tokens.access_token}`,
					},
				})
				.then(result => {
					console.log(result.data.data)
					setData(result.data.data)
					setIsLoading(false)
				})
				.catch(error => {
					alert('Что-то пошло не так')
					console.error(error)
				})
		}
		getData()
	}, [])

	async function downloadFile(filename: string) {
		let tokens: TokenType = getTokens()
		try {
			const response = await axios.get(
				`${PASSPORT_MICROSERVICE}/api/v1/passport/download/${filename}`,
				{
					responseType: 'blob',
					headers: {
						Authorization: `Bearer ${tokens.access_token}`,
					},
				}
			)

			const url = window.URL.createObjectURL(new Blob([response.data]))

			const link = document.createElement('a')
			link.href = url

			let fileName = 'commandPassport.pdf'

			link.setAttribute('download', fileName)
			document.body.appendChild(link)
			link.click()
			link.remove()
		} catch (error) {
			console.error('Произошла ошибка при скачивании файла:', error)
		}
	}

	const COLUMNS = [
		{
			Header: '№',
			Cell: ({ row }: { row: any }) => <span>{row.index + 1}</span>,
		},
		{
			Header: 'Название команды',
			accessor: 'teamTitle',
		},
		{
			Header: 'Программа',
			accessor: 'programTitle',
		},
		{
			Header: 'Паспорт',
			accessor: 'filename',
			Cell: ({ value, row }: { value: any; row: any }) => (
				<>
					{value === 'У команды еще нет паспорта' ? (
						<span>{value}</span>
					) : (
						<Link
							className='profile-link'
							to='#'
							onClick={() => downloadFile(row.original.filename)}
						>
							Скачать паспорт
						</Link>
					)}
				</>
			),
		},
		{
			Header: 'Комментарий',
			accessor: 'feedback',
			Cell: ({ value, row }: { value: any; row: any }) => (
				<>
					{value ? (
						<div>
							<h6>{value}</h6>
							<Link
								className='profile-link'
								to='#'
								onClick={() => {
									setShowContent(true)
									setTeamid(row.original.teamId)
									setInfo(value)
								}}
							>
								Редактировать
							</Link>
						</div>
					) : row.original.filename === 'У команды еще нет паспорта' ? (
						<div>
							<h6>Пока пусто</h6>
						</div>
					) : (
						<div>
							<Link
								className='profile-link'
								to='#'
								onClick={() => {
									setShowContent(true)
									setTeamid(row.original.teamId)
									setInfo('')
								}}
							>
								Написать комментарий
							</Link>
						</div>
					)}
				</>
			),
		},
	]

	const [isLoading, setIsLoading] = useState<boolean>(true)

	return (
		<>
			<ContainerMain>
				<ContentCardWrapper>
					{showContent && (
						<ModalContentPassport
							setWindowContent={setShowContent}
							id={teamid}
							info={info}
						/>
					)}
					<ContentCard>
						<ContentCardHeading topic='Список паспортов'></ContentCardHeading>
						<ContentCardInner>
							{isLoading ? (
								'Loading...'
							) : (
								<>
									{data.length == 0 ? (
										'Команд пока нет'
									) : (
										<div className='table-wrapper'>
											<Table
												columns={COLUMNS}
												data={data}
												itemsOnPage={15}
												className='all-user-table'
											/>
										</div>
									)}
								</>
							)}
						</ContentCardInner>
					</ContentCard>
				</ContentCardWrapper>
			</ContainerMain>
		</>
	)
}

export default PassportList
