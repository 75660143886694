import { useDispatch, useSelector } from 'react-redux'
import { toggleUserSkills } from '../../../../redux/slices/userQuestionnaireSlice'
import { RootState } from '../../../../redux/store'
import Button from '../../common/Button/Button'
import FormCheckboxItem from '../../common/Form/FormCheckboxItem'
import FormNavigateButtons from '../../common/Form/FormNavigateButtons'

const SkillsWrapper = () => {
	const dispatch = useDispatch()

	const { user, allSkills, skills, anotherSkills } = useSelector(
		(state: RootState) => state.userQuestionnaire
	)

	const toggleSkill = ({ target }: any) => {
		dispatch(toggleUserSkills({ skill: target?.textContent }))
	}

	const canGoNext = () => {
		return skills.length !== 0 || anotherSkills.length !== 0
	}

	const removeAnother = () => {
		if (skills.includes('Другое'))
			dispatch(toggleUserSkills({ skill: 'Другое' }))
	}

	return (
		<>
			<div className='input-checkbox-wrapper'>
				{[...allSkills, 'Другое'].map(skill => (
					<FormCheckboxItem
						key={skill}
						value={skill}
						checked={
							skill === 'Другое'
								? anotherSkills.length !== 0 || skills.includes(skill)
								: skills.includes(skill)
						}
						onClick={toggleSkill}
					/>
				))}
			</div>
			<FormNavigateButtons>
				<Button action={'link'} to='../role' spacing='8'>
					Назад
				</Button>
				<Button
					action='link'
					to={
						skills.includes('Другое') || anotherSkills.length !== 0
							? '../skills/another'
							: '../survey'
					}
					disabled={!canGoNext()}
					onClick={removeAnother}
				>
					Далее
				</Button>
			</FormNavigateButtons>
		</>
	)
}

export default SkillsWrapper
