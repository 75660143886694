import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { TeamDTO } from '../../@types/createCommand'
import { UserInTeamDTO } from '../../@types/dtos/UserInTeamDTO'
import { ApplicationType } from '../../@types/entities/ApplicationType'
import notifStyle from '../../assets/styles/components/notifStyle.module.scss'
import ModalWindow from '../../components/common/ModalWindow/ModalWindow'
import SquadAbout from '../../hooks/components/Team/SquadAbout'
import TeamCommonInformation from '../../hooks/components/Team/TeamCommonInformation'
import ContainerMain from '../../hooks/components/common/ContainerMain/ContainerMain'
import ContentCard from '../../hooks/components/common/ContentCard/ContentCard'
import ContentCardInner from '../../hooks/components/common/ContentCard/ContentCardInner'
import ContentCardWrapper from '../../hooks/components/common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../hooks/components/common/ContentMainTheme'
import { setCurrentPage } from '../../redux/slices/currentPageReducer'
import {
	APPLICATION_MICROSERVICE_URL,
	fetchSquadList,
	fetchTeamInfo,
	sendAxiosWithTokens,
} from '../../utils/team'
import { ModalContent } from '../notifications/ModalContent'

const TeamPage = () => {
	const [team, setTeam] = useState<TeamDTO | null>(null)
	const [squad, setSquad] = useState<UserInTeamDTO[] | null>(null)
	const location = useLocation()
	const pageIndex = location.state?.pageIndex || 0
	const idTeam = location.state?.idTeam

	const previousPath = location.state?.previousPath
	const [windowContent, setWindowContent] = useState<React.ReactNode>(null)
	const dispatch = useDispatch()

	const handleSetCurrentPage = (pageIndex: number) => {
		dispatch(setCurrentPage(pageIndex))
		window.history.back()
	}

	const { teamId } = useParams()

	useEffect(() => {
		fetchTeamInfo(Number(teamId)).then(setTeam).catch(console.error)
		fetchSquadList(Number(teamId)).then(setSquad).catch(console.error)
		console.log(teamId)
	}, [])
	const [applicationid, setapplicationid] = useState()

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await sendAxiosWithTokens(
					`get`,
					`${APPLICATION_MICROSERVICE_URL}/api/v1/requestsToAdmin/getTeamActiveApplications/${idTeam}`
				)
				setapplicationid(response.data.data[0].id)
			} catch (error) {
				console.error(error)
			}
		}

		fetchData()
	}, [])

	return (
		<>
			<ModalWindow visibility={!!windowContent}>{windowContent}</ModalWindow>
			<ContainerMain>
				<ContentMainTheme>Команда "{team?.title}"</ContentMainTheme>

				<ContentCardWrapper>
					<ContentCard topic={'Общая информация'}>
						<TeamCommonInformation team={team} teamIsFrozen />
						<ContentCardInner></ContentCardInner>
					</ContentCard>
				</ContentCardWrapper>

				<ContentMainTheme>Состав команды</ContentMainTheme>

				<ContentCardWrapper>
					<ContentCard topic='Список команды'>
						<SquadAbout squad={squad} team={team} />
					</ContentCard>
				</ContentCardWrapper>
				{previousPath == '/notifications/all' ||
				previousPath == '/notifications/read' ||
				previousPath == '/notifications/unread' ? (
					<div className={notifStyle.ThreeButton}>
						<button
							className={notifStyle.back}
							onClick={() => handleSetCurrentPage(pageIndex)}
						>
							Назад
						</button>
						{applicationid ? (
							<div className={notifStyle.TwoButton}>
								<button
									onClick={() =>
										setWindowContent(
											<ModalContent
												admin
												action='ACCEPTED'
												setWindowContent={setWindowContent}
												applicationType={ApplicationType.MEMBERS_APPLICATION}
												teamTitle={team?.title}
												applicationid={applicationid}
												teamId={idTeam}
											/>
										)
									}
									className={notifStyle.Accept}
								>
									Принять
								</button>
								<button
									onClick={() =>
										setWindowContent(
											<ModalContent
												admin
												action='REJECTED'
												setWindowContent={setWindowContent}
												applicationType={ApplicationType.MEMBERS_APPLICATION}
												teamTitle={team?.title}
												applicationid={applicationid}
												teamId={idTeam}
											/>
										)
									}
									className={notifStyle.Reject}
								>
									Отклонить
								</button>
							</div>
						) : (
							''
						)}
					</div>
				) : (
					''
				)}
			</ContainerMain>
		</>
	)
}
export default TeamPage
