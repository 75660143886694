import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import RightText from '../../../Pages/Lk`s/RightText'
import { updateMydata, updateSkills } from '../../../redux/slices/paSlice'
import ErrorHandler from '../../../utils/ErrorHandler'
import { PERSONAL_ACCOUNT_MICROSERVICE } from '../../../utils/team'
import {
	checkIsTokenExpired,
	getDecodedToken,
	getTokens,
	isTokenInLS,
	updateToken,
} from '../../../utils/token'
import ModalWindow from '../common/ModalWindow/ModalWindow'
import EnterInviteLinkInPA from './EnterInviteLinkInPA'
import PARightSidebar from './RightSidebar/PARightSidebar'
type TokenType = {
	access_token: string
	refresh_token: string
}

export default function PersonalAccount() {
	const navigate = useNavigate()
	const [visibility, setVisibility] = useState(false)
	let tokens: TokenType = getTokens()

	const saveData = async () => {
		if (tokens != null) {
			const data_from_token = getDecodedToken()

			if (checkIsTokenExpired(tokens.access_token)) {
				if (checkIsTokenExpired(tokens.refresh_token)) {
					window.localStorage.removeItem('token')
					navigate('/')
				} else {
					await updateToken()
					tokens = getTokens()
				}
			}

			await axios
				.get(
					`${PERSONAL_ACCOUNT_MICROSERVICE}/api/v1/personal_account/${data_from_token.preferred_username}`,
					{
						headers: {
							Authorization: `Bearer ${tokens.access_token}`,
						},
					}
				)
				.then(response => {
					console.log('fdsf', response.data.data)
					dispatch(updateMydata(response.data.data))
				})
				.catch(error => {
					ErrorHandler(error, navigate)
				})
			await axios
				.get(
					`${PERSONAL_ACCOUNT_MICROSERVICE}/api/v1/personal_account/getAboutAndSkillsInfo/${data_from_token.preferred_username}`,
					{
						headers: {
							Authorization: `Bearer ${tokens.access_token}`,
						},
					}
				)
				.then(response => {
					console.log(response.data.data)
					dispatch(updateSkills(response.data.data))
				})
				.catch(error => {
					ErrorHandler(error, navigate)
				})
		} else {
			console.log('no token')
		}
	}

	const dispatch = useDispatch()
	useEffect(() => {
		saveData()
	}, [])

	let admin: boolean = false
	let student: boolean = false
	let customer: boolean = false
	let ProgrammCurator: boolean = false

	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		const isAdmin = roles.indexOf('client-main-admin') > -1
		if (isAdmin) admin = isAdmin

		const isStudent = roles.indexOf('client-student') > -1
		if (isStudent) student = isStudent

		const isCustomer = roles.indexOf('client-customer') > -1
		if (isCustomer) customer = isCustomer
		ProgrammCurator = roles.indexOf('client-program-curator') > -1
	}

	return (
		<>
			<div className='personal-account'>
				<ModalWindow visibility={visibility}>
					<EnterInviteLinkInPA setvisibility={setVisibility} />
				</ModalWindow>

				<div className='content-wrapper personal-account-content-wrapper'>
					{admin ? (
						<RightText admin={admin} />
					) : customer ? (
						<RightText customer={customer} />
					) : ProgrammCurator ? (
						<RightText curator={ProgrammCurator} />
					) : student ? (
						<PARightSidebar setvisibility={setVisibility} />
					) : (
						''
					)}

					<Outlet />
				</div>
			</div>
		</>
	)
}
