import axios from 'axios'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { initTeam, setTeamInfo } from '../../../redux/slices/myTeamSlice'
import { RootState } from '../../../redux/store'
import { CREATE_COMMAND_MICROSERVICE_URL } from '../../../utils/team'
import {
	TokenType,
	checkIsTokenExpired,
	getTokens,
	updateToken,
} from '../../../utils/token'
import Button from '../common/Button/Button'
import ContainerMain from '../common/ContainerMain/ContainerMain'
import ContentCard from '../common/ContentCard/ContentCard'
import ContentCardInner from '../common/ContentCard/ContentCardInner'
import ContentMainTheme from '../common/ContentMainTheme'
import FormLabel from '../common/Form/FormLabel'

const CreateTeamForm = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { team } = useSelector((state: RootState) => state.myTeam)

	interface ITeamCreateForm {
		title: string
		teamDescription: string
	}

	const postTeamCreate = async (teamData: ITeamCreateForm) => {
		let tokens: TokenType = getTokens()

		if (checkIsTokenExpired(tokens.access_token)) {
			if (checkIsTokenExpired(tokens.refresh_token)) {
				navigate('/')
			} else {
				await updateToken()
				tokens = getTokens()
			}
		}

		dispatch(initTeam())

		dispatch(setTeamInfo({ name: 'title', value: teamData.title }))
		dispatch(
			setTeamInfo({ name: 'description', value: teamData.teamDescription })
		)

		const postBody = {
			title: teamData.title,
			teamDescription: teamData.teamDescription,
		}

		if (tokens?.access_token) {
			await axios
				.post(`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/team`, postBody, {
					headers: {
						Authorization: `Bearer ${tokens.access_token}`,
					},
				})
				.catch(error => {
					if (error.response.status === 404) {
						console.error(error)
						navigate('/not-found')
					} else {
						console.error(error)
						navigate('/AnyError')
					}
				})
			await updateToken()
		}
	}

	const {
		handleSubmit,
		register,
		resetField,
		formState: { errors, isValid },
	} = useForm<ITeamCreateForm>({
		mode: 'onTouched',
	})

	const onSubmit: SubmitHandler<ITeamCreateForm> = (data: ITeamCreateForm) => {
		resetField('title')
		resetField('teamDescription')
		postTeamCreate(data)
		navigate('/team/my')
	}

	return (
		<ContainerMain>
			<ContentMainTheme>Создание команды</ContentMainTheme>

			<ContentCard>
				<ContentCardInner>
					<form onSubmit={handleSubmit(onSubmit)}>
						{/* <FormInput required label="Введите название вашей команды:" {...makeProps.forInput("title", team, handleChange)}/> */}

						<div className='form-field'>
							<FormLabel required>Введите название вашей команды:</FormLabel>

							<input
								className={
									'form-field-input ' +
									(errors.title ? 'form-field-input--not-valid ' : '')
								}
								{...register('title', { required: true })}
							/>
						</div>
						<div className='form-field'>
							<FormLabel description='Например, вы можете указать роли, которые вам нужны в команде, рассказать про свои цели и планы'>
								Добавьте описание вашей команды (необязательный шаг):
							</FormLabel>
							<textarea
								className='form-textarea'
								rows={5}
								{...register('teamDescription')}
							/>
						</div>
						<div className='button-block'>
							<Button action='formButton' type='submit' disabled={!isValid}>
								Создать команду
							</Button>
						</div>
					</form>
				</ContentCardInner>
			</ContentCard>
		</ContainerMain>
	)
}

export default CreateTeamForm
