import React, { useState } from "react";
import form from "./form.module.scss";

interface CheckBoxProps {
	value?: string;
	checked?: boolean;
	onClick?: (e: React.MouseEvent<HTMLDataElement, MouseEvent>) => void;
	round?: boolean;
}

const CheckBox = ({ value = "", checked, onClick, round }: CheckBoxProps) => {
	return (
		<data
			onClick={onClick}
			className={form["checkbox"] + ` ${round ? form["checkbox--round"] : ""}` + ` noselect`}
			value={value}
		>
			{checked &&
				(round ? <div className={form["checkbox__circle"]} /> : <span>&#10004;</span>)}
		</data>
	);
};

export default CheckBox;
