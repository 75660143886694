import { useDispatch } from "react-redux";
import { TokenType, checkIsTokenExpired, isTokenInLS, getTokens } from "./token";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { updateNotificationList } from "../redux/slices/notificationSlice";
import { CREATE_COMMAND_MICROSERVICE_URL } from "./team";

export const useGetNotifications = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const GetNotifications = async () => {
		if (isTokenInLS()) {
			const tokens: TokenType = getTokens();
			if (checkIsTokenExpired(tokens.access_token)) {
				if (checkIsTokenExpired(tokens.refresh_token)) {
					window.localStorage.removeItem("token");
					navigate("/");
				}
			}
			if (tokens?.access_token) {
				await axios
					.get(`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/notification`, {
						headers: {
							Authorization: `Bearer ${tokens.access_token}`,
						},
					})
					.then((response) => {
						dispatch(updateNotificationList(response.data.data));
					})
					.catch((error) => {
						console.error(error);
						// ErrorHandler(error, navigate)
					});
			}
		}
	};

	return GetNotifications;
};
