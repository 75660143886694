import { useDispatch, useSelector } from 'react-redux'

import { Link, useMatch } from 'react-router-dom'
import firstNotif from '../../../assets/img/icons/1stnotif.svg'
import seconNotif from '../../../assets/img/icons/2ndnotif.svg'
import thirdnotif from '../../../assets/img/icons/3dnotif.svg'
import notifStyle from '../../../assets/styles/components/notifStyle.module.scss'
import { setActiveIndex } from '../../../redux/slices/activeListSlice'
import { RootState } from '../../../redux/store'

export default function ActiveList({}) {
	const activeIndex = useSelector(
		(state: RootState) => state.activeIndexReducer.activeIndex
	)
	const dispatch = useDispatch()

	const matches = [
		Boolean(useMatch('/notifications/all')),
		Boolean(useMatch('/notifications/read')),
		Boolean(useMatch('/notifications/unread')),
	]

	// console.log(testMatch);

	const categories = [`Всё`, `Прочитанное`, `Непрочитанное`]

	const images = [firstNotif, seconNotif, thirdnotif]

	const Links = [`all`, `read`, `unread`]

	const OnClickCategory = (index: number) => {
		dispatch(setActiveIndex(index))
	}

	return (
		<ul>
			{categories.map((value, i) => (
				<li
					onClick={() => OnClickCategory(i)}
					className={matches[i] ? `active` : ``}
					key={i}
				>
					<Link to={Links[i]}>
						<div
							className={i == 0 ? notifStyle.linkItem1 : notifStyle.linkItem}
						>
							<img src={images[i]} alt='' />
							<span
								className={
									i == 2
										? notifStyle.linkText3
										: notifStyle.linkText && i == 0
										? notifStyle.linkText1
										: notifStyle.linkText
								}
							>
								{' '}
								{value}{' '}
							</span>
						</div>
					</Link>
				</li>
			))}
		</ul>
	)
}
