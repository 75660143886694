import { ReactNode } from "react";
import SeparateLine from "../SeparateLine/SeparateLine";
import styles from "./DoubleList.module.scss";

export interface DoubleListItemData {
	identifier?: ReactNode;
	content?: ReactNode | string;
	className?: string;
	children?: any;
	nonUnderLine?: boolean;
}

const DoubleListItem = ({ identifier, content, className, nonUnderLine }: DoubleListItemData) => {
	return (
		<>
			<li className={styles["double-list__item"]}>
				<div className={styles["double-list__item-wrapper"]}>
					<div className={styles["identifier"]}>{identifier}</div>
					<div className={styles["content"]}>{content}</div>
				</div>
				{nonUnderLine ? null : <SeparateLine />}
			</li>
		</>
	);
};

export default DoubleListItem;
