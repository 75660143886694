import { useDispatch, useSelector } from 'react-redux'
import ApplicationsPage from '../../../Pages/notifications/Applications'
import NotificationsPage from '../../../Pages/notifications/Notifications'
import SendedNotificationsPage from '../../../Pages/notifications/SendedNotifications'
import notifStyles from '../../../assets/styles/components/notifStyle.module.scss'
import { setHeadButtons } from '../../../redux/slices/notificationSlice'
import { RootState } from '../../../redux/store'
import { getDecodedToken } from '../../../utils/token'
import ContentCardWrapper from '../common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../common/ContentMainTheme'

const Notifications: React.FC = () => {
	const dispatch = useDispatch()
	const ActivePage = useSelector(
		(state: RootState) => state.NotifcationReducer.SwapPages
	)

	const Swap = (i: number) => {
		dispatch(setHeadButtons(i))
	}
	const roles = getDecodedToken()?.resource_access['spring-client'].roles

	return (
		<>
			<div className='personal-account'>
				<div className='personal-account-mydata'>
					<ContentMainTheme>Уведомления и заявки</ContentMainTheme>
					{ActivePage === 1 ? (
						<div className={notifStyles.help}>
							Чтобы пометить прочитанным уведомление, нажмите на него
						</div>
					) : (
						''
					)}
					<ContentCardWrapper>
						<div className={notifStyles.area}>
							<div className={notifStyles.headerSection}>
								<div
									onClick={() => Swap(1)}
									className={
										ActivePage === 1
											? `${notifStyles.Areabutton} ${notifStyles.active}`
											: notifStyles.Areabutton
									}
								>
									<button className={notifStyles.Varbutton}>Уведомления</button>
								</div>
								<div
									onClick={() => Swap(0)}
									className={
										ActivePage === 0
											? `${notifStyles.Areabutton} ${notifStyles.active}`
											: notifStyles.Areabutton
									}
								>
									<button className={notifStyles.Varbutton}>Заявки</button>
								</div>
								{!roles?.includes('client-main-admin') ? (
									<div
										onClick={() => Swap(2)}
										className={
											ActivePage === 2
												? `${notifStyles.Areabutton} ${notifStyles.active}`
												: notifStyles.Areabutton
										}
									>
										<button className={notifStyles.Varbutton}>Исходящее</button>
									</div>
								) : null}
							</div>
							{ActivePage === 0 ? (
								<ApplicationsPage />
							) : ActivePage === 1 ? (
								<NotificationsPage />
							) : ActivePage === 2 ? (
								<SendedNotificationsPage />
							) : (
								''
							)}
						</div>
					</ContentCardWrapper>
				</div>
			</div>
		</>
	)
}

export default Notifications
