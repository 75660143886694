const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const pageNumbers = Array.from({ length: totalPages }, (_, index) => index);

    return (
        <div className="pagination">
            {pageNumbers.map((pageNumber) => (
                <button
                    key={pageNumber}
                    className={`page-number ${
                        pageNumber === currentPage ? "active" : ""
                    }`}
                    onClick={() => onPageChange(pageNumber)}
                >
                    {pageNumber + 1}
                </button>
            ))}
        </div>
    );
};

export default Pagination;
