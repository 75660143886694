import { useDispatch } from "react-redux";
import { TokenType, checkIsTokenExpired, getTokens, isTokenInLS, updateToken } from "./token";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { updateApplicationsList, updateSendedApplicationsList } from "../redux/slices/notificationSlice";
import { APPLICATION_MICROSERVICE_URL } from "./team";

export const useGetApplications = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const GetApplications = async () => {
        if (isTokenInLS()) {
            let tokens: TokenType = getTokens();
            if (checkIsTokenExpired(tokens.access_token)) {
                if (checkIsTokenExpired(tokens.refresh_token)) {
                    window.localStorage.removeItem("token");
                    navigate("/");
                } else {
                    await updateToken();
                    tokens = getTokens();
                }
            }
            if (tokens?.access_token) {
                await
                axios
                    .get(
                        `${APPLICATION_MICROSERVICE_URL}/api/v1/joinToCommand/getApplicationsWithAllTypes`,
                        {
                            headers: {
                                Authorization: `Bearer ${tokens.access_token}`,
                            },
                        }
                    )
                    .then((response) => {
                        dispatch(updateApplicationsList(response.data.data));
                    })
                    .catch((error) => {
                        console.error(error);
                        // ErrorHandler(error, navigate);
                    });

                axios.get(
                    `${APPLICATION_MICROSERVICE_URL}/api/v1/joinToCommand/getSendedApplicationsWithAllTypes`,
                    {
                        headers: {
                            Authorization: `Bearer ${tokens.access_token}`,
                        }
                    }
                ).then((response) => {
                    dispatch(updateSendedApplicationsList(response.data.data));
                }).catch((error) => {
                    console.error(error);
                    // ErrorHandler(error, navigate);
                });
            }
        }
    };

    return GetApplications;
};
