import React from "react";
import loading from "./loading.module.scss";

interface LoadingTextProps {
	center?: boolean;
}

const LoadingText = ({ center }: LoadingTextProps) => {
	return (
		<div
			className={
				loading["text__wrapper"] +
				((center && ` ${loading["text__wrapper--center"]}`) || "")
			}
		>
			<span className={loading["text"] + " noselect"}>
				Загрузка
				<strong>
					<span className={loading["text__dots"]}>
						<span>.</span>
						<span>.</span>
						<span>.</span>
					</span>
				</strong>
			</span>
		</div>
	);
};

export default LoadingText;
