import { useState } from 'react'
import { ApplicationType } from '../../@types/entities/ApplicationType'
import Button from '../../hooks/components/common/Button/Button'
import ButtonWrapper from '../../hooks/components/common/Button/ButtonWrapper'
import { useResponseOnApplication } from '../../utils/useResponseOnApplication'

type ActionType = 'ACCEPTED' | 'REJECTED'

interface ModalContentProps {
	action: ActionType
	feedback?: boolean
	fio?: string
	setWindowContent: Function
	applicationid?: number // for requests
	teamId?: number // for requests
	applicationType: ApplicationType // and for requests
	teamTitle?: string
	admin?: boolean // the application will be generate for admin
	hackForTeamLeadApplication?: 'approve' | 'transfer' | '' // for choose 1 of 2 application of type teamLead for admin
}

interface TextOfTypesForAdministration {
	TEAMLEAD_APPLICATION: {
		transfer: string
		approve: string
	}
	REQUEST_TO_THE_TEAM: string
	MEMBERS_APPLICATION: string
}

export const ModalContent = ({
	action,
	feedback,
	fio,
	setWindowContent,
	applicationid,
	teamId,
	applicationType,
	teamTitle,
	admin,
	hackForTeamLeadApplication,
}: ModalContentProps) => {
	const [feedbackValue, setFeedBackValue] = useState<string>('')
	const actionVar = {
		ACCEPTED: 'принять',
		REJECTED: 'отклонить',
	}
	const textsOfTypesForStudent = {
		INVITE_TO_THE_TEAM: `приглашение на присоединение к команде "${
			teamTitle ? teamTitle : ''
		}" `,
		REQUEST_TO_THE_TEAM: `${fio ? fio : ''} в вашу команду`,
		TEAMLEAD_APPLICATION: `роль Team Lead\`a от ${fio ? fio : ''}`,
	} as Record<ApplicationType, string>

	const textOfTypesForAdministration = {
		TEAMLEAD_APPLICATION: {
			transfer: `заявку на передачу роли TeamLead от ${fio ? fio : ''}`,
			approve: `заявку от ${fio ? fio : ''} о присвоении роли TeamLead`,
		},
		REQUEST_TO_THE_TEAM: `заявку на вступление ${fio ? fio : ''} в команду "${
			teamTitle ? teamTitle : ''
		}"`,
		MEMBERS_APPLICATION: `заявку на одобрение состава команды "${
			teamTitle ? teamTitle : ''
		}"`,
	} as any

	const typeForRequest = {
		// костыль, пока ничего не придумал больше
		'': 'TEAMLEAD_APPLICATIONstudentTostudent',
		transfer: 'TEAMLEAD_APPLICATIONstudentToAdmin',
		approve: 'TEAMLEAD_APPLICATIONapproveByAdmin',
	} as Record<string, string>

	let myType: string = ''
	if (hackForTeamLeadApplication || hackForTeamLeadApplication == '') {
		myType = typeForRequest[hackForTeamLeadApplication]
	} else myType = applicationType

	const { ResponseOnApplications } = useResponseOnApplication()

	return (
		<>
			<p>
				Вы уверены,что хотите {actionVar[action]}{' '}
				{admin
					? hackForTeamLeadApplication
						? textOfTypesForAdministration[applicationType]?.[
								hackForTeamLeadApplication as string
						  ]
						: textOfTypesForAdministration[applicationType]
					: textsOfTypesForStudent[applicationType]}{' '}
				?
			</p>
			<br />
			{admin &&
			(applicationType === 'TEAMLEAD_APPLICATION' ||
				applicationType === 'MEMBERS_APPLICATION') &&
			action === 'REJECTED' ? (
				<div>
					<label htmlFor=''>Опишите почему:</label>
					<textarea
						value={feedbackValue}
						onChange={e => setFeedBackValue(e.target.value)}
						cols={30}
						rows={5}
						className='info-block-textareaForEdit'
					></textarea>
				</div>
			) : null}
			<br />
			<ButtonWrapper>
				<Button
					onClick={() => {
						setWindowContent(null)
						ResponseOnApplications(
							applicationid,
							action,
							myType,
							teamId,
							feedbackValue
						)
					}}
				>
					Да
				</Button>
				<Button
					onClick={() => {
						setWindowContent(null)
					}}
				>
					Назад
				</Button>
			</ButtonWrapper>
		</>
	)
}

// сделал пока что для 'Всё' в инвайтТуЗеТим
