import SelectCaseWrapper from '../../hooks/components/Team/SelectCaseWrapper'
import ContainerMain from '../../hooks/components/common/ContainerMain/ContainerMain'
import ContentCard from '../../hooks/components/common/ContentCard/ContentCard'
import ContentCardInner from '../../hooks/components/common/ContentCard/ContentCardInner'
import ContentCardWrapper from '../../hooks/components/common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../hooks/components/common/ContentMainTheme'
import FormLabel from '../../hooks/components/common/Form/FormLabel'

const SelectCasePage = () => {
	return (
		<ContainerMain size='large'>
			<ContentMainTheme>Выбор кейса</ContentMainTheme>
			<ContentCardWrapper>
				<ContentCard>
					<ContentCardInner>
						<FormLabel required>Выберите кейс:</FormLabel>
						<SelectCaseWrapper />
					</ContentCardInner>
				</ContentCard>
			</ContentCardWrapper>
		</ContainerMain>
	)
}

export default SelectCasePage
