import React from "react";
import FormRadioItem from "./FormRadioItem";
import FormInput, { makeProps } from "./FormInput";
import FormLabel from "./FormLabel";

export function getRoleDescription(item: string) {
    let description = "";
    switch(item) {
        case "Team Lead":
            description = "Team Lead: отвечает за управление и координацию работы команды, устанавливает приоритеты и обеспечивает достижение целей проекта.";
            break;
        case "Автоматизатор технологических процессов":
            description = "Автоматизатор технологических процессов: занимается автоматизацией процессов производства или разработки, внедряет системы контроля и управления.";
            break;
        case "Product manager":
            description = "Product manager: отвечает за создание стратегии развития и продвижения продукта, управление его жизненным циклом и координацию работы команды.";
            break;
        case "Systems architect":
            description = "Systems architect: разрабатывает архитектуру системы, определяет ее компоненты, связи и принципы работы, заботится о масштабируемости и безопасности системы.";
            break;
		case "Front-end developer":
			description = "Front-end developer: занимается разработкой и поддержкой клиентской части веб-приложений, созданием интерфейсов, анимаций и взаимодействием с бэкендом.";
			break;
		case "ML Engineer":
			description = "ML Engineer: занимается разработкой и оптимизацией алгоритмов и моделей машинного обучения, работает с большими объемами данных.";
			break;
		case "Back-end developer":
			description = "Back-end developer: разрабатывает и поддерживает серверную часть приложений, занимается разработкой серверной логики, баз данных и взаимодействием с фронтендом.";
			break;
		case "Data Scientist":
			description = "Data Scientist: занимается обработкой и анализом больших объемов данных, разработкой алгоритмов машинного обучения и моделей прогнозирования.";
			break;
		case "Тестировщик (QA)":
			description = "Тестировщик (QA): отвечает за тестирование приложений на соответствие заданным требованиям, выявление и исправление ошибок.";
			break;
		case "DevOps Engineer":
			description = "DevOps Engineer: отвечает за автоматизацию процессов разработки, тестирования и развертывания приложений, обеспечивает стабильность и безопасность инфраструктуры.";
			break;
		case "Designer":
			description = "Designer: создает дизайн интерфейсов и визуальные компоненты для приложений, учитывая пользовательский опыт и требования бренда.";
			break;
		case "Математик":
			description = "Математик: проводит математические и статистические исследования, разрабатывает модели и алгоритмы для оптимизации процессов.";
			break;
		case "Full-stack developer":
			description = "Full-stack developer: имеет комплексные навыки и работает и с фронтендом, и с бэкендом, разрабатывает приложения от идеи до внедрения.";
			break;
		case "мобильный разработчик,дизайнер":
			description = "Мобильный разработчик, дизайнер: занимается разработкой приложений для мобильных устройств, включая визуальное оформление и пользовательский интерфейс.";
			break;
		case "Тестировщик, оформление отчетов":
			description = "Тестировщик, оформление отчетов: занимается тестированием и отчетностью о выполнении функциональных требований в рамках проекта.";
			break;
		case "Data Scientists & Designer":
			description = "Data Scientists & Designer: команда, которая объединяет специалистов по анализу данных и дизайнеров для разработки продуктов с учетом удобства использования и потребностей пользователя.";
			break;
		case "Дизайнер, Специалист по данным":
			description = "Дизайнер, специалист по данным: занимается разработкой дизайна и визуализацией данных, созданием информационных дашбордов и отчетов.";
			break;
		case "Составление и компоновка итогового отчета":
			description = "Составление и компоновка итогового отчета: отвечает за сбор данных, их анализ и подготовку итогового отчета с выводами и рекомендациями.";
			break;
		case "маркетолог":
			description = "Маркетолог: разрабатывает и реализует маркетинговые стратегии и активности для продвижения продукта или услуги.";
			break;
		case "Расчеты на прочность":
			description = "Расчеты на прочность: проводит расчеты и анализ прочности конструкций и материалов в соответствии с требованиями безопасности.";
			break;
		case "C#, C++ developer":
			description = "C#, C++ developer: разрабатывают приложения, используя языки программирования C# и C++, отвечают за создание и поддержку соответствующего кода.";
			break;
		case "BI-аналитик":
			description = "BI-аналитик: занимается анализом данных, разработкой и внедрением бизнес-интеллекта, помогает в принятии управленческих решений.";
			break;
		case "Инженер машинного обучения":
			description = "Инженер машинного обучения: разрабатывает алгоритмы и модели машинного обучения, занимается обработкой и анализом данных для решения задач.";
			break;
		case "Системный аналитик":
			description = "Системный аналитик: проводит исследование бизнес-процессов, выявляет требования к системе, определяет ее структуру и функциональность.";
			break;
		case "Аэродинамик":
			description = "Аэродинамик: занимается исследованиями в области аэродинамики, разрабатывает и оптимизирует форму и конструкцию объектов для достижения оптимальной аэродинамической производительности.";
			break;
		case "Руководитель группы":
			description = "Руководитель группы: отвечает за управление и организацию работы небольшой группы специалистов, координирует их деятельность и отчетность.";
			break;
		case "Аналитик":
			description = "Аналитик: проводит анализ данных, дает рекомендации и делает прогнозы на основе собранных данных для поддержки принятия решений.";
			break;
		case "Расчётчик":
			description = "Расчётчик: проводит математические и инженерные расчеты для определения параметров и характеристик объектов и систем.";
			break;
		case "Расчетчик в части механики":
			description = "Расчетчик в части механики: специалист, работающий с механическими расчетами и анализом деформаций и напряжений в конструкциях.";
			break;
		case "Project manager":
			description = "Project manager: управляет проектом от начала до конца, планирует и контролирует выполнение задач, управляет ресурсами и взаимодействует с клиентами.";
			break;
		case "Разработчик мобильных приложений":
			description = "Разработчик мобильных приложений: занимается разработкой мобильных приложений для операционных систем Android и iOS.";
			break;
		case "Промпт-инженер":
			description = "Промпт-инженер: отвечает за разработку и внедрение промышленных сетей и систем передачи данных, обеспечение их безопасности и стабильности работы.";
			break;
		default:
            description = "";		
		}
    return description;
}


interface InputRadioProps {
	itemList?: string[];
	valueList?: string[];
	selected?: string;
	onClick?: React.MouseEventHandler<HTMLDataElement>;
	onSelect?: (selected: any) => void;
	name?: string;
	label?: string;
	required?: boolean;
	extra?: boolean;
}

const FormInputRadio = ({
	itemList = [],
	valueList,
	selected = "",
	onClick,
	onSelect,
	name,
	label = "",
	required,
	extra,
}: InputRadioProps) => {
	const isAnother = !(valueList ? valueList : itemList).includes(selected);

	const handleSelect = ({ target }: any) => {
		if (onSelect) {
			onSelect(target.value);
		}
	};

	return (
		<div className="input-radio">
			{label && <FormLabel required={required}>{label}</FormLabel>}
			<div className="input-radio-wrapper">
				{itemList.map((item, index) => (
					<FormRadioItem
						key={item}
						selected={(valueList ? valueList[index] : item) === selected}
						value={valueList ? valueList[index] : item}
						onClick={onClick || handleSelect}
					>
						<div title={getRoleDescription(item)}>{item}</div>
					</FormRadioItem>
				))}

				{extra && (
					<FormRadioItem
						selected={isAnother}
						value={valueList ? "another" : "Другое"}
						onClick={onClick}
					>
						Другое
					</FormRadioItem>
				)}

				{extra && isAnother && (
					<FormInput
						label="Укажите другую роль:"
						name={name}
						value={selected}
						onChange={onClick}
					/>
				)}
			</div>
		</div>
	);
};

export default FormInputRadio;
