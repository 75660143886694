import { PassportMyTeamDTO } from '../../../../@types/dtos/PassportMyTeamDTO'
import DoubleList from '../../../../components/common/DoubleList/DoubleList'

const PassportProjectCard = ({
	passportData,
	slide,
}: {
	passportData: PassportMyTeamDTO
	slide: number
}) => {
	return (
		<>
			{slide == 1 ? (
				<DoubleList
					listData={[
						{
							identifier:
								'Наименование дополнительной профессиональной программы профессиональной переподготовки',
							content: passportData?.dppTitle,
						},
						{
							identifier: 'Наименование проекта',
							content: passportData?.name,
						},
						{
							identifier: 'Шифр проекта (команды)',
							content: passportData?.shifr,
						},
						{
							identifier: 'Заказчик проекта ',
							content: passportData?.customer,
						},
						{
							identifier: 'Руководитель темы от МАИ',
							content: passportData?.supervisor,
						},
						{
							identifier: 'Консультант',
							content: passportData?.consultant,
						},
						{
							identifier: 'Рецензент темы ',
							content: passportData?.reviewer,
						},
						{
							identifier:
								'Целевая аудитория результата проекта (кто потребитель результата проекта)',
							content: passportData?.endUser,
						},
						{
							identifier: 'Длительность проекта (даты начала и окончания)',
							content: passportData?.duration,
						},
					]}
				/>
			) : (
				''
			)}
			{slide == 2 ? (
				<DoubleList
					listData={[
						{
							identifier: 'Название команды ',
							content: passportData?.comandName,
						},
						{
							identifier: 'Роли в проекте:',
							content: passportData?.roles,
						},
						{
							identifier: 'Роли участников',
							content: passportData?.role.map(role => {
								return (
									<div>
										<p>
											{role.role} - {role.owner}
										</p>
									</div>
								)
							}),
						},
						{
							identifier: 'Ссылка на гитхаб',
							content: passportData?.github,
						},
						{
							identifier: 'Сcылка на MIRO',
							content: passportData?.miro,
						},
						{
							identifier: 'Ссылка на трекер',
							content: passportData?.trello,
						},
						{
							identifier: 'Образ результата проекта',
							content: passportData?.image,
						},

						{
							identifier: 'Цель проекта',
							content: passportData?.goal,
						},
						{
							identifier: 'Задачи проекта',
							content: passportData?.tasks.map((task, index) => {
								return (
									<div key={index}>
										<p>
											{index + 1}) {task?.condition}
										</p>
									</div>
								)
							}),
						},
					]}
				/>
			) : (
				''
			)}
			{slide == 3 ? (
				<DoubleList
					listData={[
						{
							identifier: 'Проблемы',
							content: passportData?.problems.map((problem, index) => {
								return (
									<div key={index}>
										{index + 1}) {problem?.condition}
									</div>
								)
							}),
						},
						{
							identifier: 'Результат проекта',
							content: passportData?.result,
						},
						{
							identifier:
								'Ограничения и допущения, которые имеют или могут оказать существенное влияние на результат проекта',
							content: passportData?.restrictions,
						},
						{
							identifier: 'S.M.A.R.T',
							content: passportData?.smart,
						},
						{
							identifier:
								'Необходимые ресурсы для выполнения проекта (компетенции исполнителей, материальные ресурсы и др.)',
							content: passportData?.resources,
						},
						{
							identifier: 'Риски проекта ',
							content: passportData?.risks,
						},
						{
							identifier: 'Команда проекта',
							content: passportData?.members.map(member => {
								return (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											gap: '8px',
											marginBottom: '8px',
										}}
									>
										<p
											style={{
												fontSize: '20px',
											}}
										>
											ФИО: {member.fio}
										</p>
										<p>
											Компетенции: <br /> {member?.competence}
										</p>
										<p>
											Роль в команде: <br /> {member?.role}
										</p>
										<p>
											Задачи проекта: <br /> {member?.task}
										</p>
										<hr
											style={{
												border: '1px solid gray',
											}}
										/>
									</div>
								)
							}),
						},
						{
							identifier: 'Задачи проекта с оценкой по времени',
							content: passportData?.tasks.map(task => {
								return (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
											gap: '8px',
										}}
									>
										<p>
											Задача: <br />
											{task?.condition}
										</p>
										<p>
											Подзадачи: <br />
											{task?.subtask}
										</p>
										<p>
											Часов на выполнение: <br />
											{task?.time}
										</p>
										<hr
											style={{
												border: '1px solid gray',
											}}
										/>
									</div>
								)
							}),
						},
						{
							identifier: 'ИТОГО ПЛАНИРУЕМОЕ ВРЕМЯ НА ПРОЕКТ:',
							content: passportData?.totalTime,
						},
						{
							identifier: 'MVP',
							content: passportData?.mvp,
						},
					]}
				/>
			) : (
				''
			)}
		</>
	)
}
export default PassportProjectCard
