import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {CompanyUserProfile} from "../../@types/entities/CompanyUserProfile";
import {UserRole} from "../../@types/entities/UserRole";


export interface CompanyInfo {
    profile: {
        CompanyUserProfile: CompanyUserProfile;
        CompanySite: string;
    }
}

const initialState: CompanyInfo = {
    profile: {
        CompanyUserProfile: {
            owner: {
                id: 0,
                username: "",
                name: "",
                surname: "",
                patronymic: "",
                phoneNumber: "",
                MAIEmail: "",
                telegram_link: "",
                email: "",
                authorities: "",
                credentialsNonExpired: "",
                enabled: "",
                password: "",
                role: UserRole.CURATOR,
                fio: ""
            },
            company: {
                id: 0,
                contactPerson: '',
                contactPersonContact: '',
                title: 'Наименование компании',
                description: 'Роль представителя компании',
            }

        },
        CompanySite: 'Ссылка'
    }
}

type AdminFieldsType =
    | "surname"
    | "name"
    | "patronymic"
    | "email"
    | "phoneNumber"
    | "telegram_link";

type setUserInfoAction = PayloadAction<{
    name: AdminFieldsType;
    value: string;
}>;

export const CompanyInfo = createSlice({
    name: 'CompanyInfo',
    initialState,
    reducers: {
        setCompanyInfo(state: CompanyInfo, action: setUserInfoAction) {
            if (action.payload.name in state.profile.CompanyUserProfile.owner)
                state.profile.CompanyUserProfile.owner[action.payload.name] = action.payload.value;
        },
    }
});

export const {setCompanyInfo} = CompanyInfo.actions;
export default CompanyInfo.reducer;