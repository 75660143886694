// import React from "react";
import styles from "./content-card.module.scss";

interface ContentCardInnerPrompt {
    children?: React.ReactNode;
    className?: string;
}

const ContentCardInner = ({ children, className = "" }: ContentCardInnerPrompt) => {
    return <div className={styles["inner"] + " " + className}>{children}</div>;
};

export default ContentCardInner;
