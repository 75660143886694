import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import PassportDTO from "../../@types/dtos/PassportDTO";

interface init {
  PasportCase: PassportDTO
}


const initialState: init = {
  PasportCase: {}
};
type pasport = {
  projectTitle?: string;
  projectCustomer?: string;
  projectSupervisor?: string;
  projectMentor?: string;
  endUser?: string;
  projectDuration?: string;
  projectType?: string;
  projectGoal?: string;
  projectTasks?: string;
  projectResult?: string;
  projectRestrictions?: string;
  projectResources?: string;
  projectTaskTracker?: string;
  projectCompitentions?: string;
  additionalInformation?: string;
  example?: string;
}

type PasportKeys = keyof pasport
type PasportFieldsUpdate = {
  [K in PasportKeys]?: string;
};
export const companyPasportSlice = createSlice({
  name: "case",
  initialState,
  reducers: {
    setPasportCase(state, action) {
      state.PasportCase = action.payload
    },
    setFieldPasport(state, action: PayloadAction<PasportFieldsUpdate>) {
      Object.entries(action.payload).forEach(([key, value]) => {
        (state.PasportCase as any)[key] = value;
      });
    }
  },
});

export const { setPasportCase,setFieldPasport } = companyPasportSlice.actions;
export default companyPasportSlice.reducer;
