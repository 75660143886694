import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";

import QuestionnaireCreateDTO from "../../@types/dtos/QuestionnaireCreateDTO";

export interface userQuestionnaireSlice {
	user: QuestionnaireCreateDTO;
	roles: string[];
	skills: string[];
	allSkills: string[];
	anotherSkills: string[];
}

export type UserFieldsType = keyof QuestionnaireCreateDTO;
// | "mail"
// | "phoneNumber"
// | "MAIEmail"
// | "telegram_link"
// | "role"
// | "skills"
// | "isEasyGoing"
// | "isSolid"
// | "behaviourInTeam"
// | "doesLikeSurprises"
// | "fears"
// | "doesLikePlanning"
// | "isCreative";

type setUserInfoAction = PayloadAction<{
	name: UserFieldsType;
	value: string;
}>;

// const initialState = {
const initialState: userQuestionnaireSlice = {
	user: {
		mail: "",
		phoneNumber: "",
		maiEmail: "",
		telegram_link: "",

		role: "",

		skills: "",

		isEasyGoing: "",
		isSolid: "",
		behaviourInTeam: "",
		doesLikeSurprises: "",
		fears: "",
		doesLikePlanning: "",
		isCreative: "",
	},
	skills: [],
	roles: [
		"Team Lead",
		"ML Engineer",
		"Front-end developer",
		"Back-end developer",
		"Data Scientist",
		"Тестировщик (QA)",
		"DevOps Engineer",
		"Designer",
		"Математик",
	],
	allSkills: [
		"AirFlow",
		"Atlassian stack",
		"Back-end разработка",
		"С",
		"С#",
		"С++",
		"СNN",
		"CSS",
		"Canva",
		"Computer Vision",
		"Confluence",
		"Data Engineering",
		"Data Science",
		"Deep Learning",
		"DevOps",
		"Django",
		"Docker",
		"ETL",
		"Excel",
		"Figma",
		"Front-end разработка",
		"Git",
		"Go",
		"HTML",
		"Hadoop",
		"Jira",
		"Jupyter",
		"Kafka",
		"Keras",
		"Kotlin",
		"Kubernetes",
		"Linux",
		"Machine Learning",
		"Marketing",
		"Matplotlib",
		"Microsoft Power BI",
		"NumPy",
		"ONNX Runtime",
		"OpenCV",
		"Pandas",
		"Photoshop",
		"PowerPoint",
		"PyTorch",
		"Python",
		"R",
		"React",
		"SMM",
		"SQL",
		"Scikit-Learn",
		"Seaborn",
		"TensorFlow",
		"Использование принципов Rest",
		"Математическая статистика",
		"Построение Rest API",
		"Работа с JSON",
		"Работа с XML",
		"Работа с YAML",
		"Разработка моделей данных",
		"СУБД GreenPulm",
		"СУБД MS SQL Server",
		"СУБД Oracle",
		"СУБД PostgreSQL",
		"Умение работать с API",
		"Управление проектами",
	],
	anotherSkills: [],
};

export const userQuestionnaireSlice = createSlice({
	name: "userQuestionnaire",
	initialState: initialState,
	reducers: {
		setUserInfo(state, action: setUserInfoAction) {
			if (action.payload.name in state.user)
				state.user[action.payload.name] = action.payload.value;
		},
		toggleUserSkills(state, action) {
			const index = state.skills.indexOf(action.payload.skill);
			state.skills = [...state.skills]
			if (typeof index === "number")
				if (index !== -1) {
					state.skills.splice(index, 1);
				} else {
					state.skills.push(action.payload.skill);
				}

		},
		setUserAnotherSkills(state, action: PayloadAction<{ anotherSkills: string[] }>) {
			state.anotherSkills = [];
			action.payload.anotherSkills.forEach((skill) => {
				if (skill) state.anotherSkills.push(skill);
			});
			state.skills = [...state.skills,...state.anotherSkills]
		},
	},
});

const { actions, reducer } = userQuestionnaireSlice;

export const { setUserInfo, toggleUserSkills, setUserAnotherSkills } = actions;

export default reducer;
