import { createSlice } from "@reduxjs/toolkit";

// export interface UserListSlice {
//     users: User[];
// }

// const initialState: UserListSlice = {
const initialState = {
    // users: [],
    teams: [
        {
            "id": 0,
            "createdAt": null,
            "updatedAt": null,
            "deletedAt": null,
            "description": "ewewew",
            "teamlead": {
                "id": 2,
                "createdAt": "2023-08-02T06:29:20.016563",
                "updatedAt": null,
                "deletedAt": null,
                "username": "dima",
                "name": "Дмитрий",
                "surname": "Сираков",
                "patronymic": "Сергеевич",
                "telegram_link": "@berezaIV",
                "email": null,
                "phoneNumber": "+79605587877",
                "role": "STUDENT",
                "enabled": true,
                "authorities": null,
                "password": null,
                "accountNonExpired": true,
                "accountNonLocked": true,
                "credentialsNonExpired": true,
                "maiemail": "dima@mai.education"
            },
            "company": {
                "id": 1,
                "createdAt": "2023-05-20T20:30:11",
                "updatedAt": "2023-07-17T19:21:22.38524",
                "deletedAt": null,
                "title": "bestCompany",
                "description": null
            },
            "title": "ewew",
            "topic": {
                "id": 1,
                "createdAt": "2023-05-20T20:35:37",
                "updatedAt": null,
                "deletedAt": null,
                "title": "my topic 1",
                "company": {
                    "id": 1,
                    "createdAt": "2023-05-20T20:30:11",
                    "updatedAt": "2023-07-17T19:21:22.38524",
                    "deletedAt": null,
                    "title": "bestCompany",
                    "description": null
                },
                "maxAmountOfTeams": 2,
                "description": "my topic 1 description",
                "delegate": {
                    "id": 1,
                    "createdAt": "2023-07-06T11:52:54",
                    "updatedAt": "2023-07-06T08:53:01.388454",
                    "deletedAt": "2023-07-06T08:53:01.388454",
                    "owner": null,
                    "company": {
                        "id": 1,
                        "createdAt": "2023-05-20T20:30:11",
                        "updatedAt": "2023-07-17T19:21:22.38524",
                        "deletedAt": null,
                        "title": "bestCompany",
                        "description": null
                    }
                },
                "programs": [
                    {
                        "id": 1,
                        "title": "program1"
                    }
                ]
            },
            "supervisor": null,
            "reviewer": null,
            "adminApplication": null,
            "companyApplication": null,
            "consultant": null,
            "program": {
                "id": 1,
                "title": "program1"
            }
        },
        {
            "id": 0,
            "createdAt": null,
            "updatedAt": null,
            "deletedAt": null,
            "description": "ewewew",
            "teamlead": {
                "id": 2,
                "createdAt": "2023-08-02T06:29:20.016563",
                "updatedAt": null,
                "deletedAt": null,
                "username": "dima",
                "name": "Дмитрий",
                "surname": "Сираков",
                "patronymic": "Сергеевич",
                "telegram_link": "@berezaIV",
                "email": null,
                "phoneNumber": "+79605587877",
                "role": "STUDENT",
                "enabled": true,
                "authorities": null,
                "password": null,
                "accountNonExpired": true,
                "accountNonLocked": true,
                "credentialsNonExpired": true,
                "maiemail": "dima@mai.education"
            },
            "company": {
                "id": 1,
                "createdAt": "2023-05-20T20:30:11",
                "updatedAt": "2023-07-17T19:21:22.38524",
                "deletedAt": null,
                "title": "bestCompany",
                "description": null
            },
            "title": "ewew",
            "topic": {
                "id": 1,
                "createdAt": "2023-05-20T20:35:37",
                "updatedAt": null,
                "deletedAt": null,
                "title": "my topic 1",
                "company": {
                    "id": 1,
                    "createdAt": "2023-05-20T20:30:11",
                    "updatedAt": "2023-07-17T19:21:22.38524",
                    "deletedAt": null,
                    "title": "bestCompany",
                    "description": null
                },
                "maxAmountOfTeams": 2,
                "description": "my topic 1 description",
                "delegate": {
                    "id": 1,
                    "createdAt": "2023-07-06T11:52:54",
                    "updatedAt": "2023-07-06T08:53:01.388454",
                    "deletedAt": "2023-07-06T08:53:01.388454",
                    "owner": null,
                    "company": {
                        "id": 1,
                        "createdAt": "2023-05-20T20:30:11",
                        "updatedAt": "2023-07-17T19:21:22.38524",
                        "deletedAt": null,
                        "title": "bestCompany",
                        "description": null
                    }
                },
                "programs": [
                    {
                        "id": 1,
                        "title": "program1"
                    }
                ]
            },
            "supervisor": null,
            "reviewer": null,
            "adminApplication": null,
            "companyApplication": null,
            "consultant": null,
            "program": {
                "id": 1,
                "title": "program1"
            }
        },
        {
            "id": 0,
            "createdAt": null,
            "updatedAt": null,
            "deletedAt": null,
            "description": "ewewew",
            "teamlead": {
                "id": 2,
                "createdAt": "2023-08-02T06:29:20.016563",
                "updatedAt": null,
                "deletedAt": null,
                "username": "dima",
                "name": "Дмитрий",
                "surname": "Сираков",
                "patronymic": "Сергеевич",
                "telegram_link": "@berezaIV",
                "email": null,
                "phoneNumber": "+79605587877",
                "role": "STUDENT",
                "enabled": true,
                "authorities": null,
                "password": null,
                "accountNonExpired": true,
                "accountNonLocked": true,
                "credentialsNonExpired": true,
                "maiemail": "dima@mai.education"
            },
            "company": {
                "id": 1,
                "createdAt": "2023-05-20T20:30:11",
                "updatedAt": "2023-07-17T19:21:22.38524",
                "deletedAt": null,
                "title": "bestCompany",
                "description": null
            },
            "title": "ewew",
            "topic": {
                "id": 1,
                "createdAt": "2023-05-20T20:35:37",
                "updatedAt": null,
                "deletedAt": null,
                "title": "my topic 1",
                "company": {
                    "id": 1,
                    "createdAt": "2023-05-20T20:30:11",
                    "updatedAt": "2023-07-17T19:21:22.38524",
                    "deletedAt": null,
                    "title": "bestCompany",
                    "description": null
                },
                "maxAmountOfTeams": 2,
                "description": "my topic 1 description",
                "delegate": {
                    "id": 1,
                    "createdAt": "2023-07-06T11:52:54",
                    "updatedAt": "2023-07-06T08:53:01.388454",
                    "deletedAt": "2023-07-06T08:53:01.388454",
                    "owner": null,
                    "company": {
                        "id": 1,
                        "createdAt": "2023-05-20T20:30:11",
                        "updatedAt": "2023-07-17T19:21:22.38524",
                        "deletedAt": null,
                        "title": "bestCompany",
                        "description": null
                    }
                },
                "programs": [
                    {
                        "id": 1,
                        "title": "program1"
                    }
                ]
            },
            "supervisor": null,
            "reviewer": null,
            "adminApplication": null,
            "companyApplication": null,
            "consultant": null,
            "program": {
                "id": 1,
                "title": "program1"
            }
        },
    ],
};

export const allTeamListSlice = createSlice({
    name: "teamlist",
    initialState,
    reducers: {
        setAllTeamsListData(state, action) {
            
            state.teams = action.payload.list;
        },
    },
});

const {actions, reducer} = allTeamListSlice;

export const {setAllTeamsListData} = actions;

export default reducer;
