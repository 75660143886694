import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import ContentCard from '../../hooks/components/common/ContentCard/ContentCard'
import ContentCardWrapper from '../../hooks/components/common/ContentCard/ContentCardWrapper'
import QuestionSetActive from './QuestionSetActive'
import QuestionSetActiveEdit from './QuestionSetActiveEdit'

const QuestionSet = () => {
	const location = useLocation()
	const { queItem, answerItem, id } = location.state || {}
	const [infoEditing, setInfoEditing] = useState(false)

	const MainInfoEditingProp = {
		status: infoEditing,
		toggle: () => setInfoEditing(!infoEditing),
		content: (
			<QuestionSetActiveEdit question={answerItem} answer={queItem} id={id} />
		),
	}
	return (
		<>
			<ContentCardWrapper>
				<ContentCard
					transparent
					low
					topic='Вопрос'
					editing={MainInfoEditingProp}
				>
					<QuestionSetActive question={answerItem} answer={queItem} id={id} />
				</ContentCard>
			</ContentCardWrapper>
		</>
	)
}

export default QuestionSet
