import { useNavigate } from "react-router-dom";
import { TokenType, checkIsTokenExpired } from "./token";
import { useGetApplications } from "./useGetApplications";
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAmountNotifications } from "../redux/slices/notificationSlice";
import { useGetNotifications } from "./useGetNotifications";
import { APPLICATION_MICROSERVICE_URL, CREATE_COMMAND_MICROSERVICE_URL, sendAxiosWithTokens } from "./team";



export const useResponseOnApplication = () => {
    const navigate = useNavigate();
    const getApplications = useGetApplications();
    const getNotifications = useGetNotifications();
    const dispatch = useDispatch();
    const [resultBadRequset, setResultBadRequset] = useState<boolean>(false);

    const ResponseOnApplications = async (applicationId: number | undefined, action: string, type?: string, teamId?: number,feedbackValue?:string) => {
        const tokens: TokenType = JSON.parse(window.localStorage.getItem("token")!);
        if (checkIsTokenExpired(tokens.access_token)) {
            if (checkIsTokenExpired(tokens.refresh_token)) {
                window.localStorage.removeItem("token");
                navigate("/");
            }
        }
        if (tokens?.access_token) {
            if (type === 'TEAMLEAD_APPLICATIONstudentTostudent') {
                await axios.put(
                    `${APPLICATION_MICROSERVICE_URL}/api/v1/requestsToAdmin/setStatusOfRequestToTransferTeamLeadByStudent`, {
                    "status": action,
                    "applicationId": applicationId,
                    "feedback": feedbackValue
                }, {
                    headers: {
                        Authorization: `Bearer ${tokens.access_token}`,
                    }
                }
                ).catch((error) => {
                    console.error(error);
                    setResultBadRequset(true)
                })
                getApplications();
            } else if (type === 'TEAMLEAD_APPLICATIONapproveByAdmin') {
                await axios.put(
                    `${APPLICATION_MICROSERVICE_URL}/api/v1/requestsToAdmin/setStatusTeamLeadRequest`, {
                    "status": action,
                    "applicationId": applicationId,
                    "feedback": feedbackValue
                }, {
                    headers: {
                        Authorization: `Bearer ${tokens.access_token}`,
                    }
                }
                
                )
                .then(r => {
                    console.log(r.data.data)
                })
                .catch((error) => {
                    console.error(error);
                    setResultBadRequset(true)
                })
                getApplications();
            } else if (type === 'TEAMLEAD_APPLICATIONstudentToAdmin') {
                await axios.put(
                    `${APPLICATION_MICROSERVICE_URL}/api/v1/requestsToAdmin/setStatusOfRequestToTransferTeamLeadByAdmin`, {
                    "status": action,
                    "applicationId": applicationId,
                    "feedback": feedbackValue
                }, {
                    headers: {
                        Authorization: `Bearer ${tokens.access_token}`,
                    }
                }
                ).catch((error) => {
                    console.error(error);
                    setResultBadRequset(true)
                })
                getApplications();
            } else if (type === "MEMBERS_APPLICATION") {
                await axios.put(
                    `${APPLICATION_MICROSERVICE_URL}/api/v1/requestsToAdmin`, {
                    "status": action,
                    "applicationId": applicationId,
                    "feedback": feedbackValue
                }, {
                    headers: {
                        Authorization: `Bearer ${tokens.access_token}`,
                    }
                }
                ).catch((error) => {
                    console.error(error);
                    setResultBadRequset(true)
                })

                await sendAxiosWithTokens(`post`, `${APPLICATION_MICROSERVICE_URL}/api/v1/topic/${teamId}`,{})
                    .then((r) => {
                        console.log(r.data.data)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                getApplications();
            }
            else {
                if (action === 'ACCEPTED') {
                    await axios.put(`${APPLICATION_MICROSERVICE_URL}/api/v1/joinToCommand/accept/${applicationId}`, {}, {
                        headers: {
                            Authorization: `Bearer ${tokens.access_token}`,
                        }
                    }).catch((error) => {
                        console.error(error);
                        setResultBadRequset(true)
                    })
                    getApplications();
                }
                if (action === 'REJECTED') {
                    await axios.put(`${APPLICATION_MICROSERVICE_URL}/api/v1/joinToCommand/reject/${applicationId} `, {}, {
                        headers: {
                            Authorization: `Bearer ${tokens.access_token}`,
                        }
                    }).catch((error) => {
                        console.error(error);
                        setResultBadRequset(true)
                    })
                    getApplications();
                }
            }




        }
    }


    const PutRequestForReadMessage = async (notificationId: number | undefined) => {
        const tokens: TokenType = JSON.parse(window.localStorage.getItem("token")!);
        if (checkIsTokenExpired(tokens.access_token)) {
            if (checkIsTokenExpired(tokens.refresh_token)) {
                window.localStorage.removeItem("token");
                navigate("/");
            }
        }
        if (tokens?.access_token) {
            await axios.put(`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/notification/${notificationId}`, {}, {
                headers: {
                    Authorization: `Bearer ${tokens.access_token}`,
                }
            })
                .then((response) => {
                })
            await getNotifications();
            dispatch(setAmountNotifications());
        }
    }

    return {
        ResponseOnApplications,
        resultBadRequest: resultBadRequset,
        setResultBadRequset: setResultBadRequset,
        PutRequestForReadMessage: PutRequestForReadMessage,
    };
}