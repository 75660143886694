import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setUserInfo } from '../../../../redux/slices/userQuestionnaireSlice'
import { RootState } from '../../../../redux/store'
import Button from '../../common/Button/Button'
import FormInput, { makeProps } from '../../common/Form/FormInput'
import FormNavigateButtons from '../../common/Form/FormNavigateButtons'
import FormPanel from '../../common/Form/FormPanel'

const MainWrapper = () => {
	const dispatch = useDispatch()

	const user = useSelector((state: RootState) => state.userQuestionnaire.user)

	const onChange = ({ target }: any) => {
		dispatch(setUserInfo({ name: target?.name, value: target?.value }))
	}

	const [allFilled, setAllFilled] = useState(false)

	const canGoNext = () => {
		return [
			// user.birthDate !== "",
			user.mail !== '',
			// user.MAIEmail !== "",
			user.telegram_link !== '',
			user.phoneNumber !== '',
		].reduce((result, current) => result && current)
	}

	const patterns = {
		email: /^[A-Z0-9._%+-]+@[A-Z.]+\.[A-Z]{2,4}$/i,
		phoneNumber: /^(8|\+7)[0-9]{10}$/,
		vkLink: /^@[A-Za-z0-9_.]{5,30}$/,
	}

	return (
		<FormPanel method='POST'>
			{/* <FormInput
                    required
                    label="Дата рождения"
                    type="date"
                    {...makeProps.forInput("birthDate", user, onChange)}
                /> */}
			<div className='mobile-qustion'>
				<FormInput
					required
					label='Личная почта'
					type='email'
					{...makeProps.forInput('mail', user, onChange)}
					pattern={patterns.email}
				/>
			</div>
			<div className='desktop-qustion'>
				<FormInput
					required
					label='Личная почта'
					type='email'
					{...makeProps.forInput('mail', user, onChange)}
					pattern={patterns.email}
				/>
			</div>
			<div className='mobile-qustion'>
				<FormInput
					required
					label='Номер телефона'
					type='tel'
					{...makeProps.forInput('phoneNumber', user, onChange)}
					pattern={patterns.phoneNumber}
				/>
			</div>
			<div className='desktop-qustion'>
				<FormInput
					required
					label='Номер телефона'
					type='tel'
					{...makeProps.forInput('phoneNumber', user, onChange)}
					inputDescription='
                        Номер вводится с +7 или 8. Пример: +79251234567'
					labelDescription='Скрыто по умолчанию'
					pattern={patterns.phoneNumber}
				/>
			</div>
			<div className='mobile-qustion'>
				<FormInput
					required
					label='Telegram'
					{...makeProps.forInput('telegram_link', user, onChange)}
					pattern={patterns.vkLink}
				/>
			</div>
			<div className='desktop-qustion'>
				<FormInput
					required
					label='Telegram'
					{...makeProps.forInput('telegram_link', user, onChange)}
					inputDescription='Ник в формате @ivanivanov'
					labelDescription='Можно будет скрыть в личном кабинете'
					pattern={patterns.vkLink}
				/>
			</div>

			<FormNavigateButtons>
				<Button action='link' to='../role' disabled={!canGoNext()} spacing='8'>
					Далее
				</Button>
			</FormNavigateButtons>
		</FormPanel>
	)
}

export default MainWrapper
