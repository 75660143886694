import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Company } from '../../@types/entities/Company'
import Button from '../../hooks/components/common/Button/Button'
import ContentCard from '../../hooks/components/common/ContentCard/ContentCard'
import DoubleList from '../../hooks/components/common/DoubleList/DoubleList'
import FileUploader from '../../hooks/components/common/FileUploader'
import { setActiveCompany } from '../../redux/slices/companySlice'
import Logo from '../Case/Logo'

interface CompanyCardProps {
	Company: Company
	editting: boolean
	idCompany: number
	icon: string
}

const CompanyCard: React.FC<CompanyCardProps> = ({
	Company,
	editting,
	idCompany,
	icon,
}) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [image, setImage] = useState('')
	const ClickToButton = async () => {
		const body = {
			title: Company?.title,
			id: Company?.id,
			shortTitle: Company?.description,
			contactPerson: Company?.contactPerson,
			contactPersonContact: Company?.contactPersonContact,
		}

		dispatch(setActiveCompany(body))
		navigate(`/InfoAboutCompany`, { state: { editting, idCompany: idCompany } })
	}

	const fileUploader = new FileUploader()
	useEffect(() => {
		if (icon !== null) {
			const blobImage = fileUploader.base64ToBlob(icon)
			const url = URL.createObjectURL(blobImage)
			setImage(url)
		}
	}, [])

	// let title: string = Company.title;
	// let titleArray: string[] = title.split(' ')
	// let shortCompanyName: string = ''
	// if (titleArray.length > 1) {
	//     shortCompanyName = titleArray.map(word => word[0].toUpperCase()).join('')
	// }

	return (
		<>
			<ContentCard low blured>
				<div className='blocks'>
					<div className='case-content-card'>
						<Logo imageURL={image}></Logo>
					</div>
					<div className='blocks-1st'>
						<DoubleList
							nonUnderLine={true}
							className='initials'
							listData={[
								{
									identifier: 'Название компании:',
									content: Company?.title,
								},
								{
									identifier: 'Контактное лицо:',
									content: Company?.contactPerson || 'Пока пусто',
								},
							]}
						/>
						<i className='blocks-look'>
							<Button styling='text' onClick={ClickToButton}>
								{editting ? 'Внести изменения' : 'Просмотр'}
							</Button>
						</i>
					</div>
					<div className='blocks-2nd'>
						<DoubleList
							nonUnderLine={true}
							className='initials'
							listData={[
								{
									identifier: 'Короткое название компании:',
									content:
										//@ts-ignore
										Company?.description || Company?.title || 'Пока пусто',
								},
								{
									identifier: 'Связь с контактным лицом:',
									content: Company?.contactPersonContact || 'Пока пусто',
								},
							]}
						/>
					</div>
				</div>
			</ContentCard>
		</>
	)
}

export default CompanyCard
