import React, { useEffect } from 'react'
import Button from '../Button/Button'
import ModalWindowStyles from './ModalWindow.module.scss'

interface ModalWindowProps {
	children?: React.ReactNode
	blured?: boolean
	rounded?: boolean
	visibility?: boolean
	setVisibility?: Function
	autoClose?: boolean
	fastClose?: boolean
	fixClass?: boolean
}

const ModalWindow = ({
	children,
	blured,
	rounded,
	visibility,
	setVisibility,
	autoClose,
	fastClose,
	fixClass,
}: ModalWindowProps) => {
	useEffect(() => {
		let timer: NodeJS.Timeout
		if (visibility && setVisibility && autoClose) {
			timer = setTimeout(
				() => {
					setVisibility(false)
				},
				fastClose ? 700 : 2000
			)
		}

		return () => {
			clearTimeout(timer)
		}
	}, [visibility, setVisibility])

	return (
		<>
			{visibility ? (
				<div
					className={
						fixClass
							? ModalWindowStyles.containerFix
							: ModalWindowStyles.container
					}
				>
					<div
						className={
							ModalWindowStyles.window +
							(blured ? ` ${ModalWindowStyles.blured}` : '') +
							(rounded ? ` ${ModalWindowStyles.rounded}` : '')
						}
					>
						{children}

						{setVisibility && !autoClose ? (
							<div className={ModalWindowStyles.ModalCloseButton}>
								<Button onClick={() => setVisibility(false)}>Закрыть</Button>
							</div>
						) : null}
					</div>
				</div>
			) : (
				''
			)}
		</>
	)
}

export default ModalWindow
