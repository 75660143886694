import { useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const isMulti = true;

function MultiSelect({ options, infobefore,onSelectedValuesChange }) {
  const [currentCountries, setCurrentCountries] = useState(infobefore);

  const getValue = () => {
    if (currentCountries) {
      return isMulti
        ? options.filter((c) => currentCountries.indexOf(c.value) >= 0)
        : options?.find((c) => c.value === currentCountries);
    } else {
      return isMulti ? [] : '';
    }
  };

  const onChange = (newValue) => {
    const selectedValues = isMulti ? newValue.map((v) => v.value) : newValue.value;
    setCurrentCountries(isMulti ? newValue.map((v) => v.value) : newValue.value);
    onSelectedValuesChange && onSelectedValuesChange(selectedValues)
  };

  const animatedComponents = makeAnimated();

  return (
    <div className="dropdown">
      <Select
        classNamePrefix="custom-select"
        onChange={onChange}
        value={getValue()}
        options={options}
        isMulti={isMulti}
        placeholder="Choose skills"
        components={animatedComponents}
      />
    </div>
  );
}

export default MultiSelect;
