import { Link } from 'react-router-dom'
import ContentCard from '../../../hooks/components/common/ContentCard/ContentCard'
import ContentCardHeading from '../../../hooks/components/common/ContentCard/ContentCardHeading'
import ContentCardInner from '../../../hooks/components/common/ContentCard/ContentCardInner'
import ContentCardWrapper from '../../../hooks/components/common/ContentCard/ContentCardWrapper'
import FormNavigateButtons from '../../../hooks/components/common/Form/FormNavigateButtons'

const FinishQuestionnaire = () => {
	return (
		<div className='questionnaire-finnish'>
			<div className='forms-page-counter'>3/3</div>
			<ContentCardWrapper>
				<ContentCard>
					<ContentCardHeading topic='Поздравляем, анкетирование подошло к концу!' />
					<ContentCardInner>
						<p>
							Анкетирование завершено, вся информация будет отображаться в
							личном кабинете. При желании вы всегда сможете изменить её.
						</p>
						<FormNavigateButtons>
							<Link to='/PersonalAccount/MyData'>
								<div className='button'>Вперёд к знаниям!</div>
							</Link>
						</FormNavigateButtons>
					</ContentCardInner>
				</ContentCard>
			</ContentCardWrapper>
		</div>
	)
}

export default FinishQuestionnaire
