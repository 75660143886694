import ImgpasportProject from '../../../../src/assets/img/icons/pin21.svg'
import ImgprojectDescription from '../../../../src/assets/img/icons/pin22.svg'
import ImgexampleProject from '../../../../src/assets/img/icons/pin23.svg'

import axios from 'axios'
import Button from '../../../hooks/components/common/Button/Button'
import { PASSPORT_MICROSERVICE } from '../../../utils/team'
import { TokenType, getTokens } from '../../../utils/token'

interface SideBarProps {
	setActualPasportFrame: Function
	actualPasportFrame: number
	caseId?: number
}
export default function CasePassportListSideBar({
	setActualPasportFrame,
	actualPasportFrame,
	caseId,
}: SideBarProps) {
	const categories = [`Паспорт проекта`, `Описание проекта`, `Пример`]

	const images = [
		<img src={ImgpasportProject} />,
		<img src={ImgprojectDescription} />,
		<img src={ImgexampleProject} />,
	]

	const OnClickCategory = (index: number) => {
		setActualPasportFrame(index)
	}
	async function downloadFile() {
		let tokens: TokenType = getTokens()
		try {
			const response = await axios.get(
				`${PASSPORT_MICROSERVICE}/api/v1/passport/download/passport${caseId}.pdf`,
				{
					responseType: 'blob',
					headers: {
						Authorization: `Bearer ${tokens.access_token}`,
					},
				}
			)

			const url = window.URL.createObjectURL(new Blob([response.data]))

			const link = document.createElement('a')
			link.href = url

			let fileName = 'Project Pasport.pdf'

			link.setAttribute('download', fileName)
			document.body.appendChild(link)
			link.click()
			link.remove()
		} catch (error) {
			console.error('Произошла ошибка при скачивании файла:', error)
		}
	}
	return (
		<div className='pasport-right-sidebar'>
			<div className='pasport-right-sidebar__topic'>Меню</div>
			<div className='categoriesPasport'>
				<ul>
					{categories.map((value, i) => (
						<li
							onClick={() => OnClickCategory(i)}
							className={actualPasportFrame == i ? `active` : ``}
							key={i}
						>
							<div className='link-wrapper'>
								{images[i]}
								<span className='list-text'> {value} </span>
							</div>
						</li>
					))}
				</ul>
			</div>
			<div className='link-wrapperPasport'>
				{/* <ButtonWrapper > */}
				<Button onClick={downloadFile}>Скачать паспорт проекта</Button>
				{/* </ButtonWrapper> */}
			</div>
		</div>
	)
}
