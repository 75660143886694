import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { DPPGroup } from '../../../@types/entities/DPPGroup'
import { Degree } from '../../../@types/entities/Degree'
import { User } from '../../../@types/entities/User'
import paths from '../../../paths'
import ErrorHandler from '../../../utils/ErrorHandler'
import {
	CREATE_COMMAND_MICROSERVICE_URL,
	MODERATION_MICROSERVICE_URL,
	PERSONAL_ACCOUNT_MICROSERVICE,
	sendAxiosWithTokens,
} from '../../../utils/team'
import { getDecodedToken } from '../../../utils/token'
import SingleSelect from '../SingleSelect'
import Button from '../common/Button/Button'
import ButtonWrapper from '../common/Button/ButtonWrapper'
import ContainerMain from '../common/ContainerMain/ContainerMain'
import ContentCard from '../common/ContentCard/ContentCard'
import ContentCardWrapper from '../common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../common/ContentMainTheme'
import styles from '../common/DoubleList/DoubleList.module.scss'
import LoadingText from '../common/Loaders/LoadingText'
import AllStudents from './AllStudents'

type SelectType = {
	valueName: string
	valueId: number | string
	label: string
}

export const ExtraSearchStudents = () => {
	const programs = [
		{
			valueId: 1,
			label: 'Цифровые технологии в социально-гуманитарной сфере',
			valueName: 'Цифровые технологии в социально-гуманитарной сфере',
		},
		{
			valueId: 2,
			label: 'Цифровое моделирование и суперкомпьютерные технологии',
			valueName: 'Цифровое моделирование и суперкомпьютерные технологии',
		},
		{
			valueId: 3,
			label:
				'Методы искусственного интеллекта в задачах обработки результатов дистанционного зондирования Земли',
			valueName:
				'Методы искусственного интеллекта в задачах обработки результатов дистанционного зондирования Земли',
		},
		{
			valueId: 4,
			label:
				'Методы искусственного интеллекта и предиктивная аналитика в проектах дефектоскопии',
			valueName:
				'Методы искусственного интеллекта и предиктивная аналитика в проектах дефектоскопии',
		},
		{
			valueId: 5,
			label: 'Прикладные системы инженерных расчетов',
			valueName: 'Прикладные системы инженерных расчетов',
		},
		{
			valueId: 6,
			label: 'Интеллектуальные технические системы',
			valueName: 'Интеллектуальные технические системы',
		},
		{
			valueId: 7,
			label: 'Прикладные задачи машинного обучения и анализа больших данных',
			valueName:
				'Прикладные задачи машинного обучения и анализа больших данных',
		},
	]

	const [programCurators, setProgramCurators] = useState<User[]>([])
	const [dppGroups, setDPPGroups] = useState<SelectType[]>([])
	const [isFetch, setFetch] = useState<boolean>(false)
	const [students, setStudents] = useState<[]>([])

	const [selectedSkills, setSelectedSkills] = useState<string>('')
	const [selectedFio, setSelectedFio] = useState<string>('')
	const [selectedProgram, setSelectedProgram] = useState<number>(0)
	const [selectedDPPGroup, setSelectedDPPGroup] = useState<number>(0)
	const [selectedProgramCurator, setSelectedProgramCurator] =
		useState<number>(0)
	const [selectedDegree, setSelectedDegree] = useState<string>('')

	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const degrees = [
		{
			valueName: 'Бакалавр',
			label: 'Бакалавр',
			valueId: Degree.UNDERGRADUATE,
		},
		{
			valueName: 'Магистратура',
			label: 'Магистратура',
			valueId: Degree.MAGISTRACY,
		},
		{
			valueName: 'Специалитет',
			label: 'Специалитет',
			valueID: Degree.SPACIALITY,
		},
	]
	const rawSkills = [
		'AirFlow',
		'Atlassian stack',
		'Back-end разработка',
		'С',
		'С#',
		'С++',
		'СNN',
		'CSS',
		'Canva',
		'Computer Vision',
		'Confluence',
		'Data Engineering',
		'Data Science',
		'Deep Learning',
		'DevOps',
		'Django',
		'Docker',
		'ETL',
		'Excel',
		'Figma',
		'Front-end разработка',
		'Git',
		'Go',
		'HTML',
		'Hadoop',
		'Jira',
		'Jupyter',
		'Kafka',
		'Keras',
		'Kotlin',
		'Kubernetes',
		'Linux',
		'Machine Learning',
		'Marketing',
		'Matplotlib',
		'Microsoft Power BI',
		'NumPy',
		'ONNX Runtime',
		'OpenCV',
		'Pandas',
		'Photoshop',
		'PowerPoint',
		'PyTorch',
		'Python',
		'R',
		'React',
		'SMM',
		'SQL',
		'Scikit-Learn',
		'Seaborn',
		'TensorFlow',
		'Использование принципов Rest',
		'Математическая статистика',
		'Построение Rest API',
		'Работа с JSON',
		'Работа с XML',
		'Работа с YAML',
		'Разработка моделей данных',
		'СУБД GreenPulm',
		'СУБД MS SQL Server',
		'СУБД Oracle',
		'СУБД PostgreSQL',
		'Умение работать с API',
		'Управление проектами',
	]
	const formattedSkills = rawSkills.slice(1).map(skill => {
		return { label: skill, value: skill }
	})
	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true)
			const username = getDecodedToken().preferred_username

			const getProgramId = await sendAxiosWithTokens(
				'get',
				`${PERSONAL_ACCOUNT_MICROSERVICE}/api/v1/personal_account/${username}`
			)
			const programId = getProgramId.data.data.programId
			let url = `${MODERATION_MICROSERVICE_URL}/api/v1/admin/users/admins/`

			if (programId !== null) {
				url += `?program_id=${programId}`
			}

			sendAxiosWithTokens('get', url)
				.then(r => {
					// @ts-ignore
					const programCuratorsList = []
					r.data.data.forEach((user: User) => {
						programCuratorsList.push({
							valueName: user.fio,
							valueId: user.id,
							label: user.fio,
						})
					})
					// @ts-ignore
					setProgramCurators(programCuratorsList)
				})
				.catch(error => ErrorHandler(error, navigate))

			sendAxiosWithTokens(
				'get',
				`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/group-selector?is_dpp=true`
			).then(r => {
				const dppData: SelectType[] = []
				r.data.data.forEach((item: DPPGroup) => {
					dppData.push({
						valueName: item.title,
						valueId: item.id,
						label: item.title,
					} as SelectType)
				})
				setDPPGroups(dppData)
				setIsLoading(false)
			})
		}

		fetchData()
	}, [])

	const findTeams = () => {
		let queryParams = new URLSearchParams()
		queryParams.append('is_high_rate', false.toString())
		if (selectedDPPGroup !== 0) {
			//@ts-ignore
			queryParams.append('dpp_group_id', selectedDPPGroup)
		}
		if (selectedDegree !== '') {
			queryParams.append('degree', selectedDegree)
		}
		if (selectedFio !== '') {
			queryParams.append('fio', selectedFio)
		}
		if (selectedProgram !== 0) {
			//@ts-ignore
			queryParams.append('program_id', selectedProgram)
		}
		if (selectedSkills !== '') {
			queryParams.append('skills', selectedSkills)
		}
		let url = new URL(
			`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/extend-search/students`
		)
		//@ts-ignore
		url.search = queryParams

		console.log(url.toString())

		sendAxiosWithTokens('get', `${url.toString()}`).then(r => {
			setStudents(r.data.data)
			setFetch(true)
		})
	}
	return (
		<>
			{' '}
			{isLoading ? (
				<LoadingText />
			) : !isFetch ? (
				<ContainerMain>
					<ContentMainTheme>Расширенный поиск</ContentMainTheme>
					<ContentCardWrapper>
						<ContentCard>
							<div className={'double-lsit-wrapper'}>
								<ul className={styles['double-list']}>
									<li className={styles['double-list__item']}>
										<div className={styles['double-list__item-wrapper']}>
											<div className={styles['identifier']}>
												Поиск по необходимым навыкам в команде:
											</div>
											<div className={styles['content']}>
												<Select
													options={formattedSkills}
													isMulti
													onChange={value => {
														setSelectedSkills(
															value
																.map(v => v.value)
																.join(';')
																.toString()
														)
													}}
												/>
											</div>
										</div>
									</li>
									<li className={styles['double-list__item']}>
										<div className={styles['double-list__item-wrapper']}>
											<div className={styles['identifier']}>
												ФИО пользователя
											</div>
											<div className={styles['content']}>
												<div className='form-field'>
													<div className='form-field-input-wrapper'>
														<input
															className={'form-field-input '}
															//@ts-ignore
															value={selectedFio}
															onChange={e => {
																setSelectedFio(e.target.value)
															}}
														/>
													</div>
												</div>
											</div>
										</div>
									</li>
									<li className={styles['double-list__item']}>
										<div className={styles['double-list__item-wrapper']}>
											<div className={styles['identifier']}>
												Наименование ДПП ПП:
											</div>
											<div className={styles['content']}>
												<SingleSelect
													options={programs}
													infobefore={'ewew'}
													onChangeValue={(
														valueName: string,
														valueId: number
													) => {
														console.log(valueId)
														setSelectedProgram(valueId)
													}}
												/>
											</div>
										</div>
									</li>
									<li className={styles['double-list__item']}>
										<div className={styles['double-list__item-wrapper']}>
											<div className={styles['identifier']}>
												Номер группы ДПП:
											</div>
											<div className={styles['content']}>
												<SingleSelect
													options={dppGroups}
													infobefore={'ewew'}
													onChangeValue={(
														valueName: string,
														valueID: number
													) => {
														setSelectedDPPGroup(valueID)
													}}
												/>
											</div>
										</div>
									</li>
									<li className={styles['double-list__item']}>
										<div className={styles['double-list__item-wrapper']}>
											<div className={styles['identifier']}>
												Руководитель программы:
											</div>
											<div className={styles['content']}>
												<SingleSelect
													options={programCurators}
													infobefore={'ewew'}
													onChangeValue={(
														valueName: string,
														valueID: number
													) => {
														setSelectedProgramCurator(valueID)
													}}
												/>
											</div>
										</div>
									</li>
									<li className={styles['double-list__item']}>
										<div className={styles['double-list__item-wrapper']}>
											<div className={styles['identifier']}>
												Уровень образования:
											</div>
											<div className={styles['content']}>
												<SingleSelect
													options={degrees}
													infobefore={'ewew'}
													onChangeValue={(
														valueName: string,
														valueID: string
													) => {
														setSelectedDegree(valueID)
													}}
												/>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<ButtonWrapper>
								<Button styling={'classic'} onClick={findTeams}>
									Поиск
								</Button>
								<Button
									styling={'classic'}
									onClick={() => {
										navigate(`${paths.team.base}/${paths.team.search}`)
									}}
								>
									Вернуться к списку команд
								</Button>
							</ButtonWrapper>
						</ContentCard>
					</ContentCardWrapper>
				</ContainerMain>
			) : (
				<AllStudents />
			)}
		</>
	)
}
