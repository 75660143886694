import axios from 'axios'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PassportMyTeamDTO } from '../../../@types/dtos/PassportMyTeamDTO'
import { SelectType } from '../../../components/admin/tables/ExtendedSearchApi/ExtendedSearchApi'
import Button from '../../../components/common/Button/Button'
import ContainerMain from '../../../components/common/ContainerMain/ContainerMain'
import ContentCard from '../../../components/common/ContentCard/ContentCard'
import ContentCardWrapper from '../../../components/common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../../components/common/ContentMainTheme'
import SingleSelect from '../../../hooks/components/SingleSelect'
import {
	PASSPORT_MICROSERVICE,
	clientIsTeamLead,
	fetchSquadList,
	fetchTeamInfo,
} from '../../../utils/team'
import { TokenType, getTokens } from '../../../utils/token'
import { ExamplePassport } from './examplePassport.data'
import { fetchPassportMyTeam } from './passport.data'
import PassportProjectCard from './passportProjectCard/passportProjectCard'

const PassportProjectMainBoard = () => {
	const [myPassport, setMyPassport] = useState<PassportMyTeamDTO | null>()
	const [teamId, setTeamId] = useState<number>(0)
	const [showExample, setShowExample] = useState<boolean>(false)
	const [slide, setSlide] = useState<number>(1)
	const navigate = useNavigate()
	useLayoutEffect(() => {
		fetchTeamInfo(teamId)
			.then(r => {
				setTeamId(r.id)
			})
			.catch(e => console.log(e))
		fetchSquadList(teamId).catch(error => console.error(error))
	}, [])

	useEffect(() => {
		if (teamId) {
			fetchPassportMyTeam(teamId)
				.then(r => {
					setMyPassport(r)
				})
				.catch(error => {
					console.log(error)
					setMyPassport(null)
				})
		}
	}, [teamId])

	const goToCreatePassport = (teamId: number) => {
		navigate('/team/passportProject/createPassport', {
			state: { teamId: teamId, actualPassport: myPassport },
		})
	}
	async function downloadFile(teamId: string) {
		let tokens: TokenType = getTokens()
		try {
			const response = await axios.get(
				`${PASSPORT_MICROSERVICE}/api/v1/passport/download/Commandpassport${teamId}.pdf`,
				{
					responseType: 'blob',
					headers: {
						Authorization: `Bearer ${tokens.access_token}`,
					},
				}
			)

			const url = window.URL.createObjectURL(new Blob([response.data]))

			const link = document.createElement('a')
			link.href = url

			let fileName = 'commandPassport.pdf'

			link.setAttribute('download', fileName)
			document.body.appendChild(link)
			link.click()
			link.remove()
		} catch (error) {
			console.error('Произошла ошибка при скачивании файла:', error)
		}
	}
	const selectVars: SelectType[] = [
		{
			label: 'Страница 1',
			valueId: 1,
			valueName: 1,
		},
		{
			label: 'Страница 2',
			valueId: 2,
			valueName: 2,
		},
		{
			label: 'Страница 3',
			valueId: 3,
			valueName: 3,
		},
	]
	return (
		<ContainerMain>
			<ContentMainTheme>
				{myPassport ? 'Паспорт проекта' : 'Пример паспорта проекта'}
			</ContentMainTheme>
			<ContentCardWrapper>
				<ContentCard>
					{myPassport && (
						<div
							style={{
								display: 'grid',
								gridTemplateColumns: 'repeat(2, 1fr)',
								gap: '80px',
								alignItems: 'center',
							}}
						>
							<Button
								onClick={() => {
									downloadFile(teamId.toString())
								}}
							>
								Скачать паспорт проекта
							</Button>
							<SingleSelect
								options={selectVars}
								infobefore={`Страница ${slide}`}
								onChangeValue={(valueID: number) => {
									setSlide(valueID)
								}}
							/>
						</div>
					)}
					{myPassport !== null && myPassport !== undefined && (
						<PassportProjectCard slide={slide} passportData={myPassport} />
					)}
					{!myPassport && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								gap: '16px',
								marginBottom: '24px',
							}}
						>
							<div>У вас пока нет паспорта проекта...</div>
							<Button
								styling={showExample ? 'classic' : 'text'}
								onClick={() => setShowExample(!showExample)}
							>
								{showExample ? 'Закрыть пример' : 'Пример'}
							</Button>
						</div>
					)}
					{showExample && (
						<PassportProjectCard slide={slide} passportData={ExamplePassport} />
					)}
					{!myPassport && clientIsTeamLead() && (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								marginTop: '16px',
							}}
						>
							<Button onClick={() => goToCreatePassport(teamId)}>
								Создать паспорт проекта
							</Button>
						</div>
					)}
					{myPassport && clientIsTeamLead() && (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								marginTop: '16px',
							}}
						>
							<Button onClick={() => goToCreatePassport(teamId)}>
								Обновить паспорт проекта
							</Button>
						</div>
					)}
				</ContentCard>
			</ContentCardWrapper>
		</ContainerMain>
	)
}
export default PassportProjectMainBoard
