import { Link, useNavigate } from 'react-router-dom'

import { useEffect, useState } from 'react'
import { GetImportantInfoDTO } from '../../../../@types/dtos/GetImportantInfoDTO'
import { Program } from '../../../../@types/entities/Program'
import {
	CREATE_COMMAND_MICROSERVICE_URL,
	sendAxiosWithTokens,
} from '../../../../utils/team'
import Table from '../../common/Tables/TableFilterAndPagination'

interface user {
	userId: number
	username?: string
	name?: string
	surname?: string
	patronymic?: string
	fio: string
	dppGroup?: {
		id?: number
		title?: string
		program?: Program
	}
	preferedRole: {
		title: string
	}
}

const AllStudentsTable = ({
	all = false,
	studentsFromExtendedSearch = [],
}: {
	all?: boolean
	studentsFromExtendedSearch?: GetImportantInfoDTO[]
}) => {
	const [users, setUsers] = useState<user[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const navigate = useNavigate()

	const COLUMNS = [
		{
			Header: '№',
			Cell: ({ row }: { row: any }) => <span>{row.index + 1}</span>,
		},
		{
			Header: 'ФИО пользователя',
			accessor: 'fio',
		},
		{
			Header: 'Программа',
			accessor: 'dppGroup.program.title',
			Cell: ({ value }: { value: string }) => {
				const programs: { [key: string]: string } = {
					'Методы искусственного интеллекта и предиктивная аналитика в проектах дефектоскопии (2023/2024)':
						'ДСК',
					'Цифровое моделирование и суперкомпьютерные технологии (2023/2024)':
						'СКТ',
					'Методы искусственного интеллекта в задачах обработки результатов дистанционного зондирования Земли (2023/2024)':
						'ДЗЗ',
					'Прикладные задачи машинного обучения и анализа больших данных (2023/2024)':
						'ML',
					'Прикладные системы инженерных расчетов (2023/2024)': 'ПСИР',
					'Интеллектуальные технические системы (2023/2024)': 'ИТС',
					'Цифровые технологии в социально-гуманитарной сфере (2023/2024)':
						'СГС',
				}
				return <span>{programs[value]}</span>
			},
		},
		{
			Header: 'Предпочтительная роль',
			accessor: 'preferedRole.title',
		},
		{
			Header: 'Профиль',
			accessor: 'username',
			Cell: ({ value }: { value: any }) => (
				<Link className='profile-link' to={`/profile/${value}/data`}>
					Перейти в профиль
				</Link>
			),
		},
	]
	// if (!all) {
	//     // @ts-ignore
	//     COLUMNS.push({
	//             Header: "Запрос",
	//             Cell: ({value}: { value: any }) => (
	//                 <div className="all-user-table-invite-button">отправить</div>
	//             ),
	//         })
	// }

	useEffect(() => {
		;(async () => {
			const currentUrl = all
				? `${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/user/all`
				: `${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/user/getUsersImportantInfo`
			await sendAxiosWithTokens(`get`, currentUrl)
				.then(res => {
					const arrayToSaveInState: user[] = []
					res.data.data.forEach((element: user) => {
						let body: user = {
							userId: element.userId,
							fio: element.fio,
							username: element.username,
							dppGroup: {
								id: element.dppGroup?.id,
								title: element.dppGroup?.title,
								program: {
									id: element.dppGroup?.program?.id,
									title: element.dppGroup?.program?.title,
								},
							},
							preferedRole: {
								title: element.preferedRole?.title,
							},
						}
						arrayToSaveInState.push(body)
					})

					setUsers(arrayToSaveInState)
					// setUsers(res.data.data)
					setIsLoading(false)
				})
				.catch(error => {
					console.error(error)
				})
		})()
	}, [])

	return (
		<>
			{isLoading ? (
				'Loading...'
			) : (
				<Table
					columns={COLUMNS}
					data={
						studentsFromExtendedSearch.length === 0
							? users
							: studentsFromExtendedSearch
					}
					itemsOnPage={15}
					className='all-user-table'
				/>
			)}
		</>
	)
}

export default AllStudentsTable
