import React, { MouseEventHandler } from "react";

interface FormCheckboxItemProps {
	children?: React.ReactNode;
	checked?: boolean;
	value?: string;
	name?: string;
	onClick?: MouseEventHandler<HTMLDataElement>;
}

const FormCheckboxItem = ({ checked, onClick = () => {}, value, name }: FormCheckboxItemProps) => {
	return (
		<data className={!checked ? `${'input-checkbox-item'}` : 'input-checkbox-active'} onClick={onClick}>
			{value}
			{/* <input type="checkbox" name={name} checked={checked} onClick={onClick}/> */}
		</data>
	);
};

export default FormCheckboxItem;
