import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../../hooks/components/common/Button/Button'
import { APPLICATION_MICROSERVICE_URL } from '../../utils/team'
import {
	TokenType,
	checkIsTokenExpired,
	getTokens,
	isTokenInLS,
	updateToken,
} from '../../utils/token'

interface QuestionSetActivePrompt {
	question: string
	answer: string
	id: string
}

const QuestionSetActive: React.FC<QuestionSetActivePrompt> = ({
	question,
	answer,
	id,
}) => {
	let admin: boolean = false
	let student: boolean = false

	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		const isAdmin = roles.indexOf('client-admin') > -1
		if (isAdmin) admin = isAdmin
		const isStudent = roles.indexOf('client-student') > -1
		const isTeamLead = roles.indexOf('client-teamlead') > -1
	}
	const navigate = useNavigate()
	const dispatch = useDispatch()
	let tokens: TokenType = getTokens()

	const handleDeleteQuestion = async (questionId: any) => {
		if (tokens != null) {
			if (checkIsTokenExpired(tokens.access_token)) {
				if (checkIsTokenExpired(tokens.refresh_token)) {
					window.localStorage.removeItem('token')
					navigate('/')
				} else {
					await updateToken()
					tokens = getTokens()
				}
			}

			axios
				.delete(`${APPLICATION_MICROSERVICE_URL}/help/faq/${questionId}`, {
					headers: {
						Authorization: `Bearer ${getTokens().access_token}`,
					},
				})
				.then(response => {
					console.log(response)
				})
				.catch(error => {
					console.log(error)
				})
		}
	}

	useEffect(() => {
		if (isTokenInLS()) {
			const token: any = jwt_decode(getTokens().access_token)
		}
	}, [])

	return (
		<>
			<div className='ask-question-textarea-wrapper'>
				Вопрос:
				<textarea
					rows={10}
					cols={36}
					value={question}
					className='ask-question-textarea-input1'
				/>
			</div>
			<div className='ask-question-textarea-wrapper'>
				Ответ:
				<textarea
					rows={10}
					cols={36}
					value={answer}
					className='ask-question-textarea-input2'
				/>
				<div className='ask-question-button-wrapper'>
					<Button action='link' spacing='16' to='../popular-question'>
						Назад
					</Button>
					<Link
						onClick={e => handleDeleteQuestion(id)}
						className='ask-question-link-wrapper'
						to={'../popular-question'}
						state={{ que: question }}
					>
						Удалить вопрос
					</Link>
				</div>
			</div>
		</>
	)
}

export default QuestionSetActive
