import React from "react";

interface FormNavigateButtonsProps {
    children: React.ReactNode;
}

const FormNavigateButtons = ({ children }: FormNavigateButtonsProps) => {
    return <div className="form-navigate-buttons">{children}</div>;
};

export default FormNavigateButtons;
