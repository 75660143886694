import SyrveyWrapper from '../../../hooks/components/Questionnaire/UserForm/SyrveyWrapper'
import ContentCard from '../../../hooks/components/common/ContentCard/ContentCard'
import ContentCardHeading from '../../../hooks/components/common/ContentCard/ContentCardHeading'
import ContentCardInner from '../../../hooks/components/common/ContentCard/ContentCardInner'
import ContentCardWrapper from '../../../hooks/components/common/ContentCard/ContentCardWrapper'

const Questions = () => {
	return (
		<>
			<div className='questionnaire-survey'>
				<div className='forms-page-counter'>3/3</div>
				<ContentCardWrapper>
					<ContentCard>
						<ContentCardHeading topic='Ответьте на вопросы' />
						<ContentCardInner>
							<SyrveyWrapper />
						</ContentCardInner>
					</ContentCard>
				</ContentCardWrapper>
			</div>
		</>
	)
}

export default Questions
