import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import { User } from "../../@types/dtos/GetImportantInfoDTO";

// export interface UserListSlice {
//     users: User[];
// }

// const initialState: UserListSlice = {
const initialState = {
	// users: [],
	users: [
		{
			userId: 0,
			fio: "Фамилия Имя Отчество",
			telegram_link: "@telegram",
			email: "example@email.com",
			username: "Username",
			birthDate: "01.01.2001",
			degree: "UNDERGRADUATE",
			dppGroup: {
				title: "Group title",
			},
			teamTitle: "Название команды",
			phoneNumber: "8 900 123 45 67",
			preferedRole: { title: "Предпочтительная роль" },
		},
		{
			userId: 0,
			fio: "Фамилия Имя Отчество",
			telegram_link: "@telegram",
			email: "example@email.com",
			username: "Username",
			birthDate: "01.01.2001",
			degree: "UNDERGRADUATE",
			dppGroup: {
				title: "Group title",
			},
			teamTitle: "Название команды",
			phoneNumber: "8 900 123 45 67",
			preferedRole: { title: "Предпочтительная роль" },
		},
		{
			userId: 0,
			fio: "Фамилия Имя Отчество",
			telegram_link: "@telegram",
			email: "example@email.com",
			username: "Username",
			birthDate: "01.01.2001",
			degree: "UNDERGRADUATE",
			dppGroup: {
				title: "Group title",
			},
			teamTitle: "Название команды",
			phoneNumber: "8 900 123 45 67",
			preferedRole: { title: "Предпочтительная роль" },
		},
		{
			userId: 0,
			fio: "Фамилия Имя Отчество",
			telegram_link: "@telegram",
			email: "example@email.com",
			username: "Username",
			birthDate: "01.01.2001",
			degree: "UNDERGRADUATE",
			dppGroup: {
				title: "Group title",
			},
			teamTitle: "Название команды",
			phoneNumber: "8 900 123 45 67",
			preferedRole: { title: "Предпочтительная роль" },
		},
		{
			userId: 0,
			fio: "Фамилия Имя Отчество",
			telegram_link: "@telegram",
			email: "example@email.com",
			username: "Username",
			birthDate: "01.01.2001",
			degree: "UNDERGRADUATE",
			dppGroup: {
				title: "Group title",
			},
			teamTitle: "Название команды",
			phoneNumber: "8 900 123 45 67",
			preferedRole: { title: "Предпочтительная роль" },
		},
		{
			userId: 0,
			fio: "Фамилия Имя Отчество",
			telegram_link: "@telegram",
			email: "example@email.com",
			username: "Username",
			birthDate: "01.01.2001",
			degree: "UNDERGRADUATE",
			dppGroup: {
				title: "Group title",
			},
			teamTitle: "Название команды",
			phoneNumber: "8 900 123 45 67",
			preferedRole: { title: "Предпочтительная роль" },
		},
		{
			userId: 0,
			fio: "Фамилия Имя Отчество",
			telegram_link: "@telegram",
			email: "example@email.com",
			username: "Username",
			birthDate: "01.01.2001",
			degree: "UNDERGRADUATE",
			dppGroup: {
				title: "Group title",
			},
			teamTitle: "Название команды",
			phoneNumber: "8 900 123 45 67",
			preferedRole: { title: "Предпочтительная роль" },
		},
		{
			userId: 0,
			fio: "Фамилия Имя Отчество",
			telegram_link: "@telegram",
			email: "example@email.com",
			username: "Username",
			birthDate: "01.01.2001",
			degree: "UNDERGRADUATE",
			dppGroup: {
				title: "Group title",
			},
			teamTitle: "Название команды",
			phoneNumber: "8 900 123 45 67",
			preferedRole: { title: "Предпочтительная роль" },
		},
		{
			userId: 0,
			fio: "Фамилия Имя Отчество",
			telegram_link: "@telegram",
			email: "example@email.com",
			username: "Username",
			birthDate: "01.01.2001",
			degree: "UNDERGRADUATE",
			dppGroup: {
				title: "Group title",
			},
			teamTitle: "Название команды",
			phoneNumber: "8 900 123 45 67",
			preferedRole: { title: "Предпочтительная роль" },
		},
		{
			userId: 0,
			fio: "Фамилия Имя Отчество",
			telegram_link: "@telegram",
			email: "example@email.com",
			username: "Username",
			birthDate: "01.01.2001",
			degree: "UNDERGRADUATE",
			dppGroup: {
				title: "Group title",
			},
			teamTitle: "Название команды",
			phoneNumber: "8 900 123 45 67",
			preferedRole: { title: "Предпочтительная роль" },
		},
		{
			userId: 0,
			fio: "Фамилия Имя Отчество",
			telegram_link: "@telegram",
			email: "example@email.com",
			username: "Username",
			birthDate: "01.01.2001",
			degree: "UNDERGRADUATE",
			dppGroup: {
				title: "Group title",
			},
			teamTitle: "Название команды",
			phoneNumber: "8 900 123 45 67",
			preferedRole: { title: "Предпочтительная роль" },
		},
		{
			userId: 0,
			fio: "Фамилия Имя Отчество",
			telegram_link: "@telegram",
			email: "example@email.com",
			username: "Username",
			birthDate: "01.01.2001",
			degree: "UNDERGRADUATE",
			dppGroup: {
				title: "Group title",
			},
			teamTitle: "Название команды",
			phoneNumber: "8 900 123 45 67",
			preferedRole: { title: "Предпочтительная роль" },
		},
	],
};

export const allUserListSlice = createSlice({
	name: "userList",
	initialState,
	reducers: {
		setAllUserListData(state, action) {
			state.users = action.payload.list;

			// action.payload.list.forEach((user) => state.users.push(user));
		},
	},
});

const { actions, reducer } = allUserListSlice;

export const { setAllUserListData } = actions;

export default reducer;
