import DoubleList from "../../../common/DoubleList/DoubleList";

function parseAdditionals(role) {
    return [
        {
            identifier: "Предпочтительная роль",
            content: role?.preferedRole?.title,
        },
        {
            identifier: "Навыки",
            content: role?.skills,
        },
    ];
}

export default function Role({ role }) {
    return <DoubleList listData={parseAdditionals(role)} />;
}
