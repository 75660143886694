import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { CompanyUserDTO } from '../../@types/dtos/CompanyUserDTO'
import { Company } from '../../@types/entities/Company'
import { Program } from '../../@types/entities/Program'
import { UserRole } from '../../@types/entities/UserRole'
import styles from '../../components/common/DoubleList/DoubleList.module.scss'
import SingleSelect from '../../hooks/components/SingleSelect'
import Button from '../../hooks/components/common/Button/Button'
import ContainerMain from '../../hooks/components/common/ContainerMain/ContainerMain'
import ContentCard from '../../hooks/components/common/ContentCard/ContentCard'
import ContentCardInner from '../../hooks/components/common/ContentCard/ContentCardInner'
import ContentMainTheme from '../../hooks/components/common/ContentMainTheme'
import FormLabel from '../../hooks/components/common/Form/FormLabel'
import LoadingText from '../../hooks/components/common/Loaders/LoadingText'
import ModalWindow from '../../hooks/components/common/ModalWindow/ModalWindow'
import SeparateLine from '../../hooks/components/common/SeparateLine/SeparateLine'
import ErrorHandler from '../../utils/ErrorHandler'
import {
	FILES_MICROSERVICE,
	MODERATION_MICROSERVICE_URL,
	sendAxiosWithTokens,
} from '../../utils/team'
import caseStyles from './Case.module.scss'

// import hljs from 'highlight.js';

import MDEditor from '@uiw/react-md-editor'
import { sendAxiosWithTokensAndCustomHeaders } from '../../utils/team'

type SelectType = { valueName: string; valueId: number; label: string }
type SelectProgramType = { valueName: string; label: string; value: number }
const CaseCreate = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [secondLoading, setSecondLoading] = useState<boolean>(true)
	const navigate = useNavigate()
	const [companies, setCompanies] = useState<SelectType[]>()
	const [curators, setCurators] = useState<SelectType[]>()
	const [customers, setCustomers] = useState<SelectType[]>()
	const [programsList, setProgramsList] = useState<SelectProgramType[]>()
	useEffect(() => {
		;(async () => {
			await sendAxiosWithTokens(
				`get`,
				`${MODERATION_MICROSERVICE_URL}/api/v1/program/`
			)
				.then(r => {
					const dataToSaveInState: SelectProgramType[] = []
					r.data.data.forEach((item: Program) => {
						dataToSaveInState.push({
							valueName: item.title,
							label: item.title,
							value: item.id,
						} as SelectProgramType)
					})
					setProgramsList(dataToSaveInState)
				})
				.catch(error => {
					console.log(error)
				})
		})()
	}, [])

	const [data, setData] = useState({
		title: '',
		description: '',
		companyId: -1,
		curatorId: -1,
		customerId: -1,
		amountOfTeams: -1,
	})
	const [files, setFiles] = useState<FileList>()

	function getData() {
		return async () => {
			if (data.companyId === -1)
				await sendAxiosWithTokens(
					'get',
					`${MODERATION_MICROSERVICE_URL}/api/v1/company`
				)
					.then(r => {
						console.log(r)
						const dataToSaveInState: SelectType[] = []
						r.data.data.forEach((item: Company) => {
							dataToSaveInState.push({
								valueName: item.title,
								valueId: item.id,
								label: item.title,
							} as SelectType)
						})
						setCompanies(dataToSaveInState)
						setIsLoading(false)
					})
					.catch(err => {
						console.error(err)
						ErrorHandler(err, navigate)
					})
			else {
				setSecondLoading(true)
				await sendAxiosWithTokens(
					'get',
					`${MODERATION_MICROSERVICE_URL}/api/v1/admin/users/company_users/?company_id=${data.companyId}&role=${UserRole.CUSTOMER}`
				)
					.then(r => {
						console.log(r)
						const dataToSaveInState: SelectType[] = []
						r.data.data.forEach((item: CompanyUserDTO) => {
							if (item.company.id === data.companyId)
								dataToSaveInState.push({
									valueName: item.fio,
									valueId: item.id,
									label: item.fio,
								} as SelectType)
						})
						// @ts-ignore
						setCustomers(dataToSaveInState)
					})
					.catch(err => {
						console.error(err)
						ErrorHandler(err, navigate)
					})
				await sendAxiosWithTokens(
					'get',
					`${MODERATION_MICROSERVICE_URL}/api/v1/admin/users/company_users/?company_id=${data.companyId}&role=${UserRole.CURATOR}`
				)
					.then(r => {
						console.log(r)
						const dataToSaveInState: SelectType[] = []
						r.data.data.forEach((item: CompanyUserDTO) => {
							if (item.company.id === data.companyId)
								dataToSaveInState.push({
									valueName: item.fio,
									valueId: item.id,
									label: item.fio,
								} as SelectType)
						})
						setCurators(dataToSaveInState)
						setSecondLoading(false)
					})
					.catch(err => {
						console.error(err)
						ErrorHandler(err, navigate)
					})
			}
		}
	}

	useEffect(() => {
		getData()()
	}, [data.companyId])

	const [requestResult, setRequestResult] = useState<boolean>(false)

	const CreateNewCase = async (data: FormData) => {
		console.log(JSON.stringify(data))
		await sendAxiosWithTokensAndCustomHeaders(
			'post',
			`${FILES_MICROSERVICE}/api/v1/topic`,
			data,
			{
				'Content-Type': 'multipart/form-data',
			}
		)
			.then(response => {
				setRequestResult(true)
				console.log('result of request', response.data)
				navigate('/Case')
			})
			.catch(error => {
				if (error.response.data.type === 'business') {
					setVisibility(true)
				}
				console.error(error)
			})
	}

	// состояния для значений селектов , остальное с инпутом через регистры достается
	const [actualCompany, setActualCompany] = useState<number>(0)
	const [actualCurator, setActualCurator] = useState<number>(0)
	const [actualCustomer, setActualCustomer] = useState<number>(0)
	const [actualProgram, setActualProgram] = useState<number[]>([])

	const [isVisible, setVisibility] = useState<boolean>(false)

	const [mdRedactorContent, setMdRedactorContent] = useState<string>('')

	const EndCLick = () => {
		const value = getValues()
		const amountOfTeam = parseInt(value['amountOfTeam'])
		const formData = new FormData()
		if (files) {
			for (let i = 0; i < files.length; i++) {
				formData.append('files', files[i])
			}
		}

		formData.append('title', value['title'])
		formData.append('description', mdRedactorContent)
		formData.append('companyId', actualCompany.toString())
		formData.append('programs', actualProgram.toString())
		formData.append('delegateId', actualCurator.toString())
		formData.append('customerId', actualCustomer.toString())
		formData.append('maxAmountOfTeams', amountOfTeam.toString())
		CreateNewCase(formData)
	}

	const {
		handleSubmit,
		register,
		getValues,
		formState: { errors, isValid },
	} = useForm<any>({
		mode: 'onTouched',
	})

	return (
		<>
			{isLoading ? (
				<LoadingText />
			) : (
				<ContainerMain size={'large'}>
					<ContentMainTheme>
						Создание своего собственного кейса
					</ContentMainTheme>
					<div>
						<ModalWindow
							visibility={isVisible}
							setVisibility={setVisibility}
							autoClose
						>
							{requestResult ? 'Кейс успешно создан' : 'Произошла ошибка'}
						</ModalWindow>

						<ContentCard>
							<ContentCardInner>
								{/* <FormInput required label="Введите название вашей команды:" {...makeProps.forInput("title", team, handleChange)}/> */}
								<div className='form-field'>
									<FormLabel required>Наименование кейса:</FormLabel>
									<input
										className={
											'form-field-input ' +
											(errors.title ? 'form-field-input--not-valid ' : '')
										}
										{...register('title', {
											required: true,
										})}
									/>
								</div>
								<div className='form-field'>
									<FormLabel required>
										Краткое описание компании (поможет заинтересовать и привлечь
										студентов):
									</FormLabel>
									<div className={styles['md-container']}>
										<MDEditor
											value={mdRedactorContent}
											height={300}
											highlightEnable={true}
											// @ts-ignore
											onChange={setMdRedactorContent}
										/>
									</div>
								</div>
							</ContentCardInner>
						</ContentCard>
						<ContentCard>
							<ContentCardInner>
								<div className='form-field'>
									<FormLabel>Прикрепите файлы</FormLabel>
									<div className={styles['md-container']}>
										<input
											type='file'
											className={caseStyles.fileUpload}
											multiple
											id={caseStyles.fileUpload}
											onChange={e => setFiles(e.target?.files as FileList)}
										/>
										<label
											htmlFor='fileUpload'
											className={caseStyles.fileUploadLabel}
										>
											Выбрать файлы
										</label>
									</div>
								</div>
							</ContentCardInner>
						</ContentCard>
						<br />
						<br />
						<ContentCard>
							<ContentCardInner>
								<div className={'double-lsit-wrapper'}>
									<ul className={styles['double-list']}>
										<li className={styles['double-list__item']}>
											<div className={styles['double-list__item-wrapper']}>
												<div className={styles['identifier']}>
													<FormLabel required>Компания:</FormLabel>
												</div>
												<div className={styles['content']}>
													<SingleSelect
														options={companies}
														infobefore={'ewew'}
														onChangeValue={(
															valueName: string,
															valueID: number
														) => {
															setData({
																...data,
																companyId: valueID,
															})
															setActualCompany(valueID)
														}}
														//   {...register("company", {required: true})}
													/>
												</div>
											</div>
											<SeparateLine />
										</li>
										<li className={styles['double-list__item']}>
											<div className={styles['double-list__item-wrapper']}>
												<div className={styles['identifier']}>
													<FormLabel required>Программа:</FormLabel>
												</div>
												<div className={styles['content']}>
													{/* @ts-ignore */}
													{/* {...register("programs", {required: true})} */}

													<Select
														options={programsList}
														isMulti
														onChange={e => {
															//@ts-ignore
															const ids: number[] = []

															e.forEach(
																(pair: { value: number; label: string }) => {
																	ids.push(pair.value)
																}
															)
															setActualProgram(ids)
														}}
													/>
												</div>
											</div>
											<SeparateLine />
										</li>
										{data.companyId > -1 && !secondLoading ? (
											<>
												<li className={styles['double-list__item']}>
													<div className={styles['double-list__item-wrapper']}>
														<div className={styles['identifier']}>
															<FormLabel required>Куратор кейса:</FormLabel>
														</div>
														<div className={styles['content']}>
															<SingleSelect
																options={curators}
																infobefore={'ewew'}
																onChangeValue={(
																	valueName: string,
																	valueID: number
																) => {
																	setActualCurator(valueID)
																}}
																//   {...register("curator", {required: true})}
															/>
														</div>
													</div>
													<SeparateLine />
												</li>
												<li className={styles['double-list__item']}>
													<div className={styles['double-list__item-wrapper']}>
														<div className={styles['identifier']}>
															<FormLabel required>Заказчик кейса:</FormLabel>
														</div>
														<div className={styles['content']}>
															<SingleSelect
																options={customers}
																infobefore={'fsdfd'}
																onChangeValue={(
																	valueName: string,
																	valueID: number
																) => {
																	setActualCustomer(valueID)
																}}
															/>
														</div>
													</div>
													<SeparateLine />
												</li>

												<li className={styles['double-list__item']}>
													<div className={styles['double-list__item-wrapper']}>
														<div className={styles['identifier']}>
															<FormLabel description={'Необязательно'}>
																Кол-во команд:
															</FormLabel>
														</div>
														<div className={styles['content']}>
															<input
																defaultValue={100}
																className={
																	'form-field-input ' +
																	(errors.title
																		? 'form-field-input--not-valid '
																		: '')
																}
																{...register('amountOfTeam')}
															/>
														</div>
													</div>
													<SeparateLine />
												</li>
											</>
										) : (
											''
										)}
									</ul>

									<Button
										className={caseStyles['button-case-create']}
										action='formButton'
										type='submit'
										// disabled={!isValid}
										onClick={EndCLick}
									>
										Создать кейс
									</Button>
								</div>
							</ContentCardInner>
						</ContentCard>
					</div>
				</ContainerMain>
			)}
		</>
	)
}

export default CaseCreate
