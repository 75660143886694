import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activeIndex: 0,
};

export const activeIndexSlice = createSlice({
  name: "paTest",
  initialState: initialState,
  reducers: {
    setActiveIndex: (state, action) => {
        state.activeIndex = action.payload;
    },
  }
});

export const {setActiveIndex} = activeIndexSlice.actions;

export default activeIndexSlice.reducer;
