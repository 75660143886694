import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {User} from "../../../src/@types/entities/User";
import {UserRole} from "../../@types/entities/UserRole";

export interface AdminInfoSlice {
    profile: {
        owner: User;
    };
}

const initialState: AdminInfoSlice = {
    profile: {
        owner: {
            id: 0,
            username: "",
            name: "",
            surname: "",
            patronymic: "",
            phoneNumber: "",
            MAIEmail: "",
            telegram_link: "",
            email: "",
            authorities: "",
            credentialsNonExpired: "",
            enabled: "",
            password: "",
            role: UserRole.CURATOR,
            fio: ""
        },
    },
};

type AdminFieldsType =
    | "surname"
    | "name"
    | "patronymic"
    | "email"
    | "phoneNumber"
    | "telegram_link";

type setUserInfoAction = PayloadAction<{
    name: AdminFieldsType;
    value: string;
}>;

export const AdminInfoSlice = createSlice({
    name: "AdminInfo",
    initialState,
    reducers: {
        setAdminInfo(state: AdminInfoSlice, action: setUserInfoAction) {
            if (action.payload.name in state.profile.owner)
                state.profile.owner[action.payload.name] = action.payload.value;
        },
    },
});

export const {setAdminInfo} = AdminInfoSlice.actions;
export default AdminInfoSlice.reducer;
