import React from "react";
import GearImg from "../../../assets/img/icons/gear.svg";
import card from "./content-card.module.scss";

interface ContentCardEditButtonProps {
	toggle?: () => void;
}

const ContentCardEditButton = ({ toggle }: ContentCardEditButtonProps) => {
	return (
		<div className={card["edit-button-gear"]} onClick={toggle}>
			<img src={GearImg} alt="" />
		</div>
	);
};

export default ContentCardEditButton;
