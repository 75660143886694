import { useState } from 'react'
import { GetImportantInfoDTO } from '../../../../../@types/dtos/GetImportantInfoDTO'
import Button from '../../../common/Button/Button'
import ButtonWrapper from '../../../common/Button/ButtonWrapper'
import ContainerMain from '../../../common/ContainerMain/ContainerMain'
import ContentCard from '../../../common/ContentCard/ContentCard'
import ContentCardFooter from '../../../common/ContentCard/ContentCardFooter'
import ContentCardHeading from '../../../common/ContentCard/ContentCardHeading'
import ContentCardInner from '../../../common/ContentCard/ContentCardInner'
import ContentCardWrapper from '../../../common/ContentCard/ContentCardWrapper'
import AllStudentsTable from '../../studentsList/AllStudentsTable'
import ExtendSearchStudents from './ExtendSearchStudents'

const AllUsers = ({
	studentsFromExtendedSearch = [],
}: {
	studentsFromExtendedSearch: GetImportantInfoDTO[]
}) => {
	const [isSearch, setIsSearch] = useState<boolean>(false)
	const [studentsFromExtendedSearchh, setStudentsFromExtendedSearch] = useState<
		GetImportantInfoDTO[]
	>([])
	return (
		<ContainerMain>
			<ContentCardWrapper>
				<ContentCard low>
					<div className='text-mobile'>
						Для облегчения поиска мы решили сделать список рекомендаций, в
						котором каждый из вас сможет найти себе команду в зависимости от
						вашей предпочтительной роли, указанной в личном кабинете. Вы также
						можете воспользоваться расширенным поиском, который поможет найти
						любого пользователя и команду по названию или фильтрам
					</div>
					<div className='text-desktop'>
						Для облегчения поиска мы решили сделать список рекомендаций, в
						котором каждый из вас сможет найти себе команду в зависимости от
						вашей предпочтительной роли, указанной в личном кабинете. Вы также
						можете воспользоваться расширенным поиском, который поможет найти
						любого пользователя и команду по названию или фильтрам
					</div>
				</ContentCard>
				<ContentCard>
					<ContentCardHeading topic='Список студентов'>
						{studentsFromExtendedSearch.length == 0 && !isSearch ? (
							<Button onClick={() => setIsSearch(true)}>
								Расширенный поиск
							</Button>
						) : (
							<Button
								onClick={() => {
									window.location.reload()
								}}
							>
								Вернуться назад
							</Button>
						)}
					</ContentCardHeading>
					<ContentCardInner>
						<div className='table-wrapper'>
							{isSearch ? (
								<ExtendSearchStudents
									setIsSearch={setIsSearch}
									setStudentsFromExtendedSearch={setStudentsFromExtendedSearch}
								/>
							) : (
								<AllStudentsTable
									all
									studentsFromExtendedSearch={studentsFromExtendedSearchh}
								/>
							)}
						</div>
					</ContentCardInner>
					<ContentCardFooter>
						{isSearch ? null : (
							<ButtonWrapper>
								<Button action={'link'} to='/admin/users'>
									Студенты
								</Button>
								<Button action={'link'} to='/admin/companies'>
									Партнеры
								</Button>
								<Button action={'link'} to='/admin/admins'>
									Админы
								</Button>
							</ButtonWrapper>
						)}
					</ContentCardFooter>
				</ContentCard>
			</ContentCardWrapper>
		</ContainerMain>
	)
}

export default AllUsers
