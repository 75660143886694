interface PaginationProps {
	pageIndex: number;
	visiblePaginationRange: number;
	pageCount: number;
	pageOptions: number[];
	previousPage: Function;
	nextPage: Function;
	gotoPage: Function;
	canPreviousPage: Boolean;
	canNextPage: Boolean;
}

const PaginationBar = ({
	pageIndex,
	visiblePaginationRange,
	pageCount,
	pageOptions,
	previousPage,
	nextPage,
	gotoPage,
	canPreviousPage,
	canNextPage,
}: PaginationProps) => {
	const changePage = (e: any) => {
		gotoPage(Number(e.target.value));
	};

	const getVisiblePageOptions = (
		pageIndex: number,
		pageCount: number,
		pageOptions: number[],
		visiblePaginationRange: number,
	) => {
		if (visiblePaginationRange * 2 + 1 + 4 >= pageCount) {
			return pageOptions;
		}

		if (pageIndex - visiblePaginationRange <= 2) {
			return pageOptions.slice(0, visiblePaginationRange * 2 + 1 + 2);
		} else if (pageCount - pageIndex - 1 - visiblePaginationRange <= 2) {
			return pageOptions.slice(pageCount - 1 - 2 - visiblePaginationRange * 2);
		} else {
			return pageOptions.slice(
				pageIndex - visiblePaginationRange,
				pageIndex + visiblePaginationRange + 1,
			);
		}
	};

	return (
		<div className="table-pagination">
			<div
				className={
					"table-pagination__item table-pagination__previous" +
					(!canPreviousPage ? " table-pagination__previous--disabled" : "")
				}
				onClick={() => previousPage()}
			>
				<span> &#9668;</span>
			</div>

			{/* Folding paginating buttons */}

			{pageIndex > 2 + visiblePaginationRange &&
			pageCount > 2 + 2 + visiblePaginationRange * 2 + 1 ? (
				<>
					<data
						className={
							(pageIndex === 0 ? "table-pagination__current " : "") +
							"table-pagination__item"
						}
						value={0}
						onClick={changePage}
					>
						{1}
					</data>
					<div className="table-pagination__item table-pagination__folded">&#8230;</div>
				</>
			) : null}

			{getVisiblePageOptions(pageIndex, pageCount, pageOptions, visiblePaginationRange).map(
				(page, i) => (
					<data
						key={i}
						className={
							(page === pageIndex ? "table-pagination__current " : "") +
							"table-pagination__item"
						}
						value={page}
						onClick={changePage}
					>
						{page + 1}
					</data>
				),
			)}

			{pageCount - pageIndex > visiblePaginationRange + 2 + 1 &&
			pageCount > 2 + 2 + visiblePaginationRange * 2 + 1 ? (
				<>
					<div className="table-pagination__item table-pagination__folded">&#8230;</div>
					<data
						className={
							(pageIndex === pageCount - 1 ? "table-pagination__current " : "") +
							"table-pagination__item"
						}
						value={pageCount - 1}
						onClick={changePage}
					>
						{pageCount}
					</data>
				</>
			) : null}

			<div
				className={
					"table-pagination__item table-pagination__next" +
					(!canNextPage ? " table-pagination__previous--disabled" : "")
				}
				onClick={() => nextPage()}
			>
				<span>&#9658;</span>
			</div>
		</div>
	);
};

export default PaginationBar;
