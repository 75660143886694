import axios from 'axios'
import { useState } from 'react'
import Button from '../../../../hooks/components/common/Button/Button'
import ButtonWrapper from '../../../../hooks/components/common/Button/ButtonWrapper'
import { PASSPORT_MICROSERVICE } from '../../../../utils/team'
import { TokenType } from '../../../../utils/token'
import styles from './modalContentPassport.module.scss'

interface ModalContentProps {
	id?: number
	setWindowContent: Function
	info: string
}

const ModalContentPassport = ({
	setWindowContent,
	id,
	info,
}: ModalContentProps) => {
	const [feedbackValue, setFeedBackValue] = useState<string>(info)
	const tokens: TokenType = JSON.parse(window.localStorage.getItem('token')!)

	const sendFeedback = async () => {
		if (tokens && tokens.access_token) {
			await axios
				.put(
					`${PASSPORT_MICROSERVICE}/passport/command/setFeedback`,
					{
						teamId: id,
						feedback: feedbackValue,
					},
					{
						headers: {
							Authorization: `Bearer ${tokens.access_token}`,
						},
					}
				)
				.then(r => {
					console.log(r.data)
					setWindowContent(false)
					window.location.reload()
				})
				.catch(error => {
					console.error(error)
				})
		}
	}

	return (
		<div className={styles.modalcontainer}>
			<p>{info === '' ? 'Оставьте комментарий:' : 'Внесите свои изменения:'}</p>

			<textarea
				value={feedbackValue}
				onChange={e => setFeedBackValue(e.target.value)}
				cols={30}
				rows={5}
				className='info-block-textareaForEdit'
			></textarea>

			<ButtonWrapper>
				<Button
					onClick={() => {
						sendFeedback()
					}}
				>
					{info === '' ? 'Отправить' : 'Сохранить'}
				</Button>
				<Button
					onClick={() => {
						setWindowContent(null)
					}}
				>
					Назад
				</Button>
			</ButtonWrapper>
		</div>
	)
}

export default ModalContentPassport
