import { useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '../../hooks/components/common/Button/Button'
import ButtonWrapper from '../../hooks/components/common/Button/ButtonWrapper'
import { RootState } from '../../redux/store'
import {
	MODERATION_MICROSERVICE_URL,
	sendAxiosWithTokens,
} from '../../utils/team'
import { getDecodedToken } from '../../utils/token'

type TextProps = {
	admin?: boolean
	customer?: boolean
	curator?: boolean
}

export const RightText = ({ admin, customer, curator }: TextProps) => {
	const { profile } = useSelector((state: RootState) => state.personalAccount)

	const [newPassword, setnewPassword] = useState<string>('')
	const [changePass, setChangePass] = useState<boolean>(false)
	const [dateDef, setDateDef] = useState<boolean>(false)
	const [dateValue, setDateValue] = useState<string>('')

	const ChangePasswordReq = async (password: string) => {
		const data_from_token = getDecodedToken()
		await sendAxiosWithTokens(
			`post`,
			`${MODERATION_MICROSERVICE_URL}/api/v1/admin/users/password/${data_from_token.preferred_username}`,
			{
				password: password,
			}
		)
			.then(r => {
				console.log(r.data)
			})
			.catch(err => {
				console.log(err)
			})
	}

	const SavePassword = () => {
		setChangePass(!changePass)
		ChangePasswordReq(newPassword)
	}

	const SetDateDefenseIAR = async (date: string) => {
		await sendAxiosWithTokens(
			`post`,
			`${MODERATION_MICROSERVICE_URL}/api/v1/admin/config/`,
			{
				graduationDate: date,
			}
		)
			.then(resp => {
				console.log(resp)
			})
			.catch(err => {
				console.log(err)
			})
	}

	const SetNewGraduationDate = () => {
		setDateDef(false)
		SetDateDefenseIAR(dateValue)
	}

	return (
		<div className='personal-account-right-sidebar'>
			<ButtonWrapper wide>
				{admin ? (
					<>
						<div className='desktop-button'>
							<Button onClick={() => setChangePass(!changePass)}>
								Сменить пароль
							</Button>
						</div>

						{changePass ? (
							<div className='personal-account-right-sidebar-change-password'>
								Введите новый пароль:
								<br />
								<input
									type='text'
									className='form-field-input'
									onChange={e => setnewPassword(e.target.value)}
								/>
								{newPassword ? (
									<Button onClick={SavePassword}>Сохранить</Button>
								) : (
									<Button onClick={() => setChangePass(false)}>Закрыть</Button>
								)}
							</div>
						) : null}
						<div className='desktop-button'>
							<Button onClick={() => setDateDef(!dateDef)}>
								Установить дату защиты ИАР
							</Button>
						</div>

						{dateDef ? (
							<div className='personal-account-right-sidebar-change-password'>
								Выберите дату:
								<br />
								<input
									type='date'
									className='form-field-input'
									onChange={e => setDateValue(e.target.value)}
								/>
								{dateValue != '' ? (
									<Button onClick={SetNewGraduationDate}>Сохранить</Button>
								) : (
									<Button onClick={() => setDateDef(false)}>Закрыть</Button>
								)}
							</div>
						) : null}
					</>
				) : customer ? (
					<>
						<div className='desktop-button'>
							<Button action='link' to='/admin/admins'>
								Связь с администрацией
							</Button>
						</div>
					</>
				) : curator ? (
					<>
						<div className='desktop-button'>
							<Button onClick={() => setChangePass(!changePass)}>
								Сменить пароль
							</Button>
						</div>

						{changePass && (
							<div className='personal-account-right-sidebar-change-password'>
								Введите новый пароль:
								<br />
								<input
									type='text'
									className='form-field-input'
									onChange={e => setnewPassword(e.target.value)}
								/>
								{newPassword ? (
									<Button onClick={SavePassword}>Сохранить</Button>
								) : (
									<Button onClick={() => setChangePass(false)}>Закрыть</Button>
								)}
							</div>
						)}
					</>
				) : (
					''
				)}
			</ButtonWrapper>
		</div>
	)
}

export default RightText
