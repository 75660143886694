import jwt_decode from 'jwt-decode'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Button from '../../hooks/components/common/Button/Button'
import ButtonWrapper from '../../hooks/components/common/Button/ButtonWrapper'
import ContainerMain from '../../hooks/components/common/ContainerMain/ContainerMain'
import ContentCard from '../../hooks/components/common/ContentCard/ContentCard'
import ContentCardWrapper from '../../hooks/components/common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../hooks/components/common/ContentMainTheme'
import DoubleList from '../../hooks/components/common/DoubleList/DoubleList'
import FileUploader from '../../hooks/components/common/FileUploader'
import { setCompanyList } from '../../redux/slices/companySlice'
import { RootState } from '../../redux/store'
import {
	FILES_MICROSERVICE,
	sendAxiosWithTokens,
	sendAxiosWithTokensAndCustomHeaders,
} from '../../utils/team'
import { getTokens, isTokenInLS } from '../../utils/token'
import Logo from '../Case/Logo'

const InfoAboutCompany = () => {
	const [logoUrl, setLogoUrl] = useState('')
	const location = useLocation()
	const editting = location.state.editting
	const idCompany = location.state.id
	const fileUploader = new FileUploader()
	const [logoIcon, setLogoIcon] = useState<File | null>()
	const [title, setTitle] = useState('')
	const [shortTitle, setShortTitle] = useState('')
	const [contactPerson, setContactPerson] = useState('')
	const [contactPersonContact, setContactPersonContact] = useState('')
	const [casesOfCompany, setCasesOfCompany] = useState<number>(0)
	const fileUpload = new FileUploader()
	const [isLoadingUpdCompany, setIsLoadingUpdCompan] = useState<boolean>(false)
	useEffect(() => {
		const fetchData = async () => {
			try {
				const companyResponse = await sendAxiosWithTokens(
					'get',
					`${FILES_MICROSERVICE}/api/v1/company/${activeCompany.id}`
				)
				if (companyResponse.data.data) {
					// console.log(companyResponse.data.data)
					setTitle(companyResponse.data.data.company.title)
					setShortTitle(companyResponse.data.data.company.description)
					setContactPerson(companyResponse.data.data.company.contactPerson)
					setContactPersonContact(
						companyResponse.data.data.company.contactPersonContact
					)
					setCasesOfCompany(companyResponse.data.data.amountAvailableCases)
					const logoUrl = fileUpload.base64ToBlob(
						companyResponse.data.data.icon
					)
					if (companyResponse.data.data.icon !== null) {
						const blobImage = fileUploader.base64ToBlob(
							companyResponse.data.data.icon
						)
						const url = URL.createObjectURL(blobImage)
						setLogoUrl(url)
					}
				} else {
					console.error(
						'Элемент с индексом idCompany не найден в массиве данных'
					)
				}
			} catch (error) {
				console.error('Ошибка при получении данных о компании:', error)
			}
		}

		fetchData()
	}, [])

	const fetchImage = async () => {
		const url = `${FILES_MICROSERVICE}/api/v1/avatar`
		try {
			const blobImage = await fileUploader.downloadPhoto(url)
			if (blobImage) {
				setLogoUrl(URL.createObjectURL(blobImage))
			} else {
				// Обработка случая, когда blobImage undefined
				console.error(
					'Не удалось загрузить изображение: изображение не найдено'
				)
			}
		} catch (error) {
			console.error('Ошибка загрузки изображения:', error)
		}
	}
	const handleFileUpload = async () => {
		if (!setLogoIcon) {
			alert('Пожалуйста, выберите файл.')
			return
		}

		const maxFileSize = 3 * 1024 * 1024 // 3 МБ
		if (logoIcon && logoIcon.size > maxFileSize) {
			alert('Файл слишком большой. Размер файла должен быть менее 3 МБ.')
			return
		}

		const url = `${FILES_MICROSERVICE}/api/v1/avatar`
		try {
			await fileUploader.uploadFile(url, setLogoIcon)
		} catch (error) {
			console.error('Ошибка при загрузке файла:', error)
		}
		fetchImage()
	}
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { activeCompany } = useSelector((state: RootState) => state.company)

	let admin: boolean = false

	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)
		const roles = token.resource_access['spring-client'].roles
		const isAdmin = roles.indexOf('client-main-admin') > -1
		if (isAdmin) admin = isAdmin
	}

	const UpdateInfoAboutCompany = async (formData: FormData) => {
		await sendAxiosWithTokensAndCustomHeaders(
			'put',
			`${FILES_MICROSERVICE}/api/v1/company/${activeCompany.id}`,
			formData,
			{
				'Content-Type': 'multipart/form-data',
			}
		).then(r => {
			setIsLoadingUpdCompan(true)
		})
		await sendAxiosWithTokens('get', `${FILES_MICROSERVICE}/api/v1/company`)
			.then(response => {
				dispatch(setCompanyList(response.data.data))
				// console.log('ИЗ ЭБАУТ КОМПАНИ', response.data.data)
				navigate(`/company`)
			})
			.catch(error => {
				console.log(error)
			})
	}

	interface EdittigCompanyTypes {
		title: string
		shortTitle: string
		contactPerson: string
		contactPersonContact: string
	}

	const {
		register,
		getValues,
		formState: { errors, isValid },
	} = useForm<EdittigCompanyTypes>({
		mode: 'onTouched',
		defaultValues: {
			title: activeCompany?.title,
			shortTitle: activeCompany?.shortTitle,
			contactPerson: activeCompany?.contactPerson,
			contactPersonContact: activeCompany?.contactPersonContact,
		},
	})

	const SaveChanges = () => {
		const value = getValues()
		const formData = new FormData()
		formData.append('title', value['title'])
		formData.append('description', value['shortTitle'])
		formData.append('contactPerson', value['contactPerson'])
		formData.append('contactPersonContact', value['contactPersonContact'])
		if (logoIcon !== null && logoIcon !== undefined) {
			formData.append('logo', logoIcon)
		}
		UpdateInfoAboutCompany(formData)
	}

	return (
		<>
			<ContainerMain size='medium'>
				<ContentMainTheme>
					Компания "{`${title || 'Пока пусто'}`}"
				</ContentMainTheme>
				<ContentCardWrapper>
					<ContentCard blured>
						<DoubleList
							nonUnderLine={true}
							className='initials'
							listData={[
								{
									identifier: 'Название компании:',
									content: (
										<>
											{editting ? (
												<input
													className='company-info-input-forEdit'
													defaultValue={title}
													{...register('title', {
														required: 'Необходимо заполнить',
													})}
												></input>
											) : (
												title || 'Пока пусто'
											)}
											{errors.title && (
												<p className='form-field-input--error-message'>
													{errors.title.message}
												</p>
											)}
										</>
									),
								},
								{
									identifier: 'Короткое название компании:',
									content: (
										<>
											{editting ? (
												<input
													className='company-info-input-forEdit'
													defaultValue={shortTitle}
													{...register(
														'shortTitle'
														// {
														//     required:'Необходимо заполнить'
														// }
													)}
												></input>
											) : (
												shortTitle || 'Пока пусто'
											)}
											{errors.shortTitle && (
												<p className='form-field-input--error-message'>
													{errors.shortTitle.message}
												</p>
											)}
										</>
									),
								},
								{
									identifier: 'Контактное лицо:',
									content: (
										<>
											{editting ? (
												<input
													className='company-info-input-forEdit'
													defaultValue={contactPerson}
													{...register('contactPerson', {
														required: 'Необходимо заполнить',
													})}
												></input>
											) : (
												contactPerson || 'Пока пусто'
											)}
											{errors.contactPerson && (
												<p className='form-field-input--error-message'>
													{errors.contactPerson.message}
												</p>
											)}
										</>
									),
								},
								{
									identifier: 'Связь с контактным лицом:',
									content: (
										<>
											{editting ? (
												<input
													className='company-info-input-forEdit'
													defaultValue={contactPersonContact}
													{...register('contactPersonContact', {
														required: 'Необходимо заполнить',
													})}
												></input>
											) : (
												contactPersonContact || 'Пока пусто'
											)}
											{errors.contactPersonContact && (
												<p className='form-field-input--error-message'>
													{errors.contactPersonContact.message}
												</p>
											)}
										</>
									),
								},
								{
									identifier: 'Иконка логотипа:',
									content: (
										<>
											{editting ? (
												<>
													<input
														type='file'
														onChange={e => {
															if (e.target.files && e.target.files.length > 0) {
																setLogoIcon(e.target.files[0])
															}
														}}
														accept='image/*'
													></input>
													<button onClick={handleFileUpload}>
														Загрузить логотип
													</button>
												</>
											) : null}
											<Logo imageURL={logoUrl} />
										</>
									),
								},
								{
									identifier: 'Кейсы 2024:',
									content: casesOfCompany,
								},
							]}
						/>
						<div className='company-Go-To-Back-Button-In-Info'>
							<ButtonWrapper>
								{editting ? (
									<>
										<Button onClick={SaveChanges} disabled={!isValid}>
											Сохранить
										</Button>
										{isLoadingUpdCompany && 'Компания обновляется...'}
									</>
								) : (
									<>
										{/* {admin ? <Button disabled={true}>
                                    Смотреть список всех команд(не сделано)
                                </Button> : ''} */}
									</>
								)}

								<Link to='/company'>
									<Button>Назад</Button>
								</Link>
							</ButtonWrapper>
						</div>
					</ContentCard>
				</ContentCardWrapper>
			</ContainerMain>
		</>
	)
}

export default InfoAboutCompany
