import { useEffect, useState } from 'react'
import {
	GetImportantInfoDTO,
	advancedSearchStudents,
} from '../../../../../@types/dtos/GetImportantInfoDTO'
import {
	CREATE_COMMAND_MICROSERVICE_URL,
	sendAxiosWithTokens,
} from '../../../../../utils/team'
import MultiSelect from '../../../MultiSelect'
import SingleSelect from '../../../SingleSelect'
import Button from '../../../common/Button/Button'
import ButtonWrapper from '../../../common/Button/ButtonWrapper'
import DoubleList from '../../../common/DoubleList/DoubleList'
import {
	SelectType,
	degrees,
	getGroups,
	getPrograms,
	rawSkills,
} from '../ExtendedSearchApi/ExtendedSearchApi'
import { checkAllKyesIsEmpty } from '../teams/ExtendedSearchTeamt'

interface ExtendSearchStudentsProps {
	setIsSearch: (value: boolean) => void
	setStudentsFromExtendedSearch: (value: GetImportantInfoDTO[]) => void
}

const ExtendSearchStudents = ({
	setIsSearch,
	setStudentsFromExtendedSearch,
}: ExtendSearchStudentsProps) => {
	//state возможных выборов
	const [programsList, setProgramsList] = useState<SelectType[]>([])
	const [dppGroupsList, setDppGroupsList] = useState<SelectType[]>([])
	const [skillsList, setSkillsList] = useState<
		{
			value: string
			label: string
		}[]
	>([])
	//state актуального выбора
	const [programId, setProgramId] = useState<number | null>(null)
	const [dppGroupId, setDppGroupId] = useState<number | null>(null)
	const [degree, setDegree] = useState<string | null>(null)
	const [fio, setFio] = useState<string | null>(null)
	const [skills, setSkills] = useState<string | null>(null)

	const requestBody: {
		programId: number | null
		dppGroupId: number | null
		degree: string | null
		fio: string | null
		skills: string | null
	} = {
		programId: programId,
		dppGroupId: dppGroupId,
		degree: degree,
		fio: fio,
		skills: skills,
	}

	useEffect(() => {
		const dataFetch = async () => {
			const allPrograms = await getPrograms()
			const allDPPGroups = await getGroups('dpp')
			setProgramsList(allPrograms)
			setDppGroupsList(allDPPGroups)
		}
		const skills = rawSkills.map((skill, index) => {
			return {
				value: skill,
				label: skill,
			}
		})
		setSkillsList(skills)
		dataFetch()
	}, [])

	const getExtendedStudents = async () => {
		const url = `${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/extend-search/students`
		console.log(requestBody)
		await sendAxiosWithTokens(`post`, url, requestBody)
			.then(res => {
				const newStudents: GetImportantInfoDTO[] = res.data.data.map(
					(studentData: advancedSearchStudents) => {
						return {
							userId: studentData?.owner?.id,
							fio: studentData?.owner?.fio,
							telegram_link: studentData?.owner?.telegram_link,
							email: studentData?.owner?.email,
							username: studentData?.owner?.username,
							birthDate: studentData?.birthDate,
							preferedRole: studentData?.preferedRole,
							degree: studentData?.academicGroup?.degree,
							dppGroup: studentData?.dppGroup,
							teamTitle: '',
							phoneNumber: studentData?.owner?.phoneNumber,
						}
					}
				)
				setStudentsFromExtendedSearch(newStudents)
			})
			.catch(error => {
				console.log(error)
			})
	}
	return (
		<div className='extended-search'>
			<DoubleList
				listData={[
					{
						identifier: 'Программа ДПП',
						content: (
							<SingleSelect
								options={programsList}
								infobefore={'Программа ДПП'}
								onChangeValue={(valueID: number) => {
									setProgramId(valueID)
								}}
							/>
						),
					},
					{
						identifier: 'Группа ДПП',
						content: (
							<SingleSelect
								options={dppGroupsList}
								infobefore={'Группа ДПП'}
								onChangeValue={(valueId: number) => {
									setDppGroupId(valueId)
								}}
							/>
						),
					},
					{
						identifier: 'Степень',
						content: (
							<SingleSelect
								options={degrees}
								infobefore={'Степень'}
								onChangeValue={(valueName: string) => {
									setDegree(valueName)
								}}
							/>
						),
					},
					{
						identifier: 'ФИО',
						content: (
							<input
								type='text'
								className='info-block-textareaForEdit'
								value={fio as string}
								onChange={e => setFio(e.target.value as string)}
							/>
						),
					},
					{
						identifier: 'Навыки',
						content: (
							<MultiSelect
								options={skillsList}
								infobefore={'ewew'}
								onSelectedValuesChange={(selectedSkills: string[]) =>
									setSkills(selectedSkills.join(';'))
								}
							/>
						),
					},
				]}
			/>
			<ButtonWrapper spacing>
				{checkAllKyesIsEmpty(requestBody) ? null : (
					<Button
						onClick={() => {
							setIsSearch(false)
							getExtendedStudents()
						}}
					>
						Найти
					</Button>
				)}
			</ButtonWrapper>
		</div>
	)
}

export default ExtendSearchStudents
