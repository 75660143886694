import { useState } from 'react'
import { useSelector } from 'react-redux'
import ContentCard from '../../../../hooks/components/common/ContentCard/ContentCard'
import DoubleList from '../../../../hooks/components/common/DoubleList/DoubleList'
import { RootState } from '../../../../redux/store'
import PasportprojectEditing from './ProjectPasportEditing'

const ProjectPasport = ({
	caseId,
	admin,
}: {
	caseId: number | undefined
	admin: boolean
}) => {
	const { PasportCase } = useSelector(
		(state: RootState) => state.companyPasportSlice
	)
	const [pasportEditing, setPasportEditing] = useState<boolean>(false)

	const PasportEditing = {
		status: pasportEditing,
		toggle: () => setPasportEditing(!pasportEditing),
		content: (
			<PasportprojectEditing
				caseId={caseId}
				toggle={() => setPasportEditing(!pasportEditing)}
			/>
		),
	}

	return (
		<ContentCard
			blured
			topic={!admin ? null : ' '}
			myEditButton
			editing={admin ? PasportEditing : undefined}
		>
			<DoubleList
				className='initials'
				listData={[
					{
						identifier: 'Наименование проекта',
						content: PasportCase?.projectTitle,
					},
					{
						identifier: 'Заказчик проекта',
						content: PasportCase?.projectCustomer,
					},
					{
						identifier: 'Руководитель проекта',
						content: PasportCase?.projectSupervisor,
					},
					{
						identifier: 'Ментор проекта',
						content: PasportCase?.projectMentor,
					},
					{
						identifier: 'Конечный пользователь',
						content: PasportCase?.endUser,
					},
					{
						identifier: 'Длительность проекта',
						content: PasportCase?.projectDuration,
					},
				]}
			/>
		</ContentCard>
	)
}

export default ProjectPasport
