import React from "react";
import button from "./button.module.scss";

interface ButtonWrapperProps {
	children: React.ReactNode;
	center?: boolean;
	wide?: boolean;
	spacing?: boolean;
	direction?: "row" | "column";
	className?: string;
}

const ButtonWrapper = ({
	children,
	center,
	wide,
	spacing,
	direction = "row",
	className = "",
}: ButtonWrapperProps) => {
	return (
		<div
			className={[
				button["wrapper"],
				center && button["wrapper--center"],
				wide && button["wrapper--wide"],
				spacing && button[`wrapper--spacing`],
				button[`wrapper--${direction}`],
				className,
			].join(" ")}
		>
			{children}
		</div>
	);
};

export default ButtonWrapper;
