import { createSlice } from "@reduxjs/toolkit";

export interface LinkInTeam{
    Link?:string
    OpenModalEnterLink?:boolean
    OpenModalGetLink?:boolean
}

const initialState:LinkInTeam = {
    Link:'',
}

export const InviteLinkInTeamSlice  = createSlice({
    name: 'InviteLinkInTeam',
    initialState,
    reducers:{
        SetLink(state,action){
            state.Link = action.payload;
        },
    }
});


export const {SetLink} = InviteLinkInTeamSlice.actions;
export default InviteLinkInTeamSlice.reducer;