import jwt_decode from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TeamDTO } from '../../@types/createCommand'
import { UserInTeamDTO } from '../../@types/dtos/UserInTeamDTO'
import { ApplicationStatus } from '../../@types/entities/ApplicationStatus'
import { ApplicationType } from '../../@types/entities/ApplicationType'
import GetInviteLink from '../../hooks/components/Team/GetInviteLink'
import SquadAbout from '../../hooks/components/Team/SquadAbout'
import SquadAboutEditing from '../../hooks/components/Team/SquadAboutEditing'
import TeamCommonInformation from '../../hooks/components/Team/TeamCommonInformation'
import TeamCommonInformationEditing from '../../hooks/components/Team/TeamCommonInformationEditing'
import Button from '../../hooks/components/common/Button/Button'
import ButtonWrapper from '../../hooks/components/common/Button/ButtonWrapper'
import ContainerMain from '../../hooks/components/common/ContainerMain/ContainerMain'
import ContentCard, {
	ContentCardEditingProp,
} from '../../hooks/components/common/ContentCard/ContentCard'
import ContentCardFooter from '../../hooks/components/common/ContentCard/ContentCardFooter'
import ContentCardWrapper from '../../hooks/components/common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../hooks/components/common/ContentMainTheme'
import LoadingText from '../../hooks/components/common/Loaders/LoadingText'
import ModalWindow from '../../hooks/components/common/ModalWindow/ModalWindow'
import useContentCardEditing from '../../hooks/useContentCardEditing'
import { setEntireTeam, setSquadList } from '../../redux/slices/myTeamSlice'
import { RootState } from '../../redux/store'
import {
	clientIsTeamLead,
	updateSquadList,
	updateTeamData,
} from '../../utils/team'
import { getDecodedToken, getTokens } from '../../utils/token'

export default function Team() {
	const [visibility, setVisibility] = React.useState(false)
	// const [team, setTeam] = useState<TeamDTO | null>(null);
	// const [squad, setSquad] = useState<UserInTeamDTO[]>([]);
	const { team, squad } = useSelector((state: RootState) => state.myTeam)
	const [isLoading, setLoading] = useState<boolean>(true)
	const [teamIsApproved, setTeamIsApproved] = useState(false)
	const dispatch = useDispatch()
	const teamIsFrozen =
		team?.adminApplication?.status === ApplicationStatus.ACCEPTED ||
		team?.adminApplication?.status === ApplicationStatus.IN_REVIWEVING

	useEffect(() => {
		// CheckTokenAndGetRequest();

		updateTeamData()
			.then((teamResponse: TeamDTO) => {
				dispatch(setEntireTeam({ team: teamResponse }))
				setTeamIsApproved(
					team.adminApplication?.status === ApplicationStatus.ACCEPTED &&
						team.adminApplication?.type !== ApplicationType.EMPTY
				)
				return updateSquadList()
			})
			.then((squadResponse: UserInTeamDTO[]) => {
				dispatch(setSquadList({ squad: squadResponse }))
			})
			.catch(console.error)
			.finally(() => setLoading(false))
	}, [])

	const teamEditing = useContentCardEditing()
	const squadEditing = useContentCardEditing()

	const teamEditingProp: ContentCardEditingProp = {
		status: teamEditing.isEditing,
		toggle: teamEditing.toggleEditing,
		content: <TeamCommonInformationEditing />,
	}

	const squadEditingProp: ContentCardEditingProp = {
		status: squadEditing.isEditing,
		toggle: squadEditing.toggleEditing,
		content: <SquadAboutEditing />,
	}

	const isTeamLead = clientIsTeamLead()
	const token: any = jwt_decode(getTokens().access_token)
	const roles = token.resource_access['spring-client'].roles
	const isAdmin = roles.indexOf('client-main-admin') > -1
	const isProgramCurator = roles.indexOf('client-program-curator') > -1

	const currentUser = squad.find(user => {
		return user.member.username === getDecodedToken().preferred_username
	})

	const isDeputyTeamLead = !!currentUser?.isDeputy

	let viewer: 'teamlead' | 'deputy' | 'member' | 'non-member' | 'admin' =
		isTeamLead ? 'teamlead' : isDeputyTeamLead ? 'deputy' : 'member'

	if (isAdmin || isProgramCurator) viewer = 'admin'

	return (
		<>
			{isLoading ? (
				<LoadingText />
			) : (
				<ContainerMain>
					<ContentMainTheme>Моя команда</ContentMainTheme>
					<ModalWindow visibility={visibility}>
						<GetInviteLink setvisibility={setVisibility} />
					</ModalWindow>
					<ContentCardWrapper>
						<ContentCard
							topic={'Общая информация'}
							{...((isTeamLead || isDeputyTeamLead) && {
								editing: teamEditingProp,
							})}
						>
							<TeamCommonInformation
								team={team}
								viewer={isTeamLead ? 'teamlead' : 'member'}
								teamIsFrozen={teamIsFrozen}
							/>
							<ContentCardFooter>
								<ButtonWrapper>
									{(isTeamLead || isDeputyTeamLead) && (
										<Button onClick={() => setVisibility(!visibility)}>
											Реферальная ссылка
										</Button>
									)}

									{/*<Button>Ссылка на паспорт проекта </Button>*/}
								</ButtonWrapper>
							</ContentCardFooter>
						</ContentCard>
					</ContentCardWrapper>

					<ContentMainTheme>Состав команды</ContentMainTheme>

					<ContentCardWrapper>
						{!teamIsFrozen && (
							<>
								<ContentCard low>
									<div className='text-mobile'>
										Обратите внимание, что в команде должно быть до 7-10
										участников. В противном случае, ваша команда будет считаться
										неполной.
									</div>
									<div className='text-desktop'>
										Обратите внимание, что в команде должно быть до 7-10
										участников. В противном случае, ваша команда будет считаться
										неполной.
									</div>
								</ContentCard>

								{!squad.find(user => user.isDeputy) && (
									<ContentCard low>
										<div className='text-mobile'>
											У вас нет заместителя Team Lead. Чтобы его назначить,
											зайдите в настройки (значок шестерёнки рядом со “Списком
											команд”)
										</div>
										<div className='text-desktop'>
											У вас нет заместителя Team Lead. Чтобы его назначить,
											зайдите в настройки (значок шестерёнки рядом со “Списком
											команд”)
										</div>
									</ContentCard>
								)}
							</>
						)}

						<ContentCard
							topic='Список команды'
							{...((isTeamLead || isDeputyTeamLead) &&
								!teamIsFrozen && { editing: squadEditingProp })}
						>
							<SquadAbout
								squad={squad.length ? squad : null}
								viewer={viewer}
								teamIsFrozen={teamIsFrozen}
							/>
						</ContentCard>
					</ContentCardWrapper>
				</ContainerMain>
			)}
		</>
	)
}
