import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import UpdatePassportDTO from '../../../../@types/dtos/UpdatePassportDTO'
import Button from '../../../../hooks/components/common/Button/Button'
import DoubleList from '../../../../hooks/components/common/DoubleList/DoubleList'
import { setFieldPasport } from '../../../../redux/slices/companyPasportSlice'
import { RootState } from '../../../../redux/store'
import {
	PASSPORT_MICROSERVICE,
	sendAxiosWithTokens,
} from '../../../../utils/team'

interface PropsTypes {
	toggle: Function
	caseId?: number
}

const PasportDescrEdit2of2 = ({ toggle, caseId }: PropsTypes) => {
	const { PasportCase } = useSelector(
		(state: RootState) => state.companyPasportSlice
	)
	const dispatch = useDispatch()

	const RequestForNewData = async (body: UpdatePassportDTO) => {
		await sendAxiosWithTokens(
			`post`,
			`${PASSPORT_MICROSERVICE}/api/v1/passport/${caseId}`,
			body
		)
			.then(r => {
				console.log('fdsf', r.data.data)
			})
			.catch(error => [console.log(error)])
	}

	const {
		register,
		getValues,
		formState: { errors, isValid },
	} = useForm<UpdatePassportDTO>({
		mode: 'onTouched',
	})

	const onSetNewData = () => {
		const value = getValues()
		const body: UpdatePassportDTO = {
			endUser: PasportCase?.endUser,
			projectDuration: PasportCase?.projectDuration,
			projectType: PasportCase?.projectType,
			projectGoal: PasportCase?.projectGoal,
			projectTasks: PasportCase?.projectTasks,
			projectResult: PasportCase?.projectResult,
			projectRestrictions: value['projectRestrictions'],
			projectResources: PasportCase?.projectResources,
			projectTaskTracker: value['projectTaskTracker'],
			projectCompitentions: value['projectCompitentions'],
			additionalInformation: value['additionalInformation'],
			projectMentor: PasportCase?.projectMentor,
			projectSupervisor: PasportCase?.projectSupervisor,
			example: PasportCase?.example,
		}
		dispatch(
			setFieldPasport({
				projectRestrictions: value['projectRestrictions'],
				projectTaskTracker: value['projectTaskTracker'],
				projectCompitentions: value['projectCompitentions'],
				additionalInformation: value['additionalInformation'],
			})
		)

		RequestForNewData(body)
	}

	return (
		<>
			<DoubleList
				className='initials'
				listData={[
					{
						identifier: 'Трекер задач (Miro, Trello и т.п.)',
						content: (
							<textarea
								className='company-info-input-forEdit'
								defaultValue={PasportCase?.projectTaskTracker}
								{...register('projectTaskTracker')}
								rows={4}
							/>
						),
					},
					{
						identifier: 'Ограничения и допущения',
						content: (
							<textarea
								className='company-info-input-forEdit'
								defaultValue={PasportCase?.projectRestrictions}
								{...register('projectRestrictions')}
								rows={4}
							/>
						),
					},
					{
						identifier: 'Доп. информация',
						content: (
							<textarea
								className='company-info-input-forEdit'
								defaultValue={PasportCase?.additionalInformation}
								{...register('additionalInformation')}
								rows={4}
							/>
						),
					},
					{
						identifier: 'Пререквизиты проекта (компетенции участников команды)',
						content: (
							<textarea
								className='company-info-input-forEdit'
								defaultValue={PasportCase?.projectCompitentions}
								{...register('projectCompitentions')}
								rows={4}
							/>
						),
					},
				]}
			/>
			<div className='link-wrapperPasport' onClick={onSetNewData}>
				<Button onClick={toggle}>Сохранить</Button>
			</div>
		</>
	)
}

export default PasportDescrEdit2of2
