import jwt_decode from 'jwt-decode'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import { FILES_MICROSERVICE } from '../../../../utils/team'
import { getTokens, isTokenInLS } from '../../../../utils/token'
import DoubleList from '../../common/DoubleList/DoubleList'
import FileUploader from '../../common/FileUploader'
import ProfileImage from './ProfileImage'
const AccountAboutMain = () => {
	const fileUploader = new FileUploader()
	const [imageUrl, setImageUrl] = useState('')
	const [selectedFile, setSelectedFile] = useState<File | null>(null)

	let admin: boolean = false
	let student: boolean = false
	let customer: boolean = false
	let curator: boolean = false
	let programCurator: boolean = false

	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		const isAdmin = roles.indexOf('client-main-admin') > -1
		if (isAdmin) admin = isAdmin
		const isCurator = roles.indexOf('client-curator') > -1
		if (isCurator) curator = isCurator
		const isStudent = roles.indexOf('client-student') > -1
		if (isStudent) student = isStudent
		const isCustomer = roles.indexOf('client-customer') > -1
		if (isCustomer) customer = isCustomer
		const isProgramCurator = roles.indexOf('client-program-curator') > -1
		if (isProgramCurator) programCurator = isProgramCurator
	}

	const { profile } = useSelector((state: RootState) => state.personalAccount)

	const date = profile.Mydata.birthDate
	const Rusdate = new Date(date)
	const day = String(Rusdate.getDate()).padStart(2, '0')
	const month = String(Rusdate.getMonth() + 1).padStart(2, '0')
	const year = Rusdate.getFullYear()

	const fio: string = profile.Mydata.fio
	const fioParts = fio.split(' ')

	let name = fioParts[1]
	let surname = fioParts[0]
	let patr = fioParts[2]

	const fetchImage = async () => {
		const url = `${FILES_MICROSERVICE}/api/v1/avatar`
		try {
			const blobImage = await fileUploader.downloadPhoto(url)
			if (blobImage) {
				setImageUrl(URL.createObjectURL(blobImage))
			} else {
				console.error(
					'Не удалось загрузить изображение: изображение не найдено'
				)
			}
		} catch (error) {
			console.error('Ошибка загрузки изображения:', error)
		}
	}

	if (fioParts.length > 3) {
		name = ''
		surname = fioParts[0]
		patr = fioParts.slice(1).join(' ')
	}
	useEffect(() => {
		fetchImage()
	}, [])

	return (
		<>
			<div className='profile'>
				<ProfileImage imageURL={imageUrl} size='large' />

				{student ? (
					<DoubleList
						className='initials'
						listData={
							surname
								? [
										{
											identifier: 'Фамилия',
											content: surname,
										},
										{
											identifier: 'Имя',
											content: name,
										},
										{
											identifier: 'Отчество',
											content: patr,
										},
								  ]
								: [
										{
											identifier: 'Имя',
											content: name,
										},
										{
											identifier: 'Отчество',
											content: patr,
										},
								  ]
						}
					/>
				) : customer ? (
					<DoubleList
						className='initials'
						listData={[
							{
								identifier: 'Фамилия',
								content: name,
							},
							{
								identifier: 'Имя',
								content: surname,
							},
							{
								identifier: 'Отчество',
								content: patr,
							},
						]}
					/>
				) : admin || curator || programCurator ? (
					<DoubleList
						className='initials'
						listData={[
							{
								identifier: 'Фамилия',
								content: surname,
							},
							{
								identifier: 'Имя',
								content: name,
							},
							{
								identifier: 'Отчество',
								content: patr,
							},
						]}
					/>
				) : (
					''
				)}
			</div>
			{student ? (
				<DoubleList
					className='another-info'
					listData={[
						{
							identifier: 'Дата рождения',
							content: `${day}.${month}.${year}`,
						},
						{
							identifier: 'Личная почта',
							content: profile.Mydata.email,
						},
						{
							identifier: 'Почта МАИ',
							content: profile.Mydata.maiemail,
						},
						{
							identifier: 'Номер телефона',
							content: profile.Mydata.phoneNumber,
						},
						{
							identifier: 'Telegram',
							content: profile.Mydata.telegram_link,
						},
					]}
				/>
			) : customer ? (
				<DoubleList
					className='another-info'
					listData={[
						{
							identifier: 'Компания',
							content: profile.Mydata.companyTitle,
						},
						{
							identifier: 'Роль',
							content:
								profile.Mydata.role === 'CUSTOMER'
									? 'Представитель компании'
									: null,
						},
					]}
				/>
			) : (
				''
			)}
		</>
	)
}

export default AccountAboutMain
