import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RoleInProject } from '../../../../@types/entities/RoleInProject'
import { setSkills, updateSkills } from '../../../../redux/slices/paSlice'
import ErrorHandler from '../../../../utils/ErrorHandler'
import {
	CREATE_COMMAND_MICROSERVICE_URL,
	PERSONAL_ACCOUNT_MICROSERVICE,
	sendAxiosWithTokens,
} from '../../../../utils/team'
import {
	TokenType,
	checkIsTokenExpired,
	getDecodedToken,
	getTokens,
	updateToken,
} from '../../../../utils/token'
import MultiSelect from '../../MultiSelect'
import SingleSelect from '../../SingleSelect'
import DoubleList from '../../common/DoubleList/DoubleList'
import LoadingText from '../../common/Loaders/LoadingText'

type RolesSelect = {
	valueName: string
	valueId: number
	label: string
}

const rawSkills = [
	'Atlassian stack',
	'Back-end разработка',
	'С',
	'С#',
	'С++',
	'СNN',
	'CSS',
	'Canva',
	'Computer Vision',
	'Confluence',
	'Data Engineering',
	'Data Science',
	'Deep Learning',
	'DevOps',
	'Django',
	'Docker',
	'ETL',
	'Excel',
	'Figma',
	'Front-end разработка',
	'Git',
	'Go',
	'HTML',
	'Hadoop',
	'Jira',
	'Jupyter',
	'Kafka',
	'Keras',
	'Kotlin',
	'Kubernetes',
	'Linux',
	'Machine Learning',
	'Marketing',
	'Matplotlib',
	'Microsoft Power BI',
	'NumPy',
	'ONNX Runtime',
	'OpenCV',
	'Pandas',
	'Photoshop',
	'PowerPoint',
	'PyTorch',
	'Python',
	'R',
	'React',
	'SMM',
	'SQL',
	'Scikit-Learn',
	'Seaborn',
	'TensorFlow',
	'Использование принципов Rest',
	'Математическая статистика',
	'Построение Rest API',
	'Работа с JSON',
	'Работа с XML',
	'Работа с YAML',
	'Разработка моделей данных',
	'СУБД GreenPulm',
	'СУБД MS SQL Server',
	'СУБД Oracle',
	'СУБД PostgreSQL',
	'Умение работать с API',
	'Управление проектами',
]

interface skillsProps {
	SkillsRole?: {
		preferedRole?: {
			title?: string
			id?: number
		}
		skills?: string
	}
}

type Skill = {
	value: string
	label: string
}

const RoleEditing: React.FC<skillsProps> = ({ SkillsRole = {} }) => {
	let tokens: TokenType = getTokens()
	const navigate = useNavigate()
	const PutSKillsAndRole = async () => {
		if (tokens != null) {
			const data_from_token = getDecodedToken()

			if (checkIsTokenExpired(tokens.access_token)) {
				if (checkIsTokenExpired(tokens.refresh_token)) {
					window.localStorage.removeItem('token')
					navigate('/')
				} else {
					await updateToken()
					tokens = getTokens()
				}
			}

			await axios
				.put(
					`${PERSONAL_ACCOUNT_MICROSERVICE}/api/v1/personal_account/changeSkillsInfo`,
					{
						skills: actualSkills?.skills,
						preferedRoleId: actualSkills?.preferedRole?.id,
					},
					{
						headers: {
							Authorization: `Bearer ${tokens.access_token}`,
						},
					}
				)
				.catch(error => {
					console.log(error)
				})
			await axios
				.get(
					`${PERSONAL_ACCOUNT_MICROSERVICE}/api/v1/personal_account/getAboutAndSkillsInfo/${data_from_token.preferred_username}`,
					{
						headers: {
							Authorization: `Bearer ${tokens.access_token}`,
						},
					}
				)
				.then(response => {
					dispatch(updateSkills(response.data.data))
					setIsLoading(false)
				})
				.catch(error => {
					ErrorHandler(error, navigate)
				})
		}
	}

	const [MySkills, setMySkills] = useState<Skill[]>([
		{
			value: 'AirFlow',
			label: 'AirFlow',
		},
	])

	useEffect(() => {
		const skills = rawSkills.map(skill => ({
			value: skill,
			label: skill,
		}))
		// console.log(skills)
		setMySkills(skills)
	}, [])

	const [actualSkills, setActualSkills] = useState(SkillsRole)
	const [isLoading, setIsLoading] = useState<boolean>(true)

	const [MyRole, setMyRole] = useState<RolesSelect[]>([])
	const dispatch = useDispatch()
	const getRoles = async () => {
		if (tokens != null) {
			const data_from_token = getDecodedToken()

			if (checkIsTokenExpired(tokens.access_token)) {
				if (checkIsTokenExpired(tokens.refresh_token)) {
					window.localStorage.removeItem('token')
					navigate('/')
				} else {
					await updateToken()
					tokens = getTokens()
				}
			}

			await sendAxiosWithTokens(
				'get',
				`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/data/roles`
			).then(response => {
				const formattedRoles: RolesSelect[] = []
				response.data.data.forEach((role: RoleInProject) => {
					formattedRoles.push({
						valueName: role.title,
						valueId: role.id,
						label: role.title,
					})
				})

				setMyRole(formattedRoles)
			})
		}
	}

	const ChangeValuesSkills = (
		name: any,
		valueName: string,
		valueID: number
	) => {
		// dispatch(setSkills({ name: name, value: valueName,id:valueID }))
		if (name === 'preferedRole.id') {
			setActualSkills(prev => ({
				...prev,
				preferedRole: {
					...prev?.preferedRole,
					id: valueID,
				},
			}))
		} else if (name === 'preferedRole.title') {
			setActualSkills(prev => ({
				...prev,
				preferedRole: {
					...prev?.preferedRole,
					title: valueName,
				},
			}))
		}
	}
	const handleSelectedSkillsChange = (selectedValues: any) => {
		const skillsString = selectedValues.join(', ')
		dispatch(setSkills({ name: 'skills', value: skillsString }))
		setActualSkills(prev => ({ ...prev, ['skills']: skillsString }))
	}
	useEffect(() => {
		getRoles()
		PutSKillsAndRole()
	}, [actualSkills])

	const parseAdditionals = [
		{
			identifier: 'Предпочтительная роль',
			content: (
				<SingleSelect
					options={MyRole}
					infobefore={SkillsRole?.preferedRole?.title}
					onChangeValue={(valueName: string, valueID: number) => {
						ChangeValuesSkills('preferedRole.id', valueName, valueID)
					}}
				/>
			),
		},
		{
			identifier: 'Навыки',
			content: (
				<MultiSelect
					options={MySkills}
					infobefore={SkillsRole?.skills}
					onSelectedValuesChange={handleSelectedSkillsChange}
				/>
			),
		},
	]
	return isLoading ? (
		<LoadingText />
	) : (
		<DoubleList className={'role'} listData={parseAdditionals} />
	)
}
export default RoleEditing
