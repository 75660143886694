import axios from 'axios'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setMainInfo, updateMydata } from '../../../../redux/slices/paSlice'
import { RootState } from '../../../../redux/store'
import ErrorHandler from '../../../../utils/ErrorHandler'
import {
	FILES_MICROSERVICE,
	PERSONAL_ACCOUNT_MICROSERVICE,
} from '../../../../utils/team'
import {
	TokenType,
	checkIsTokenExpired,
	getDecodedToken,
	getTokens,
	updateToken,
} from '../../../../utils/token'
import Button from '../../common/Button/Button'
import ContentCardFooter from '../../common/ContentCard/ContentCardFooter'
import DoubleList from '../../common/DoubleList/DoubleList'
import FileUploader from '../../common/FileUploader'
import ProfileImage from './ProfileImage'

interface props {
	toggle: Function
}

const AccountAboutMainEditing = ({ toggle }: props) => {
	const fileUploader = new FileUploader()
	const dispatch = useDispatch()
	const { profile } = useSelector((state: RootState) => state.personalAccount)
	const date = profile.Mydata.birthDate
	const Rusdate = new Date(date)
	const day = String(Rusdate.getDate()).padStart(2, '0')
	const month = String(Rusdate.getMonth() + 1).padStart(2, '0')
	const year = Rusdate.getFullYear()

	let tokens: TokenType = getTokens()
	const navigate = useNavigate()

	const PutNewInfo = async (
		email: string | undefined,
		phoneNumber: string | undefined,
		telegram_link: string | undefined,
		isInfoHidden?: boolean | undefined
	) => {
		if (tokens != null) {
			const data_from_token = getDecodedToken()

			if (checkIsTokenExpired(tokens.access_token)) {
				if (checkIsTokenExpired(tokens.refresh_token)) {
					window.localStorage.removeItem('token')
					navigate('/')
				} else {
					await updateToken()
					tokens = getTokens()
				}
			}
			await axios.put(
				`${PERSONAL_ACCOUNT_MICROSERVICE}/api/v1/personal_account/changeUserMainInformation`,
				{
					email: email,
					phoneNumber: phoneNumber,
					telegram_link: telegram_link,
					isInfoHidden: isInfoHidden,
				},
				{
					headers: {
						Authorization: `Bearer ${tokens.access_token}`,
					},
				}
			)

			await axios
				.get(
					`${PERSONAL_ACCOUNT_MICROSERVICE}/api/v1/personal_account/${data_from_token.preferred_username}`,
					{
						headers: {
							Authorization: `Bearer ${tokens.access_token}`,
						},
					}
				)
				.then(response => {
					dispatch(updateMydata(response.data.data))
				})
				.catch(error => {
					ErrorHandler(error, navigate)
				})
		}
	}
	interface EditingInfo {
		email?: string
		phoneNumber?: string
		telegram_link?: string
		isInfoHidden?: boolean
	}

	const {
		handleSubmit,
		register,
		getValues,
		resetField,
		formState: { errors, isValid },
	} = useForm<EditingInfo>({
		mode: 'onTouched',
		defaultValues: {
			email: profile.Mydata.email,
			phoneNumber: profile.Mydata?.phoneNumber,
			telegram_link: profile.Mydata?.telegram_link,
		},
	})
	const PutInfoToServer = () => {
		const value = getValues()
		dispatch(setMainInfo({ name: 'email', value: value['email'] }))
		dispatch(setMainInfo({ name: 'phoneNumber', value: value['phoneNumber'] }))
		dispatch(
			setMainInfo({
				name: 'telegram_link',
				value: value['telegram_link'],
			})
		)
		PutNewInfo(
			value['email'],
			value['phoneNumber'],
			value['telegram_link'],
			profile.Mydata.isInfoHidden
		)
		resetField('email')
		resetField('phoneNumber')
		resetField('telegram_link')
		toggle()
	}

	const emailPattern = /^[A-Z0-9._%+-]+@[A-Z.]+\.[A-Z]{2,4}$/i
	const phonePattern = /^((\+7|7|8)+([0-9]){10})$/
	const telegramPattern = /^@[A-Za-z0-9_.]{5,30}$/

	const fio: string = profile.Mydata.fio
	const fioParts = fio.split(' ')
	let namee = fioParts[1]
	let surnamee = fioParts[0]
	let patr = fioParts[2]

	if (fioParts.length > 3) {
		surnamee = ''
		namee = fioParts[0]
		patr = fioParts.slice(1).join(' ')
	}
	// ... существующий код ...
	const [selectedFile, setSelectedFile] = useState<File | null>(null)
	const [imageUrl, setImageUrl] = useState('')

	const fetchImage = async () => {
		const url = `${FILES_MICROSERVICE}/api/v1/avatar`
		try {
			const blobImage = await fileUploader.downloadPhoto(url)

			if (blobImage) {
				setImageUrl(URL.createObjectURL(blobImage))
			} else {
				console.error(
					'Не удалось загрузить изображение: изображение не найдено'
				)
			}
		} catch (error) {
			console.error('Ошибка загрузки изображения:', error)
		}
	}

	const handleAvatarUpload = async () => {
		if (!selectedFile) {
			alert('Пожалуйста, выберите файл.')
			return
		}

		const maxFileSize = 3 * 1024 * 1024 // 3 МБ
		if (selectedFile.size > maxFileSize) {
			alert('Файл слишком большой. Размер файла должен быть менее 3 МБ.')
			return
		}

		const url = `${FILES_MICROSERVICE}/api/v1/avatar`
		try {
			await fileUploader.uploadFile(url, selectedFile)
			fetchImage() // Обновить изображение после загрузки
		} catch (error) {
			console.error('Ошибка при загрузке файла:', error)
		}
	}
	useEffect(() => {
		fetchImage()
	}, [])
	return (
		<>
			<div className='profile'>
				<div className='image-section'>
					<ProfileImage imageURL={imageUrl} size='large' />
					<input
						type='file'
						accept='image/*'
						onChange={e => {
							if (e.target.files && e.target.files.length > 0) {
								setSelectedFile(e.target.files[0])
							}
						}}
					/>
					<Button onClick={() => handleAvatarUpload()}>
						Увидеть измененную аватарку <br />
						(Сохранить)
					</Button>
				</div>

				<DoubleList
					className='initials'
					listData={
						surnamee
							? [
									{
										identifier: 'Фамилия',
										content: surnamee,
									},
									{
										identifier: 'Имя',
										content: namee,
									},
									{
										identifier: 'Отчество',
										content: patr,
									},
							  ]
							: [
									{
										identifier: 'Имя',
										content: namee,
									},
									{
										identifier: 'Отчество',
										content: patr,
									},
							  ]
					}
				/>
			</div>
			<DoubleList
				className='another-info'
				listData={[
					{
						identifier: 'Дата рождения',
						content: `${day}.${month}.${year}`,
					},
					{
						identifier: 'Личная почта',
						content: (
							<>
								<input
									className={
										'form-field-input ' +
										(errors.email ? 'form-field-input--not-valid ' : '')
									}
									{...register('email', {
										required: 'Необходимо заполнить',

										pattern: {
											value: emailPattern,
											message: 'Некорректный формат ввода',
										},
									})}
								/>
								{errors.email && (
									<p className='form-field-input--error-message'>
										{errors.email.message}
									</p>
								)}
							</>
						),
					},
					{
						identifier: 'Почта МАИ',
						content: profile.Mydata.maiemail,
					},
					{
						identifier: 'Номер телефона',
						content: (
							<>
								<input
									className={
										'form-field-input ' +
										(errors.phoneNumber ? 'form-field-input--not-valid ' : '')
									}
									{...register('phoneNumber', {
										required: 'Номер телефона обязателен для заполнения',
										pattern: {
											value: phonePattern,
											message: 'Некорректный формат ввода',
										},
									})}
								/>
								{errors.phoneNumber && (
									<p className='form-field-input--error-message'>
										{errors.phoneNumber.message}
									</p>
								)}
							</>
						),
					},
					{
						identifier: 'Telegram',
						content: (
							<>
								<input
									className={
										'form-field-input ' +
										(errors.telegram_link ? 'form-field-input--not-valid ' : '')
									}
									{...register('telegram_link', {
										required: 'Необходимо заполнить',
										pattern: {
											value: telegramPattern,
											message: 'Некорректный формат ввода',
										},
									})}
								/>
								{errors.telegram_link && (
									<p className='form-field-input--error-message'>
										{errors.telegram_link.message}
									</p>
								)}
							</>
						),
					},
				]}
			/>
			<ContentCardFooter>
				<Button
					onClick={PutInfoToServer}
					disabled={Object.keys(errors).length > 0}
				>
					Сохранить изменения
				</Button>
			</ContentCardFooter>
		</>
	)
}

export default AccountAboutMainEditing
