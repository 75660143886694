import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import UpdatePassportDTO from '../../../../@types/dtos/UpdatePassportDTO'
import Button from '../../../../hooks/components/common/Button/Button'
import DoubleList from '../../../../hooks/components/common/DoubleList/DoubleList'
import { setFieldPasport } from '../../../../redux/slices/companyPasportSlice'
import { RootState } from '../../../../redux/store'
import {
	PASSPORT_MICROSERVICE,
	sendAxiosWithTokens,
} from '../../../../utils/team'

interface PropsTypes {
	toggle: Function
	caseId?: number
}

const ProjectPasportEditing = ({ toggle, caseId }: PropsTypes) => {
	const { PasportCase } = useSelector(
		(state: RootState) => state.companyPasportSlice
	)
	const dispatch = useDispatch()

	const RequestForNewData = async (body: UpdatePassportDTO) => {
		await sendAxiosWithTokens(
			`post`,
			`${PASSPORT_MICROSERVICE}/api/v1/passport/${caseId}`,
			body
		)
			.then(r => {
				console.log('fdsf', r.data.data)
			})
			.catch(error => [console.log(error)])
	}

	const {
		register,
		getValues,
		formState: { errors, isValid },
	} = useForm<UpdatePassportDTO>({
		mode: 'onTouched',
	})

	const onSetNewData = () => {
		const value = getValues()
		const body: UpdatePassportDTO = {
			endUser: value['endUser'],
			projectDuration: value['projectDuration'],
			projectType: PasportCase?.projectType,
			projectGoal: PasportCase?.projectGoal,
			projectTasks: PasportCase?.projectTasks,
			projectResult: PasportCase?.projectResult,
			projectRestrictions: PasportCase?.projectRestrictions,
			projectResources: PasportCase?.projectResources,
			projectTaskTracker: PasportCase?.projectTaskTracker,
			projectCompitentions: PasportCase?.projectCompitentions,
			additionalInformation: PasportCase?.additionalInformation,
			projectMentor: value['projectMentor'],
			projectSupervisor: value['projectSupervisor'],
			example: PasportCase?.example,
		}
		dispatch(
			setFieldPasport({
				endUser: value['endUser'],
				projectDuration: value['projectDuration'],
				projectMentor: value['projectMentor'],
				projectSupervisor: value['projectSupervisor'],
			})
		)

		RequestForNewData(body)
	}

	return (
		<>
			<DoubleList
				className='initials'
				listData={[
					{
						identifier: 'Наименование проекта',
						content: PasportCase?.projectTitle,
					},
					{
						identifier: 'Заказчик проекта',
						content: PasportCase?.projectCustomer,
					},
					{
						identifier: 'Руководитель проекта',
						content: (
							<input
								className='company-info-input-forEdit'
								defaultValue={PasportCase?.projectSupervisor}
								{...register('projectSupervisor')}
							/>
						),
					},
					{
						identifier: 'Ментор проекта',
						content: (
							<input
								className='company-info-input-forEdit'
								defaultValue={PasportCase?.projectMentor}
								{...register('projectMentor')}
							/>
						),
					},
					{
						identifier: 'Конечный пользователь',
						content: (
							<input
								className='company-info-input-forEdit'
								defaultValue={PasportCase?.endUser}
								{...register('endUser')}
							/>
						),
					},
					{
						identifier: 'Длительность проекта',
						content: (
							<input
								className='company-info-input-forEdit'
								defaultValue={PasportCase?.projectDuration}
								{...register('projectDuration')}
							/>
						),
					},
				]}
			/>

			<div className='link-wrapperPasport' onClick={onSetNewData}>
				<Button onClick={toggle}>Сохранить</Button>
			</div>
		</>
	)
}

export default ProjectPasportEditing
