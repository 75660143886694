import axios from 'axios'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ContainerMain from '../../hooks/components/common/ContainerMain/ContainerMain'
import ContentCard from '../../hooks/components/common/ContentCard/ContentCard'
import ContentCardWrapper from '../../hooks/components/common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../hooks/components/common/ContentMainTheme'
import Table from '../../hooks/components/common/Tables/TableFilterAndPagination'
import { MODERATION_MICROSERVICE_URL } from '../../utils/team'
import {
	TokenType,
	checkIsTokenExpired,
	getTokens,
	updateToken,
} from '../../utils/token'

const Connection = () => {
	const [data, setData] = useState([])
	const [isVisible, setVisibility] = useState(false)
	const [recipientId, setId] = useState<number>()
	const [inviteRoleId, setRoleId] = useState<number>()
	const navigate = useNavigate()

	const adminRoles: { PROGRAM_CURATOR: string; MAIN_ADMIN: string } = {
		PROGRAM_CURATOR: 'Руководитель программы',
		MAIN_ADMIN: 'Главный администратор',
	}

	useEffect(() => {
		const getData = async () => {
			let tokens: TokenType = JSON.parse(window.localStorage.getItem('token')!)
			if (checkIsTokenExpired(tokens.access_token)) {
				if (checkIsTokenExpired(tokens.refresh_token)) {
					navigate('/')
				} else {
					await updateToken()
					tokens = getTokens()
				}
			}
			if (tokens && tokens.access_token) {
				await axios
					.get(`${MODERATION_MICROSERVICE_URL}/api/v1/admin/users/admins/`, {
						headers: {
							Authorization: `Bearer ${tokens.access_token}`,
						},
					})
					.then(result => {
						console.log(result)
						setData(result.data.data)
					})
					.catch(error => {
						console.error(error)
					})
			}
		}
		getData()
	}, [])

	const COLUMNS = [
		{
			Header: '№',
			Cell: ({ row }: { row: any }) => <span>{row.index + 1}</span>,
		},
		{
			Header: 'ФИО пользователя',
			accessor: 'fio',
		},
		{
			Header: 'Должность',
			Cell: ({ row }: { row: any }) => {
				// @ts-ignore
				const userRole = adminRoles[row.original.role]
				return <>{userRole || ''}</>
			},
		},
		{
			Header: 'Телеграм',
			accessor: 'telegram_link',
		},
		{
			Header: 'Профиль',
			accessor: 'username',
			Cell: ({ value }: { value: any }) => (
				<Link className='profile-link' to={`/profile/${value}/data`}>
					Перейти в профиль
				</Link>
			),
		},
	]

	return (
		<ContainerMain size={'large'}>
			<ContentMainTheme>Список контактных лиц</ContentMainTheme>

			<ContentCardWrapper>
				<>
					<ContentCard low>
						Ниже представлен список контактных лиц, с кем вы можете связаться
						напрямую:
					</ContentCard>
				</>

				<ContentCard>
					{data.length === 0 ? (
						'Loading...'
					) : (
						<>
							{' '}
							<Table
								columns={COLUMNS}
								data={data}
								itemsOnPage={10}
								visiblePaginationRange={2}
								className='all-user-table'
							/>
						</>
					)}
				</ContentCard>
			</ContentCardWrapper>
		</ContainerMain>
	)
}

export default Connection
