import MainWrapper from '../../../hooks/components/Questionnaire/UserForm/MainWrapper'
import ContentCard from '../../../hooks/components/common/ContentCard/ContentCard'
import ContentCardInner from '../../../hooks/components/common/ContentCard/ContentCardInner'
import ContentCardWrapper from '../../../hooks/components/common/ContentCard/ContentCardWrapper'

const MainInfo = () => {
	return (
		<>
			{window.location.pathname.split('/').at(-1) === 'main' && (
				<h1 className='questionnare-fill-invitation'>
					Для продолжения заполните следующую форму:
				</h1>
			)}
			<div className='questionnaire-user-info'>
				<div className='forms-page-counter'>{'1/3'}</div>
				<ContentCardWrapper>
					<ContentCard>
						<div className='questionnaire-topic'>Укажите свои данные</div>
						<ContentCardInner>
							<MainWrapper />
						</ContentCardInner>
					</ContentCard>
				</ContentCardWrapper>
			</div>
		</>
	)
}

export default MainInfo
