import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Notification } from "../../../src/@types/dtos/Notifiation"
import { Application } from "../../../src/@types/entities/Application"
import { getTokens, isTokenInLS } from "../../utils/token";
import jwt_decode from "jwt-decode";

export interface Notif {
    notifications: Notification[];
    applications: Application[];
    sendedApplications: Application[];

    unreadNotification: Notification[];
    unreadApplication: Application[];
    amountUnreadNotification:number;
    amountUnreadApplication:number;

    amount: number;
    openwindow: boolean;
    SwapPages: number;
    SwapListsInWindow: number;
    ClickAns: {
        ClickAccept: boolean;
        ClickReject: boolean;
    }
}

const initialState: Notif = {
    notifications: [],
    applications: [],
    sendedApplications: [],

    unreadNotification: [],
    unreadApplication: [],

    amountUnreadNotification:0,
    amountUnreadApplication:0,

    amount: 0,
    openwindow: false,
    SwapListsInWindow: 0,
    SwapPages: 0,
    ClickAns: {
        ClickAccept: false,
        ClickReject: false,
    }
}

const NotificationInfo = createSlice({
    name: "NotificationInfo",
    initialState,
    reducers: {
        updateNotificationList(state, action) {
            state.notifications = action.payload;
        },
        updateApplicationsList(state, action) {
            state.applications = action.payload;
        },
        updateSendedApplicationsList(state, action) {
            state.sendedApplications = action.payload;
        },
        setAmountNotifications(state) {
            state.amount = 0;

            


            const MainAdminList = state.applications?.filter(item => item.type === 'QUESTION_FOR_MAIN_ADMIN' || item.type === 'QUESTION_FOR_SUPPORT' ||
                (item.type === 'TEAMLEAD_APPLICATION' && item?.subject && !item?.recipient) || (item.type === 'TEAMLEAD_APPLICATION' && !item?.subject) || item.type === 'MEMBERS_APPLICATION');
            const ProgrammCuratorList = state.applications?.filter(item => item.type === 'QUESTION_FOR_CURATOR' || item.type === 'MEMBERS_APPLICATION' || item.type === 'TEAMLEAD_APPLICATION');
            const StudentTeamLeadList = state.applications?.filter(item => item.type === 'REQUEST_TO_THE_TEAM');
            const StudentOnlyList = state.applications?.filter(item => item.type === 'INVITE_TO_THE_TEAM' || (item.type === 'TEAMLEAD_APPLICATION' && item?.subject?.id && item?.recipient))

            let teamlead: boolean = false;
            let Mainadmin: boolean = false;
            let student: boolean = false;
            let ProgrammCurator: boolean = false;

            if (isTokenInLS()) {
                const token: any = jwt_decode(getTokens().access_token);

                const roles = token.resource_access["spring-client"].roles;
                Mainadmin = roles.indexOf("client-main-admin") > -1
                teamlead = roles.indexOf("client-teamlead") > -1
                student = roles.indexOf("client-student") > -1;
                ProgrammCurator = roles.indexOf("client-program-curator") > -1;
            }

            let currentApplicationList: Application[] = [];

            switch (true) {
                case Mainadmin:
                    currentApplicationList = MainAdminList;
                    break;
                case student:
                    if (teamlead) {
                        currentApplicationList = StudentTeamLeadList;
                    } else {
                        currentApplicationList = StudentOnlyList;
                    }
                    break;
                case ProgrammCurator:
                    currentApplicationList = ProgrammCuratorList
                    break;
            }
            
            const unreadApplicationList = currentApplicationList?.filter(item =>  item.status === 'IN_REVIWEVING')
            const currentnotificationList = state.notifications?.filter(item => !item.wasRead);

            const activeNotifications = currentnotificationList?.length
            const activeApplications = unreadApplicationList?.length;

            state.amount += activeNotifications + activeApplications;
            state.amountUnreadApplication = activeApplications;
            state.amountUnreadNotification = activeNotifications
        },

        setOpenWindow(state, action) {
            state.openwindow = action.payload;
        },
        setHeadButtons(state, action) {
            state.SwapPages = action.payload;
        },
        setClickAccept(state, action) {
            if (!state.ClickAns.ClickAccept) state.ClickAns.ClickAccept = action.payload;
        },
        setClickReject(state, action) {
            if (!state.ClickAns.ClickReject) state.ClickAns.ClickReject = action.payload;
        },
        setCurrentListsInWindow(state, action) {
            state.SwapListsInWindow = action.payload
        },
    }

});

export const { setOpenWindow, setHeadButtons, setClickAccept, setClickReject, updateNotificationList
    , updateApplicationsList, setAmountNotifications, updateSendedApplicationsList, setCurrentListsInWindow
} = NotificationInfo.actions;
export default NotificationInfo.reducer;