import React from "react";
import { Link } from "react-router-dom";
import Button from "../common/Button/Button";

const Search: React.FC = () => {
    return (
        <div className="wrapper-ct">
            <div>
                <div className="title">
                    <h1>Найти команду</h1>
                </div>
                <div className="content-block">
                    <p>Тут будет поиск:</p>
                    <Button action="link" to={"/"}>
                        Назад
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Search;
