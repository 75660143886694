import React, { useEffect, useMemo } from "react";

import {
	TableInstance,
	useGlobalFilter,
	useFilters,
	UseGlobalFiltersInstanceProps,
	UseGlobalFiltersState,
	usePagination,
	UsePaginationInstanceProps,
	UsePaginationState,
	useSortBy,
	UseSortByInstanceProps,
	useTable,
} from "react-table";
import PaginationBar from "./PaginationBar";
import GlobalFilter from "./GlobalFilter";
import { useLocation } from "react-router-dom";

type TableProps = {
	columns: any;
	columnFilter?: boolean;
	data: any;
	className?: string;
	itemsOnPage?: number;
	visiblePaginationRange?: number;
};

export type TableInstanceWithHooks<T extends object> = TableInstance<T> &
	UsePaginationInstanceProps<T> &
	UseSortByInstanceProps<T> &
	UseGlobalFiltersInstanceProps<T> & {
		state: UsePaginationState<T> & UseGlobalFiltersState<T>;
	};

const Table = ({
	columns,
	data,
	className,
	itemsOnPage = 12,
	visiblePaginationRange = 2,
	columnFilter,
}: TableProps) => {
	const {
		getTableProps, // table props from react-table
		getTableBodyProps, // table body props from react-table
		headerGroups, // headerGroups, if your table has groupings

		page,
		previousPage,
		nextPage,
		canPreviousPage,
		canNextPage,
		gotoPage,
		pageOptions,
		setPageSize,
		pageCount,

		setGlobalFilter,

		state: { pageIndex, pageSize, globalFilter },

		prepareRow,
	} = useTable(
		{
			columns: columns,
			data: data,
		},
		useFilters,
		useGlobalFilter,
		usePagination,
	) as TableInstanceWithHooks<{}>;

	useEffect(() => setPageSize(itemsOnPage), []);

	const paginationProps = {
		pageIndex,
		visiblePaginationRange,
		pageCount,
		pageOptions,
		previousPage,
		nextPage,
		gotoPage,
		canPreviousPage,
		canNextPage,
	};

	const location = useLocation()

	return (
		<>
			<div className="scroll-table">
				<table {...getTableProps()} className={className}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps()}>
										<span className="column-topic">{column.render("Header")}</span>
										{columnFilter && column.canFilter
											? column.render("Filter")
											: null}
									</th>
								))}
							</tr>
						))}
					</thead>

					<tbody {...getTableBodyProps()}>
						{page.map((row) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell: any) => {
										return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<div className="table-superfooter">
				{location.pathname == "/admin/passportList"? "" :
					<GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
				}
				<PaginationBar {...paginationProps} />
			</div>
		</>
	);
};

export default Table;
