import { createSlice } from "@reduxjs/toolkit";
export interface helpSliceState {
    popularQuestions: FAQItem[];
    currentPage: number;
    questionsPerPage: number;
    questionHistory: questionHistoryItem[];
}

export interface FAQItem {
    id: string;
    content: string;
    feedback: string;
}
export interface questionHistoryItem {
    id: string;
    initiator: string;
    content: string;
    type: string;
    status: string;
    feedback: string;
}
const initialState: helpSliceState = {
    popularQuestions: [],
    currentPage: 0,
    questionsPerPage: 100,
    questionHistory: [],
};

export const helpSlice = createSlice({
    name: "help",
    initialState,
    reducers: {
        setPopularQuestions(state, action) {
            state.popularQuestions = action.payload;
        },
        setCurrentPage(state, action) {
            state.currentPage = action.payload;
        },
        setQuestionHistory(state, action) {
            state.questionHistory = action.payload;
        },
    },
});

export const { setPopularQuestions, setCurrentPage, setQuestionHistory } =
    helpSlice.actions;
export default helpSlice.reducer;
