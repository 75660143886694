import { useDispatch } from 'react-redux'
import InviteLink from '../../../assets/styles/components/Team/InviteLink.module.scss'
import Button from '../common/Button/Button'

import axios from 'axios'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { CREATE_COMMAND_MICROSERVICE_URL } from '../../../utils/team'
import {
	TokenType,
	checkIsTokenExpired,
	updateToken,
} from '../../../utils/token'

const EnterInviteLinkInPA = ({
	setvisibility,
}: {
	setvisibility: Function
}) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [Access, setAccess] = useState<number>(2)
	const [TitleTeam, setTitleTeam] = useState<string>('')

	const EnterInviteLinkRequest = async (Link: string) => {
		const tokens: TokenType = JSON.parse(window.localStorage.getItem('token')!)
		if (checkIsTokenExpired(tokens.access_token)) {
			if (checkIsTokenExpired(tokens.refresh_token)) {
				navigate('/')
			}
			updateToken()
		}
		if (tokens?.access_token) {
			await axios
				.post(
					`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/link/accept/${Link}`,
					{},
					{
						headers: {
							Authorization: `Bearer ${tokens.access_token}`,
						},
					}
				)
				.then(response => {
					// console.log(response);
					setTitleTeam(response.data.data.team.title)
					setAccess(1)
				})
				.catch(error => {
					console.log(error)
					setAccess(0)
				})
		}
	}

	interface InviteLink {
		Link: string
	}

	const {
		register,
		handleSubmit,
		formState: { isValid },
	} = useForm<InviteLink>()

	const AcceptInvite = (data: InviteLink) => {
		EnterInviteLinkRequest(data.Link)
	}

	return (
		<div className={InviteLink.content}>
			<form onSubmit={handleSubmit(AcceptInvite)}>
				<h2>Поле для ввода реферальной ссылки:</h2>
				<div className={InviteLink.inputField}>
					<input {...register('Link')} type='text' placeholder='Ваша ссылка' />
				</div>
				<h3>
					Ссылку нужно получить у Team Lead`а команды, в которую вы хотите
					вступить
				</h3>
				<Button
					spacing={'16'}
					action='formButton'
					type='submit'
					disabled={!isValid}
				>
					Вступить
				</Button>
				<Button spacing={'16'} onClick={() => setvisibility(false)}>
					Закрыть
				</Button>
			</form>
			<div className={InviteLink.result}>
				{Access === 1 && (
					<h2 className={InviteLink.success}>
						Вы успешно вступили в команду {TitleTeam}
					</h2>
				)}
				{Access === 0 && (
					<h2 className={InviteLink.incorrect}>Что-то пошло не так</h2>
				)}
			</div>
		</div>
	)
}

export default EnterInviteLinkInPA
