import React from 'react'
import { Link } from 'react-router-dom'
import { UserInTeamDTO } from '../../../../@types/dtos/UserInTeamDTO'
import ProfileImage from '../../PersonalAccount/MyData/ProfileImage'

interface UserListItemProps {
	user: UserInTeamDTO
	role?: boolean
	children?: React.ReactNode
	highlighted?: boolean | (() => boolean)
	className?: string
}

const UserListItem = ({
	user,
	role,
	children,
	highlighted,
	className = '',
}: UserListItemProps) => {
	return (
		<div
			className={
				'user-list-item' +
				(highlighted ? ' user-list-item--highlighted' : '') +
				' ' +
				className
			}
		>
			<div className='user-list-item__profile-img'>
				<ProfileImage imageURL='' size={'small'} />
			</div>
			<div className='user-list-item__name'>
				<Link
					to={`/profile/${user?.member?.username}/data`}
				>{`${user?.member?.fio}`}</Link>{' '}
				<br />
				{user.member.academicGroup}
			</div>
			{role ? (
				<div className='user-list-item__role'>
					{user?.role?.title || 'Роль не выбрана'}
				</div>
			) : null}
			{children ? (
				<div className='user-list-item__payload'>{children}</div>
			) : null}
		</div>
	)
}

export default UserListItem
