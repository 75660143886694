import DoubleList from '../../common/DoubleList/DoubleList'

import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setMainInfo, updateMydata } from '../../../../redux/slices/paSlice'
import { RootState } from '../../../../redux/store'
import ErrorHandler from '../../../../utils/ErrorHandler'
import { PERSONAL_ACCOUNT_MICROSERVICE } from '../../../../utils/team'
import {
	TokenType,
	checkIsTokenExpired,
	getDecodedToken,
	getTokens,
	isTokenInLS,
	updateToken,
} from '../../../../utils/token'
import Button from '../../common/Button/Button'
import ContentCardFooter from '../../common/ContentCard/ContentCardFooter'

interface props {
	toggle: Function
}

const AddListEditing = ({ toggle }: props) => {
	const { profile }: any = useSelector(
		(state: RootState) => state.personalAccount
	)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	let admin: boolean = false
	let student: boolean = false
	let customer: boolean = false
	let curator: boolean = false
	let programCurator: boolean = false

	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		const isAdmin = roles.indexOf('client-main-admin') > -1
		if (isAdmin) admin = isAdmin
		const isCurator = roles.indexOf('client-curator') > -1
		if (isCurator) curator = isCurator
		const isStudent = roles.indexOf('client-student') > -1
		if (isStudent) student = isStudent
		const isCustomer = roles.indexOf('client-customer') > -1
		if (isCustomer) customer = isCustomer
		const isProgramCurator = roles.indexOf('client-program-curator') > -1
		if (isProgramCurator) programCurator = isProgramCurator
	}
	let tokens: TokenType = getTokens()
	const PutNewInfo = async (
		email: string | undefined,
		phoneNumber: string | undefined,
		telegram_link: string | undefined,
		isInfoHidden?: boolean | undefined
	) => {
		if (tokens != null) {
			const data_from_token = getDecodedToken()

			if (checkIsTokenExpired(tokens.access_token)) {
				if (checkIsTokenExpired(tokens.refresh_token)) {
					window.localStorage.removeItem('token')
					navigate('/')
				} else {
					await updateToken()
					tokens = getTokens()
				}
			}
			await axios.put(
				`${PERSONAL_ACCOUNT_MICROSERVICE}/api/v1/personal_account/changeUserMainInformation`,
				{
					email: email,
					phoneNumber: phoneNumber,
					telegram_link: telegram_link,
					isInfoHidden: isInfoHidden,
				},
				{
					headers: {
						Authorization: `Bearer ${tokens.access_token}`,
					},
				}
			)

			await axios
				.get(
					`${PERSONAL_ACCOUNT_MICROSERVICE}/api/v1/personal_account/${data_from_token.preferred_username}`,
					{
						headers: {
							Authorization: `Bearer ${tokens.access_token}`,
						},
					}
				)
				.then(response => {
					dispatch(updateMydata(response.data.data))
				})
				.catch(error => {
					ErrorHandler(error, navigate)
				})
		}
	}

	interface EditingInfo {
		email?: string
		phoneNumber?: string
		telegram_link?: string
		isInfoHidden?: boolean
	}

	const {
		handleSubmit,
		register,
		getValues,
		resetField,
		formState: { errors, isValid },
	} = useForm<EditingInfo>({
		mode: 'onTouched',
		defaultValues: {
			email: profile.Mydata.email,
			phoneNumber: profile.Mydata?.phoneNumber,
			telegram_link: profile.Mydata?.telegram_link,
		},
	})

	const PutInfoToServer = () => {
		const value = getValues()
		dispatch(setMainInfo({ name: 'email', value: value['email'] }))
		dispatch(setMainInfo({ name: 'phoneNumber', value: value['phoneNumber'] }))
		dispatch(
			setMainInfo({ name: 'telegram_link', value: value['telegram_link'] })
		)
		PutNewInfo(
			value['email'],
			value['phoneNumber'],
			value['telegram_link'],
			profile.Mydata.isInfoHidden
		)
		toggle()
	}

	const emailPattern = /^[A-Z0-9._%+-]+@[A-Z.]+\.[A-Z]{2,4}$/i
	const phonePattern = /^(8|\+7)[0-9]{10}$/
	const telegramPattern = /^@[A-Za-z0-9_.]{5,30}$/
	return (
		<>
			{admin || curator || programCurator || customer ? (
				<DoubleList
					className={'additionals'}
					listData={[
						{
							identifier: 'Почта',
							content: (
								<>
									<input
										className={
											'form-field-input ' +
											(errors.email ? 'form-field-input--not-valid ' : '')
										}
										{...register('email', {
											required: 'Необходимо заполнить',

											pattern: {
												value: emailPattern,
												message: 'Некорректный формат ввода',
											},
										})}
									/>
									{errors.email && (
										<p className='form-field-input--error-message'>
											{errors.email.message}
										</p>
									)}
								</>
							),
						},

						{
							identifier: 'Номер телефона',
							content: (
								<>
									<input
										className={
											'form-field-input ' +
											(errors.phoneNumber ? 'form-field-input--not-valid ' : '')
										}
										{...register('phoneNumber', {
											required: 'Номер телефона обязателен для заполнения',
											pattern: {
												value: phonePattern,
												message: 'Некорректный формат ввода',
											},
										})}
									/>
									{errors.phoneNumber && (
										<p className='form-field-input--error-message'>
											{errors.phoneNumber.message}
										</p>
									)}
								</>
							),
						},
						{
							identifier: 'Telegram',
							content: (
								<>
									<input
										className={
											'form-field-input ' +
											(errors.telegram_link
												? 'form-field-input--not-valid '
												: '')
										}
										{...register('telegram_link', {
											required: 'Необходимо заполнить',
											pattern: {
												value: telegramPattern,
												message: 'Некорректный формат ввода',
											},
										})}
									/>
									{errors.telegram_link && (
										<p className='form-field-input--error-message'>
											{errors.telegram_link.message}
										</p>
									)}
								</>
							),
						},
					]}
				/>
			) : (
				''
			)}
			<ContentCardFooter>
				<Button
					onClick={PutInfoToServer}
					disabled={Object.keys(errors).length > 0}
				>
					Сохранить изменения
				</Button>
			</ContentCardFooter>
		</>
	)
}

export default AddListEditing
