import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Application } from '../../@types/entities/Application'
import { ApplicationType } from '../../@types/entities/ApplicationType'
import forInvite from '../../assets/img/icons/forInvite.svg'
import isEmptyImg from '../../assets/img/icons/человечек в уведах.svg'
import notifStyle from '../../assets/styles/components/notifStyle.module.scss'
import Button from '../../hooks/components/common/Button/Button'
import ModalWindow from '../../hooks/components/common/ModalWindow/ModalWindow'
import { RootState } from '../../redux/store'
import { getDecodedToken } from '../../utils/token'
import { ModalContent } from './ModalContent'

interface ViewApplicationProps {
	currentList: Application[]
	ListToShow: Application[]
	Accept: Function
	Reject: Function
	GoToQuestion: Function
	visibleModalWithError: boolean
	setVisibilityModal: Function
	setWindowContent: Function
	pageIndex: number
}

const ViewApplication: React.FC<ViewApplicationProps> = ({
	currentList,
	ListToShow,
	Accept,
	Reject,
	GoToQuestion,
	visibleModalWithError,
	setVisibilityModal,
	setWindowContent,
	pageIndex,
}) => {
	const location = useLocation();
	const ActiveIndex = useSelector(
		(state: RootState) => state.activeIndexReducer.activeIndex
	)
	const roles = getDecodedToken()?.resource_access['spring-client'].roles
	
	return (
		<>
			<ModalWindow
				visibility={visibleModalWithError}
				setVisibility={setVisibilityModal}
				autoClose
				fastClose
			>
				Произошла ошибка{' '}
			</ModalWindow>
			{currentList.length === 0 ? (
				<div className={notifStyle.isEmptyWindow}>
					<img src={isEmptyImg} alt='' />
					<h2>В заявках пусто</h2>
				</div>
			) : (
				<div>
					{ActiveIndex === 0 ? (
						<ul>
							{ListToShow.map((item, i) => {
								if (item.type === 'INVITE_TO_THE_TEAM') {
									return (
										<li
											key={i}
											className={
												item.status === 'ACCEPTED' || item.status === 'REJECTED'
													? `${notifStyle.oldApp}`
													: `${notifStyle.NewApp}`
											}
										>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													<span className={notifStyle.LinkToUserByQuestion}>
														<Link
															to={`/profile/${item.initiator?.username}/data`}
														>{`${item.initiator?.fio}`}</Link>
													</span>
													{` пригласил вас в команду "${
														item.subject?.title || 'Названия команды нет'
													}" ${
														item.role?.title
															? `на роль ${item.role?.title}`
															: ''
													}`}
													<br />
													{item.feedback != '' && item.feedback
														? `Комментарий: ${item.feedback}`
														: ''}
												</span>
											</div>
											{item.status === 'ACCEPTED' ? (
												<p className={notifStyle.acceptInviteToTheTeam}>
													Принято
												</p>
											) : item.status === 'REJECTED' ? (
												<p className={notifStyle.rejectInviteToTheTeam}>
													Отклонено
												</p>
											) : (
												<div className={notifStyle.TwoButton}>
													<button
														onClick={() =>
															setWindowContent(
																<ModalContent
																	action='ACCEPTED'
																	setWindowContent={setWindowContent}
																	applicationType={
																		ApplicationType.INVITE_TO_THE_TEAM
																	}
																	teamTitle={item.subject?.title}
																	applicationid={item.id}
																/>
															)
														}
														className={notifStyle.Accept}
													>
														Принять
													</button>
													<button
														onClick={() =>
															setWindowContent(
																<ModalContent
																	action='REJECTED'
																	setWindowContent={setWindowContent}
																	applicationType={
																		ApplicationType.INVITE_TO_THE_TEAM
																	}
																	teamTitle={item.subject?.title}
																	applicationid={item.id}
																/>
															)
														}
														className={notifStyle.Reject}
													>
														Отклонить
													</button>
												</div>
											)}
										</li>
									)
								} else if (item.type === 'REQUEST_TO_THE_TEAM') {
									if (
										item.type === 'REQUEST_TO_THE_TEAM' &&
										roles?.includes('client-main-admin')
									) {
										return (
											<li
												key={i}
												className={
													item.status === 'ACCEPTED' ||
													item.status === 'REJECTED'
														? `${notifStyle.oldApp}`
														: `${notifStyle.NewApp}`
												}
											>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.text}>
														<span className={notifStyle.LinkToUserByQuestion}>
															<Link
																to={`/profile/${item.initiator?.username}/data`}
															>{`${item.initiator?.fio}`}</Link>
														</span>{' '}
														{` хочет вступить в команду "${
															item.subject?.title
														}" ${
															item.role?.title
																? `на роль ${item.role?.title}`
																: ''
														} `}
														<br />
														{item.feedback != '' && item.feedback
															? `Комментарий: ${item.feedback}`
															: ''}
													</span>
												</div>
												{item.status === 'ACCEPTED' ? (
													<p className={notifStyle.acceptInviteToTheTeam}>
														Принято
													</p>
												) : item.status === 'REJECTED' ? (
													<p className={notifStyle.rejectInviteToTheTeam}>
														Отклонено
													</p>
												) : (
													<div className={notifStyle.TwoButton}>
														<button
															onClick={() =>
																setWindowContent(
																	<ModalContent
																		admin
																		action='ACCEPTED'
																		setWindowContent={setWindowContent}
																		applicationType={
																			ApplicationType.REQUEST_TO_THE_TEAM
																		}
																		fio={item.initiator?.fio}
																		teamTitle={item.subject?.title}
																		applicationid={item.id}
																	/>
																)
															}
															className={notifStyle.Accept}
														>
															Принять
														</button>
														<button
															onClick={() =>
																setWindowContent(
																	<ModalContent
																		admin
																		action='REJECTED'
																		setWindowContent={setWindowContent}
																		applicationType={
																			ApplicationType.REQUEST_TO_THE_TEAM
																		}
																		fio={item.initiator?.fio}
																		teamTitle={item.subject?.title}
																		applicationid={item.id}
																	/>
																)
															}
															className={notifStyle.Reject}
														>
															Отклонить
														</button>
													</div>
												)}
											</li>
										)
									}
									return (
										<li
											key={i}
											className={
												item.status === 'ACCEPTED' || item.status === 'REJECTED'
													? `${notifStyle.oldApp}`
													: `${notifStyle.NewApp}`
											}
										>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													<span className={notifStyle.LinkToUserByQuestion}>
														<Link
															to={`/profile/${item.initiator?.username}/data`}
														>{`${item.initiator?.fio}`}</Link>
													</span>{' '}
													{`хочет вступить в вашу команду "${
														item.subject?.title
													}" ${
														item.role?.title
															? `на роль ${item.role?.title}`
															: ''
													} `}
													<br />
													{item.feedback != '' && item.feedback
														? `Комментарий: ${item.feedback}`
														: ''}
												</span>
											</div>
											{item.status === 'ACCEPTED' ? (
												<p className={notifStyle.acceptInviteToTheTeam}>
													Принято
												</p>
											) : item.status === 'REJECTED' ? (
												<p className={notifStyle.rejectInviteToTheTeam}>
													Отклонено
												</p>
											) : (
												<div className={notifStyle.TwoButton}>
													<button
														onClick={() =>
															setWindowContent(
																<ModalContent
																	action='ACCEPTED'
																	setWindowContent={setWindowContent}
																	applicationType={
																		ApplicationType.REQUEST_TO_THE_TEAM
																	}
																	fio={item.initiator?.fio}
																	teamTitle={item.subject?.title}
																	applicationid={item.id}
																/>
															)
														}
														className={notifStyle.Accept}
													>
														Принять
													</button>
													<button
														onClick={() =>
															setWindowContent(
																<ModalContent
																	action='REJECTED'
																	setWindowContent={setWindowContent}
																	applicationType={
																		ApplicationType.REQUEST_TO_THE_TEAM
																	}
																	fio={item.initiator?.fio}
																	teamTitle={item.subject?.title}
																	applicationid={item.id}
																/>
															)
														}
														className={notifStyle.Reject}
													>
														Отклонить
													</button>
												</div>
											)}
										</li>
									)
								} else if (item.type === 'MEMBERS_APPLICATION') {
									return (
										<li
											key={i}
											className={
												item.status === 'ACCEPTED' || item.status === 'REJECTED'
													? `${notifStyle.oldApp}`
													: `${notifStyle.NewApp}`
											}
										>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{`Команда ${
														item?.subject?.title
															? item?.subject?.title
															: 'без названия'
													} хочет подтвердить список своей команды.`}
													<br />
													<Link
														className='profile-link'
														to={`/team/${item.subject?.id}`}
														state = {{pageIndex: pageIndex, idTeam: item.subject?.id, applicationId: item.id, previousPath: location.pathname}}
														>
														Посмотреть состав команды
													</Link>
													

													
													<br />
													{`Кейс: ${
														item.subject?.topic?.title || 'еще не выбран'
													} `}
													<br />
													{item.feedback != '' && item.feedback
														? `Комментарий: ${item.feedback}`
														: ''}
												</span>
											</div>
											{item.status === 'ACCEPTED' ? (
												<p className={notifStyle.acceptInviteToTheTeam}>
													Принято
												</p>
											) : item.status === 'REJECTED' ? (
												<p className={notifStyle.rejectInviteToTheTeam}>
													Отклонено
												</p>
											) : (
												<div className={notifStyle.TwoButton}>
													<button
														onClick={() =>
															setWindowContent(
																<ModalContent
																	admin
																	action='ACCEPTED'
																	setWindowContent={setWindowContent}
																	applicationType={
																		ApplicationType.MEMBERS_APPLICATION
																	}
																	teamTitle={item.subject?.title}
																	applicationid={item.id}
																	teamId={item.subject?.id}
																/>
															)
														}
														className={notifStyle.Accept}
													>
														Принять
													</button>
													<button
														onClick={() =>
															setWindowContent(
																<ModalContent
																	admin
																	action='REJECTED'
																	setWindowContent={setWindowContent}
																	applicationType={
																		ApplicationType.MEMBERS_APPLICATION
																	}
																	teamTitle={item.subject?.title}
																	applicationid={item.id}
																	teamId={item.subject?.id}
																/>
															)
														}
														className={notifStyle.Reject}
													>
														Отклонить
													</button>
												</div>
											)}
										</li>
									)
								} else if (item.type === 'TEAMLEAD_APPLICATION') {
									if (item?.subject?.id && item?.recipient) {
										return (
											<li
												key={i}
												className={
													item.status === 'ACCEPTED' ||
													item.status === 'REJECTED'
														? `${notifStyle.oldApp}`
														: `${notifStyle.NewApp}`
												}
											>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.text}>
														<span className={notifStyle.LinkToUserByQuestion}>
															<Link
																to={`/profile/${item.initiator?.username}/data`}
															>{`${item.initiator?.fio}`}</Link>
														</span>{' '}
														{`хочет передать вам роль Team lead'а`}
														<br />
														{item.feedback != '' && item.feedback
															? `Комментарий: ${item.feedback}`
															: ''}
													</span>
												</div>
												{item.status === 'ACCEPTED' ? (
													<p className={notifStyle.acceptInviteToTheTeam}>
														Принято
													</p>
												) : item.status === 'REJECTED' ? (
													<p className={notifStyle.rejectInviteToTheTeam}>
														Отклонено
													</p>
												) : (
													<div className={notifStyle.TwoButton}>
														<button
															onClick={() =>
																setWindowContent(
																	<ModalContent
																		action='ACCEPTED'
																		setWindowContent={setWindowContent}
																		applicationType={
																			ApplicationType.TEAMLEAD_APPLICATION
																		}
																		fio={item.initiator?.fio}
																		applicationid={item.id}
																		hackForTeamLeadApplication=''
																	/>
																)
															}
															className={notifStyle.Accept}
														>
															Принять
														</button>
														<button
															onClick={() =>
																setWindowContent(
																	<ModalContent
																		action='REJECTED'
																		setWindowContent={setWindowContent}
																		applicationType={
																			ApplicationType.TEAMLEAD_APPLICATION
																		}
																		fio={item.initiator?.fio}
																		applicationid={item.id}
																		hackForTeamLeadApplication=''
																	/>
																)
															}
															className={notifStyle.Reject}
														>
															Отклонить
														</button>
													</div>
												)}
											</li>
										)
									} else if (!item?.subject) {
										return (
											<li
												key={i}
												className={
													item.status === 'ACCEPTED' ||
													item.status === 'REJECTED'
														? `${notifStyle.oldApp}`
														: `${notifStyle.NewApp}`
												}
											>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.text}>
														<span className={notifStyle.LinkToUserByQuestion}>
															<Link
																to={`/profile/${item.initiator?.username}/data`}
															>{`${item.initiator?.fio}`}</Link>
														</span>{' '}
														{`хочет стать тимлидом команды.`}
														<br />
														{`Программа: ${
															item?.program?.title ||
															'программа пока не известна'
														}`}
														<br />
														{item.feedback != '' && item.feedback
															? `Комментарий: ${item.feedback}`
															: ''}
													</span>
												</div>
												{item.status === 'ACCEPTED' ? (
													<p className={notifStyle.acceptInviteToTheTeam}>
														Принято
													</p>
												) : item.status === 'REJECTED' ? (
													<p className={notifStyle.rejectInviteToTheTeam}>
														Отклонено
													</p>
												) : (
													<div className={notifStyle.TwoButton}>
														<button
															onClick={() =>
																setWindowContent(
																	<ModalContent
																		admin
																		action='ACCEPTED'
																		setWindowContent={setWindowContent}
																		applicationType={
																			ApplicationType.TEAMLEAD_APPLICATION
																		}
																		fio={item.initiator?.fio}
																		hackForTeamLeadApplication='approve'
																		applicationid={item.id}
																	/>
																)
															}
															className={notifStyle.Accept}
														>
															Принять
														</button>
														<button
															onClick={() =>
																setWindowContent(
																	<ModalContent
																		admin
																		action='REJECTED'
																		setWindowContent={setWindowContent}
																		applicationType={
																			ApplicationType.TEAMLEAD_APPLICATION
																		}
																		fio={item.initiator?.fio}
																		hackForTeamLeadApplication='approve'
																		applicationid={item.id}
																	/>
																)
															}
															className={notifStyle.Reject}
														>
															Отклонить
														</button>
													</div>
												)}
											</li>
										)
									} else if (item?.subject && !item?.recipient) {
										return (
											<li
												key={i}
												className={
													item.status === 'ACCEPTED' ||
													item.status === 'REJECTED'
														? `${notifStyle.oldApp}`
														: `${notifStyle.NewApp}`
												}
											>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.text}>
														<span className={notifStyle.LinkToUserByQuestion}>
															<Link
																to={`/profile/${item.initiator?.username}/data`}
															>{`${item.initiator?.fio}`}</Link>
														</span>{' '}
														{`хочет передать роль Team Lead'а .`}
														<br />
														{item.feedback != '' && item.feedback
															? `Комментарий: ${item.feedback}`
															: ''}
													</span>
												</div>
												{item.status === 'ACCEPTED' ? (
													<p className={notifStyle.acceptInviteToTheTeam}>
														Принято
													</p>
												) : item.status === 'REJECTED' ? (
													<p className={notifStyle.rejectInviteToTheTeam}>
														Отклонено
													</p>
												) : (
													<div className={notifStyle.TwoButton}>
														<button
															onClick={() =>
																setWindowContent(
																	<ModalContent
																		admin
																		action='ACCEPTED'
																		setWindowContent={setWindowContent}
																		applicationType={
																			ApplicationType.TEAMLEAD_APPLICATION
																		}
																		fio={item.initiator?.fio}
																		hackForTeamLeadApplication='transfer'
																		applicationid={item.id}
																	/>
																)
															}
															className={notifStyle.Accept}
														>
															Принять
														</button>
														<button
															onClick={() =>
																setWindowContent(
																	<ModalContent
																		admin
																		action='REJECTED'
																		setWindowContent={setWindowContent}
																		applicationType={
																			ApplicationType.TEAMLEAD_APPLICATION
																		}
																		fio={item.initiator?.fio}
																		hackForTeamLeadApplication='transfer'
																		applicationid={item.id}
																	/>
																)
															}
															className={notifStyle.Reject}
														>
															Отклонить
														</button>
													</div>
												)}
											</li>
										)
									}
								} else if (item.type === 'QUESTION_FOR_CURATOR') {
									return (
										<li
											key={i}
											className={
												item.status === 'ACCEPTED' || item.status === 'REJECTED'
													? `${notifStyle.oldApp}`
													: `${notifStyle.NewApp}`
											}
										>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{' '}
													{`От `}
													<span className={notifStyle.LinkToUserByQuestion}>
														<Link
															to={`/profile/${item.initiator?.username}/data`}
														>{`${item.initiator?.fio}`}</Link>
													</span>
													{` поступил вопрос для куратора`}
												</span>
											</div>
											<div className={notifStyle.GoToQuestion}>
												<Button styling='text' onClick={GoToQuestion}>
													Перейти к вопросу
												</Button>
											</div>
										</li>
									)
								} else if (item.type === 'QUESTION_FOR_SUPPORT') {
									return (
										<li
											key={i}
											className={
												item.status === 'ACCEPTED' || item.status === 'REJECTED'
													? `${notifStyle.oldApp}`
													: `${notifStyle.NewApp}`
											}
										>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{' '}
													{`От `}
													<span className={notifStyle.LinkToUserByQuestion}>
														<Link
															to={`/profile/${item.initiator?.username}/data`}
														>{`${item.initiator?.fio}`}</Link>
													</span>
													{` поступил вопрос для тех. поддержки`}
												</span>
											</div>
											<div className={notifStyle.GoToQuestion}>
												<Button styling='text' onClick={GoToQuestion}>
													Перейти к вопросу
												</Button>
											</div>
										</li>
									)
								} else if (item.type === 'QUESTION_FOR_MAIN_ADMIN') {
									return (
										<li
											key={i}
											className={
												item.status === 'ACCEPTED' || item.status === 'REJECTED'
													? `${notifStyle.oldApp}`
													: `${notifStyle.NewApp}`
											}
										>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{' '}
													{`От `}
													<span className={notifStyle.LinkToUserByQuestion}>
														<Link
															to={`/profile/${item.initiator?.username}/data`}
														>{`${item.initiator?.fio}`}</Link>
													</span>
													{` поступил вопрос для администрации`}
												</span>
											</div>
											<div className={notifStyle.GoToQuestion}>
												<Button styling='text' onClick={GoToQuestion}>
													Перейти к вопросу
												</Button>
											</div>
										</li>
									)
								}
							})}
						</ul>
					) : (
						''
					)}
					{ActiveIndex === 1 ? (
						<ul>
							{ListToShow.map((item, i) => {
								if (item.type === 'INVITE_TO_THE_TEAM') {
									return (
										<li key={i} className={notifStyle.oldApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													<span className={notifStyle.LinkToUserByQuestion}>
														<Link
															to={`/profile/${item.initiator?.username}/data`}
														>{`${item.initiator?.fio}`}</Link>
													</span>
													{` пригласил вас в команду "${
														item.subject?.title || 'Названия команды нет'
													}" ${
														item.role?.title
															? `на роль ${item.role?.title}`
															: ''
													}`}
													<br />
													{item.feedback != '' && item.feedback
														? `Комментарий: ${item.feedback}`
														: ''}
												</span>
											</div>
											{item.status === 'ACCEPTED' ? (
												<p className={notifStyle.acceptInviteToTheTeam}>
													Принято
												</p>
											) : item.status === 'REJECTED' ? (
												<p className={notifStyle.rejectInviteToTheTeam}>
													Отклонено
												</p>
											) : (
												''
											)}
										</li>
									)
								} else if (item.type === 'REQUEST_TO_THE_TEAM') {
									if (
										item.type === 'REQUEST_TO_THE_TEAM' &&
										roles?.includes('client-main-admin')
									) {
										return (
											<li key={i} className={notifStyle.oldApp}>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.text}>
														<span className={notifStyle.LinkToUserByQuestion}>
															<Link
																to={`/profile/${item.initiator?.username}/data`}
															>{`${item.initiator?.fio}`}</Link>
														</span>{' '}
														{` хочет вступить в команду "${
															item.subject?.title
														}" ${
															item.role?.title
																? `на роль ${item.role?.title}`
																: ''
														} `}
														<br />
														{item.feedback != '' && item.feedback
															? `Комментарий: ${item.feedback}`
															: ''}
													</span>
												</div>
												{item.status === 'ACCEPTED' ? (
													<p className={notifStyle.acceptInviteToTheTeam}>
														Принято
													</p>
												) : item.status === 'REJECTED' ? (
													<p className={notifStyle.rejectInviteToTheTeam}>
														Отклонено
													</p>
												) : (
													''
												)}
											</li>
										)
									}
									return (
										<li key={i} className={notifStyle.oldApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													<span className={notifStyle.LinkToUserByQuestion}>
														<Link
															to={`/profile/${item.initiator?.username}/data`}
														>{`${item.initiator?.fio}`}</Link>
													</span>{' '}
													{`хочет вступить в вашу команду ${
														item.subject?.title
													} ${
														item.role?.title
															? `на роль ${item.role?.title}`
															: ''
													} `}
													<br />
													{item.feedback != '' && item.feedback
														? `Комментарий: ${item.feedback}`
														: ''}
												</span>
											</div>
											{item.status === 'ACCEPTED' ? (
												<p className={notifStyle.acceptInviteToTheTeam}>
													Принято
												</p>
											) : item.status === 'REJECTED' ? (
												<p className={notifStyle.rejectInviteToTheTeam}>
													Отклонено
												</p>
											) : (
												''
											)}
										</li>
									)
								} else if (item.type === 'MEMBERS_APPLICATION') {
									return (
										<li key={i} className={notifStyle.oldApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{`Команда ${
														item?.subject?.title
															? item?.subject?.title
															: 'без названия'
													} хочет подтвердить список своей команды.`}
													<br />
													<Link
														className='profile-link'
														to={`/team/${item.subject?.id}`}
														state = {{pageIndex: pageIndex, idTeam: item.subject?.id, applicationId: item.id, previousPath: location.pathname}}
														>
														Посмотреть состав команды
													</Link>
													<br />
													{`Кейс: ${
														item.subject?.topic?.title || 'еще не выбран'
													} `}
													<br />
													{item.feedback != '' && item.feedback
														? `Комментарий: ${item.feedback}`
														: ''}
												</span>
											</div>
											{item.status === 'ACCEPTED' ? (
												<p className={notifStyle.acceptInviteToTheTeam}>
													Принято
												</p>
											) : item.status === 'REJECTED' ? (
												<p className={notifStyle.rejectInviteToTheTeam}>
													Отклонено
												</p>
											) : (
												''
											)}
										</li>
									)
								} else if (item.type === 'TEAMLEAD_APPLICATION') {
									if (item?.subject?.id && item?.recipient) {
										return (
											<li
												key={i}
												className={
													item.status === 'ACCEPTED' ||
													item.status === 'REJECTED'
														? `${notifStyle.oldApp}`
														: `${notifStyle.NewApp}`
												}
											>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.text}>
														<span className={notifStyle.LinkToUserByQuestion}>
															<Link
																to={`/profile/${item.initiator?.username}/data`}
															>{`${item.initiator?.fio}`}</Link>
														</span>{' '}
														{`хочет передать вам роль Team lead'а`}
														<br />
														{item.feedback != '' && item.feedback
															? `Комментарий: ${item.feedback}`
															: ''}
													</span>
												</div>
												{item.status === 'ACCEPTED' ? (
													<p className={notifStyle.acceptInviteToTheTeam}>
														Принято
													</p>
												) : item.status === 'REJECTED' ? (
													<p className={notifStyle.rejectInviteToTheTeam}>
														Отклонено
													</p>
												) : (
													''
												)}
											</li>
										)
									} else if (!item?.subject) {
										return (
											<li
												key={i}
												className={
													item.status === 'ACCEPTED' ||
													item.status === 'REJECTED'
														? `${notifStyle.oldApp}`
														: `${notifStyle.NewApp}`
												}
											>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.text}>
														<span className={notifStyle.LinkToUserByQuestion}>
															<Link
																to={`/profile/${item.initiator?.username}/data`}
															>{`${item.initiator?.fio}`}</Link>
														</span>{' '}
														{`хочет стать тимлидом команды.`}
														<br />
														{`Программа: ${
															item?.program?.title ||
															'программа пока не известна'
														}`}{' '}
														<br />
														{item.feedback != '' && item.feedback
															? `Комментарий: ${item.feedback}`
															: ''}
													</span>
												</div>
												{item.status === 'ACCEPTED' ? (
													<p className={notifStyle.acceptInviteToTheTeam}>
														Принято
													</p>
												) : item.status === 'REJECTED' ? (
													<p className={notifStyle.rejectInviteToTheTeam}>
														Отклонено
													</p>
												) : (
													''
												)}
											</li>
										)
									} else if (item?.subject && !item?.recipient) {
										return (
											<li
												key={i}
												className={
													item.status === 'ACCEPTED' ||
													item.status === 'REJECTED'
														? `${notifStyle.oldApp}`
														: `${notifStyle.NewApp}`
												}
											>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.text}>
														<span className={notifStyle.LinkToUserByQuestion}>
															<Link
																to={`/profile/${item.initiator?.username}/data`}
															>{`${item.initiator?.fio}`}</Link>
														</span>{' '}
														{`хочет передать роль Team Lead'а .`}
														<br />
														{item.feedback != '' && item.feedback
															? `Комментарий: ${item.feedback}`
															: ''}
													</span>
												</div>
												{item.status === 'ACCEPTED' ? (
													<p className={notifStyle.acceptInviteToTheTeam}>
														Принято
													</p>
												) : item.status === 'REJECTED' ? (
													<p className={notifStyle.rejectInviteToTheTeam}>
														Отклонено
													</p>
												) : (
													''
												)}
											</li>
										)
									}
								} else if (item.type === 'QUESTION_FOR_CURATOR') {
									return (
										<li key={i} className={notifStyle.oldApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{' '}
													{`От `}
													<span className={notifStyle.LinkToUserByQuestion}>
														<Link
															to={`/profile/${item.initiator?.username}/data`}
														>{`${item.initiator?.fio}`}</Link>
													</span>
													{` поступил вопрос для куратора`}
												</span>
											</div>
											<div className={notifStyle.GoToQuestion}>
												<Button styling='text' onClick={GoToQuestion}>
													Перейти к вопросу
												</Button>
											</div>
										</li>
									)
								} else if (item.type === 'QUESTION_FOR_SUPPORT') {
									return (
										<li key={i} className={notifStyle.oldApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{' '}
													{`От `}
													<span className={notifStyle.LinkToUserByQuestion}>
														<Link
															to={`/profile/${item.initiator?.username}/data`}
														>{`${item.initiator?.fio}`}</Link>
													</span>
													{` поступил вопрос для тех. поддержки`}
												</span>
											</div>
											<div className={notifStyle.GoToQuestion}>
												<Button styling='text' onClick={GoToQuestion}>
													Перейти к вопросу
												</Button>
											</div>
										</li>
									)
								} else if (item.type === 'QUESTION_FOR_MAIN_ADMIN') {
									return (
										<li key={i} className={notifStyle.oldApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{' '}
													{`От `}
													<span className={notifStyle.LinkToUserByQuestion}>
														<Link
															to={`/profile/${item.initiator?.username}/data`}
														>{`${item.initiator?.fio}`}</Link>
													</span>
													{` поступил вопрос для администрации`}
												</span>
											</div>
											<div className={notifStyle.GoToQuestion}>
												<Button styling='text' onClick={GoToQuestion}>
													Перейти к вопросу
												</Button>
											</div>
										</li>
									)
								}
							})}
						</ul>
					) : (
						''
					)}
					{ActiveIndex === 2 ? (
						<ul>
							{ListToShow.map((item, i) => {
								if (item.type === 'INVITE_TO_THE_TEAM') {
									return (
										<li key={i} className={notifStyle.NewApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													<span className={notifStyle.LinkToUserByQuestion}>
														<Link
															to={`/profile/${item.initiator?.username}/data`}
														>{`${item.initiator?.fio}`}</Link>
													</span>
													{` пригласил вас в команду "${
														item.subject?.title || 'Названия команды нет'
													}" ${
														item.role?.title
															? `на роль ${item.role?.title}`
															: ''
													}`}
												</span>
											</div>
											<div className={notifStyle.TwoButton}>
												<button
													onClick={() =>
														setWindowContent(
															<ModalContent
																action='ACCEPTED'
																setWindowContent={setWindowContent}
																applicationType={
																	ApplicationType.INVITE_TO_THE_TEAM
																}
																teamTitle={item.subject?.title}
																applicationid={item.id}
															/>
														)
													}
													className={notifStyle.Accept}
												>
													Принять
												</button>
												<button
													onClick={() =>
														setWindowContent(
															<ModalContent
																action='REJECTED'
																setWindowContent={setWindowContent}
																applicationType={
																	ApplicationType.INVITE_TO_THE_TEAM
																}
																teamTitle={item.subject?.title}
																applicationid={item.id}
															/>
														)
													}
													className={notifStyle.Reject}
												>
													Отклонить
												</button>
											</div>
										</li>
									)
								} else if (item.type === 'REQUEST_TO_THE_TEAM') {
									if (
										item.type === 'REQUEST_TO_THE_TEAM' &&
										roles?.includes('client-main-admin')
									) {
										return (
											<li key={i} className={notifStyle.NewApp}>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.text}>
														<span className={notifStyle.LinkToUserByQuestion}>
															<Link
																to={`/profile/${item.initiator?.username}/data`}
															>{`${item.initiator?.fio}`}</Link>
														</span>{' '}
														{` хочет вступить в команду "${
															item.subject?.title
														}" ${
															item.role?.title
																? `на роль ${item.role?.title}`
																: ''
														} `}
													</span>
												</div>
												<div className={notifStyle.TwoButton}>
													<button
														onClick={() =>
															setWindowContent(
																<ModalContent
																	admin
																	action='ACCEPTED'
																	setWindowContent={setWindowContent}
																	applicationType={
																		ApplicationType.REQUEST_TO_THE_TEAM
																	}
																	fio={item.initiator?.fio}
																	teamTitle={item.subject?.title}
																	applicationid={item.id}
																/>
															)
														}
														className={notifStyle.Accept}
													>
														Принять
													</button>
													<button
														onClick={() =>
															setWindowContent(
																<ModalContent
																	admin
																	action='REJECTED'
																	setWindowContent={setWindowContent}
																	applicationType={
																		ApplicationType.REQUEST_TO_THE_TEAM
																	}
																	fio={item.initiator?.fio}
																	teamTitle={item.subject?.title}
																	applicationid={item.id}
																/>
															)
														}
														className={notifStyle.Reject}
													>
														Отклонить
													</button>
												</div>
											</li>
										)
									}
									return (
										<li key={i} className={notifStyle.NewApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													<span className={notifStyle.LinkToUserByQuestion}>
														<Link
															to={`/profile/${item.initiator?.username}/data`}
														>{`${item.initiator?.fio}`}</Link>
													</span>{' '}
													{`хочет вступить в вашу команду "${
														item.subject?.title
													}" ${
														item.role?.title
															? `на роль ${item.role?.title}`
															: ''
													} `}
												</span>
											</div>
											<div className={notifStyle.TwoButton}>
												<button
													onClick={() =>
														setWindowContent(
															<ModalContent
																action='ACCEPTED'
																setWindowContent={setWindowContent}
																applicationType={
																	ApplicationType.REQUEST_TO_THE_TEAM
																}
																fio={item.initiator?.fio}
																teamTitle={item.subject?.title}
																applicationid={item.id}
															/>
														)
													}
													className={notifStyle.Accept}
												>
													Принять
												</button>
												<button
													onClick={() =>
														setWindowContent(
															<ModalContent
																action='REJECTED'
																setWindowContent={setWindowContent}
																applicationType={
																	ApplicationType.REQUEST_TO_THE_TEAM
																}
																fio={item.initiator?.fio}
																teamTitle={item.subject?.title}
																applicationid={item.id}
															/>
														)
													}
													className={notifStyle.Reject}
												>
													Отклонить
												</button>
											</div>
										</li>
									)
								} else if (item.type === 'MEMBERS_APPLICATION') {
									return (
										<li key={i} className={notifStyle.NewApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{`Команда ${
														item?.subject?.title
															? item?.subject?.title
															: 'без названия'
													} хочет подтвердить список своей команды.`}
													<br />
													<Link
														className='profile-link'
														to={`/team/${item.subject?.id}`}
														state = {{pageIndex: pageIndex, idTeam: item.subject?.id, applicationId: item.id, previousPath: location.pathname}}
														>
														Посмотреть состав команды
													</Link>
													<br />
													{`Кейс: ${
														item?.subject?.topic?.title || 'еще не выбран'
													} `}
												</span>
											</div>
											<div className={notifStyle.TwoButton}>
												<button
													onClick={() =>
														setWindowContent(
															<ModalContent
																admin
																action='ACCEPTED'
																setWindowContent={setWindowContent}
																applicationType={
																	ApplicationType.MEMBERS_APPLICATION
																}
																teamTitle={item.subject?.title}
																applicationid={item.id}
																teamId={item.subject?.id}
															/>
														)
													}
													className={notifStyle.Accept}
												>
													Принять
												</button>
												<button
													onClick={() =>
														setWindowContent(
															<ModalContent
																admin
																action='REJECTED'
																setWindowContent={setWindowContent}
																applicationType={
																	ApplicationType.MEMBERS_APPLICATION
																}
																teamTitle={item.subject?.title}
																applicationid={item.id}
																teamId={item.subject?.id}
															/>
														)
													}
													className={notifStyle.Reject}
												>
													Отклонить
												</button>
											</div>
										</li>
									)
								} else if (item.type === 'TEAMLEAD_APPLICATION') {
									if (item?.subject?.id && item?.recipient) {
										return (
											<li
												key={i}
												className={
													item.status === 'ACCEPTED' ||
													item.status === 'REJECTED'
														? `${notifStyle.oldApp}`
														: `${notifStyle.NewApp}`
												}
											>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.text}>
														<span className={notifStyle.LinkToUserByQuestion}>
															<Link
																to={`/profile/${item.initiator?.username}/data`}
															>{`${item.initiator?.fio}`}</Link>
														</span>{' '}
														{`хочет передать вам роль Team lead'а`}
													</span>
												</div>
												<div className={notifStyle.TwoButton}>
													<button
														onClick={() =>
															setWindowContent(
																<ModalContent
																	action='ACCEPTED'
																	setWindowContent={setWindowContent}
																	applicationType={
																		ApplicationType.TEAMLEAD_APPLICATION
																	}
																	fio={item.initiator?.fio}
																	applicationid={item.id}
																	hackForTeamLeadApplication=''
																/>
															)
														}
														className={notifStyle.Accept}
													>
														Принять
													</button>
													<button
														onClick={() =>
															setWindowContent(
																<ModalContent
																	action='REJECTED'
																	setWindowContent={setWindowContent}
																	applicationType={
																		ApplicationType.TEAMLEAD_APPLICATION
																	}
																	fio={item.initiator?.fio}
																	applicationid={item.id}
																	hackForTeamLeadApplication=''
																/>
															)
														}
														className={notifStyle.Reject}
													>
														Отклонить
													</button>
												</div>
											</li>
										)
									} else if (!item?.subject) {
										return (
											<li
												key={i}
												className={
													item.status === 'ACCEPTED' ||
													item.status === 'REJECTED'
														? `${notifStyle.oldApp}`
														: `${notifStyle.NewApp}`
												}
											>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.text}>
														<span className={notifStyle.LinkToUserByQuestion}>
															<Link
																to={`/profile/${item.initiator?.username}/data`}
															>{`${item.initiator?.fio}`}</Link>
														</span>{' '}
														{`хочет стать тимлидом команды.`}
														<br />
														{`Программа: ${
															item?.program?.title ||
															'программа пока не известна' ||
															'программа пока не известна'
														}`}
													</span>
												</div>
												<div className={notifStyle.TwoButton}>
													<button
														onClick={() =>
															setWindowContent(
																<ModalContent
																	admin
																	action='ACCEPTED'
																	setWindowContent={setWindowContent}
																	applicationType={
																		ApplicationType.TEAMLEAD_APPLICATION
																	}
																	fio={item.initiator?.fio}
																	hackForTeamLeadApplication='approve'
																	applicationid={item.id}
																/>
															)
														}
														className={notifStyle.Accept}
													>
														Принять
													</button>
													<button
														onClick={() =>
															setWindowContent(
																<ModalContent
																	admin
																	action='REJECTED'
																	setWindowContent={setWindowContent}
																	applicationType={
																		ApplicationType.TEAMLEAD_APPLICATION
																	}
																	fio={item.initiator?.fio}
																	hackForTeamLeadApplication='approve'
																	applicationid={item.id}
																/>
															)
														}
														className={notifStyle.Reject}
													>
														Отклонить
													</button>
												</div>
											</li>
										)
									} else if (item?.subject && !item?.recipient) {
										return (
											<li
												key={i}
												className={
													item.status === 'ACCEPTED' ||
													item.status === 'REJECTED'
														? `${notifStyle.oldApp}`
														: `${notifStyle.NewApp}`
												}
											>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.text}>
														<span className={notifStyle.LinkToUserByQuestion}>
															<Link
																to={`/profile/${item.initiator?.username}/data`}
															>{`${item.initiator?.fio}`}</Link>
														</span>{' '}
														{`хочет передать роль Team Lead'а .`}
													</span>
												</div>
												<div className={notifStyle.TwoButton}>
													<button
														onClick={() =>
															setWindowContent(
																<ModalContent
																	admin
																	action='ACCEPTED'
																	setWindowContent={setWindowContent}
																	applicationType={
																		ApplicationType.TEAMLEAD_APPLICATION
																	}
																	fio={item.initiator?.fio}
																	hackForTeamLeadApplication='transfer'
																	applicationid={item.id}
																/>
															)
														}
														className={notifStyle.Accept}
													>
														Принять
													</button>
													<button
														onClick={() =>
															setWindowContent(
																<ModalContent
																	admin
																	action='REJECTED'
																	setWindowContent={setWindowContent}
																	applicationType={
																		ApplicationType.TEAMLEAD_APPLICATION
																	}
																	fio={item.initiator?.fio}
																	hackForTeamLeadApplication='transfer'
																	applicationid={item.id}
																/>
															)
														}
														className={notifStyle.Reject}
													>
														Отклонить
													</button>
												</div>
											</li>
										)
									}
								} else if (item.type === 'QUESTION_FOR_CURATOR') {
									return (
										<li key={i} className={notifStyle.NewApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{' '}
													{`От `}
													<span className={notifStyle.LinkToUserByQuestion}>
														<Link
															to={`/profile/${item.initiator?.username}/data`}
														>{`${item.initiator?.fio}`}</Link>
													</span>
													{` поступил вопрос для куратора`}
												</span>
											</div>
											<div className={notifStyle.GoToQuestion}>
												<Button styling='text' onClick={GoToQuestion}>
													Перейти к вопросу
												</Button>
											</div>
										</li>
									)
								} else if (item.type === 'QUESTION_FOR_SUPPORT') {
									return (
										<li key={i} className={notifStyle.NewApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{' '}
													{`От `}
													<span className={notifStyle.LinkToUserByQuestion}>
														<Link
															to={`/profile/${item.initiator?.username}/data`}
														>{`${item.initiator?.fio}`}</Link>
													</span>
													{` поступил вопрос для тех. поддержки`}
												</span>
											</div>
											<div className={notifStyle.GoToQuestion}>
												<Button styling='text' onClick={GoToQuestion}>
													Перейти к вопросу
												</Button>
											</div>
										</li>
									)
								} else if (item.type === 'QUESTION_FOR_MAIN_ADMIN') {
									return (
										<li key={i} className={notifStyle.NewApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{' '}
													{`От `}
													<span className={notifStyle.LinkToUserByQuestion}>
														<Link
															to={`/profile/${item.initiator?.username}/data`}
														>{`${item.initiator?.fio}`}</Link>
													</span>
													{` поступил вопрос для администрации`}
												</span>
											</div>
											<div className={notifStyle.GoToQuestion}>
												<Button styling='text' onClick={GoToQuestion}>
													Перейти к вопросу
												</Button>
											</div>
										</li>
									)
								}
							})}
						</ul>
					) : (
						''
					)}
				</div>
			)}
		</>
	)
}

export default ViewApplication
