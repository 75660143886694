import AnotherSkillsWrapper from '../../../hooks/components/Questionnaire/UserForm/AnotherSkillsWrapper'
import ContentCard from '../../../hooks/components/common/ContentCard/ContentCard'
import ContentCardHeading from '../../../hooks/components/common/ContentCard/ContentCardHeading'
import ContentCardInner from '../../../hooks/components/common/ContentCard/ContentCardInner'
import ContentCardWrapper from '../../../hooks/components/common/ContentCard/ContentCardWrapper'
import FormLabel from '../../../hooks/components/common/Form/FormLabel'
import FormPanel from '../../../hooks/components/common/Form/FormPanel'

const AnotherSkills = () => {
	return (
		<div className='questionnaire-relevant-skills'>
			<div className='forms-page-counter'>2/3</div>
			<ContentCardWrapper>
				<ContentCard>
					<ContentCardHeading />
					<ContentCardInner>
						<FormPanel>
							<FormLabel
								required
								className='user-form-another-role'
								description='Под "обладанием" навыка понимается готовность применения знаний в проекте сьюминутно на данный момент, с минимальным участием сторонних людей.'
							>
								Какими навыками и/или языками вы обладаете и готовы применить их
								прямо сейчас?
							</FormLabel>

							<AnotherSkillsWrapper />
						</FormPanel>
					</ContentCardInner>
				</ContentCard>
			</ContentCardWrapper>
		</div>
	)
}

export default AnotherSkills
