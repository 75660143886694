import jwt_decode from 'jwt-decode'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TeamDTO } from '../../../@types/createCommand'
import { UserInTeamDTO } from '../../../@types/dtos/UserInTeamDTO'
import { leaveTeam } from '../../../redux/slices/myTeamSlice'
import {
	clientIsTeamLead,
	isInTeam,
	sendJoinToTeamRequest,
	sendLeaveTeam,
	sendRequestToApproveTeam,
	undoApproveTeam,
	updateTeamData,
} from '../../../utils/team'
import { getDecodedToken, getTokens, updateToken } from '../../../utils/token'
import Button from '../common/Button/Button'
import ButtonWrapper from '../common/Button/ButtonWrapper'
import ContentCardFooter from '../common/ContentCard/ContentCardFooter'
import ContentCardInner from '../common/ContentCard/ContentCardInner'
import LoadingText from '../common/Loaders/LoadingText'
import ModalWindow from '../common/ModalWindow/ModalWindow'
import SeparateLine from '../common/SeparateLine/SeparateLine'
import UserListItem from '../common/UserList/UserListItem'

interface SquadAboutProps {
	squad: UserInTeamDTO[] | null
	viewer?: 'teamlead' | 'deputy' | 'member' | 'non-member' | 'admin'
	teamIsFrozen?: boolean
	team?: TeamDTO | null
}

// const REACT_APP_CREATE_COMMAND_PORT = process.env.REACT_APP_CREATE_COMMAND_PORT;

const SquadAbout = ({
	squad,
	viewer = 'non-member',
	teamIsFrozen = false,
	team,
}: SquadAboutProps) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const canSendRequest = squad ? squad.length >= 7 : false
	const [modalWindowContent, setModalWindowContent] =
		useState<React.ReactNode>(null)

	const teamLead =
		squad &&
		squad.find(user => {
			return user.role?.title === 'Team Lead'
		})

	let footer: React.ReactNode

	const confirmLeavingTeam = (
		<>
			<p>Вы уверены, что хотите выйти из команды?</p>
			<ContentCardFooter>
				<ButtonWrapper>
					<Button
						onClick={() => {
							dispatch(leaveTeam())
							const decodedJWT = getDecodedToken()
							sendLeaveTeam(decodedJWT.preferred_username)
								.then(() => {
									updateToken().then(() => {
										console.log(getDecodedToken())

										navigate('/team')
									})
								})
								.catch(console.error)
						}}
					>
						Да
					</Button>
					<Button onClick={() => setModalWindowContent(null)}>
						Нет, я передумал
					</Button>
				</ButtonWrapper>
			</ContentCardFooter>
		</>
	)
	// sendDeleteUserFromTeam(team.id, user.member.id).catch(console.error);

	const isTeamlead = clientIsTeamLead()
	const token: any = jwt_decode(getTokens().access_token)
	const roles = token.resource_access['spring-client'].roles
	const isAdmin = roles.indexOf('client-main-admin') > -1
	const isProgramCurator = roles.indexOf('client-program-curator') > -1

	const CheckRole = () => {
		return (
			roles?.includes('client-main-admin') ||
			roles.includes('client-program-curator') ||
			roles.includes('client-customer')
		)
	}

	const undoApproveTeamCLick = (teamId?: number) => {
		undoApproveTeam(teamId)
		setModalWindowContent(
			<>
				<p>Одобрение команды отменено.</p>
				<br />
				<ButtonWrapper>
					<Button
						onClick={() => {
							setModalWindowContent(null)
						}}
					>
						Закрыть
					</Button>
				</ButtonWrapper>
			</>
		)
	}

	React.useEffect(() => {
		updateTeamData().then().catch(console.error)
		// console.log("getClientRoles", getClientRoles());
	}, [])

	let updViewer: 'teamlead' | 'member' | 'deputy' | 'non-member' | 'admin' =
		isTeamlead ? 'teamlead' : viewer
	if (isAdmin || isProgramCurator) viewer = 'admin'

	switch (viewer) {
		case 'admin':
		case 'teamlead':
		case 'deputy':
			footer = (
				<>
					<ButtonWrapper direction='column'>
						{!CheckRole() ? (
							<Button
								action={'link'}
								styling={'text'}
								to='../student-list'
								// disabled={!canSendRequest}
							>
								Найти новых участников команды
							</Button>
						) : null}

						{!CheckRole() ? (
							<Button
								disabled={!canSendRequest}
								onClick={() => {
									sendRequestToApproveTeam()
										.then(() =>
											setModalWindowContent(
												<>
													<p>
														Заявка на утверждение администрации успешно
														отправлена.
													</p>
													<ButtonWrapper>
														<Button
															onClick={() => {
																setModalWindowContent(null)
															}}
														>
															Закрыть
														</Button>
													</ButtonWrapper>
												</>
											)
										)
										// .then(() => navigate("/team"))
										.catch(err => {
											setModalWindowContent(
												<>
													<p>
														{err.response.data.message === 'Заявка уже создана'
															? 'Вы уже подали заявку на утверждение команды.'
															: 'Произошла ошибка.'}
													</p>
													<ButtonWrapper>
														<Button
															onClick={() => {
																setModalWindowContent(null)
															}}
														>
															Закрыть
														</Button>
													</ButtonWrapper>
												</>
											)
											console.error(err)
										})
								}}
							>
								Подать заявку на утверждение администрации
							</Button>
						) : null}
						{!CheckRole() ? (
							<Button
								onClick={() => {
									setModalWindowContent(confirmLeavingTeam)
								}}
								disabled={
									(!!squad &&
										(squad.length > 1
											? !squad.find(user => user.isDeputy)
											: squad.length == 1
											? false
											: true)) ||
									viewer == 'deputy'
								}
							>
								Покинуть команду
							</Button>
						) : null}
						{roles?.includes('client-main-admin') &&
						team?.adminApplication.status === 'ACCEPTED' ? (
							<Button onClick={() => undoApproveTeamCLick(team?.id)}>
								Отменить одобрение команды
							</Button>
						) : null}
					</ButtonWrapper>
				</>
			)
			break
		case 'member':
			footer = (
				<ButtonWrapper>
					<Button
						styling='text'
						onClick={() => {
							setModalWindowContent(confirmLeavingTeam)
						}}
					>
						Покинуть команду
					</Button>
				</ButtonWrapper>
			)
			break
		case 'non-member':
			footer = !isInTeam() ? (
				<ButtonWrapper>
					<Button
						onClick={() => {
							teamLead &&
								sendJoinToTeamRequest(teamLead.member.id)
									.then(res => {
										setModalWindowContent(
											<>
												<p>Вы подали заявку на вступление в команду.</p>
												<ContentCardFooter>
													<ButtonWrapper>
														<Button onClick={() => setModalWindowContent(null)}>
															Хорошо
														</Button>
													</ButtonWrapper>
												</ContentCardFooter>
											</>
										)
									})
									.catch(err => {
										console.error(err)
										setModalWindowContent(
											<>
												<p>
													{err.response.data.message === 'Такая заявка уже есть'
														? 'Вы уже подали заявку на вступление в команду'
														: 'Произошла ошибка, попробуйте позже.'}
												</p>
												<ContentCardFooter>
													<ButtonWrapper>
														<Button onClick={() => setModalWindowContent(null)}>
															Назад
														</Button>
													</ButtonWrapper>
												</ContentCardFooter>
											</>
										)
									})
						}}
					>
						Подать заявку на вступление
					</Button>
				</ButtonWrapper>
			) : null
			break
		default:
			footer = null
			break
	}

	return (
		<>
			<ModalWindow visibility={!!modalWindowContent}>
				{modalWindowContent}
			</ModalWindow>

			<ContentCardInner>
				{squad ? (
					<div className='user-list'>
						<UserListItem user={teamLead!} role highlighted />

						<SeparateLine />

						{squad
							.filter(user => user.role?.title !== 'Team Lead')
							.map((user, i) => (
								<UserListItem key={i} user={user} role>
									{user.isDeputy && <strong> Заместитель</strong>}
								</UserListItem>
							))}
					</div>
				) : (
					<LoadingText />
				)}
			</ContentCardInner>
			{footer && !teamIsFrozen && (
				<ContentCardFooter>{footer}</ContentCardFooter>
			)}
		</>
	)
}

export default SquadAbout
