import { useEffect, useState } from 'react'
import { TeamDTO } from '../../../@types/createCommand'
import { AcademicGroup } from '../../../@types/entities/AcademicGroup'
import { Company } from '../../../@types/entities/Company'
import { DPPGroup } from '../../../@types/entities/DPPGroup'
import {
	CREATE_COMMAND_MICROSERVICE_URL,
	MODERATION_MICROSERVICE_URL,
	PERSONAL_ACCOUNT_MICROSERVICE,
	getTeamList,
	sendAxiosWithTokens,
} from '../../../utils/team'
import { getDecodedToken } from '../../../utils/token'
import SingleSelect from '../SingleSelect'
import Button from '../common/Button/Button'
import ButtonWrapper from '../common/Button/ButtonWrapper'
import ContainerMain from '../common/ContainerMain/ContainerMain'
import ContentCard from '../common/ContentCard/ContentCard'
import ContentCardHeading from '../common/ContentCard/ContentCardHeading'
import ContentCardInner from '../common/ContentCard/ContentCardInner'
import ContentCardWrapper from '../common/ContentCard/ContentCardWrapper'
import DoubleList from '../common/DoubleList/DoubleList'
import AllTeamsList from './AllTeamsList'

type SelectType = { valueName: string; valueId: number; label: string }

const AllTeams = ({
	teamsFromExtendedSearch = [],
}: {
	teamsFromExtendedSearch?: TeamDTO[]
}) => {
	const [teams, setTeams] = useState<TeamDTO[] | null>(null)
	const [extendedSearchIsOn, setExtendedSearchIsOn] = useState(false)

	const [dppGroups, setDPPGroups] = useState<SelectType[]>([])
	const [academicGroups, setAcademicGroups] = useState<SelectType[]>([])
	const [companies, setCompanies] = useState<SelectType[]>([])
	const facults: SelectType[] = new Array(13).fill('Факультет ').map((x, i) => {
		const fac = 'Факультет ' + (i + 1)
		return { valueName: fac, valueId: i + 1, label: fac }
	})

	const [extendedSearchParams, setExtendedSearchParams] = useState<{
		dppGroup: number
		academicGroup: number
		company: number
		faculty: number
	}>({
		dppGroup: 0,
		academicGroup: 0,
		company: 0,
		faculty: 0,
	})

	const [isLoading, setIsLoading] = useState<boolean>(true)
	const fetchDefaultTeams = async () => {
		try {
			const username = getDecodedToken().preferred_username

			const getProgramId = await sendAxiosWithTokens(
				'get',
				`${PERSONAL_ACCOUNT_MICROSERVICE}/api/v1/personal_account/program/${username}`
			)
			const programId = getProgramId.data.data

			const teamsGetResponse = await sendAxiosWithTokens(
				'get',
				`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/team/all/${programId}`
			)
			return teamsGetResponse.data.data
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		getTeamList().then(teams => {
			setTeams(teams)
			console.log(teams)
			setTeams(teams)
		})

		console.log('teams:', teams)

		const fetchData = async () => {
			setIsLoading(true)
			try {
				const requests = [
					sendAxiosWithTokens(
						'get',
						`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/group-selector?is_dpp=true`
					),
					sendAxiosWithTokens(
						'get',
						`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/group-selector?is_academic=true`
					),
					sendAxiosWithTokens(
						'get',
						`${MODERATION_MICROSERVICE_URL}/api/v1/company`
					),
				]

				Promise.all(requests).then(
					([dppResponse, academicResponse, companyResponse]) => {
						const dppData: SelectType[] = []
						dppResponse.data.data.forEach((item: DPPGroup) => {
							dppData.push({
								valueName: item.title,
								valueId: item.id,
								label: item.title,
							} as SelectType)
						})
						setDPPGroups(dppData)

						const academicData: SelectType[] = []
						academicResponse.data.data.forEach(
							(item: AcademicGroup & { title: string }) => {
								academicData.push({
									valueName: item.title,
									valueId: item.id,
									label: item.title,
								})
							}
						)
						setAcademicGroups(academicData)

						const companyData: SelectType[] = []
						companyResponse.data.data.forEach((item: Company) => {
							companyData.push({
								valueName: item.title,
								valueId: item.id,
								label: item.title,
							})
						})
						setCompanies(companyData)
					}
				)
			} catch (error) {
				console.error('Error fetching data', error)
			}
			setIsLoading(false)
		}

		fetchData().catch(console.error)

		fetchDefaultTeams().then(setTeams).catch(console.error)
	}, [])

	const findTeams = () => {
		const queryList = []

		if (extendedSearchParams['dppGroup'] != 0) {
			queryList.push(`dpp_group_id=${extendedSearchParams['dppGroup']}`)
		}
		if (extendedSearchParams['academicGroup'] != 0) {
			queryList.push(
				`academic_group_id=${extendedSearchParams['academicGroup']}&`
			)
		}
		if (extendedSearchParams['company'] != 0) {
			queryList.push(`company_id=${extendedSearchParams['company']}&`)
		}
		if (extendedSearchParams['faculty'] != 0) {
			queryList.push(`facultyNumber=${extendedSearchParams['faculty']}`)
		}

		let queryURL = `?${queryList.join('&')}`

		console.log('queryURL', queryURL)

		setIsLoading(true)

		sendAxiosWithTokens(
			'get',
			`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/extend-search/teams${queryURL}`
		)
			.then(r => {
				setTeams(r.data.data)
				setIsLoading(false)
			})
			.catch(console.error)
	}

	const clearQueryFields = () => {
		setExtendedSearchParams({
			dppGroup: 0,
			academicGroup: 0,
			company: 0,
			faculty: 0,
		})
	}

	const extendedSearch = (
		<>
			<div className='extended-search'>
				<DoubleList
					listData={[
						{
							identifier: 'Группа ДПП (одного из участников):',
							content: (
								<SingleSelect
									options={dppGroups}
									infobefore={'ewew'}
									onChangeValue={(valueName: string, valueID: number) => {
										setExtendedSearchParams({
											...extendedSearchParams,
											dppGroup: valueID,
										})
									}}
									//   {...register("company", {required: true})}
								/>
							),
						},
						{
							identifier: 'Академическая Группа (одного из участников):',
							content: (
								<SingleSelect
									options={academicGroups}
									infobefore={'ewew'}
									onChangeValue={(valueName: string, valueID: number) => {
										setExtendedSearchParams({
											...extendedSearchParams,
											academicGroup: valueID,
										})
									}}
								/>
							),
						},
						{
							identifier: 'Факультет:',
							content: (
								<SingleSelect
									options={facults}
									infobefore={'ewew'}
									onChangeValue={(valueName: string, valueID: number) => {
										setExtendedSearchParams({
											...extendedSearchParams,
											faculty: valueID,
										})
									}}
								/>
							),
						},
						{
							identifier: 'Компания, которую выбрали:',
							content: (
								<SingleSelect
									options={companies}
									infobefore={'ewew'}
									onChangeValue={(valueName: string, valueID: number) => {
										setExtendedSearchParams({
											...extendedSearchParams,
											company: valueID,
										})
									}}
								/>
							),
						},
					]}
				/>
				<ButtonWrapper spacing>
					<Button onClick={() => findTeams()}>Найти</Button>
					{/* <Button onClick={() => clearQueryFields()}>Очистить поля</Button> */}
				</ButtonWrapper>
			</div>
		</>
	)

	// console.log(dppGroups, academicGroups, facults, companies);

	return (
		<ContainerMain>
			<ContentCardWrapper>
				<ContentCard low>
					<div className='text-mobile'>
						Для облегчения поиска мы решили сделать список рекомендаций, в
						котором каждый из вас сможет найти себе команду в зависимости от
						вашей предпочтительной роли, указанной в личном кабинете. Вы также
						можете воспользоваться расширенным поиском, который поможет найти
						любого пользователя и команду по названию или фильтрам
					</div>
					<div className='text-desktop'>
						Для облегчения поиска мы решили сделать список рекомендаций, в
						котором каждый из вас сможет найти себе команду в зависимости от
						вашей предпочтительной роли, указанной в личном кабинете. Вы также
						можете воспользоваться расширенным поиском, который поможет найти
						любого пользователя и команду по названию или фильтрам
					</div>
				</ContentCard>
				<ContentCard>
					<ContentCardHeading topic='Команды в активном поиске'>
						<Button
							type={'button'}
							onClick={() => {
								if (extendedSearchIsOn) {
									clearQueryFields()
									fetchDefaultTeams().then(setTeams).catch(console.error)
								}
								setExtendedSearchIsOn(!extendedSearchIsOn)
							}}
						>
							{extendedSearchIsOn
								? 'Скрыть расширенный поиск'
								: 'Расширенный поиск'}
						</Button>
					</ContentCardHeading>
					<ContentCardInner>
						{extendedSearchIsOn && extendedSearch}
						<div className='table-wrapper'>
							<AllTeamsList teams={teams} isLoading={isLoading} />
						</div>
					</ContentCardInner>
				</ContentCard>
			</ContentCardWrapper>
		</ContainerMain>
	)
}

export default AllTeams
