import { Link } from 'react-router-dom'
import GetUserMainInformationDTO from '../../../../@types/dtos/GetUserMainInformationDTO'
import { UserRole } from '../../../../@types/entities/UserRole'
import ContentCard from '../../common/ContentCard/ContentCard'
import ContentCardHeading from '../../common/ContentCard/ContentCardHeading'
import ContentCardInner from '../../common/ContentCard/ContentCardInner'
import DoubleList from '../../common/DoubleList/DoubleList'

type AdditionalInfoProps = {
	userData: GetUserMainInformationDTO | undefined
}

export const AdditionalInfo = ({ userData }: AdditionalInfoProps) => {
	const teamContent = userData?.teamTitle ? userData?.teamTitle : 'Команды нет'
	const teamId = userData?.teamId
	let educationLevel = ''
	const groupNumber = userData?.academicGroup
	if (groupNumber) {
		const groupParts = groupNumber.split('-')
		const secondWord = groupParts[1]
		if (secondWord.includes('М')) {
			educationLevel = 'Магистратура'
		} else if (secondWord.includes('С')) {
			educationLevel = 'Специалитет'
		} else if (secondWord.includes('Б')) {
			educationLevel = 'Бакалавриат'
		}
	}

	return (
		<ContentCard>
			<ContentCardHeading topic='Допонительная информация' />
			<ContentCardInner>
				{userData?.role == UserRole.MAIN_ADMIN ||
				userData?.role == UserRole.PROGRAM_CURATOR ||
				userData?.role == UserRole.CUSTOMER ||
				userData?.role == UserRole.CURATOR ? (
					<DoubleList
						className={'additionals'}
						listData={[
							{
								identifier: 'Почта МАИ',
								content: userData?.maiemail,
							},
							{
								identifier: 'Номер телефона',
								content: userData?.phoneNumber,
							},
							{
								identifier: 'Telegram',
								content: userData?.telegram_link,
							},
						]}
					/>
				) : userData?.role == UserRole.STUDENT ? (
					<DoubleList
						className={'additionals'}
						listData={[
							// {
							//     identifier: "Номер группы ДПП",
							//     content: userData?.DPPGroup,
							// },
							{
								identifier: 'Наименование ДПП ПП',
								content: userData?.programTitle,
							},
							{
								identifier: 'Руководитель программы',
								content: userData?.programCurator,
							},
							{
								identifier: 'Уровень образования',
								content: educationLevel,
							},
							{
								identifier: 'Номер группы',
								content: userData?.academicGroup,
							},
							{
								identifier: 'Команда',
								content:
									teamContent !== 'Команды нет' ? (
										<Link className='profile-link' to={`/team/${teamId}`}>
											{teamContent}
										</Link>
									) : (
										teamContent
									),
							},
						]}
					/>
				) : (
					''
				)}
			</ContentCardInner>
		</ContentCard>
	)
}
