import jwt_decode from 'jwt-decode'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from '../../../../redux/store'
import { getTokens, isTokenInLS } from '../../../../utils/token'
import DoubleList from '../../common/DoubleList/DoubleList'

const AdditionalsList = () => {
	const { profile }: any = useSelector(
		(state: RootState) => state.personalAccount
	)
	let admin: boolean = false
	let student: boolean = false
	let customer: boolean = false
	let curator: boolean = false
	let programCurator: boolean = false
	let pervak: boolean = false

	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		const isAdmin = roles.indexOf('client-main-admin') > -1
		if (isAdmin) admin = isAdmin
		const isCurator = roles.indexOf('client-curator') > -1
		if (isCurator) curator = isCurator
		const isStudent = roles.indexOf('client-student') > -1
		if (isStudent) student = isStudent
		const isCustomer = roles.indexOf('client-customer') > -1
		if (isCustomer) customer = isCustomer
		const isProgramCurator = roles.indexOf('client-program-curator') > -1
		if (isProgramCurator) programCurator = isProgramCurator
		pervak = roles.indexOf('client-pervak') > -1
	}

	const teamContent = profile.Mydata.teamTitle
		? profile.Mydata.teamTitle
		: 'Команды нет'
	const teamId: number = profile.Mydata.teamId
	let educationLevel = ''
	const groupNumber = profile.Mydata.academicGroup
	if (groupNumber) {
		const groupParts = groupNumber.split('-')
		const secondWord = groupParts[1]
		if (secondWord.includes('М')) {
			educationLevel = 'Магистратура'
		} else if (secondWord.includes('С')) {
			educationLevel = 'Специалитет'
		} else if (secondWord.includes('Б')) {
			educationLevel = 'Бакалавриат'
		}
	}

	return (
		<>
			{admin || curator || programCurator ? (
				<DoubleList
					className={'additionals'}
					listData={[
						{
							identifier: 'Почта',
							content: profile.Mydata.email,
						},
						{
							identifier: 'Номер телефона',
							content: profile.Mydata.phoneNumber,
						},
						{
							identifier: 'Telegram',
							content: profile.Mydata.telegram_link,
						},
					]}
				/>
			) : customer ? (
				<DoubleList
					className={'additionals'}
					listData={[
						{
							identifier: 'Почта',
							content: profile.Mydata.email,
						},
						{
							identifier: 'Номер телефона',
							content: profile.Mydata.phoneNumber,
						},
						{
							identifier: 'Telegram',
							content: profile.Mydata.telegram_link,
						},
						{
							identifier: 'Команда',
							content: teamContent,
						},
					]}
				/>
			) : student ? (
				<DoubleList
					className={'additionals'}
					listData={
						!pervak
							? [
									// {
									//     identifier: "Номер группы ДПП",
									//     content: profile.Mydata.dppgroup,
									// },
									{
										identifier: !pervak ? 'Наименование ДПП ПП' : 'Предмет',
										content: profile.Mydata.programTitle,
									},
									{
										identifier: !pervak
											? 'Руководитель программы'
											: 'Руководитель предмета',
										content: profile.Mydata.programCurator,
									},
									{
										identifier: 'Уровень образования',
										content: educationLevel,
									},
									{
										identifier: 'Номер группы',
										content: profile.Mydata.academicGroup,
									},
									{
										identifier: 'Команда',
										content:
											teamContent !== 'Команды нет' ? (
												<Link className='profile-link' to={`/team/${teamId}`}>
													{teamContent}
												</Link>
											) : (
												teamContent
											),
										// ,
									},
							  ]
							: [
									{
										identifier: !pervak ? 'Наименование ДПП ПП' : 'Предмет',
										content: profile.Mydata.programTitle,
									},
									{
										identifier: !pervak
											? 'Руководитель программы'
											: 'Руководитель предмета',
										content: profile.Mydata.programCurator,
									},
									{
										identifier: 'Уровень образования',
										content: educationLevel,
									},
									{
										identifier: 'Номер группы',
										content: profile.Mydata.academicGroup,
									},
									{
										identifier: 'Команда',
										content: teamContent,
									},
							  ]
					}
				/>
			) : (
				''
			)}
		</>
	)
}

export default AdditionalsList
