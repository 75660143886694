import axios from 'axios'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import InviteLink from '../../../assets/styles/components/Team/InviteLink.module.scss'
import { SetLink } from '../../../redux/slices/InviteLinkInTeamSlice'
import { RootState } from '../../../redux/store'
import { CREATE_COMMAND_MICROSERVICE_URL } from '../../../utils/team'
import {
	TokenType,
	checkIsTokenExpired,
	updateToken,
} from '../../../utils/token'
import Button from '../common/Button/Button'
import ButtonWrapper from '../common/Button/ButtonWrapper'

const GetInviteLink = ({ setvisibility }: { setvisibility: Function }) => {
	const { Link } = useSelector((state: RootState) => state.InviteLinkInTeam)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const teamID = useSelector((state: RootState) => state.myTeam?.team?.id)

	const CreateLinkForInvite = async () => {
		const tokens: TokenType = JSON.parse(window.localStorage.getItem('token')!)
		if (checkIsTokenExpired(tokens.access_token)) {
			if (checkIsTokenExpired(tokens.refresh_token)) {
				navigate('/')
			}
			updateToken()
		}
		if (tokens?.access_token) {
			await axios
				.post(
					`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/link/${teamID}`,
					{},
					{
						headers: {
							Authorization: `Bearer ${tokens.access_token}`,
						},
					}
				)
				.then(response => {
					dispatch(SetLink(response.data.data))
				})
				.catch(error => {
					// dispatch(setOpenModalGetLink(!OpenModalGetLink));
					if (error.response.status === 404) {
						navigate('*')
						console.log(error)
					} else {
						navigate('/AnyError')
						console.log(error)
					}
				})
		}
	}

	useEffect(() => {
		CreateLinkForInvite()
	}, [])

	return (
		<form className={InviteLink.content}>
			<h2>Реферальная ссылка для вступления в вашу команду:</h2>
			<p className={InviteLink.Link} id='invite-link-to-copy'>
				{Link}
			</p>
			<h3>
				Эту ссылку необходимо передать студенту, которого вы хотите пригласить в
				команду
			</h3>
			<ButtonWrapper>
				<Button action='toCopy' copyId='#invite-link-to-copy' copyText={Link}>
					Скопировать
				</Button>
				<Button onClick={() => setvisibility(false)}>Закрыть</Button>
			</ButtonWrapper>
		</form>
	)
}

export default GetInviteLink
