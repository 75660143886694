import { Cell } from 'react-table'
import { TeamDTO } from '../../../@types/createCommand'
import Button from '../common/Button/Button'
import FoldedText from '../common/FoldedText/FoldedText'
import LoadingText from '../common/Loaders/LoadingText'
import Table from '../common/Tables/TableFilterAndPagination'

const log = console.log

const AllTeamsList = ({
	teams = [],
	isLoading = true,
}: {
	teams: TeamDTO[] | null
	isLoading?: boolean
}) => {
	const COLUMNS = [
		{
			Header: '№',
			Cell: ({ row }: { row: any }) => <span>{row.index + 1}</span>,
		},
		{
			Header: 'Название команды',
			accessor: 'title',
		},
		{
			Header: 'Количество человек',
			accessor: 'studentsCount',
		},
		{
			Header: 'Описание команды',
			accessor: 'description',
			Cell: ({ value }: { value: any }) => (
				<FoldedText buttonCenter textCenter>
					{value}
				</FoldedText>
			),
		},
		{
			Header: 'Просмотр',
			accessor: 'id',
			Cell: ({ value }: Cell<TeamDTO>) => (
				<Button styling='text' action='link' to={`../${value}`}>
					<div className='link-table-teams'>перейти</div>
				</Button>
			),
		},
	]

	return (
		<>
			{!isLoading && teams ? (
				<Table
					columns={COLUMNS}
					data={teams}
					itemsOnPage={10}
					visiblePaginationRange={2}
					className='team-select-table'
				/>
			) : (
				<LoadingText center />
			)}
		</>
	)
}

export default AllTeamsList
