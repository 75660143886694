import React, { useState } from "react";
import FormLabel from "./FormLabel";

interface FormInputProps {
    children?: React.ReactNode;
    label: string;
    type?: "text" | "password" | "tel" | "email" | "date";
    name?: string;
    value?: string;
    onChange?: Function;
    required?: boolean;
    inputDescription?: string;
    labelDescription?: string;
    pattern?: RegExp
}

export const makeProps = {
    forInput(name: string, values: any, onChange: React.ChangeEventHandler) {
        return { name, value: values[name], onChange };
    },
};

const FormInput = ({
    label,
    required,
    type = "text",
    name = "",
    onChange = () => {},
    value = "",
    inputDescription = "",
    labelDescription,
    pattern
}: FormInputProps) => {
    const handleChange = (event: React.FormEvent) => {
        if (onChange) onChange(event);
        
    };

    const [notValid, setNotValid] = useState(false);

    const handleOnBlur = ({ target }: any) => {
        if (required && target.value === "") {
            setNotValid(true);
        } else if (pattern && !pattern.test(target.value)) {
            setNotValid(true);
        } else {
            setNotValid(false);
        }
    };

    return (
        <div className="form-field">
            <FormLabel htmlFor={name} required={required === true} description={labelDescription}>
                <div className="other">
                    {label}
                </div>
            </FormLabel>
            <div className="form-field-input-wrapper">
                <input
                    className={
                        "form-field-input " +
                        (Boolean(notValid) ? "form-field-input--not-valid " : "")
                    }
                    type={type}
                    name={name}
                    id={name}
                    value={value}
                    onChange={handleChange}
                    onBlur={handleOnBlur}
                />
                {inputDescription && (
                    <span className="form-field-input-description">{inputDescription}</span>
                )}
                {!!notValid ? <p className="form-field-input--error-message">Некорректный формат ввода</p> : ''}
            </div>
        </div>
    );
};

export default FormInput;
