import { initTeam } from '../../../redux/slices/myTeamSlice'
import Button from '../common/Button/Button'
import ContainerMain from '../common/ContainerMain/ContainerMain'
import ContentCard from '../common/ContentCard/ContentCard'
import ContentCardFooter from '../common/ContentCard/ContentCardFooter'
import ContentCardInner from '../common/ContentCard/ContentCardInner'
import ContentCardWrapper from '../common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../common/ContentMainTheme'

const Request = () => {
	return (
		<ContainerMain>
			<ContentMainTheme>Заявка на тимлидерство</ContentMainTheme>
			<ContentCardWrapper>
				<ContentCard>
					<ContentCardInner>
						<p>
							Ваша заявка отправлена администрации на согласование. Ожидайте.
							Руководитель программы свяжется с вами.
						</p>
					</ContentCardInner>
					<ContentCardFooter>
						<Button
							action='link'
							to='/PersonalAccount/MyData'
							onClick={() => initTeam()}
						>
							Ок
						</Button>
					</ContentCardFooter>
				</ContentCard>
			</ContentCardWrapper>
		</ContainerMain>
	)
}

export default Request
