import ContentCardWrapper from "../../../common/ContentCard/ContentCardWrapper";
import ContentCard from "../../../common/ContentCard/ContentCard";
import ContentCardHeading from "../../../common/ContentCard/ContentCardHeading";
import ContentCardInner from "../../../common/ContentCard/ContentCardInner";
import MainWrapper from "./MainWrapper";

const AdminMainWrapper = () => {
   

    return (
        <>
            
            {window.location.pathname.split("/").at(-1) === "main" && (
                <h1 className="questionnare-fill-invitation">
                    Для продолжения заполните следующую форму:
                </h1>
            )}
            <div className="questionnaire-user-info">
                <ContentCardWrapper>
                    <ContentCard>
                        <ContentCardHeading subtopic="Укажите свои данные" />
                        <ContentCardInner>
                            <MainWrapper />
                        </ContentCardInner>
                    </ContentCard>
                </ContentCardWrapper>
            </div>
        </>
    );
};

export default AdminMainWrapper;
