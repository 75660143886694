import { useDispatch, useSelector } from 'react-redux'

import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as ImgMyData } from '../../../../assets/img/icons/pin1.svg'
import { ReactComponent as ImgSkills } from '../../../../assets/img/icons/pin2.svg'
import { setActiveIndex } from '../../../../redux/slices/activeListSlice'
import useMatchRoute from '../../../../utils/useMatchRoute'

export default function ViewedUserActiveList({}) {
	const activeIndex = useSelector(state => state.activeIndexReducer.activeIndex)

	const location = useLocation()
	const dispatch = useDispatch()
	const matches = [useMatchRoute('data'), useMatchRoute('Skills')]
	// console.log(testMatch);

	const categories = [`Данные пользователя`, `Навыки`]

	const images = [<ImgMyData />, <ImgSkills />]

	const Links = [`data`, `Skills`]

	const OnClickCategory = index => {
		dispatch(setActiveIndex(index))
	}

	return (
		<ul>
			{categories.map((value, i) => (
				<li
					onClick={() => OnClickCategory(i)}
					className={matches[i] ? `active` : ``}
					key={i}
				>
					<Link to={Links[i]}>
						<style></style>
						<div className='link-wrapper'>
							{images[i]}
							<span className='list-text'> {value} </span>
						</div>
					</Link>
				</li>
			))}
		</ul>
	)
}
