import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { isTokenInLS } from "./token";
import { setOpenOrCloseSideBar } from "../redux/slices/OpenSideBarSlice";
import { Navigate } from "react-router-dom";
interface ComponentProps {
    component: React.ElementType;
}

const ProtectedElementForRoute = ({ component: Component }: ComponentProps) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isTokenInLS()) dispatch(setOpenOrCloseSideBar(false));
    }, []);

    return isTokenInLS() ? React.createElement(Component) : <Navigate to="/" replace />;
};
export default ProtectedElementForRoute;
