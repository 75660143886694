import { configureStore } from '@reduxjs/toolkit'

import { api } from './api/api'
import AdminInfoReducer from './slices/AdminInfoSlice'
import CompanyInfoReducer from './slices/CompanyInfoSlice'
import CustomerInfoReducer from './slices/CustomerInfoSlice'
import InviteLinkInTeamReducer from './slices/InviteLinkInTeamSlice'
import OpenSideBarReducer from './slices/OpenSideBarSlice'
import TeamLeadInfoReducer from './slices/TeamLeadInfoSlice'
import activeIndexReducer from './slices/activeListSlice'
import allTeamListReducer from './slices/allTeamListSlice'
import allUserListReducer from './slices/allUserListSlice'
import caseReducer from './slices/caseSlice'
import companyPasportSlice from './slices/companyPasportSlice'
import companyReducer from './slices/companySlice'
import createTeamReducer from './slices/createTeamSlice'
import currentPageReducer from './slices/currentPageReducer'
import formReducer from './slices/formSlice'
import helpReducer from './slices/helpSlice'
import myTeamReducer from './slices/myTeamSlice'
import NotifcationReducer from './slices/notificationSlice'
import paReducer from './slices/paSlice'
import tableReducer from './slices/tableSlice'
import userQuestionnaireReducer from './slices/userQuestionnaireSlice'

export const store = configureStore({
	reducer: {
		formReducer,
		tables: tableReducer,
		personalAccount: paReducer,
		activeIndexReducer,
		currentPage: currentPageReducer,
		userQuestionnaire: userQuestionnaireReducer,
		AdminInfoReducer,
		TeamLeadInfoReducer,
		CompanyInfoReducer,
		NotifcationReducer,
		CustomerInfoReducer,
		myTeam: myTeamReducer,
		InfoCompany: caseReducer,
		Cases: caseReducer,
		allUserList: allUserListReducer,
		allTeamList: allTeamListReducer,
		[api.reducerPath]: api.reducer,
		createTeam: createTeamReducer,
		help: helpReducer,
		company: companyReducer,
		OpenSideBar: OpenSideBarReducer,
		InviteLinkInTeam: InviteLinkInTeamReducer,
		companyPasportSlice,
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(api.middleware),
})

export type RootState = ReturnType<typeof store.getState>
