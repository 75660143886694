import { useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '../../../../hooks/components/common/Button/Button'
import ContentCard from '../../../../hooks/components/common/ContentCard/ContentCard'
import DoubleList from '../../../../hooks/components/common/DoubleList/DoubleList'
import { RootState } from '../../../../redux/store'
import PasportDescrEdit1of2 from '../PasportDescr/PasportDescrEdit1of2'
interface typeProps {
	swapPage: Function
	caseId?: number
	admin: boolean
}

const PasportDescription1of2 = ({ swapPage, caseId, admin }: typeProps) => {
	const { PasportCase } = useSelector(
		(state: RootState) => state.companyPasportSlice
	)
	const [pasportDescrEditing, setpasportDescrEditing] = useState<boolean>(false)

	const PasportDescrEditing = {
		status: pasportDescrEditing,
		toggle: () => setpasportDescrEditing(!pasportDescrEditing),
		content: (
			<PasportDescrEdit1of2
				caseId={caseId}
				toggle={() => setpasportDescrEditing(!pasportDescrEditing)}
			/>
		),
	}
	const scrollToTop = () => {
		const middleY = window.innerHeight / 2
		window.scrollTo({
			top: middleY,
			behavior: 'smooth', // плавная прокрутка
		})
	}
	const onNext = () => {
		swapPage(2)
		scrollToTop()
	}
	return (
		<>
			<ContentCard
				topic='1/2'
				blured
				myEditButton
				editing={admin ? PasportDescrEditing : undefined}
			>
				<DoubleList
					className='initials'
					listData={[
						{
							identifier:
								'Тип продукта (сервис, продукт, алгоритм, модель и т.п.',
							content: PasportCase?.projectType,
						},
						{
							identifier: 'Цель проекта',
							content: PasportCase?.projectGoal,
						},
						{
							identifier: 'Задачи проекта',
							content: PasportCase?.projectTasks,
						},
						{
							identifier: 'Результат проекта',
							content: PasportCase?.projectResult,
						},
						{
							identifier:
								'Необходимые ресурсы для выполнения проекта (источник данных)',
							content: PasportCase?.projectResources,
						},
					]}
				/>
				<div className='company-Go-To-Back-Button-In-Info'>
					<Button onClick={onNext}>Далее</Button>
				</div>
			</ContentCard>
		</>
	)
}

export default PasportDescription1of2
