import axios, { AxiosError } from 'axios'
import jwt_decode from 'jwt-decode'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../../hooks/components/common/Button/Button'
import ContentCard from '../../hooks/components/common/ContentCard/ContentCard'
import ContentCardWrapper from '../../hooks/components/common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../hooks/components/common/ContentMainTheme'
import { APPLICATION_MICROSERVICE_URL } from '../../utils/team'
import { checkIsTokenExpired, getTokens, isTokenInLS } from '../../utils/token'
import Comp from './Comp'
import Modal from './Modal'

type TokenType = {
	access_token: string
	refresh_token: string
}

const AskQuestionForm = () => {
	const [selectedCategory, setSelectedCategory] = useState('')
	const [questionContent, setQuestionContent] = useState('')
	const [answerContent, setAnswerContent] = useState('')

	let admin: boolean = false
	let student: boolean = false

	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		const isAdmin = roles.indexOf('client-admin') > -1
		if (isAdmin) admin = isAdmin
		const isStudent = roles.indexOf('client-student') > -1
		const isTeamLead = roles.indexOf('client-teamlead') > -1
	}
	const navigate = useNavigate()
	const [modalActive, setModalActive] = useState(false)

	const onSelect = ({ target }: any) => {
		setSelectedCategory(target.value)
	}

	const targetList = [
		'Вопрос руководителю программы',
		'Вопрос технической поддержке',
	]

	const handleSendQuestion = async () => {
		const tokens: TokenType = JSON.parse(window.localStorage.getItem('token')!)
		if (checkIsTokenExpired(tokens.access_token)) {
			if (checkIsTokenExpired(tokens.refresh_token)) {
				window.localStorage.removeItem('token')
				navigate('/')
			}
		}

		if (tokens && tokens.access_token) {
			try {
				let questionType =
					selectedCategory === 'Вопрос руководителю программы'
						? 'QUESTION_FOR_CURATOR'
						: selectedCategory === 'Вопрос технической поддержке'
						? 'QUESTION_FOR_SUPPORT'
						: ''
				if (!admin && !questionType) {
					console.error('Invalid question type:', selectedCategory)
					return
				}
				const payload = admin
					? {
							content: questionContent,
							feedback: answerContent,
					  }
					: {
							content: questionContent,
							type: questionType,
					  }
				const response = await axios.put(
					`${APPLICATION_MICROSERVICE_URL}/help/${
						admin ? `faq` : `questions`
					}/`,
					payload,
					{
						headers: {
							Authorization: `Bearer ${getTokens().access_token}`,
						},
					}
				)

				if (response.data) {
					setModalActive(true)
					setQuestionContent('')
					setAnswerContent('')
					setSelectedCategory('')
				}
			} catch (error) {
				console.error('Error sending question:', error)
				const axiosError = error as AxiosError
				if (axiosError.response) {
					console.error('Response status:', axiosError.response.status)
					console.error('Response data:', axiosError.response.data)
				}
			}
		}
	}

	useEffect(() => {
		if (isTokenInLS()) {
			const token: any = jwt_decode(getTokens().access_token)
		}
	}, [])

	return (
		<>
			{admin ? (
				<ContentMainTheme>Создать вопрос</ContentMainTheme>
			) : (
				<ContentMainTheme>Задать вопрос</ContentMainTheme>
			)}
			<ContentCardWrapper>
				<ContentCard transparent low>
					{/*The "Comp" component was needed to insert the code into the ternary operator*/}
					{admin ? (
						''
					) : (
						<Comp
							targetList={targetList}
							onSelect={onSelect}
							selectedCategory={selectedCategory}
						/>
					)}

					<div className='ask-question-textarea-wrapper'>
						{admin ? '1. Опишите вопрос пользователя:' : ''}
						{admin ? (
							<textarea
								rows={10}
								cols={36}
								value={questionContent}
								onChange={e => setQuestionContent(e.target.value)}
								className='ask-question-textarea-input'
							/>
						) : (
							''
						)}
						{admin
							? '2. Опишите ваш ответ на вопрос:'
							: '2. Опишите ваш вопрос:'}
						<textarea
							rows={10}
							cols={36}
							value={admin ? answerContent : questionContent}
							onChange={e => {
								admin
									? setAnswerContent(e.target.value)
									: setQuestionContent(e.target.value)
							}}
							className='ask-question-textarea-input'
						/>
						<div className='ask-question-button-wrapper'>
							<Button
								onClick={handleSendQuestion}
								disabled={!!!selectedCategory}
							>
								{admin ? 'Создать вопрос' : 'Отправить вопрос'}
							</Button>
							<Button action='link' spacing='16' to='../popular-question'>
								Назад
							</Button>
							<Link
								to='../question-history'
								className='ask-question-link-wrapper'
							>
								{student ? (
									<span className='ask-question-button-history'>
										история ваших вопросов
									</span>
								) : (
									''
								)}
							</Link>
						</div>
					</div>
				</ContentCard>
			</ContentCardWrapper>
			<Modal active={modalActive} setActive={setModalActive} />
		</>
	)
}

export default AskQuestionForm
