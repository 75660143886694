export enum ApplicationType {
  INVITE_TO_THE_TEAM = "INVITE_TO_THE_TEAM",
  REQUEST_TO_THE_TEAM = "REQUEST_TO_THE_TEAM",
  MEMBERS_APPLICATION = "MEMBERS_APPLICATION",
  TOPIC_APPLICATION = "TOPIC_APPLICATION",
  TEAMLEAD_APPLICATION = "TEAMLEAD_APPLICATION",
  QUESTION_FOR_CURATOR = "QUESTION_FOR_CURATOR",
  QUESTION_FOR_SUPPORT = "QUESTION_FOR_SUPPORT",
  QUESTION_FOR_MAIN_ADMIN = "QUESTION_FOR_MAIN_ADMIN",
  EMPTY = "EMPTY",
}
