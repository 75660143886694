import axios from 'axios'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import QuestionnaireCreateDTO from '../../../../@types/dtos/QuestionnaireCreateDTO'
import {
	UserFieldsType,
	setUserInfo,
} from '../../../../redux/slices/userQuestionnaireSlice'
import { RootState } from '../../../../redux/store'
import { PASSPORT_MICROSERVICE } from '../../../../utils/team'
import {
	checkIsTokenExpired,
	getTokens,
	updateToken,
} from '../../../../utils/token'
import Button from '../../common/Button/Button'
import ButtonWrapper from '../../common/Button/ButtonWrapper'
import FormInputRadio from '../../common/Form/FormInputRadio'
import FormNavigateButtons from '../../common/Form/FormNavigateButtons'

const SyrweyWrapper = () => {
	const dispatch = useDispatch()
	// @ts-ignore
	const { user, skills, anotherSkills } = useSelector(
		(state: RootState) => state.userQuestionnaire
	)
	const navigate = useNavigate()

	const handleSelect = ({ target }: any, name: UserFieldsType) => {
		dispatch(setUserInfo({ name: name, value: target?.value }))
	}

	const makeSurveyRadioProps = (
		itemList: string[],
		userFields: any,
		name: UserFieldsType,
		onClickHandler: Function
	) => {
		return {
			itemList,
			selected: userFields[name],
			onClick: (e: React.MouseEvent<HTMLDataElement, MouseEvent>) =>
				onClickHandler(e, name),
		}
	}
	const canGoNext = () => {
		return [
			user.isEasyGoing !== '',
			user.isSolid !== '',
			user.behaviourInTeam !== '',
			user.doesLikeSurprises !== '',
			user.fears !== '',
			user.doesLikePlanning !== '',
			user.isCreative !== '',
		].reduce((result, current) => result && current)
	}

	const saveQuestionnare = async (body: QuestionnaireCreateDTO) => {
		let token = getTokens()

		if (checkIsTokenExpired(token.access_token)) {
			if (checkIsTokenExpired(token.refresh_token)) navigate('/')
			updateToken()
		}

		token = getTokens()

		const headers = {
			Authorization: `Bearer ${token.access_token}`,
		}

		const options = {
			headers,
		}
		const resultOfRequest = await axios
			.post(`${PASSPORT_MICROSERVICE}/api/v1/questionnaire`, body, options)
			.catch(error => {
				if (error?.response?.status === 404) {
					navigate('*')
					console.log(error)
				} else {
					navigate('/AnyError')
					console.error(error)
				}
			})
		navigate('/PersonalAccount/MyData')
	}

	const finishFillingQueistionnare = () => {
		const copyUser = { ...user }
		copyUser.skills = skills.join(', ')

		console.log(copyUser)
		saveQuestionnare(copyUser)
	}

	return (
		<>
			<div className='survey-wrapper'>
				<FormInputRadio
					label='Легко ли вам общаться с людьми?'
					required
					{...makeSurveyRadioProps(
						['Да', 'Нет', 'Когда как', 'Затрудняюсь ответить'],
						user,
						'isEasyGoing',
						handleSelect
					)}
				/>
			</div>

			<div className='survey-wrapper'>
				<FormInputRadio
					label='Вы предпочитаете работать в команде или в одиночку?'
					required
					{...makeSurveyRadioProps(
						[
							'В команде',
							'В одиночку',
							'Мне комфортно в любом случае',
							'Затрудняюсь ответить',
						],
						user,
						'isSolid',
						handleSelect
					)}
				/>
			</div>

			<div className='survey-wrapper'>
				<FormInputRadio
					label='Как вы ведете себя при работе в команде?'
					required
					{...makeSurveyRadioProps(
						[
							'Делаю работу за всех, самый активный участник',
							'Коммуницирую с командой, выполняю свои задачи во время и качественно',
							'Не против, чтобы мою работу сделали за меня',
							'Затрудняюсь ответить',
						],
						user,
						'behaviourInTeam',
						handleSelect
					)}
				/>
			</div>

			<div className='survey-wrapper'>
				<FormInputRadio
					label='Вам нравятся сюрпризы?'
					required
					{...makeSurveyRadioProps(
						['Да', 'Нет', 'Затрудняюсь ответить'],
						user,
						'doesLikeSurprises',
						handleSelect
					)}
				/>
			</div>

			<div className='survey-wrapper'>
				<FormInputRadio
					label='Что вас может напугать/оттолкнуть от задачи?'
					required
					{...makeSurveyRadioProps(
						[
							'Рутина',
							'Сложность, непонимание как решать',
							'Необходимость обращаться за консультацией к другим людям',
							'Необходимость контакта с другими членами команды',
							'Ничего из перечисленного',
							'Затрудняюсь ответить',
						],
						user,
						'fears',
						handleSelect
					)}
				/>
			</div>

			<div className='survey-wrapper'>
				<FormInputRadio
					label='Как вы относитесь к планированию?'
					required
					{...makeSurveyRadioProps(
						[
							'Веду календарь/планер, фиксирую что и когда буду делать',
							'Веду список задач и выполняю их, когда появляется время',
							'Держу всё в голове',
							'Затрудняюсь ответить',
						],
						user,
						'doesLikePlanning',
						handleSelect
					)}
				/>
			</div>

			<div className='survey-wrapper'>
				<FormInputRadio
					label='Можете ли вы назвать себя креативной личностью?'
					required
					{...makeSurveyRadioProps(
						['Да', 'Нет', 'Затрудняюсь ответить'],
						user,
						'isCreative',
						handleSelect
					)}
				/>
			</div>

			<FormNavigateButtons>
				<ButtonWrapper>
					<Button
						action={'link'}
						to={anotherSkills.length !== 0 ? '../skills/another' : '../skills'}
					>
						Назад
					</Button>

					<Button onClick={finishFillingQueistionnare} disabled={!canGoNext()}>
						Завершить
					</Button>
				</ButtonWrapper>
			</FormNavigateButtons>
		</>
	)
}

export default SyrweyWrapper
