import React from "react";
import ContentCard from "../ContentCard/ContentCard";
import Button from "./Button";
import ContainerMain from "../ContainerMain/ContainerMain";

const Examples = () => {
	return (
		<ContainerMain>
			<ContentCard>
				<Button action="toCopy" copyText="Lorem" spacing="4">
					Test 1
				</Button>
				<Button
					value={"sldfjhdjkf"}
					onClick={(e: any) => console.log(e.target.value)}
					spacing="4"
				>
					Test 2
				</Button>
				<Button action={"link"} to="/questionnaire/user" spacing={"16"}>
					aOUaygfd
				</Button>
				<Button action={"none"} styling={"text"}>
					Test 3
				</Button>
			</ContentCard>
		</ContainerMain>
	);
};

export default Examples;
