import { Outlet } from 'react-router-dom'
import ContainerMain from '../../hooks/components/common/ContainerMain/ContainerMain'

const Help = () => {
	return (
		<>
			<div className='help'>
				<ContainerMain size={'medium'}>
					<Outlet />
				</ContainerMain>
			</div>
		</>
	)
}
export default Help
