import { useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '../../../../hooks/components/common/Button/Button'
import ContentCard from '../../../../hooks/components/common/ContentCard/ContentCard'
import DoubleList from '../../../../hooks/components/common/DoubleList/DoubleList'
import { RootState } from '../../../../redux/store'
import PasportDescrEdit2of2 from './PasportDescrEdit2of2'

interface typeProps {
	swapPage: Function
	caseId?: number
	admin: boolean
}

const PasportDescription2of2 = ({ swapPage, caseId, admin }: typeProps) => {
	const { PasportCase } = useSelector(
		(state: RootState) => state.companyPasportSlice
	)

	const [pasportDescrEditing, setpasportDescrEditing] = useState<boolean>(false)

	const PasportDescrEditing = {
		status: pasportDescrEditing,
		toggle: () => setpasportDescrEditing(!pasportDescrEditing),
		content: (
			<PasportDescrEdit2of2
				caseId={caseId}
				toggle={() => setpasportDescrEditing(!pasportDescrEditing)}
			/>
		),
	}

	const scrollToTop = () => {
		const middleY = window.innerHeight / 2
		window.scrollTo({
			top: middleY,
			behavior: 'smooth', // плавная прокрутка
		})
	}
	const onBack = () => {
		swapPage(1)
		scrollToTop()
	}
	return (
		<>
			<ContentCard
				topic='2/2'
				blured
				myEditButton
				editing={admin ? PasportDescrEditing : undefined}
			>
				<DoubleList
					className='initials'
					listData={[
						{
							identifier: 'Трекер задач (Miro, Trello и т.п.)',
							content: PasportCase?.projectTaskTracker,
						},
						{
							identifier: 'Ограничения и допущения',
							content: PasportCase?.projectRestrictions,
						},
						{
							identifier: 'Доп. информация',
							content: PasportCase?.additionalInformation,
						},
						{
							identifier:
								'Пререквизиты проекта (компетенции участников команды)',
							content: PasportCase?.projectCompitentions,
						},
					]}
				/>
				<div className='company-Go-To-Back-Button-In-Info'>
					<Button onClick={onBack}>Назад</Button>
				</div>
			</ContentCard>
		</>
	)
}

export default PasportDescription2of2
