import axios from 'axios'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CompanyUserDTO } from '../../../@types/dtos/CompanyUserDTO'
import { setCompanyInfo } from '../../../redux/slices/CompanyInfoSlice'
import { RootState } from '../../../redux/store'
import { PASSPORT_MICROSERVICE } from '../../../utils/team'
import {
	checkIsTokenExpired,
	getTokens,
	updateToken,
} from '../../../utils/token'
import Button from '../common/Button/Button'
import FormInput, { makeProps } from './FormInput'
import FormNavigateButtons from './FormNavigateButtons'
import FormPanel from './FormPanel'

const Contacts = () => {
	const dispatch = useDispatch()
	const user = useSelector(
		(state: RootState) =>
			state.CompanyInfoReducer.profile.CompanyUserProfile.company
	)
	const site = useSelector(
		(state: RootState) => state.CompanyInfoReducer.profile
	)
	const onChange = ({ target }: any) => {
		dispatch(setCompanyInfo({ name: target?.name, value: target?.value }))
	}
	const navigate = useNavigate()
	const [allFilled, setAllFilled] = useState(false)
	const canGoNext = () => {
		return [].reduce((result, current) => result && current)
	}
	const saveQuestionnare = async (body: CompanyUserDTO) => {
		let token = getTokens()

		if (checkIsTokenExpired(token.access_token)) {
			if (checkIsTokenExpired(token.refresh_token)) navigate('/')
			updateToken()
		}

		token = getTokens()

		const headers = {
			Authorization: `Bearer ${token.access_token}`,
		}

		const options = {
			headers,
		}
		const resultOfRequest = await axios
			.post(
				`${PASSPORT_MICROSERVICE}/api/v1/questionnaire/admin`,
				body,
				options
			)
			.catch(error => {
				if (error.response.status === 404) {
					navigate('*')
					console.log(error)
				} else {
					navigate('/AnyError')
					console.log(error)
				}
			})
		navigate('/PersonalAccount/MyData')
	}

	const finishFillingQueistionnare = () => {
		const copyUser = { ...user } as unknown as CompanyUserDTO

		saveQuestionnare(copyUser)
	}

	return (
		<FormPanel method='POST'>
			{/* <NameForm /> */}

			<FormInput
				required
				label='Название компании'
				{...makeProps.forInput('title', user, onChange)}
			/>

			<FormInput
				required
				label='Роль (заказчик, куратор)'
				type='text'
				{...makeProps.forInput('description', user, onChange)}
			/>

			<FormInput
				label='Сайт компании'
				type='text'
				{...makeProps.forInput('CompanySite', site, onChange)}
			/>

			<FormNavigateButtons>
				<Button action={'link'} to='../info' spacing='8'>
					Назад
				</Button>
				<Button
					action='link'
					to='../finish'
					spacing='8'
					onClick={finishFillingQueistionnare}
				>
					Далее
				</Button>
			</FormNavigateButtons>
		</FormPanel>
	)
}

export default Contacts
