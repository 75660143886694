import ContentCard from '../../common/ContentCard/ContentCard'
import ContentCardWrapper from '../../common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../common/ContentMainTheme'
import EnterInviteLinkInPA from '../EnterInviteLinkInPA'
import AccountAboutMain from './AccountAboutMain'
import AdditionalsList from './AdditionalsList'

import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store'
import AboutMe from '../Skills/AboutMe'
import AboutMeEditing from '../Skills/AboutMeEditing'
import Role from '../Skills/Role'
import RoleEditing from '../Skills/RoleEditing'

import jwt_decode from 'jwt-decode'
import { useState } from 'react'
import { getTokens, isTokenInLS } from '../../../../utils/token'
import Button from '../../common/Button/Button'
import AccountAboutMainEditing from './AccountAboutMainEditing'
import AddListEditing from './AddListEditingForAdmin'

import {
	MODERATION_MICROSERVICE_URL,
	sendAxiosWithTokens,
} from '../../../../utils/team'
import { getDecodedToken } from '../../../../utils/token'
import ModalWindow from '../../common/ModalWindow/ModalWindow'

const MyData = () => {
	const Links = {
		'Цифровые технологии в социально-гуманитарной сфере':
			'https://vk.me/join/Zren9lWoRGZdGqYW3zRWA1oX5P0/KeP/fzI=',
		'Цифровое моделирование и суперкомпьютерные технологии':
			'https://vk.me/join/KMK7dyisS4X449mQoSCvWHHblUuMOt/YPJc=',
		'Методы искусственного интеллекта для дистанционного зондирования Земли':
			'https://vk.me/join/WXIKiAEvrktBtEVRX6DxSV9oIGbk0njoztU=',
		'Методы искусственного интеллекта и предиктивная аналитика в проектах дефектоскопии':
			'https://vk.me/join/dF0Byd74X1U20dzT48JuOKSO3XrtkFe_Ysc=',
		'Прикладные системы инженерных расчетов':
			'https://vk.me/join/Z6RRjwdpBRni1OsWcSX5bgOpYRrBSU_hfXI=',
		'Интеллектуальные технические системы':
			'https://vk.me/join/VlLdHtN2ck6hZqzRoIkhTlvquLEJ4vXGcEI=',
		'Прикладные задачи машинного обучения и анализа больших данных':
			'https://vk.me/join/UgSamHrlfAm63VPMQpCxttqDNsO9o/TkVMk=',
	}
	const [visibility, setVisibility] = useState(false)
	const [infoEditingforStudent, setInfoEditingforStudent] =
		useState<boolean>(false)
	const [infoEditingForAdmin, setInfoEditingForAdmin] = useState<boolean>(false)
	const accountInformation = useSelector(
		(state: RootState) => state.personalAccount
	)
	const { profile } = useSelector((state: RootState) => state.personalAccount)

	const [isRoleEditing, setIsRoleEditing] = React.useState(false)
	const [isAboutMeEditing, setIsAboutMeEditing] = React.useState(false)

	let admin: boolean = false
	let student: boolean = false
	let customer: boolean = false
	let curator: boolean = false
	let programCurator: boolean = false
	let teamlead: boolean = false

	const AboutMePropsEdit = {
		status: isAboutMeEditing,
		toggle: () => setIsAboutMeEditing(!isAboutMeEditing),
		content: <AboutMeEditing About={profile.Skills} />,
	}
	const SkillsProps = {
		status: isRoleEditing,
		toggle: () => setIsRoleEditing(!isRoleEditing),
		content: <RoleEditing SkillsRole={profile.Skills} />,
	}

	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		const isAdmin = roles.indexOf('client-main-admin') > -1
		if (isAdmin) admin = isAdmin
		const isCurator = roles.indexOf('client-curator') > -1
		if (isCurator) curator = isCurator
		const isStudent = roles.indexOf('client-student') > -1
		if (isStudent) student = isStudent
		const isCustomer = roles.indexOf('client-customer') > -1
		if (isCustomer) customer = isCustomer
		const isProgramCurator = roles.indexOf('client-program-curator') > -1
		if (isProgramCurator) programCurator = isProgramCurator
		const isTeamlead = roles.indexOf('client-teamlead') > -1
		if (isTeamlead) teamlead = isTeamlead
	}

	const MainInfoEditingProp = {
		status: infoEditingforStudent,
		toggle: () => setInfoEditingforStudent(!infoEditingforStudent),
		content: (
			<AccountAboutMainEditing
				toggle={() => setInfoEditingforStudent(!infoEditingforStudent)}
			/>
		),
	}
	const MainInfoEditingPropforAdmin = {
		status: infoEditingForAdmin,
		toggle: () => setInfoEditingForAdmin(!infoEditingForAdmin),
		content: (
			<AddListEditing
				toggle={() => setInfoEditingForAdmin(!infoEditingForAdmin)}
			/>
		),
	}

	const [newPassword, setnewPassword] = useState<string>('')
	const [changePass, setChangePass] = useState<boolean>(false)
	const [dateDef, setDateDef] = useState<boolean>(false)
	const [dateValue, setDateValue] = useState<string>('')

	const ChangePasswordReq = async (password: string) => {
		const data_from_token = getDecodedToken()
		await sendAxiosWithTokens(
			`post`,
			`${MODERATION_MICROSERVICE_URL}/api/v1/admin/users/password/${data_from_token.preferred_username}`,
			{
				password: password,
			}
		)
			.then(r => {
				console.log(r.data)
			})
			.catch(err => {
				console.log(err)
			})
	}

	const SavePassword = () => {
		setChangePass(!changePass)
		ChangePasswordReq(newPassword)
	}

	const SetDateDefenseIAR = async (date: string) => {
		await sendAxiosWithTokens(
			`post`,
			`${MODERATION_MICROSERVICE_URL}/api/v1/admin/config/`,
			{
				graduationDate: date,
			}
		)
			.then(resp => {
				console.log(resp)
			})
			.catch(err => {
				console.log(err)
			})
	}

	const SetNewGraduationDate = () => {
		setDateDef(false)
		SetDateDefenseIAR(dateValue)
	}
	return (
		<div className='personal-account-mydata'>
			<div className='mobile-button'>
				<ModalWindow visibility={visibility}>
					<EnterInviteLinkInPA setvisibility={setVisibility} />
				</ModalWindow>
				{customer ? (
					<Button
						action='link'
						to='/admin/admins'
						className='mobile-style-button'
					>
						Связь с администрацией
					</Button>
				) : admin ? (
					<>
						<div className='mobile-styles'>
							<div className='mobile-style-button'>
								<Button onClick={() => setChangePass(!changePass)}>
									Сменить пароль
								</Button>
							</div>
							<div className='mobile-style-button'>
								<Button onClick={() => setDateDef(!dateDef)}>
									Установить дату защиты ИАР
								</Button>
							</div>
						</div>
						{changePass ? (
							<div className='personal-account-right-sidebar-change-password'>
								Введите новый пароль:
								<br />
								<input
									type='text'
									className='form-field-input'
									onChange={e => setnewPassword(e.target.value)}
								/>
								{newPassword ? (
									<Button onClick={SavePassword}>Сохранить</Button>
								) : (
									<Button onClick={() => setChangePass(false)}>Закрыть</Button>
								)}
							</div>
						) : null}
						{dateDef ? (
							<div className='personal-account-right-sidebar-change-password'>
								Выберите дату:
								<br />
								<input
									type='date'
									className='form-field-input'
									onChange={e => setDateValue(e.target.value)}
								/>
								{dateValue != '' ? (
									<Button onClick={SetNewGraduationDate}>Сохранить</Button>
								) : (
									<Button onClick={() => setDateDef(false)}>Закрыть</Button>
								)}
							</div>
						) : null}{' '}
					</>
				) : curator || programCurator ? (
					<>
						<div className='desktop-button'>
							<Button onClick={() => setChangePass(!changePass)}>
								Сменить пароль
							</Button>
						</div>

						{changePass && (
							<div className='personal-account-right-sidebar-change-password'>
								Введите новый пароль:
								<br />
								<input
									type='text'
									className='form-field-input'
									onChange={e => setnewPassword(e.target.value)}
								/>
								{newPassword ? (
									<Button onClick={SavePassword}>Сохранить</Button>
								) : (
									<Button onClick={() => setChangePass(false)}>Закрыть</Button>
								)}
							</div>
						)}
					</>
				) : student ? (
					<div className='mobile-styles'>
						<div className='mobile-style-button'>
							<Button
								className='p-a-button'
								type='button'
								to='https://lms.mai.ru'
								action={'link'}
								label='Ссылка на курс в ЛМС'
							>
								Ссылка на курс в ЛМС
							</Button>
						</div>
						<div className='mobile-style-button'>
							<Button
								className='p-a-button'
								type='button'
								//@ts-ignore
								to={Links[profile.Mydata.programTitle]}
								action={'link'}
								label='Ссылка на чат в Tg'
							>
								Ссылка на чат в Tg
							</Button>
						</div>
						{!teamlead ? (
							<div className='mobile-style-button'>
								<Button
									className='p-a-button'
									type='button'
									label='Получить линк на вступление в команду'
									onClick={() => setVisibility(true)}
								>
									Ввести ссылку для вступления в команду
								</Button>
							</div>
						) : (
							''
						)}{' '}
					</div>
				) : (
					''
				)}
			</div>
			<ContentMainTheme>Мои данные</ContentMainTheme>
			<ContentCardWrapper>
				<ContentCard
					topic='Основная информация'
					myEditButton
					editing={student ? MainInfoEditingProp : undefined}
				>
					<AccountAboutMain />
				</ContentCard>

				<ContentCard
					topic='Дополнительная информация'
					myEditButton
					editing={
						admin || curator || programCurator || customer
							? MainInfoEditingPropforAdmin
							: undefined
					}
				>
					<AdditionalsList />
				</ContentCard>
				{student ? (
					<div className='personal-account-mydata-mobile'>
						<ContentCardWrapper>
							<ContentCard topic='Роль и навыки' editing={SkillsProps}>
								<Role role={profile.Skills} />
							</ContentCard>

							<ContentCard topic='О себе' editing={AboutMePropsEdit}>
								<AboutMe about={profile.Skills} />
							</ContentCard>
						</ContentCardWrapper>
					</div>
				) : (
					''
				)}
			</ContentCardWrapper>
		</div>
	)
}

export default MyData
