import { useEffect } from 'react'
import ContentCard from '../../hooks/components/common/ContentCard/ContentCard'

import { useNavigate } from 'react-router-dom'
import ContentCardHeading from '../../hooks/components/common/ContentCard/ContentCardHeading'
import ContentCardInner from '../../hooks/components/common/ContentCard/ContentCardInner'
import paths from '../../paths'
import {
	checkIsTokenExpired,
	getTokens,
	isTokenInLS,
	TokenType,
	updateToken,
} from '../../utils/token'
import { authorise } from './AuthorisationLogic'

const AuthorisationPage = () => {
	const navigate = useNavigate()

	useEffect(() => {
		;(async () => {
			if (isTokenInLS()) {
				let tokens: TokenType = JSON.parse(
					window.localStorage.getItem('token')!
				)
				const isAccessTokenExpired = checkIsTokenExpired(tokens.access_token)
				const isRefreshTokenExpired = checkIsTokenExpired(tokens.refresh_token)
				if (isAccessTokenExpired) {
					if (!isRefreshTokenExpired) {
						await updateToken()
						tokens = getTokens()
					}
				}

				if (!isAccessTokenExpired && !isRefreshTokenExpired) {
					navigate(
						`${paths.personalAccount.base}/${paths.personalAccount.myData}`
					)
				}
			}
		})()
	}, [])

	return (
		<div className='authorisation-content-wrapper'>
			<ContentCard>
				<ContentCardHeading topic='Авторизация'></ContentCardHeading>
				<ContentCardInner>
					<button type={'button'} className='button' onClick={authorise}>
						Войти
					</button>
				</ContentCardInner>
			</ContentCard>
		</div>
	)
}

export default AuthorisationPage
