import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Application } from '../../@types/entities/Application'
import forInvite from '../../assets/img/icons/forInvite.svg'
import isEmptyImg from '../../assets/img/icons/человечек в уведах.svg'
import notifStyle from '../../assets/styles/components/notifStyle.module.scss'
import Button from '../../hooks/components/common/Button/Button'
import { RootState } from '../../redux/store'
interface ViewSendedNotificationsProps {
	currentList: Application[]
	ListToShow: Application[]
}

const ViewSendedNotifications: React.FC<ViewSendedNotificationsProps> = ({
	currentList,
	ListToShow,
}) => {
	const ActiveIndex = useSelector(
		(state: RootState) => state.activeIndexReducer.activeIndex
	)

	const navigate = useNavigate()

	const GoToQuestion = () => {
		navigate('/help/question-history')
	}
	return (
		<>
			{currentList.length === 0 ? (
				<div className={notifStyle.isEmptyWindow}>
					<img src={isEmptyImg} alt='' />
					<h2>В исходящих пусто</h2>
				</div>
			) : (
				<div>
					{ActiveIndex === 0 ? (
						<ul>
							{ListToShow.map((item, i) => {
								if (item.type === 'INVITE_TO_THE_TEAM') {
									return (
										<li
											key={i}
											className={
												item.status === 'ACCEPTED' || item.status === 'REJECTED'
													? `${notifStyle.oldApp}`
													: `${notifStyle.NewApp}`
											}
										>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{`${
														item?.recipient?.fio
													} был приглашен в команду на роль ${
														item.role?.title || 'Названия роли нет'
													}`}
													<br />
													{item.feedback != '' && item.feedback
														? `Комментарий: ${item.feedback}`
														: ''}
												</span>
											</div>
											{item.status === 'ACCEPTED' ? (
												<p className={notifStyle.acceptInviteToTheTeam}>
													Принято
												</p>
											) : item.status === 'REJECTED' ? (
												<p className={notifStyle.rejectInviteToTheTeam}>
													Отклонено
												</p>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								} else if (item.type === 'REQUEST_TO_THE_TEAM') {
									return (
										<li
											key={i}
											className={
												item.status === 'ACCEPTED' || item.status === 'REJECTED'
													? `${notifStyle.oldApp}`
													: `${notifStyle.NewApp}`
											}
										>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{`Вы отправили заявку на вступление в команду ${
														item.subject?.title
													} на роль ${item.role?.title || 'роль не указана'} `}
													<br />
													{item.feedback != '' && item.feedback
														? `Комментарий: ${item.feedback}`
														: ''}
												</span>
											</div>
											{item.status === 'ACCEPTED' ? (
												<p className={notifStyle.acceptInviteToTheTeam}>
													Принято
												</p>
											) : item.status === 'REJECTED' ? (
												<p className={notifStyle.rejectInviteToTheTeam}>
													Отклонено
												</p>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								} else if (item.type === 'MEMBERS_APPLICATION') {
									return (
										<li
											key={i}
											className={
												item.status === 'ACCEPTED' || item.status === 'REJECTED'
													? `${notifStyle.oldApp}`
													: `${notifStyle.NewApp}`
											}
										>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{`Заявка на подтверждение списка команды ${item.subject?.title} отправлена`}
													<br />
													{item.feedback != '' && item.feedback
														? `Комментарий: ${item.feedback}`
														: ''}
												</span>
											</div>
											{item.status === 'ACCEPTED' ? (
												<p className={notifStyle.acceptInviteToTheTeam}>
													Принято
												</p>
											) : item.status === 'REJECTED' ? (
												<p className={notifStyle.rejectInviteToTheTeam}>
													Отклонено
												</p>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								} else if (item.type === 'TEAMLEAD_APPLICATION') {
									if (item?.subject?.id && item?.recipient) {
										return (
											<li
												key={i}
												className={
													item.status === 'ACCEPTED' ||
													item.status === 'REJECTED'
														? `${notifStyle.oldApp}`
														: `${notifStyle.NewApp}`
												}
											>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.text}>
														{`Вы отправили заявку на передачу роли Team Lead`}
														<br />
														{item.feedback != '' && item.feedback
															? `Комментарий: ${item.feedback}`
															: ''}
													</span>
												</div>
												{item.status === 'ACCEPTED' ? (
													<p className={notifStyle.acceptInviteToTheTeam}>
														Принято
													</p>
												) : item.status === 'REJECTED' ? (
													<p className={notifStyle.rejectInviteToTheTeam}>
														Отклонено
													</p>
												) : (
													<p className={notifStyle.reviweving}>
														В рассмотрении
													</p>
												)}
											</li>
										)
									}

									return (
										<li
											key={i}
											className={
												item.status === 'ACCEPTED' || item.status === 'REJECTED'
													? `${notifStyle.oldApp}`
													: `${notifStyle.NewApp}`
											}
										>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{`Заявка на одобрение тимлидерства отправлена.`}
													<br />
													{item.feedback != '' && item.feedback
														? `Комментарий: ${item.feedback}`
														: ''}
												</span>
											</div>
											{item.status === 'ACCEPTED' ? (
												<p className={notifStyle.acceptInviteToTheTeam}>
													Принято
												</p>
											) : item.status === 'REJECTED' ? (
												<p className={notifStyle.rejectInviteToTheTeam}>
													Отклонено
												</p>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								} else if (item.type === 'QUESTION_FOR_CURATOR') {
									return (
										<li
											key={i}
											className={
												item.status === 'ACCEPTED' || item.status === 'REJECTED'
													? `${notifStyle.oldApp}`
													: `${notifStyle.NewApp}`
											}
										>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>{`${
													item.status === 'ACCEPTED' ||
													item.status === 'REJECTED'
														? `${item.responser?.fio} ответил(а) на ваш вопрос`
														: 'Вопрос куратору отправлен'
												}`}</span>
											</div>
											{item.status === 'ACCEPTED' ||
											item.status === 'REJECTED' ? (
												<div className={notifStyle.GoToQuestion}>
													<Button styling='text' onClick={GoToQuestion}>
														Ответ получен
													</Button>
												</div>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								} else if (item.type === 'QUESTION_FOR_SUPPORT') {
									return (
										<li
											key={i}
											className={
												item.status === 'ACCEPTED' || item.status === 'REJECTED'
													? `${notifStyle.oldApp}`
													: `${notifStyle.NewApp}`
											}
										>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>{`${
													item.status === 'ACCEPTED' ||
													item.status === 'REJECTED'
														? `${item.responser?.fio} ответил(а) на ваш вопрос`
														: 'Вопрос в тех. поддержку отправлен'
												}`}</span>
											</div>
											{item.status === 'ACCEPTED' ||
											item.status === 'REJECTED' ? (
												<div className={notifStyle.GoToQuestion}>
													<Button styling='text' onClick={GoToQuestion}>
														Ответ получен
													</Button>
												</div>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								} else if (item.type === 'QUESTION_FOR_MAIN_ADMIN') {
									return (
										<li
											key={i}
											className={
												item.status === 'ACCEPTED' || item.status === 'REJECTED'
													? `${notifStyle.oldApp}`
													: `${notifStyle.NewApp}`
											}
										>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>{`${
													item.status === 'ACCEPTED' ||
													item.status === 'REJECTED'
														? `${item.responser?.fio} ответил(а) на ваш вопрос`
														: 'Вопрос администрации отправлен'
												}`}</span>
											</div>
											{item.status === 'ACCEPTED' ||
											item.status === 'REJECTED' ? (
												<div className={notifStyle.GoToQuestion}>
													<Button styling='text' onClick={GoToQuestion}>
														Ответ получен
													</Button>
												</div>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								}
							})}
						</ul>
					) : (
						''
					)}
					{ActiveIndex === 1 ? (
						<ul>
							{ListToShow.map((item, i) => {
								if (item.type === 'INVITE_TO_THE_TEAM') {
									return (
										<li key={i} className={notifStyle.oldApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{`${
														item?.recipient?.fio
													} был приглашен в команду на роль ${
														item.role?.title || 'Названия роли нет'
													}`}
													<br />
													{item.feedback != '' && item.feedback
														? `Комментарий: ${item.feedback}`
														: ''}
												</span>
											</div>
											{item.status === 'ACCEPTED' ? (
												<p className={notifStyle.acceptInviteToTheTeam}>
													Принято
												</p>
											) : item.status === 'REJECTED' ? (
												<p className={notifStyle.rejectInviteToTheTeam}>
													Отклонено
												</p>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								} else if (item.type === 'REQUEST_TO_THE_TEAM') {
									return (
										<li key={i} className={notifStyle.oldApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{`Вы отправили заявку на вступление в команду ${
														item.subject?.title
													} на роль ${item.role?.title || 'роль не указана'}  `}
													<br />
													{item.feedback != '' && item.feedback
														? `Комментарий: ${item.feedback}`
														: ''}
												</span>
											</div>
											{item.status === 'ACCEPTED' ? (
												<p className={notifStyle.acceptInviteToTheTeam}>
													Принято
												</p>
											) : item.status === 'REJECTED' ? (
												<p className={notifStyle.rejectInviteToTheTeam}>
													Отклонено
												</p>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								} else if (item.type === 'MEMBERS_APPLICATION') {
									return (
										<li key={i} className={notifStyle.oldApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{`Заявка на подтверждение списка команды ${item.subject?.title} отправлена`}
													<br />
													{item.feedback != '' && item.feedback
														? `Комментарий: ${item.feedback}`
														: ''}
												</span>
											</div>
											{item.status === 'ACCEPTED' ? (
												<p className={notifStyle.acceptInviteToTheTeam}>
													Принято
												</p>
											) : item.status === 'REJECTED' ? (
												<p className={notifStyle.rejectInviteToTheTeam}>
													Отклонено
												</p>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								} else if (item.type === 'TEAMLEAD_APPLICATION') {
									if (item?.subject?.id) {
										return (
											<li
												key={i}
												className={
													item.status === 'ACCEPTED' ||
													item.status === 'REJECTED'
														? `${notifStyle.oldApp}`
														: `${notifStyle.NewApp}`
												}
											>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.text}>
														{`Вы отправили заявку на передачу роли Team Lead`}
														<br />
														{item.feedback != '' && item.feedback
															? `Комментарий: ${item.feedback}`
															: ''}
													</span>
												</div>
												{item.status === 'ACCEPTED' ? (
													<p className={notifStyle.acceptInviteToTheTeam}>
														Принято
													</p>
												) : item.status === 'REJECTED' ? (
													<p className={notifStyle.rejectInviteToTheTeam}>
														Отклонено
													</p>
												) : (
													<p className={notifStyle.reviweving}>
														В рассмотрении
													</p>
												)}
											</li>
										)
									}
									return (
										<li
											key={i}
											className={
												item.status === 'ACCEPTED' || item.status === 'REJECTED'
													? `${notifStyle.oldApp}`
													: `${notifStyle.NewApp}`
											}
										>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{`Заявка на одобрение тимлидерства отправлена.`}
													<br />
													{item.feedback != '' && item.feedback
														? `Комментарий: ${item.feedback}`
														: ''}
												</span>
											</div>
											{item.status === 'ACCEPTED' ? (
												<p className={notifStyle.acceptInviteToTheTeam}>
													Принято
												</p>
											) : item.status === 'REJECTED' ? (
												<p className={notifStyle.rejectInviteToTheTeam}>
													Отклонено
												</p>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								} else if (item.type === 'QUESTION_FOR_CURATOR') {
									return (
										<li key={i} className={notifStyle.oldApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>{`${
													item.status === 'ACCEPTED' ||
													item.status === 'REJECTED'
														? `${item.responser?.fio} ответил(а) на ваш вопрос`
														: 'Вопрос куратору отправлен'
												}`}</span>
											</div>
											{item.status === 'ACCEPTED' ||
											item.status === 'REJECTED' ? (
												<div className={notifStyle.GoToQuestion}>
													<Button styling='text' onClick={GoToQuestion}>
														Ответ получен
													</Button>
												</div>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								} else if (item.type === 'QUESTION_FOR_SUPPORT') {
									return (
										<li key={i} className={notifStyle.oldApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>{`${
													item.status === 'ACCEPTED' ||
													item.status === 'REJECTED'
														? `${item.responser?.fio} ответил(а) на ваш вопрос`
														: 'Вопрос в тех. поддержку отправлен'
												}`}</span>
											</div>
											{item.status === 'ACCEPTED' ||
											item.status === 'REJECTED' ? (
												<div className={notifStyle.GoToQuestion}>
													<Button styling='text' onClick={GoToQuestion}>
														Ответ получен
													</Button>
												</div>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								} else if (item.type === 'QUESTION_FOR_MAIN_ADMIN') {
									return (
										<li key={i} className={notifStyle.oldApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>{`${
													item.status === 'ACCEPTED' ||
													item.status === 'REJECTED'
														? `${item.responser?.fio} ответил(а) на ваш вопрос`
														: 'Вопрос администрации отправлен'
												}`}</span>
											</div>
											{item.status === 'ACCEPTED' ||
											item.status === 'REJECTED' ? (
												<div className={notifStyle.GoToQuestion}>
													<Button styling='text' onClick={GoToQuestion}>
														Ответ получен
													</Button>
												</div>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								}
							})}
						</ul>
					) : (
						''
					)}
					{ActiveIndex === 2 ? (
						<ul>
							{ListToShow.map((item, i) => {
								if (item.type === 'INVITE_TO_THE_TEAM') {
									return (
										<li key={i} className={notifStyle.NewApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{`${
														item?.recipient?.fio
													} был приглашен в команду на роль ${
														item.role?.title || 'Названия роли нет'
													}`}
													<br />
													{item.feedback != '' && item.feedback
														? `Комментарий: ${item.feedback}`
														: ''}
												</span>
											</div>
											{item.status === 'ACCEPTED' ? (
												<p className={notifStyle.acceptInviteToTheTeam}>
													Принято
												</p>
											) : item.status === 'REJECTED' ? (
												<p className={notifStyle.rejectInviteToTheTeam}>
													Отклонено
												</p>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								} else if (item.type === 'REQUEST_TO_THE_TEAM') {
									return (
										<li key={i} className={notifStyle.NewApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{`Вы отправили заявку на вступление в команду ${
														item.subject?.title
													} на роль ${item.role?.title || 'роль не указана'}  `}
													<br />
													{item.feedback != '' && item.feedback
														? `Комментарий: ${item.feedback}`
														: ''}
												</span>
											</div>
											{item.status === 'ACCEPTED' ? (
												<p className={notifStyle.acceptInviteToTheTeam}>
													Принято
												</p>
											) : item.status === 'REJECTED' ? (
												<p className={notifStyle.rejectInviteToTheTeam}>
													Отклонено
												</p>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								} else if (item.type === 'MEMBERS_APPLICATION') {
									return (
										<li key={i} className={notifStyle.NewApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{`Заявка на подтверждение списка команды ${item.subject?.title} отправлена`}
													<br />
													{item.feedback != '' && item.feedback
														? `Комментарий: ${item.feedback}`
														: ''}
												</span>
											</div>
											{item.status === 'ACCEPTED' ? (
												<p className={notifStyle.acceptInviteToTheTeam}>
													Принято
												</p>
											) : item.status === 'REJECTED' ? (
												<p className={notifStyle.rejectInviteToTheTeam}>
													Отклонено
												</p>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								} else if (item.type === 'TEAMLEAD_APPLICATION') {
									if (item?.subject?.id) {
										return (
											<li
												key={i}
												className={
													item.status === 'ACCEPTED' ||
													item.status === 'REJECTED'
														? `${notifStyle.oldApp}`
														: `${notifStyle.NewApp}`
												}
											>
												<div className={notifStyle.item}>
													<img src={forInvite} alt='fds' />
													<span className={notifStyle.text}>
														{`Вы отправили заявку на передачу роли Team Lead`}
														<br />
														{item.feedback != '' && item.feedback
															? `Комментарий: ${item.feedback}`
															: ''}
													</span>
												</div>
												{item.status === 'ACCEPTED' ? (
													<p className={notifStyle.acceptInviteToTheTeam}>
														Принято
													</p>
												) : item.status === 'REJECTED' ? (
													<p className={notifStyle.rejectInviteToTheTeam}>
														Отклонено
													</p>
												) : (
													<p className={notifStyle.reviweving}>
														В рассмотрении
													</p>
												)}
											</li>
										)
									}
									return (
										<li
											key={i}
											className={
												item.status === 'ACCEPTED' || item.status === 'REJECTED'
													? `${notifStyle.oldApp}`
													: `${notifStyle.NewApp}`
											}
										>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span className={notifStyle.text}>
													{`Заявка на одобрение тимлидерства отправлена.`}
													<br />
													{item.feedback != '' && item.feedback
														? `Комментарий: ${item.feedback}`
														: ''}
												</span>
											</div>
											{item.status === 'ACCEPTED' ? (
												<p className={notifStyle.acceptInviteToTheTeam}>
													Принято
												</p>
											) : item.status === 'REJECTED' ? (
												<p className={notifStyle.rejectInviteToTheTeam}>
													Отклонено
												</p>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								} else if (item.type === 'QUESTION_FOR_CURATOR') {
									return (
										<li key={i} className={notifStyle.NewApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span
													className={notifStyle.text}
												>{`Вопрос куратору отправлен`}</span>
											</div>
											{item.status === 'ACCEPTED' ||
											item.status === 'REJECTED' ? (
												<div className={notifStyle.GoToQuestion}>
													<Button styling='text' onClick={GoToQuestion}>
														Ответ получен
													</Button>
												</div>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								} else if (item.type === 'QUESTION_FOR_SUPPORT') {
									return (
										<li key={i} className={notifStyle.NewApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span
													className={notifStyle.text}
												>{`Вопрос тех. поддержке отправлен`}</span>
											</div>
											{item.status === 'ACCEPTED' ||
											item.status === 'REJECTED' ? (
												<div className={notifStyle.GoToQuestion}>
													<Button styling='text' onClick={GoToQuestion}>
														Ответ получен
													</Button>
												</div>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								} else if (item.type === 'QUESTION_FOR_MAIN_ADMIN') {
									return (
										<li key={i} className={notifStyle.NewApp}>
											<div className={notifStyle.item}>
												<img src={forInvite} alt='fds' />
												<span
													className={notifStyle.text}
												>{`Вопрос администрации отправлен`}</span>
											</div>
											{item.status === 'ACCEPTED' ||
											item.status === 'REJECTED' ? (
												<div className={notifStyle.GoToQuestion}>
													<Button styling='text' onClick={GoToQuestion}>
														Ответ получен
													</Button>
												</div>
											) : (
												<p className={notifStyle.reviweving}>В рассмотрении</p>
											)}
										</li>
									)
								}
							})}
						</ul>
					) : (
						''
					)}
				</div>
			)}
		</>
	)
}

export default ViewSendedNotifications
