import React, { useState } from "react";
import folded from "./folded.module.scss";
import ButtonWrapper from "../Button/ButtonWrapper";
import Button from "../Button/Button";

interface FoldedTextProps {
	children?: string;
	placeholder?: string;
	className?: string;
	font?: "14" | "16" | "18" | "20";
	buttonCenter?: boolean;
	textCenter?: boolean;
}

const FoldedText = ({
	children,
	className = "",
	font = "14",
	buttonCenter,
	textCenter,
	placeholder = "пусто",
}: FoldedTextProps) => {
	const [isOpen, setOpen] = useState<boolean>(false);

	const toggleOppen = () => {
		setOpen(!isOpen);
	};

	return (
		<div className={folded["text__wrapper"]}>
			<div
				className={
					folded["text"] +
					` ${className}` +
					` ${isOpen ? folded["text--opened"] : folded["text--folded"]}` +
					` ${folded[`fz-${font}`]}` +
					` ${textCenter ? folded["text--center"] : ""}`
				}
			>
				{/*isOpen && (children || placeholder)*/}
				{isOpen && (<div className="link-table-teams">{children||placeholder}</div>)}
				{""}
			</div>
			<ButtonWrapper center={buttonCenter}>
				<Button styling="text" onClick={toggleOppen}>
					{isOpen ? <div className="link-table-teams">скрыть</div> : <div className="link-table-teams">просмотреть</div>}
				</Button>
			</ButtonWrapper>
		</div>
	);
};

export default FoldedText;
