import RSB from './NotifRightSB';
import { Outlet } from "react-router-dom";

const NotifWelcome: React.FC = () => {
    return (
        <>
            <div className="personal-account">
                <div className="content-wrapper personal-account-content-wrapper">
                    <RSB />
                    <Outlet />
                </div>
            </div>
        </>
    );
}

export default NotifWelcome