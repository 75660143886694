import FormInputRadio from '../../hooks/components/common/Form/FormInputRadio'

interface CompPrompt {
	children?: React.ReactNode
	targetList: any
	onSelect: any
	selectedCategory: any
}

const Comp: React.FC<CompPrompt> = ({
	targetList,
	onSelect,
	selectedCategory,
}) => {
	return (
		<>
			<div className='ask-question-form-iput-radio-wrapper'>
				1. Выберите категорию вопроса:
			</div>
			<FormInputRadio
				itemList={targetList}
				onClick={onSelect}
				selected={selectedCategory}
			/>
		</>
	)
}

export default Comp
