import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../../hooks/components/common/Button/Button'
import ButtonWrapper from '../../hooks/components/common/Button/ButtonWrapper'
import ContainerMain from '../../hooks/components/common/ContainerMain/ContainerMain'
import ContentCard from '../../hooks/components/common/ContentCard/ContentCard'
import ContentCardWrapper from '../../hooks/components/common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../hooks/components/common/ContentMainTheme'
import DoubleList from '../../hooks/components/common/DoubleList/DoubleList'
import { setCompanyList } from '../../redux/slices/companySlice'
import {
	FILES_MICROSERVICE,
	sendAxiosWithTokens,
	sendAxiosWithTokensAndCustomHeaders,
} from '../../utils/team'

const CreateCompanyCard = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [logoIcon, setLogoIcon] = useState<FileList | null>()
	interface CreateCompanyTypes {
		title: string
		shortTitle: string
		contactPerson: string
		contactPersonContact: string
	}
	const [isLoadingNewCompany, setIsLoadingNewCompan] = useState<boolean>(false)
	const {
		handleSubmit,
		register,
		getValues,
		resetField,
		formState: { errors, isValid },
	} = useForm<CreateCompanyTypes>({
		mode: 'onTouched',
	})
	const CreateNewCompany = async (formData: FormData) => {
		await sendAxiosWithTokensAndCustomHeaders(
			`post`,
			`${FILES_MICROSERVICE}/api/v1/company`,
			formData,
			{
				'Content-Type': 'multipart/form-data',
			}
		).then(async r => {
			setIsLoadingNewCompan(true)
			await sendAxiosWithTokens(
				'get',
				`${FILES_MICROSERVICE}/api/v1/company`
			).then(response => {
				dispatch(setCompanyList(response.data.data))
				navigate(`/company`)
			})
		})
	}
	const CreateCompany = () => {
		const value = getValues()
		const formData = new FormData()
		if (logoIcon) formData.append('logo', logoIcon[0])
		formData.append('title', value['title'])
		formData.append('description', value['shortTitle'])
		formData.append('contactPerson', value['contactPerson'])
		formData.append('contactPersonContact', value['contactPersonContact'])

		CreateNewCompany(formData)
	}
	return (
		<>
			<ContainerMain size='medium'>
				<ContentMainTheme>Создание карточки компании</ContentMainTheme>
				<ContentCard low transparent>
					Карточка - это Lorem ipsum dolor sit amet, consectetur adipiscing
					elit, sed do eiusmod tempor incididunt ut labore et dolore magna
					aliqua. Ut enim ad minim veniam, quis nostrud
				</ContentCard>
				<ContentCardWrapper>
					<ContentCard low transparent>
						Для создания карточки компании заполните необходимые поля:
					</ContentCard>
					<ContentCard blured>
						<DoubleList
							nonUnderLine={true}
							className='initials'
							listData={[
								{
									identifier: 'Название компании:',
									content: (
										<>
											<input
												className='company-info-input-forEdit'
												{...register('title', {
													required: 'Необходимо заполнить',
												})}
											></input>
											{errors.title && (
												<p className='form-field-input--error-message'>
													{errors.title.message}
												</p>
											)}
										</>
									),
								},
								{
									identifier: 'Короткое название компании:',
									content: (
										<>
											<input
												className='company-info-input-forEdit'
												{...register(
													'shortTitle'
													// {
													//     required:"Необходимо заполнить"
													// }
												)}
											></input>
											{errors.shortTitle && (
												<p className='form-field-input--error-message'>
													{errors.shortTitle.message}
												</p>
											)}
										</>
									),
								},
								{
									identifier: 'Контактное лицо:',
									content: (
										<>
											<input
												className='company-info-input-forEdit'
												{...register('contactPerson', {
													required: 'Необходимо заполнить',
												})}
											></input>
											{errors.contactPerson && (
												<p className='form-field-input--error-message'>
													{errors.contactPerson.message}
												</p>
											)}
										</>
									),
								},
								{
									identifier: 'Связь с контактным лицом:',
									content: (
										<>
											<input
												className='company-info-input-forEdit'
												{...register('contactPersonContact', {
													required: 'Необходимо заполнить',
												})}
											></input>
											{errors.contactPersonContact && (
												<p className='form-field-input--error-message'>
													{errors.contactPersonContact.message}
												</p>
											)}
										</>
									),
								},
								{
									identifier: 'Иконка логотипа:',
									content: (
										<>
											<label
												htmlFor='imageInput'
												className='company-input-photo'
											>
												Загрузить изображение
											</label>
											<input
												type='file'
												id='imageInput'
												accept='image/*'
												// style={{ display: 'none' }}
												onChange={e => setLogoIcon(e.target.files)}
											/>
										</>
									),
								},
							]}
						/>
						<div className='company-Go-To-Back-Button-In-Info'>
							<ButtonWrapper>
								<Button onClick={CreateCompany} disabled={!isValid}>
									{' '}
									Создать{' '}
								</Button>
								{isLoadingNewCompany && <p>Идет добавление компании...</p>}
								<Link to='/company'>
									<Button>Назад</Button>
								</Link>
							</ButtonWrapper>
						</div>
					</ContentCard>
				</ContentCardWrapper>
			</ContainerMain>
		</>
	)
}

export default CreateCompanyCard
