import jwt_decode from 'jwt-decode'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TeamDTO } from '../../../@types/createCommand'
import { getTokens, isTokenInLS } from '../../../utils/token'
import Button from '../common/Button/Button'
import ContentCardInner from '../common/ContentCard/ContentCardInner'
import DoubleList from '../common/DoubleList/DoubleList'
import LoadingText from '../common/Loaders/LoadingText'
import ModalWindow from '../common/ModalWindow/ModalWindow'
import GetInviteLink from './GetInviteLink'
interface TeamCommonInformationProps {
	team: TeamDTO | null
	viewer?: 'teamlead' | 'member' | 'non-member' | 'admin'
	teamIsFrozen: boolean
}

const TeamCommonInformation = ({
	team,
	viewer = 'non-member',
	teamIsFrozen = false,
}: TeamCommonInformationProps) => {
	const [visibility, setVisibility] = useState(false)
	const navigate = useNavigate()
	const goToPassportTeam = () => {
		navigate('/team/admin/passportProject', { state: { teamId: team?.id } })
	}

	let Mainadmin: boolean = false

	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		Mainadmin = roles.indexOf('client-main-admin') > -1
	}
	return (
		<>
			<ContentCardInner>
				<ModalWindow visibility={visibility}>
					<GetInviteLink setvisibility={setVisibility} />
				</ModalWindow>
				{team ? (
					<>
						<DoubleList
							className={'common'}
							listData={[
								{
									identifier: 'Наименование команды:',
									content: team.title,
								},
								{
									identifier: 'Описание команды:',
									content: team.description,
								},
								{
									identifier: 'Наименование кейса:',
									content:
										team.topic?.title ||
										(viewer === 'teamlead' ? (
											<Button action='link' styling='text' to='../select-case'>
												Выбрать
											</Button>
										) : (
											'Кейс не выбран'
										)),
								},
								{
									identifier: 'Представитель кейса:',
									content: team.topic
										? team?.supervisor
											? [
													team.supervisor.surname,
													team.supervisor.name,
													team.supervisor.patronymic,
											  ].join(' ')
											: 'Имя отсутствует'
										: 'Кейс не выбран',
								},
								{
									identifier: 'Комментарий о паспорте проекта:',
									content: team.feedback !== null ? team.feedback : 'Нет комментария'
								},
							]}
						/>
						{Mainadmin && (
							<>
								<br />
								<Button onClick={goToPassportTeam}>
									Посмотреть паспорт проекта команды
								</Button>
							</>
						)}
					</>
				) : (
					<LoadingText />
				)}
			</ContentCardInner>
		</>
	)
}

export default TeamCommonInformation
