import jwt_decode from 'jwt-decode'
import { useState } from 'react'
import GetUserMainInformationDTO from '../../../../@types/dtos/GetUserMainInformationDTO'
import { UserRole } from '../../../../@types/entities/UserRole'
import { getTokens, isTokenInLS } from '../../../../utils/token'
import ContentCard from '../../common/ContentCard/ContentCard'
import ContentCardInner from '../../common/ContentCard/ContentCardInner'
import DoubleList from '../../common/DoubleList/DoubleList'
import ProfileImage from '../MyData/ProfileImage'
import ViewedAccountMainEditing from './ViewedAccountMainEditing'

export type MainInfoProps = { userData: GetUserMainInformationDTO | undefined }
const MainInfo = ({ userData }: MainInfoProps) => {
	const [infoEditing, setInfoEditing] = useState(false)

	let admin: boolean = false
	let student: boolean = false
	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		const isAdmin = roles.indexOf('client-main-admin') > -1
		if (isAdmin) admin = isAdmin
		const isStudent = roles.indexOf('client-student') > -1
		if (isStudent) student = isStudent
	}
	const MainInfoEditingProp = {
		status: infoEditing,
		toggle: () => setInfoEditing(!infoEditing),
		content: <ViewedAccountMainEditing userData={userData} />,
	}

	return (
		<ContentCard topic='Основная информация'>
			<ContentCardInner>
				<div className='profile'>
					<ProfileImage imageURL={''} size='large' />
					<DoubleList
						className='initials'
						listData={[
							{
								identifier: 'Фамилия',
								content: userData?.fio.split(' ')[0],
							},
							{
								identifier: 'Имя',
								content: userData?.fio.split(' ')[1],
							},
							{
								identifier: 'Отчество',
								content: userData?.fio.split(' ')[2],
							},
						]}
					/>
				</div>
				{userData?.role == UserRole.STUDENT ? (
					<DoubleList
						className='another-info'
						listData={[
							{
								identifier: 'Дата рождения',
								content: userData?.birthDate,
							},
							{
								identifier: 'Личная почта',
								content: userData?.email,
							},
							{
								identifier: 'Почта МАИ',
								content: userData?.maiemail,
							},
							{
								identifier: 'Номер телефона',
								content: userData?.phoneNumber,
							},
							{
								identifier: 'Telegram',
								content: userData?.telegram_link,
							},
						]}
					/>
				) : (
					<></>
				)}
			</ContentCardInner>
		</ContentCard>
	)
}

export default MainInfo
