import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setAboutMe, updateSkills } from '../../../../redux/slices/paSlice'
import ErrorHandler from '../../../../utils/ErrorHandler'
import { PERSONAL_ACCOUNT_MICROSERVICE } from '../../../../utils/team'
import {
	TokenType,
	checkIsTokenExpired,
	getDecodedToken,
	getTokens,
	updateToken,
} from '../../../../utils/token'
import SingleSelect from '../../SingleSelect'
import DoubleList from '../../common/DoubleList/DoubleList'

const SocialSkills = [
	{ valueName: `Да`, label: `Да` },
	{ valueName: `Нет`, label: `Нет` },
	{ valueName: `Когда как`, label: `Когда как` },
	{ valueName: `Затрудняюсь ответить`, label: `Затрудняюсь ответить` },
]

const PreferenceWork = [
	{ valueName: `В команде`, label: `В команде` },
	{ valueName: `В одиночку`, label: `В одиночку` },
	{
		valueName: `Мне комфортно в любом случае`,
		label: `Мне комфортно в любом случае`,
	},
	{ valueName: `Затрудняюсь ответить`, label: `Затрудняюсь ответить` },
]

const TeamBehavior = [
	{
		valueName: `Делаю работу за всех, самый активный участник`,
		label: `Делаю работу за всех, самый активный участник`,
	},
	{
		valueName: `Коммуницирую с командой, выполняю свои задачи во время и качественно`,
		label: `Коммуницирую с командой, выполняю свои задачи во время и качественно`,
	},
	{
		valueName: `Не против, чтобы мою работу сделали за меня`,
		label: `Не против, чтобы мою работу сделали за меня`,
	},
	{ valueName: `Затрудняюсь ответить`, label: `Затрудняюсь ответить` },
]

const YesNoDifficult = [
	{ valueName: `Да`, label: `Да` },
	{ valueName: `Нет`, label: `Нет` },
	{ valueName: `Затрудняюсь ответить`, label: `Затрудняюсь ответить` },
]

const Fears = [
	{ valueName: `Рутина`, label: `Рутина` },
	{
		valueName: `Сложность, непонимание как решать`,
		label: `Сложность, непонимание как решать`,
	},
	{
		valueName: `Необходимость обращаться за консультацией к другим людям`,
		label: `Необходимость обращаться за консультацией к другим людям`,
	},
	{
		valueName: `Необходимость контакта с другими членами команды`,
		label: `Необходимость контакта с другими членами команды`,
	},
	{ valueName: `Ничего из перечисленного`, label: `Ничего из перечисленного` },
	{ valueName: `Затрудняюсь ответить`, label: `Затрудняюсь ответить` },
]

const Plan = [
	{
		valueName: `Веду календарь/планер, фиксирую что и когда буду делать`,
		label: `Веду календарь/планер, фиксирую что и когда буду делать`,
	},
	{
		valueName: `Веду список задач и выполняю их, когда появляется время`,
		label: `Веду список задач и выполняю их, когда появляется время`,
	},
	{ valueName: `Держу всё в голове`, label: `Держу всё в голове` },
	{ valueName: `Затрудняюсь ответить`, label: `Затрудняюсь ответить` },
]

interface AboutProps {
	About: {
		isEasyGoing?: string
		isSolid?: string
		behaviourInTeam?: string
		doesLikeSurprises?: string
		fears?: string
		doesLikePlanning?: string
		isCreative?: string
	}
}

const AboutMeEditing: React.FC<AboutProps> = ({ About }) => {
	let tokens: TokenType = getTokens()
	const navigate = useNavigate()
	const [correctAbout, setCorrectAbout] = useState(About)

	const putAboutMe = async () => {
		if (tokens != null) {
			const data_from_token = getDecodedToken()

			if (checkIsTokenExpired(tokens.access_token)) {
				if (checkIsTokenExpired(tokens.refresh_token)) {
					window.localStorage.removeItem('token')
					navigate('/')
				} else {
					await updateToken()
					tokens = getTokens()
				}
			}
			await axios
				.put(
					`${PERSONAL_ACCOUNT_MICROSERVICE}/api/v1/personal_account/changeAboutInfo`,
					{
						isEasyGoing: About?.isEasyGoing,
						isSolid: About?.isSolid,
						behaviourInTeam: About?.behaviourInTeam,
						doesLikeSurprises: About?.doesLikeSurprises,
						fears: About?.fears,
						doesLikePlanning: About?.doesLikePlanning,
						isCreative: About?.isCreative,
					},
					{
						headers: {
							Authorization: `Bearer ${tokens.access_token}`,
						},
					}
				)
				.catch(error => {
					ErrorHandler(error, navigate)
				})
			await axios
				.get(
					`${PERSONAL_ACCOUNT_MICROSERVICE}/api/v1/personal_account/getAboutAndSkillsInfo/${data_from_token.preferred_username}`,
					{
						headers: {
							Authorization: `Bearer ${tokens.access_token}`,
						},
					}
				)
				.then(response => {
					dispatch(updateSkills(response.data.data))
				})
				.catch(error => {
					ErrorHandler(error, navigate)
				})
		}
	}

	const ChangeValuesAboutMe = (
		name: keyof typeof correctAbout,
		value: string
	) => {
		dispatch(setAboutMe({ name: name, value: value }))
		setCorrectAbout(prev => ({ ...prev, [name]: value }))
	}

	useEffect(() => {
		putAboutMe()
	}, [correctAbout])

	const dispatch = useDispatch()

	const AboutMeList = [
		{
			identifier: 'Легко ли вам общаться с людьми?',
			content: (
				<SingleSelect
					options={SocialSkills}
					infobefore={About?.isEasyGoing}
					onChangeValue={(value: string) => {
						ChangeValuesAboutMe('isEasyGoing', value)
					}}
				/>
			),
		},
		{
			identifier: 'Вы предпочитаете работать в команде или в одиночку?',
			content: (
				<SingleSelect
					options={PreferenceWork}
					infobefore={About?.isSolid}
					onChangeValue={(value: string) => {
						ChangeValuesAboutMe('isSolid', value)
					}}
				/>
			),
		},
		{
			identifier: 'Как вы ведете себя при работе в команде?',
			content: (
				<SingleSelect
					options={TeamBehavior}
					infobefore={About?.behaviourInTeam}
					onChangeValue={(value: string) => {
						ChangeValuesAboutMe('behaviourInTeam', value)
					}}
				/>
			),
		},
		{
			identifier: 'Вам нравятся сюрпризы?',
			content: (
				<SingleSelect
					options={YesNoDifficult}
					infobefore={About?.doesLikeSurprises}
					onChangeValue={(value: string) => {
						ChangeValuesAboutMe('doesLikeSurprises', value)
					}}
				/>
			),
		},
		{
			identifier: 'Что вас может напугать/оттолкнуть от задачи?',
			content: (
				<SingleSelect
					options={Fears}
					infobefore={About?.fears}
					onChangeValue={(value: string) => {
						ChangeValuesAboutMe('fears', value)
					}}
				/>
			),
		},
		{
			identifier: 'Как вы относитесь к планированию?',
			content: (
				<SingleSelect
					options={Plan}
					infobefore={About?.doesLikePlanning}
					onChangeValue={(value: string) => {
						ChangeValuesAboutMe('doesLikePlanning', value)
					}}
				/>
			),
		},
		{
			identifier: 'Можете ли вы назвать себя креативной личностью?',
			content: (
				<SingleSelect
					options={YesNoDifficult}
					infobefore={About?.isCreative}
					onChangeValue={(value: string) => {
						ChangeValuesAboutMe('isCreative', value)
					}}
				/>
			),
		},
	]
	return <DoubleList className={'additionals'} listData={AboutMeList} />
}
export default AboutMeEditing
