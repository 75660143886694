import AirPlan from '../../../assets/img/AirPlan.svg'
import styles from './index.module.scss'

const NotFoundPage = () => {
	return (
		<div className={styles['Terror']}>
			<div className={styles['errorBlock']}>
				<img src={AirPlan} className={styles['samoletic']} />
				<h1 className={styles['errorTitle']}>404</h1>
			</div>
			<h2 className={styles['errorTitle2']}>
				Ой... Мы не можем найти эту страницу!
			</h2>
			<p className={styles['errorTitle3']}>
				Мы сожалеем, но страница, на которую вы пытались перейти, не существует.
			</p>
			<p className={styles['errorTitle4']}>Пожалуйста, вернитесь на главную.</p>
			<a href='https://digital.mai.ru/' className={styles['buttonText']}>
				Перейти на главную страницу
			</a>
		</div>
	)
}

export default NotFoundPage
