import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { GetImportantInfoDTO } from '../../../../@types/dtos/GetImportantInfoDTO'
import paths from '../../../../paths'
import ErrorHandler from '../../../../utils/ErrorHandler'
import {
	CREATE_COMMAND_MICROSERVICE_URL,
	sendAxiosWithTokens,
} from '../../../../utils/team'
import Button from '../../common/Button/Button'
import ContainerMain from '../../common/ContainerMain/ContainerMain'
import ContentCard from '../../common/ContentCard/ContentCard'
import ContentCardInner from '../../common/ContentCard/ContentCardInner'
import ContentMainTheme from '../../common/ContentMainTheme'
import CheckBox from '../../common/Form/CheckBox'
import LoadingText from '../../common/Loaders/LoadingText'
import ModalWindow from '../../common/ModalWindow/ModalWindow'
import Table from '../../common/Tables/TableFilterAndPagination'
import FormLabel from '../AdminQuestionnaire/FormLabel'
export const AdminCreateTeam = () => {
	const navigate = useNavigate()
	const [modalwindowContent, setModalwindowContent] =
		useState<React.ReactNode>(null)
	const [availableUserList, setAvailableUserList] = useState<
		GetImportantInfoDTO[]
	>([])
	const [selectedTeamLeadId, setSelectedTeamLeadId] = useState<number | null>(
		null
	)
	const [selectedMemberIdList, setSelectedMemberIdList] = useState<number[]>([])

	const toggleItem = (valuse: number, list: number[]) => {
		if (list.includes(valuse)) {
			return list.filter(item => item !== valuse)
		} else {
			return [...list, valuse]
		}
	}

	useEffect(() => {
		const getData = async () => {
			await sendAxiosWithTokens(
				'get',
				`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/user/free`
			)
				.then(result => {
					setAvailableUserList(result.data.data)
				})
				.catch(error => ErrorHandler(error, navigate))
		}
		getData()
	}, [])

	interface ITeamCreateForm {
		title: string
		teamDescription: string
		teamleadId: number
		selectedMemberIdList: number[]
	}

	const postTeamCreate = async (teamData: ITeamCreateForm) => {
		if (selectedMemberIdList.length > 10) {
			return Promise.reject('Вы не можете больше набрать людей')
		}

		if (
			selectedTeamLeadId &&
			selectedMemberIdList.includes(selectedTeamLeadId)
		) {
			return Promise.reject('Вы дважды выбрали тимлида')
		}

		const postBody = {
			title: teamData.title,
			teamDescription: teamData.teamDescription,
			teamleadId: selectedTeamLeadId,
			memberIds: selectedMemberIdList,
		}
		// console.log(postBody);
		try {
			const teamCreateResponse = await sendAxiosWithTokens(
				'post',
				`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/team/create/admin`,
				postBody
			)
			return teamCreateResponse
		} catch (err) {
			return Promise.reject(err)
		}
	}

	const COLUMNS = [
		{
			Header: '№',
			Cell: ({ row }: { row: any }) => <span>{row.index + 1}</span>,
		},
		{
			Header: 'ФИО пользователя',
			accessor: 'fio',
		},
		{
			Header: 'Telegram',
			accessor: 'telegram_link',
		},
		{
			Header: 'Номер телефона',
			accessor: 'phoneNumber',
		},
		{
			Header: 'Выбрать',
			accessor: ({ userId }: GetImportantInfoDTO) => {
				const isSelected = userId === selectedTeamLeadId
				return (
					<CheckBox
						checked={isSelected}
						onClick={() => {
							if (userId) setSelectedTeamLeadId(userId)
							setSelectedMemberIdList([])
						}}
					/>
				)
			},
		},
	]

	const CHOOSE_STUDENTS_COLUMNS = [
		{
			Header: '№',
			Cell: ({ row }: { row: any }) => <span>{row.index + 1}</span>,
		},
		{
			Header: 'ФИО пользователя',
			accessor: 'fio',
		},
		{
			Header: 'Telegram',
			accessor: 'telegram_link',
		},
		{
			Header: 'Номер телефона',
			accessor: 'phoneNumber',
		},
		{
			Header: 'Выбрать',
			accessor: ({ userId }: GetImportantInfoDTO) => {
				return (
					<CheckBox
						checked={userId ? selectedMemberIdList.includes(userId) : false}
						onClick={() => {
							if (userId)
								setSelectedMemberIdList(
									toggleItem(userId, selectedMemberIdList)
								)
						}}
					/>
				)
			},
		},
	]
	const {
		handleSubmit,
		register,
		resetField,
		formState: { errors, isValid },
	} = useForm<ITeamCreateForm>({
		mode: 'onTouched',
	})

	const onSubmit: SubmitHandler<ITeamCreateForm> = (data: ITeamCreateForm) => {
		resetField('title')
		resetField('teamDescription')
		postTeamCreate(data)
			.then(res => {
				setModalwindowContent('Вы успешно создали команду.')
				navigate(`${paths.team.base}/${res.data.data}`)
			})
			.catch(err => {
				setModalwindowContent('Что-то пошло не так.')
				ErrorHandler(err, navigate)
			})
	}

	return (
		<>
			<ModalWindow
				visibility={!!modalwindowContent}
				setVisibility={(visibility: boolean) => {
					setModalwindowContent(!visibility ? null : 'Контент')
				}}
			>
				<div>{modalwindowContent}</div>
			</ModalWindow>
			<ContainerMain>
				<ContentMainTheme>Создание команды</ContentMainTheme>

				<ContentCard>
					<ContentCardInner>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className='form-field'>
								<FormLabel required>Введите название команды:</FormLabel>

								<input
									className={
										'form-field-input ' +
										(errors.title ? 'form-field-input--not-valid ' : '')
									}
									{...register('title', { required: true })}
								/>
							</div>
							<div className='form-field'>
								<FormLabel description='Например, вы можете указать роли, которые  нужны в команде, рассказать про  цели и планы'>
									Добавьте описание команды (необязательный шаг):
								</FormLabel>
								<textarea
									className='form-textarea'
									rows={5}
									{...register('teamDescription')}
								/>
							</div>

							<div style={{ marginTop: '3rem', marginBottom: '3rem' }}>
								{availableUserList.length === 0 ? (
									<LoadingText center />
								) : (
									<>
										{/* <div className="form-field"> */}
										<FormLabel required>Выберите тимлида</FormLabel>
										{/* </div> */}
										<Table
											columns={COLUMNS}
											data={availableUserList}
											itemsOnPage={10}
											visiblePaginationRange={2}
											className='all-user-table'
										/>

										{selectedTeamLeadId && (
											<>
												<div className='form-field'>
													<FormLabel description='Выбрать можно от 1 до 10 человек'>
														Выберите участников вашей команды
													</FormLabel>
												</div>
												<Table
													columns={CHOOSE_STUDENTS_COLUMNS}
													data={availableUserList.filter(
														user => user.userId !== selectedTeamLeadId
													)}
													itemsOnPage={10}
													visiblePaginationRange={2}
													className='all-user-table'
												/>
											</>
										)}
									</>
								)}
							</div>
							<div className='button-block'>
								<Button
									action='formButton'
									type='submit'
									disabled={
										!isValid ||
										selectedTeamLeadId === null ||
										selectedMemberIdList.length === 0
									}
								>
									Создать команду
								</Button>
							</div>
						</form>
					</ContentCardInner>
				</ContentCard>
			</ContainerMain>
		</>
	)
}
