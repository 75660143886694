import CaseFrame from '../../../Pages/Case/CaseFrame'
import ContentCard from '../ContentCard/ContentCard'

interface CaseCardPrompt {
	children?: React.ReactNode
	isRedactable?: boolean
	className?: string
	index?: number
	CaseItem: any
	editing?: boolean
	role: boolean
	topicStatus: string
}

const CaseCard: React.FC<CaseCardPrompt> = ({
	children,
	isRedactable = false,
	CaseItem: CaseItem,
	editing,
	role,
	topicStatus,
}) => {
	return (
		<ContentCard low>
			<CaseFrame
				CaseItem={CaseItem}
				editing={editing}
				role={role}
				topicStatus={topicStatus}
			/>
		</ContentCard>
	)
}
export default CaseCard
