import jwt_decode from 'jwt-decode'
import { Link } from 'react-router-dom'
import { ReactComponent as MaiLogo } from '../../assets/img/icons/mai-logo.svg'
import paths from '../../paths'
import { getTokens, isTokenInLS } from '../../utils/token'

function Navbar() {
	let notLogin = false
	let admin = false
	let student = false
	let customer = false
	let curator = false
	let pervak = false

	if (isTokenInLS()) {
		const token = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		const isAdmin = roles.indexOf('client-main-admin') > -1
		if (isAdmin) admin = isAdmin
		const isCurator = roles.indexOf('client-curator') > -1
		if (isCurator) curator = isCurator
		const isStudent = roles.indexOf('client-student') > -1
		if (isStudent) student = isStudent
		const isCustomer = roles.indexOf('client-customer') > -1
		if (isCustomer) customer = isCustomer
		pervak = roles.indexOf('client-pervak') > -1
	} else {
		notLogin = true
	}

	return (
		<>
			<div className='navbar'>
				<ul className='navbar-menu__list'>
					<div className='desktop-button'>
						{/* <Link to="/"> */}
						<li className='navbar-menu__item'>
							<MaiLogo />
						</li>
					</div>
					<div className='desktop-button'>
						{/* </Link> */}
						<Link to='/PersonalAccount/MyData'>
							<li className='navbar-menu__item'>Главная</li>
						</Link>
					</div>
					<div className='desktop-button'>
						<Link to='/company'>
							<li className='navbar-menu__item'>Компании</li>
						</Link>
					</div>
					<div className='desktop-button'>
						<Link to='/Case'>
							<li className='navbar-menu__item'>Кейсы</li>
						</Link>
					</div>
					{customer || pervak ? (
						''
					) : (
						<div className='desktop-button'>
							<Link to={paths.help.popularQuestion}>
								<li className='navbar-menu__item'>Помощь</li>
							</Link>
						</div>
					)}
				</ul>
			</div>
		</>
	)
}

export default Navbar
