import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TeamDTO } from '../../../@types/createCommand'
import { AcademicGroup } from '../../../@types/entities/AcademicGroup'
import { Company } from '../../../@types/entities/Company'
import { DPPGroup } from '../../../@types/entities/DPPGroup'
import paths from '../../../paths'
import {
	CREATE_COMMAND_MICROSERVICE_URL,
	MODERATION_MICROSERVICE_URL,
	sendAxiosWithTokens,
} from '../../../utils/team'
import SingleSelect from '../SingleSelect'
import Button from '../common/Button/Button'
import ButtonWrapper from '../common/Button/ButtonWrapper'
import ContainerMain from '../common/ContainerMain/ContainerMain'
import ContentCard from '../common/ContentCard/ContentCard'
import ContentCardWrapper from '../common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../common/ContentMainTheme'
import styles from '../common/DoubleList/DoubleList.module.scss'
import LoadingText from '../common/Loaders/LoadingText'
import AllTeams from './AllTeams'

type SelectType = { valueName: string; valueId: number; label: string }

export const ExtraSearchTeams = () => {
	const [dppGroups, setDPPGroups] = useState<SelectType[]>([])
	const [academicGroups, setAcademicGroups] = useState<SelectType[]>([])
	const [companies, setCompanies] = useState<SelectType[]>([])

	const [selectedDPPGroup, setSelectedDPPGroup] = useState<number>(0)
	const [selectedAcademicGroup, setSelectedAcademicGroup] = useState<number>(0)
	const [selectedCompany, setSelectedCompany] = useState<number>(0)
	const [selectedFaculty, setSelectedFaculty] = useState<number>(0)
	const [isFetch, setFetch] = useState<boolean>(false)
	const [teams, setTeams] = useState<TeamDTO[]>([])
	const navigate = useNavigate()

	let facults: SelectType[] = []
	for (let i = 1; i < 14; i++) {
		facults.push({
			valueName: 'Факультет ' + i,
			valueId: i,
			label: 'Факультет ' + i,
		})
	}
	const [isLoading, setIsLoading] = useState<boolean>(true)

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true)
			try {
				const requests = [
					sendAxiosWithTokens(
						'get',
						`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/group-selector?is_dpp=true`
					),
					sendAxiosWithTokens(
						'get',
						`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/group-selector?is_academic=true`
					),
					sendAxiosWithTokens(
						'get',
						`${MODERATION_MICROSERVICE_URL}/api/v1/company`
					),
				]

				Promise.all(requests).then(
					([dppResponse, academicResponse, companyResponse]) => {
						const dppData: SelectType[] = []
						dppResponse.data.data.forEach((item: DPPGroup) => {
							dppData.push({
								valueName: item.title,
								valueId: item.id,
								label: item.title,
							} as SelectType)
						})
						setDPPGroups(dppData)

						const academicData: SelectType[] = []
						academicResponse.data.data.forEach(
							(item: AcademicGroup & { title: string }) => {
								academicData.push({
									valueName: item.title,
									valueId: item.id,
									label: item.title,
								})
							}
						)
						setAcademicGroups(academicData)

						const companyData: SelectType[] = []
						companyResponse.data.data.forEach((item: Company) => {
							companyData.push({
								valueName: item.title,
								valueId: item.id,
								label: item.title,
							})
						})
						setCompanies(companyData)
					}
				)
			} catch (error) {
				console.error('Error fetching data', error)
			}
			setIsLoading(false)
		}

		fetchData()
	}, [])

	const findTeams = () => {
		let queryURL = '?'
		if (selectedDPPGroup != 0) {
			queryURL += `dpp_group_id=${selectedDPPGroup}&`
		}
		if (selectedAcademicGroup != 0) {
			queryURL += `academic_group_id=${selectedAcademicGroup}&`
		}
		if (selectedCompany != 0) {
			queryURL += `company_id=${selectedCompany}&`
		}
		if (selectedFaculty != 0) {
			queryURL += `faculty_id=${selectedFaculty}`
		}

		sendAxiosWithTokens(
			'get',
			`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/extend-search/teams${queryURL}`
		).then(r => {
			setTeams(r.data.data)
			setFetch(true)
		})
	}

	return (
		<>
			{' '}
			{isLoading ? (
				<LoadingText />
			) : !isFetch ? (
				<ContainerMain>
					<ContentMainTheme>Расширенный поиск</ContentMainTheme>
					<ContentCardWrapper>
						<ContentCard>
							<div className={'double-lsit-wrapper'}>
								<ul className={styles['double-list']}>
									<li className={styles['double-list__item']}>
										<div className={styles['double-list__item-wrapper']}>
											<div className={styles['identifier']}>
												Группа ДПП (одного из участников):
											</div>
											<div className={styles['content']}>
												<SingleSelect
													options={dppGroups}
													infobefore={'ewew'}
													onChangeValue={(
														valueName: string,
														valueID: number
													) => {
														setSelectedDPPGroup(valueID)
													}}
													//   {...register("company", {required: true})}
												/>
											</div>
										</div>
									</li>
									<li className={styles['double-list__item']}>
										<div className={styles['double-list__item-wrapper']}>
											<div className={styles['identifier']}>
												Академическая Группа (одного из участников):
											</div>
											<div className={styles['content']}>
												<SingleSelect
													options={academicGroups}
													infobefore={'ewew'}
													onChangeValue={(
														valueName: string,
														valueID: number
													) => {
														setSelectedAcademicGroup(valueID)
													}}
													//   {...register("company", {required: true})}
												/>
											</div>
										</div>
									</li>
									<li className={styles['double-list__item']}>
										<div className={styles['double-list__item-wrapper']}>
											<div className={styles['identifier']}>Факультет:</div>
											<div className={styles['content']}>
												<SingleSelect
													options={facults}
													infobefore={'ewew'}
													onChangeValue={(
														valueName: string,
														valueID: number
													) => {
														setSelectedFaculty(valueID)
													}}
												/>
											</div>
										</div>
									</li>
									<li className={styles['double-list__item']}>
										<div className={styles['double-list__item-wrapper']}>
											<div className={styles['identifier']}>
												Компания, которую выбрали:
											</div>
											<div className={styles['content']}>
												<SingleSelect
													options={companies}
													infobefore={'ewew'}
													onChangeValue={(
														valueName: string,
														valueID: number
													) => {
														setSelectedCompany(valueID)
													}}
												/>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<ButtonWrapper>
								<Button styling={'classic'} onClick={findTeams}>
									Поиск
								</Button>
								<Button
									styling={'classic'}
									onClick={() => {
										navigate(`${paths.team.base}/${paths.team.search}`)
									}}
								>
									Вернуться к списку команд
								</Button>
							</ButtonWrapper>
						</ContentCard>
					</ContentCardWrapper>
				</ContainerMain>
			) : (
				<AllTeams teamsFromExtendedSearch={teams} />
			)}
		</>
	)
}
