import { Link } from 'react-router-dom'
import Notification from '../../assets/img/icons/notification.svg'

import notifStyle from '../../assets/styles/components/notifStyle.module.scss'

function SignIn() {
	return (
		<div className='sign-in'>
			<div className={notifStyle.NotifImg}>
				<img src={Notification} alt='fdsf' />
			</div>
			<Link to='/'>
				<span className='sign-in__item__text'>Войти</span>
			</Link>
		</div>
	)
}

export default SignIn
