import { createSlice } from "@reduxjs/toolkit";


const initialState = {

};

export const createTeamSlice = createSlice({
    name: "createTeam",
    initialState,
    reducers: {},
});

export const {} = createTeamSlice.actions;
export default createTeamSlice.reducer;
