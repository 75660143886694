import ContentCardWrapper from "../common/ContentCard/ContentCardWrapper";
import ContentCard from "../common/ContentCard/ContentCard";
import ContentCardHeading from "../common/ContentCard/ContentCardHeading";
import ContentCardInner from "../common/ContentCard/ContentCardInner";
import Contacts from "./Contacts";

const AdminMainWrapper = () => {

    return (
        <>
            <div className="questionnaire-user-info">
                <div className="forms-page-counter">{"2/2"}</div>
                <ContentCardWrapper>
                    <ContentCard>
                        <ContentCardHeading subtopic="Расскажите интересы какой компании вы представляете" />
                        <ContentCardInner>
                            <Contacts />
                        </ContentCardInner>
                    </ContentCard>
                </ContentCardWrapper>
            </div>
        </>
    );
};

export default AdminMainWrapper;
