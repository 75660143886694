import { createSlice } from "@reduxjs/toolkit";
import { CreateTeamDTO } from "../../@types/createCommand";
import { ChooseTopicDTO } from "../../@types/createTopic";

export interface FormSliceState {
    createTeam: CreateTeamDTO;
    chooseTopic: ChooseTopicDTO;
}

const initialState: FormSliceState = {
    createTeam: {
        title: "",
        teamDescription: "",
    },
    chooseTopic: {
        teamId: 0,
        topicId: 0,
    },
};

export const formSlice = createSlice({
    name: "form",
    initialState: initialState,
    reducers: {
        setValues: (state, action) => {
            state = action.payload;
        },
    },
});

export const { setValues } = formSlice.actions;

export default formSlice.reducer;
