import styles from "./content-card.module.scss";

interface ContentCardWrapperProps {
	children?: React.ReactNode;
}

const ContentCardWrapper = ({ children }: ContentCardWrapperProps) => {
	return <div className={styles["wrapper"]}>{children}</div>;
};

export default ContentCardWrapper;
