import axios from 'axios'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setTeamLeadStatus } from '../../../redux/slices/myTeamSlice'
import { RootState } from '../../../redux/store'
import { APPLICATION_MICROSERVICE_URL } from '../../../utils/team'
import { getTokens } from '../../../utils/token'
import Button from '../common/Button/Button'
import ContainerMain from '../common/ContainerMain/ContainerMain'
import ContentCard from '../common/ContentCard/ContentCard'
import ContentCardFooter from '../common/ContentCard/ContentCardFooter'
import ContentCardInner from '../common/ContentCard/ContentCardInner'
import ContentCardWrapper from '../common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../common/ContentMainTheme'

const SelectTeam = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { teamLeadStatus } = useSelector((state: RootState) => state.myTeam)

	const canCreate = () => {
		return (
			teamLeadStatus === 'NOT_SENT' ||
			teamLeadStatus === 'ACCEPTED' ||
			teamLeadStatus === 'REJECTED'
		)
	}

	useEffect(() => {
		;(async () => {
			const tokens = getTokens()
			try {
				const res = await axios.get(
					`${APPLICATION_MICROSERVICE_URL}/api/v1/requestsToAdmin/status/teamLead`,
					{
						headers: {
							Authorization: `Bearer ${tokens.access_token}`,
						},
					}
				)

				if (res.data.data === null) {
					dispatch(setTeamLeadStatus({ status: 'NOT_SENT' }))
				} else {
					switch (res.data.data.status) {
						case 'ACCEPTED':
							dispatch(setTeamLeadStatus({ status: 'ACCEPTED' }))
							break
						case 'REJECTED':
							dispatch(setTeamLeadStatus({ status: 'REJECTED' }))
							break
						case 'IN_REVIWEVING':
							dispatch(setTeamLeadStatus({ status: 'IN_REVIWEVING' }))
							break
					}
				}
			} catch (err) {
				console.error(err)
			}
		})()
	}, [canCreate])
	return (
		<ContainerMain>
			<ContentMainTheme>Моя команда</ContentMainTheme>
			<ContentCardWrapper>
				<ContentCard>
					<ContentCardInner>
						<div className='standart-mobile-text'>
							У вас пока еще нет команды. Вы можете создать команду или найти
							ее:
						</div>
					</ContentCardInner>
					<ContentCardFooter>
						<div className='button-block'>
							{canCreate() && (
								<Button
									action='link'
									to={
										teamLeadStatus === 'ACCEPTED'
											? '../create/form'
											: '../create/disclaimer'
									}
								>
									Создать свою команду
								</Button>
							)}
							<Button action='link' to='search'>
								Найти команду
							</Button>
						</div>
					</ContentCardFooter>
				</ContentCard>
			</ContentCardWrapper>
		</ContainerMain>
	)
}

export default SelectTeam
