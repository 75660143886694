import ContentCardWrapper from "../common/ContentCard/ContentCardWrapper";
import ContentCard from "../common/ContentCard/ContentCard";
import ContentCardHeading from "../common/ContentCard/ContentCardHeading";
import ContentCardInner from "../common/ContentCard/ContentCardInner";
import CustomerMainInfo from "./CustomerMainInfo";

const AdminMainWrapper = () => {
	return (
		<>
			{window.location.pathname.split("/").at(-1) === "main" && (
				<h1 className="questionnare-fill-invitation">
					Для продолжения заполните следующую форму:
				</h1>
			)}
			<div className="questionnaire-user-info">
				<div className="forms-page-counter">{"1/2"}</div>
				<ContentCardWrapper>
					<ContentCard>
						<ContentCardHeading subtopic="Укажите свои данные" />
						<ContentCardInner>
							<CustomerMainInfo />
						</ContentCardInner>
					</ContentCard>
				</ContentCardWrapper>
			</div>
		</>
	);
};

export default AdminMainWrapper;
