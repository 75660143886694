import { User } from "../../@types/entities/User";
import { AcademicGroup } from "../../@types/entities/AcademicGroup";
import { DPPGroup } from "../../@types/entities/DPPGroup";
import { Degree } from "../../@types/entities/Degree";
import { Filial } from "../../@types/entities/Filial";
import { EducationForm } from "../../@types/entities/EducationForm";
import { UserRole } from "../../@types/entities/UserRole";
import { createSlice } from "@reduxjs/toolkit";

export interface TLInfo {
  profile: {
    owner: User;
    program: {
      title: string;
    };
    dppGroup: DPPGroup;
    birthDate: string;
    academicGroup: AcademicGroup;
    team: string;
    teamLead: {
      FIO: string;
    };
  };
}

const initialState: TLInfo = {
  // @ts-ignore
  profile: {
    owner: {
      id: 0,
      username: "",
      name: "",
      surname: "",
      patronymic: "",
      phoneNumber: "",
      MAIEmail: "",
      telegram_link: "",
      email: "",
      authorities: "",
      credentialsNonExpired: "",
      enabled: "",
      password: "",
      role: UserRole.CURATOR,
      fio: "",
    },
  },
  birthDate: "27.07.2004",
  program: {
    title: `Цифровое моделирование и суперкомпьютерные технологии`,
  },
  dppGroup: {
    title: `И08-806ПП-05-22`,
  },
  academicGroup: {
    id: 0,
    educationForm: EducationForm.CORRESPONDENCE,
    filial: Filial.MAI,
    degree: Degree.UNDERGRADUATE,
    facultyNumber: 8,
    courseNumber: 1,
    groupNumber: 107,
    yearOfAdmission: 2022,
  },
  team: "Отсутствует",
  teamLead: {
    FIO: "ФИО",
  },
};

export const TLInfoSLice = createSlice({
  name: "TLinfoSlice",
  initialState,
  reducers: {},
});

export const {} = TLInfoSLice.actions;
export default TLInfoSLice.reducer;
