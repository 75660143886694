import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Button from '../../hooks/components/common/Button/Button'
import ContentCard from '../../hooks/components/common/ContentCard/ContentCard'
import ContentCardWrapper from '../../hooks/components/common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../hooks/components/common/ContentMainTheme'
import { APPLICATION_MICROSERVICE_URL } from '../../utils/team'
import {
	checkIsTokenExpired,
	getTokens,
	isTokenInLS,
	updateToken,
} from '../../utils/token'

type TokenType = {
	access_token: string
	refresh_token: string
}

const AnswerUser = () => {
	const location = useLocation()
	const { answerItem, queItem, id } = location.state || {}
	const [selectedCategory, setSelectedCategory] = useState('')
	const [questionContent, setQuestionContent] = useState('')
	const [answerContent, setAnswerContent] = useState('')
	const [message, setMessage] = useState('')

	let admin: boolean = false
	let student: boolean = false

	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		const isAdmin = roles.indexOf('client-admin') > -1
		if (isAdmin) admin = isAdmin
		const isStudent = roles.indexOf('client-student') > -1
		const isTeamLead = roles.indexOf('client-teamlead') > -1
	}
	const navigate = useNavigate()
	const [modalActive, setModalActive] = useState(false)

	const onSelect = ({ target }: any) => {
		setSelectedCategory(target.value)
	}

	const dispatch = useDispatch()
	let tokens: TokenType = getTokens()

	const handleSendQuestion = async (questionId: any, answerContent: any) => {
		if (tokens != null) {
			if (checkIsTokenExpired(tokens.access_token)) {
				if (checkIsTokenExpired(tokens.refresh_token)) {
					window.localStorage.removeItem('token')
					navigate('/')
				} else {
					await updateToken()
					tokens = getTokens()
				}
			}
			axios
				.post(
					`${APPLICATION_MICROSERVICE_URL}/help/questions/answer/${questionId}`,
					{
						feedback: answerContent,
					},
					{
						headers: {
							Authorization: `Bearer ${getTokens().access_token}`,
						},
					}
				)
				.then(response => {
					console.log(response)
				})
				.catch(error => {
					console.log(error)
				})
		}
		setMessage('Ваш ответ был отправлен')
	}

	useEffect(() => {
		if (isTokenInLS()) {
			const token: any = jwt_decode(getTokens().access_token)
		}
	}, [])

	return (
		<>
			<ContentMainTheme>Ответить на вопрос</ContentMainTheme>
			<ContentCardWrapper>
				<ContentCard transparent low>
					<div className='ask-question-textarea-wrapper'>
						Опишите ваш ответ на вопрос:
						<textarea
							rows={10}
							cols={36}
							value={answerContent}
							onChange={e => setAnswerContent(e.target.value)}
							className='ask-question-textarea-input'
						/>
						{message}
						<div className='ask-question-button-wrapper'>
							<Link onClick={e => handleSendQuestion(id, answerContent)} to=''>
								<Button>Отправить</Button>
							</Link>
							<Button action='link' spacing='16' to='../question-history'>
								Назад
							</Button>
						</div>
					</div>
				</ContentCard>
			</ContentCardWrapper>
		</>
	)
}

export default AnswerUser
