const paths = {
	base: '/',
	team: {
		base: '/team',
		passportProject: '/passportProject',
		select: 'select',
		selectSearch: 'select/search',
		my: 'my',
		teamId: ':teamId',
		selectNewTeamLead: 'select-new-teamlead',
		selectDeputyTeamLead: 'select-deputy-teamlead',
		selectCase: 'select-case',
		create: {
			base: 'create',
			disclaimer: 'disclaimer',
			request: 'request',
			form: 'form',
			formFinish: 'form/finish',
		},
		search: 'search',
		studentList: 'student-list',
		extraSearch: 'extra-search',
		extraSearchStudents: 'extra-search-students',
	},
	connection: {
		base: 'connection',
	},
	questionnaire: {
		base: 'questionnaire',
		user: {
			base: 'user',
			main: 'main',
			role: 'role',
			skills: 'skills',
			anotherSkills: 'skills/another',
			survey: 'survey',
			finish: 'finish',
		},
	},
	help: {
		base: 'help',
		popularQuestion: '/help/popular-question',
		askQuestionForm: '/help/ask-question-form',
		questionHistory: '/help/question-history',
		questionSet: '/help/question-set',
		answerUser: '/help/answer-user',
	},
	admin: {
		base: 'admin',
		users: 'users',
		companies: 'companies',
		admins: 'admins',
		teams: 'teams',
		createTeam: 'create-team',
		passportList: 'passportList',
	},
	personalAccount: {
		base: 'PersonalAccount',
		myData: 'MyData',
		skills: 'Skills',
		rating: 'Rating',
	},
	profile: {
		base: `/profile/:username`,
		skills: '/profile/:username/Skills',
		data: '/profile/:username/data',
	},
	notifications: {
		base: 'notifications',
		all: 'all',
		read: 'read',
		unread: 'unread',
	},
	teamTest: '/MyTeamTest',
	case: {
		base: '/case',
		info: {
			base: 'Info/:id',
			passportProject: 'pasportProject',
			projectDescription: 'ProjectDescription',
			exampleProject: 'ExampleProject',
		},
		create: 'create',
	},
	examples: '/examples',
	adminQuestionnaire: {
		base: '/admin/questionnaire',
		info: 'info',
		role: 'role',
		skills: 'skills',
		anotherSkills: 'skills/another',
		survey: 'survey',
		finish: 'finish',
	},
	customer: {
		base: '/customer/questionnaire',
		info: 'info',
		contact: 'contact',
		finish: 'finish',
	},
	anyError: '/any-error',
	NotFoundPage: 'not-found',
	CreateCurator: '/create-curator',
}

const test = {
	base: '/',
	team: {
		base: '/team',
		select: 'select',
		selectSearch: 'select-search',
		my: 'my',
		selectNewTeamLead: 'select-new-teamlead',
		selectDeputyTeamLead: 'select-deputy-teamlead',
		selectCase: 'select-case',
		create: {
			base: 'create',
			disclaimer: 'disclaimer',
			request: 'request',
			form: 'form',
			formFinish: 'form-finish',
		},
		search: 'search',
		studentList: 'student-list',
	},
	questionnaire: {
		base: 'questionnaire',
		user: {
			base: 'user',
			main: 'main',
			role: 'role',
			skills: 'skills',
			anotherSkills: 'skills-another',
			survey: 'survey',
			finish: 'finish',
		},
	},
	help: {
		base: 'help',
		popularQuestion: '/help/popular-question',
		askQuestionForm: '/help/ask-question-form',
		questionHistory: '/help/question-history',
		questionSet: '/help/question-set',
		answerUser: '/help/answer-user',
	},
	admin: {
		base: 'admin',
		users: 'users',
		companies: 'companies',
		admins: 'admins',
	},
	personalAccount: {
		base: 'personal-account',
		myData: 'my-data',
		skills: 'skills',
		rating: 'rating',
	},
	profile: '/profile/:userId',
	notifications: {
		base: 'notifications',
		all: 'all',
		read: 'read',
		unread: 'unread',
	},
	teamTest: '/my-team-test',
	case: {
		base: '/case',
		info: {
			base: 'info',
			passportProject: 'passport-project',
			projectDescription: 'project-description',
			exampleProject: 'example-project',
		},
	},
	examples: '/examples',
	adminQuestionnaire: {
		base: '/admin/questionnaire',
		info: 'info',
		role: 'role',
		skills: 'skills',
		anotherSkills: 'skills-another',
		survey: 'survey',
		finish: 'finish',
	},
	customer: {
		base: '/customer/questionnaire',
		info: 'info',
		contact: 'contact',
		finish: 'finish',
	},
	anyError: '/any-error',
	NotFoundPage: '*',
	extraSearch: 'extra-search',
	extraSearchStudents: 'extra-search-students',
}
export default paths
