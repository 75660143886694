import jwt_decode from 'jwt-decode'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Application } from '../../@types/entities/Application'
import { ApplicationType } from '../../@types/entities/ApplicationType'
import notifStyle from '../../assets/styles/components/notifStyle.module.scss'
import ModalWindow from '../../hooks/components/common/ModalWindow/ModalWindow'
import PaginationBar from '../../hooks/components/common/Tables/PaginationBar'
import { RootState } from '../../redux/store'
import { getTokens, isTokenInLS } from '../../utils/token'
import { useResponseOnApplication } from '../../utils/useResponseOnApplication'
import ViewApplications from './ViewApplications'

const ApplicationsPage: React.FC = () => {
	const applicationList = useSelector(
		(state: RootState) => state.NotifcationReducer.applications
	)
	const ActiveIndex = useSelector(
		(state: RootState) => state.activeIndexReducer.activeIndex
	)
	const startPage = useSelector(
		(state: RootState) => state.currentPage.currentPage
	)
	const ReversedApplicationList = [...applicationList].reverse().sort((a, b) => {
		const order = {
			'REQUEST_TO_THE_TEAM': 1,
			'MEMBERS_APPLICATION': 2,
			'TEAMLEAD_APPLICATION': 3,
			'INVITE_TO_THE_TEAM': 4,
			'QUESTION_FOR_CURATOR': 5,
			'QUESTION_FOR_SUPPORT': 6,
			'QUESTION_FOR_MAIN_ADMIN': 7
		};
		const statusA = a.status === 'IN_REVIWEVING' ? -1 : 1;
		const statusB = b.status === 'IN_REVIWEVING' ? -1 : 1;
		const typeA = a.type as keyof typeof order;
		const typeB = b.type as keyof typeof order;
		
		if (statusA !== statusB) {
			return statusA - statusB;
		} else {
			return (order[typeA] || 0) - (order[typeB] || 0);
		}
	});

	const [optionPage, setOptionPage] = useState<number[]>([])
	const [currentPage, setCurrentPage] = useState<number>(startPage)
	const [searchValue, setSearchValue] = useState('')

	// филтрация по ролям
	const MainAdminList = ReversedApplicationList.filter(
		item =>
			item.type === 'QUESTION_FOR_MAIN_ADMIN' ||
			item.type === 'QUESTION_FOR_SUPPORT' ||
			(item.type === 'TEAMLEAD_APPLICATION' &&
				item?.subject &&
				!item?.recipient) ||
			(item.type === 'TEAMLEAD_APPLICATION' && !item?.subject) ||
			item.type === 'MEMBERS_APPLICATION' ||
			(item.type === 'REQUEST_TO_THE_TEAM' && item?.program)
	)
	const ProgrammCuratorList = ReversedApplicationList.filter(
		item =>
			item.type === 'QUESTION_FOR_CURATOR' ||
			item.type === 'MEMBERS_APPLICATION' ||
			item.type === 'TEAMLEAD_APPLICATION'
	)
	const StudentTeamLeadList = ReversedApplicationList.filter(
		item => item.type === 'REQUEST_TO_THE_TEAM'
	)
	const StudentOnlyList = ReversedApplicationList.filter(
		item =>
			item.type === 'INVITE_TO_THE_TEAM' ||
			(item.type === 'TEAMLEAD_APPLICATION' &&
				item?.subject?.id &&
				item?.recipient)
	)

	let teamlead: boolean = false
	let Mainadmin: boolean = false
	let student: boolean = false
	let ProgrammCurator: boolean = false

	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		Mainadmin = roles.indexOf('client-main-admin') > -1
		teamlead = roles.indexOf('client-teamlead') > -1
		student = roles.indexOf('client-student') > -1
		ProgrammCurator = roles.indexOf('client-program-curator') > -1
	}

	//выбор нужного списка заявок относительно роли и статусов(activeIndex)
	let currentList: Application[] = []
	switch (true) {
		case Mainadmin:
			switch (ActiveIndex) {
				case 1:
					currentList = MainAdminList.filter(
						item => item.status === 'ACCEPTED' || item.status === 'REJECTED'
					)
					break
				case 2:
					currentList = MainAdminList.filter(
						item => item.status === 'IN_REVIWEVING'
					)
					break
				default:
					currentList = MainAdminList
					break
			}
			break
		case student:
			if (teamlead) {
				switch (ActiveIndex) {
					case 1:
						currentList = StudentTeamLeadList.filter(
							item => item.status === 'ACCEPTED' || item.status === 'REJECTED'
						)
						break
					case 2:
						currentList = StudentTeamLeadList.filter(
							item => item.status === 'IN_REVIWEVING'
						)
						break
					default:
						currentList = StudentTeamLeadList
						break
				}
			} else {
				switch (ActiveIndex) {
					case 1:
						currentList = StudentOnlyList.filter(
							item => item.status === 'ACCEPTED' || item.status === 'REJECTED'
						)
						break
					case 2:
						currentList = StudentOnlyList.filter(
							item => item.status === 'IN_REVIWEVING'
						)
						break
					default:
						currentList = StudentOnlyList
						break
				}
			}
			break
		case ProgrammCurator:
			currentList = ProgrammCuratorList
			switch (ActiveIndex) {
				case 1:
					currentList = ProgrammCuratorList.filter(
						item => item.status === 'ACCEPTED' || item.status === 'REJECTED'
					)
					break
				case 2:
					currentList = ProgrammCuratorList.filter(
						item => item.status === 'IN_REVIWEVING'
					)
					break
				default:
					currentList = ProgrammCuratorList
					break
			}
			break
	}
	function handleChangeVal(e: {
		target: { value: React.SetStateAction<string> }
	}) {
		setSearchValue(e.target.value)
	}
	const [FilteredList, setFilteredList] = useState<Application[]>()

	useEffect(() => {
		if (searchValue) {
			setFilteredList(
				currentList.filter(
					item =>
						item?.initiator?.fio
							.toLowerCase()
							.includes(searchValue.toLowerCase()) ||
						item?.program?.title
							?.toLowerCase()
							.includes(searchValue.toLowerCase()) ||
						item?.subject?.title
							?.toLowerCase()
							.includes(searchValue.toLowerCase())
				)
			)
		} else {
			setFilteredList(currentList)
		}
	}, [searchValue, currentList])
	let totalPages: number = FilteredList
		? Math.floor(FilteredList.length / 15) + (FilteredList.length % 15 ? 1 : 0)
		: 0

	useEffect(() => {
		const pages = Array.from({ length: totalPages }, (_, i) => i)
		setOptionPage(pages)
	}, [totalPages, currentPage, ActiveIndex])

	const currentListToShow = (list: any[]) => {
		const startIndex = currentPage * 15
		const endIndex = startIndex + 15
		return list.slice(startIndex, endIndex)
	}
	const ListToShow = currentListToShow(
		FilteredList ? FilteredList : currentList
	)

	const { ResponseOnApplications, resultBadRequest, setResultBadRequset } =
		useResponseOnApplication()

	const onClickAccept = (
		applicationId: number | undefined,
		type?: string,
		teamId?: number
	) => {
		const action = 'ACCEPTED'
		ResponseOnApplications(applicationId, action, type, teamId)
	}

	const onClickReject = (applicationId: number | undefined, type?: string) => {
		const action = 'REJECTED'
		ResponseOnApplications(applicationId, action, type)
	}

	const navigate = useNavigate()

	const GoToQuestion = () => {
		navigate('/help/question-history')
	}

	const [isVisibleModal, setVisibilityModal] = useState<boolean>(false)

	if (resultBadRequest) {
		setVisibilityModal(true)
		setResultBadRequset(false)
	}

	const [windowContent, setWindowContent] = useState<React.ReactNode>(null)
	const [type, setType] = useState<ApplicationType>()

	return (
		<>
			<div className={notifStyle.mainSection}>
				<ModalWindow visibility={!!windowContent}>{windowContent}</ModalWindow>
				<ViewApplications
					setWindowContent={setWindowContent}
					currentList={currentList}
					ListToShow={ListToShow}
					Accept={onClickAccept}
					Reject={onClickReject}
					GoToQuestion={GoToQuestion}
					visibleModalWithError={isVisibleModal}
					setVisibilityModal={setVisibilityModal}
					pageIndex={currentPage}
				/>
			</div>
			<div className={notifStyle.footerSection}>
				<PaginationBar
					pageIndex={currentPage}
					visiblePaginationRange={2}
					pageOptions={optionPage}
					pageCount={totalPages}
					previousPage={() => setCurrentPage(old => Math.max(old - 1, 0))}
					nextPage={() =>
						setCurrentPage(old => Math.min(old + 1, totalPages - 1))
					}
					gotoPage={(page: number) => setCurrentPage(page)}
					canPreviousPage={currentPage > 0}
					canNextPage={currentPage < totalPages - 1}
				/>
				<input
					type='text'
					className='case-input'
					onChange={handleChangeVal}
					placeholder='Поиск по ФИО/программа/команда'
				/>
			</div>
		</>
	)
}
export default ApplicationsPage
