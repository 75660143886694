import axios from 'axios'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AdminQuestionnaireCreateDTO } from '../../../../../@types/dtos/AdminQuestionnaireCreateDTO'
import { setAdminInfo } from '../../../../../redux/slices/AdminInfoSlice'
import { RootState } from '../../../../../redux/store'
import { PASSPORT_MICROSERVICE } from '../../../../../utils/team'
import {
	checkIsTokenExpired,
	getTokens,
	updateToken,
} from '../../../../../utils/token'
import Button from '../../../common/Button/Button'
import FormInput, { makeProps } from '../FormInput'
import FormNavigateButtons from '../FormNavigateButtons'
import FormPanel from '../FormPanel'

const MainWrapper = () => {
	const dispatch = useDispatch()
	const user = useSelector(
		(state: RootState) => state.AdminInfoReducer.profile.owner
	)
	const onChange = ({ target }: any) => {
		dispatch(setAdminInfo({ name: target?.name, value: target?.value }))
	}
	const navigate = useNavigate()
	const [allFilled, setAllFilled] = useState(false)
	const canGoNext = () => {
		return [
			user.name !== '',
			user.surname !== '',
			user.email !== '',
			user.phoneNumber !== '',
		].reduce((result, current) => result && current)
	}
	const saveQuestionnare = async (body: AdminQuestionnaireCreateDTO) => {
		let token = getTokens()

		if (checkIsTokenExpired(token.access_token)) {
			if (checkIsTokenExpired(token.refresh_token)) navigate('/')
			updateToken()
		}

		token = getTokens()

		const headers = {
			Authorization: `Bearer ${token.access_token}`,
		}

		const options = {
			headers,
		}
		const resultOfRequest = await axios
			.post(
				`${PASSPORT_MICROSERVICE}/api/v1/questionnaire/admin`,
				body,
				options
			)
			.catch(error => {
				if (error?.response?.status === 404) {
					navigate('*')
					console.log(error)
				} else {
					navigate('/AnyError')
					console.log(error)
				}
			})
		navigate('/PersonalAccount/MyData')
	}

	const finishFillingQueistionnare = () => {
		const copyUser = { ...user } as AdminQuestionnaireCreateDTO

		saveQuestionnare(copyUser)
	}
	const patterns = {
		name: /^[а-яА-ЯёЁ-]+$/,
		email: /^[A-Z0-9._%+-]+@[A-Z.]+\.[A-Z]{2,4}$/i,
		phoneNumber: /^(8|\+7)[0-9]{10}$/,
		vkLink: /^@[A-Za-z0-9_.]{5,30}$/,
	}
	return (
		<FormPanel method='POST'>
			{/* <NameForm /> */}

			<FormInput
				required
				label='Имя'
				{...makeProps.forInput('name', user, onChange)}
				pattern={patterns.name}
			/>

			<FormInput
				required
				label='Фамилия'
				{...makeProps.forInput('surname', user, onChange)}
				pattern={patterns.name}
			/>

			<FormInput
				label='Отчество'
				{...makeProps.forInput('patronymic', user, onChange)}
			/>

			<FormInput
				required
				label='Личная почта'
				type='email'
				{...makeProps.forInput('email', user, onChange)}
				pattern={patterns.email}
			/>
			<div className='desktop-qustion'>
				<FormInput
					required
					label='Номер телефона'
					type='tel'
					{...makeProps.forInput('phoneNumber', user, onChange)}
					inputDescription='
                    Номер вводится с +7 или 8. Пример: +79251234567'
					labelDescription='Скрыто по умолчанию'
					pattern={patterns.phoneNumber}
				/>
			</div>
			<div className='mobile-qustion'>
				<FormInput
					required
					label='Номер телефона'
					type='tel'
					{...makeProps.forInput('phoneNumber', user, onChange)}
					pattern={patterns.phoneNumber}
				/>
			</div>
			<div className='desktop-qustion'>
				<FormInput
					label='Telegram'
					{...makeProps.forInput('telegram_link', user, onChange)}
					inputDescription='Ссылка на TG в формате @nickname'
					labelDescription='Можно будет скрыть в личном кабинете'
					pattern={patterns.vkLink}
				/>
			</div>
			<div className='mobile-qustion'>
				<FormInput
					label='Telegram'
					{...makeProps.forInput('telegram_link', user, onChange)}
					pattern={patterns.vkLink}
				/>
			</div>

			<FormNavigateButtons>
				<Button
					action='formButton'
					disabled={!canGoNext()}
					onClick={finishFillingQueistionnare}
					spacing='8'
				>
					Завершить
				</Button>
			</FormNavigateButtons>
		</FormPanel>
	)
}

export default MainWrapper
