import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../redux/store'

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { TopicDTO } from '../../@types/createTopic'
import Button from '../../components/common/Button/Button'
import CaseCard from '../../components/common/CaseCard/CaseCard'
import CaseContentCard from '../../components/common/ContentCard/CaseContentCard'
import ContentCardWrapper from '../../components/common/ContentCard/ContentCardWrapper'
import LoadingText from '../../components/common/Loaders/LoadingText'
import SingleSelect from '../../hooks/components/SingleSelect'
import paths from '../../paths'
import { setCaseList, setEditStatus } from '../../redux/slices/caseSlice'
import {
	CREATE_COMMAND_MICROSERVICE_URL,
	MODERATION_MICROSERVICE_URL,
	sendAxiosWithTokens,
} from '../../utils/team'
import { getDecodedToken } from '../../utils/token'

type TokenType = {
	access_token: string
	refresh_token: string
}

const Case = () => {
	const [textval, setTextval] = useState('')
	const [isLoading, setIsLoading] = useState(true)
	const [topicsStatus, setTopicsStatus] = useState<string>('All')

	const topicStatus = [
		{
			valueName: 'All',
			valueId: 2,
			label: 'Все',
		},
		{
			valueName: 'HIDDEN',
			valueId: 0,
			label: 'Скрытые',
		},
		{
			valueName: 'DISPLAYED',
			valueId: 1,
			label: 'Видимые',
		},
	]
	function handleChangeVal(e: {
		target: { value: React.SetStateAction<string> }
	}) {
		setTextval(e.target.value)
	}

	const { AllCases } = useSelector((state: RootState) => state.InfoCompany)
	const reversedCases = [...AllCases.CaseList].reverse()

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const roles = getDecodedToken()?.resource_access['spring-client'].roles
	const CheckRole = () => {
		return (
			roles.includes('client-program-curator') ||
			roles.includes('client-main-admin') ||
			roles.includes('client-customer')
		)
	}
	const GetCasesList = async () => {
		if (CheckRole() && topicsStatus !== 'All') {
			const body = `{
        "topicStatus": "${topicsStatus}",
        "page": 1,
        "pageSize": 8
      }`
			const obj = JSON.parse(body)
			const url = `${MODERATION_MICROSERVICE_URL}/api/v1/admin/topic/getTopics`
			await sendAxiosWithTokens(
				`post`,
				`${MODERATION_MICROSERVICE_URL}/api/v1/admin/topic/filterTopics`,
				obj
			)
				.then(r => {
					dispatch(setCaseList(r.data.data?.content))
				})
				.catch(e => console.log(e))
		} else {
			await sendAxiosWithTokens(
				`get`,
				`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/case`
			)
				.then(r => {
					dispatch(setCaseList(r.data.data))
					setIsLoading(false)
					// console.log('список всех кейсов', r.data.data)
				})
				.catch(e => {
					if (e.response?.status === 404) {
						navigate('*')
						console.log(e)
					} else {
						navigate('/AnyError')
						console.log(e)
					}
				})
		}
	}
	const [filteredCasesList, setFilteredCasesList] = useState(reversedCases)
	const [showALL, setShowAll] = useState(false)
	useEffect(() => {
		GetCasesList()
	}, [topicsStatus])
	useEffect(() => {
		GetCasesList()
	}, [])
	useEffect(() => {
		if (textval) {
		  setFilteredCasesList(
			reversedCases
			  .filter((caseItem: TopicDTO) =>
				caseItem?.company?.title
				  ?.toLowerCase()
				  .includes(textval.toLowerCase()) ||
				caseItem?.title?.toLowerCase().includes(textval.toLowerCase()) ||
				caseItem?.programs?.some((item) =>
				  item?.title?.toLocaleLowerCase().includes(textval.toLowerCase())
				)
			  )
			  .slice()
			  .sort((a: TopicDTO, b: TopicDTO) =>
				(a?.title || '').localeCompare(b?.title || '')
			  )
		  );
		} else {
		  setFilteredCasesList(
			reversedCases
			  .slice()
			  .sort((a: TopicDTO, b: TopicDTO) =>
				(a?.title || '').localeCompare(b?.title || '')
			  )
		  );
		}
	  }, [textval, reversedCases]);
	  
	  
	const ToggleShowAll = () => {
		setShowAll(!showALL)
	}
	const ListToShow = showALL ? filteredCasesList : filteredCasesList.slice(0, 5)

	const isEditing = useSelector((state: RootState) => state.Cases.editing)
	const changeEditing = () => {
		dispatch(setEditStatus(!isEditing))
	}

	return (
		<div className='company-case-help-container'>
			<div className='case-content-wrapper'>
				<div className='content-main-theme-case'>
					Здесь вы можете найти всю подробную информацию о кейсах
				</div>

				<ContentCardWrapper>
					<CaseContentCard>
						<div className='case-title'>
							<div className='text-mobile'>
								"Кейсы" в данном контексте можно определить как комплексные
								задачи или сценарии, предложенные IT-компаниями, которые
								обеспечивают студентам оригинальные и практические основы для
								разработки проектов. Эти задачи предлагают уникальные вызовы,
								имитирующие реальные условия работы, и могут включать в себя
								разнообразные темы, включая создание продуктов, синтетическую
								генерацию наборов данных, разработку приложений или
								моделирование нейронных сетей.
							</div>
							<div className='text-desktop'>
								"Кейсы" в данном контексте можно определить как комплексные
								задачи или сценарии, предложенные IT-компаниями, которые
								обеспечивают студентам оригинальные и практические основы для
								разработки проектов. Эти задачи предлагают уникальные вызовы,
								имитирующие реальные условия работы, и могут включать в себя
								разнообразные темы, включая создание продуктов, синтетическую
								генерацию наборов данных, разработку приложений или
								моделирование нейронных сетей.
							</div>
						</div>

						{roles?.includes('client-student') ? (
							<>
								<br />
								<div className='case-title'>
									<div className='text-desktop'>
										Чтобы предложить свой кейс, необходимо подробно описать его
										в блоке "Вопросы к тех. поддержке"
									</div>
									<div className='text-mobile'>
										Чтобы предложить свой кейс, необходимо подробно описать его
										в блоке "Вопросы к тех. поддержке"
									</div>
								</div>
							</>
						) : null}

						<div className='case-wrapper-search'>
							<div className='case-search'>
								Поиск
								<input
									className='case-input'
									type='text'
									onChange={handleChangeVal}
								></input>
							</div>

							<div className='case-create'>
								{roles?.includes('client-customer') ?? (
									<button>Кейсы моей компании</button>
								)}
								{roles?.includes('client-main-admin') ||
								roles.includes('client-program-curator') ||
								roles.includes('client-main-admin') ||
								roles.includes('client-customer') ? (
									<div className='case-navbar'>
										<div className='case-select'>
											{CheckRole() ? (
												<SingleSelect
													options={topicStatus}
													infobefore={topicsStatus}
													onChangeValue={(
														valueName: string,
														valueId: number
													) => {
														setTopicsStatus(valueName)
													}}
												/>
											) : null}
										</div>
										<Button
											styling={isEditing ? 'classic' : 'text'}
											onClick={changeEditing}
										>
											{isEditing
												? 'Завершить редактирование'
												: 'Редактировать кейсы'}
										</Button>
										<Button
											type={'button'}
											action={'link'}
											to={paths.case.create}
											styling={'text'}
										>
											Создать кейс
										</Button>
									</div>
								) : (
									''
								)}
							</div>
						</div>
					</CaseContentCard>
					{isLoading ? <LoadingText /> : ''}
					{ListToShow.length == 0 ? (
						<div className='cases-showAll'>
							<Button type={'button'} styling={'text'}>
								Кейсов еще нет
							</Button>
						</div>
					) : (
						ListToShow.map((item, index) => (
							<CaseCard
								key={index}
								CaseItem={item}
								editing={isEditing}
								role={CheckRole()}
								topicStatus={topicsStatus}
							/>
						))
					)}
					{ListToShow.length > 0 ? (
						<div className='cases-showAll'>
							{filteredCasesList.length < 5 ? null : (
								<Button
									onClick={() => ToggleShowAll()}
									type={'button'}
									styling={'text'}
								>
									{showALL ? 'Свернуть' : 'Показать всё'}
								</Button>
							)}
						</div>
					) : null}
				</ContentCardWrapper>
			</div>
		</div>
	)
}

export default Case
