import React from "react";
import { Outlet } from "react-router-dom";
import ContainerMain from "../common/ContainerMain/ContainerMain";

const Questionnaire = () => {
    return (
        <div className="questionnare-page">
            <ContainerMain size={"large"}>
                <Outlet />
            </ContainerMain>
        </div>
    );
};

export default Questionnaire;
