// imports

import axios from 'axios'
import jwt_decode from 'jwt-decode'
import queryString from 'query-string'
import { getTokens as getTokensFromLS } from '../../utils/token'

const log = s => {
	console.log(s)
}

export const TOKEN_URL =
	process.env.REACT_APP_TOKEN_URL ||
	`https://auth.${window.location.host}/realms/dpp-platform/protocol/openid-connect/token`
const KEYCLOACK_AUTH_URL =
	process.env.REACT_APP_KEYCLOACK_AUTH_URL ||
	`https://auth.${window.location.host}/realms/dpp-platform/protocol/openid-connect/auth`
const FRONTEND_URL =
	process.env.REACT_APP_FRONTEND_URL || window.location.origin
// ===  Before redirect  === //

function generateRandomString(length) {
	let text = ''
	const possible =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

	for (let i = 0; i < length; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length))
	}

	return text
}

function arrayBufferToBase64(buffer) {
	var binary = ''
	var bytes = new Uint8Array(buffer)
	var len = bytes.byteLength
	for (var i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i])
	}
	return window.btoa(binary)
}

function saveStateAndVerifier(state, codeVerifier) {
	if (window.location.search.includes('state')) return
	const storage = window.sessionStorage
	storage.clear()
	storage.setItem('state', state)
	storage.setItem('code_verifier', codeVerifier)
}

const forge = require('node-forge')

async function generateCodeChallenge(codeVerifier) {
	const sha256 = forge.md.sha256.create()
	sha256.update(codeVerifier)
	let digest = sha256.digest().getBytes()

	// Сначала мы переводим данные в Base64
	let base64 = forge.util.encode64(digest)

	// Затем мы заменяем некоторые символы согласно стандарту URL-safe base64
	return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

async function makeRedirectUrl(codeChallenge, state) {
	const redirectURI = window.origin.toString() + '/test/redirect'

	let queryParams = new URLSearchParams({
		client_id: 'spring-client',
		response_type: 'code',
		state: state,
		scope: 'openid profile',
		code_challenge: codeChallenge,
		code_challenge_method: 'S256',
		redirect_uri: redirectURI,
	})

	let url = new URL(KEYCLOACK_AUTH_URL)
	url.search = queryParams

	return url.toString()
}

// === ! Before redirect ! === //
// ===  After redirect  === //

function getSavedVerifier() {
	return window.sessionStorage.getItem('code_verifier')
}

function getSavedState() {
	return window.sessionStorage.getItem('state')
}

const getAuthorizationCode = () => {
	const urlParams = new URLSearchParams(window.location.search)
	return urlParams.get('code')
}

const redirectToQuestionnaire = () => {
	const token = jwt_decode(getTokensFromLS().access_token)

	const roles = token.resource_access['spring-client'].roles
	const isAdmin =
		roles.indexOf('client-main-admin') > -1 ||
		roles.indexOf('client-program-curator') > -1
	const isStudent = roles.indexOf('client-student') > -1
	const isCustomer = roles.indexOf('client-customer') > -1

	let questionnaireUrl = `${window.location.origin.toString()}`
	if (isStudent) questionnaireUrl += `/questionnaire/user/main`
	if (isCustomer) questionnaireUrl += `/customer/questionnaire/info`
	if (isAdmin) questionnaireUrl += '/admin/questionnaire/info'
	window.location.replace(questionnaireUrl)
}

const saveTokensToLocalStorage = (access_token, refresh_token) => {
	const tokens = { access_token, refresh_token }

	window.localStorage.setItem('token', JSON.stringify(tokens))
}
// === ! After redirect ! === //

const REDIRECT_URI =
	window.location.origin !== 'http://localhost:3000'
		? `${FRONTEND_URL}/test/redirect`
		: 'http://localhost:3000/test/redirect'
// ===  Main  === //

export const authorise = () => {
	const codeVerifier = generateRandomString(128)
	const state = generateRandomString(128)

	saveStateAndVerifier(state, codeVerifier)
	generateCodeChallenge(codeVerifier)
		.then(codeChallenge => makeRedirectUrl(codeChallenge, state))
		.then(redirectUrl => {
			window.location.replace(redirectUrl)
		})
}

export const getTokens = () => {
	const authorizationCode = getAuthorizationCode()

	const savedVerifier = getSavedVerifier()
	const savedState = getSavedState()

	const stateFromURL = new URLSearchParams(window.location.search).get('state')

	if (savedState === stateFromURL) {
		const response = axios.post(
			TOKEN_URL,
			queryString.stringify({
				grant_type: 'authorization_code',
				client_id: 'spring-client',
				redirect_uri: REDIRECT_URI,
				code_verifier: savedVerifier,
				code: authorizationCode,
				scope: 'openid profile email',
			}),
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			}
		)
		response
			.then(({ data }) => {
				const access_token = data.access_token
				const refresh_token = data.refresh_token
				saveTokensToLocalStorage(access_token, refresh_token)
				window.localStorage.setItem('id_token', data.id_token)
				redirectToQuestionnaire()
			})
			.catch(console.error)
	} else {
		log('ERROR Стейты не совпадают')
	}
}
