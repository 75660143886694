import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '../../hooks/components/common/Button/Button'
import ButtonWrapper from '../../hooks/components/common/Button/ButtonWrapper'
import ContainerMain from '../../hooks/components/common/ContainerMain/ContainerMain'
import ContentCard from '../../hooks/components/common/ContentCard/ContentCard'
import ContentCardFooter from '../../hooks/components/common/ContentCard/ContentCardFooter'
import ContentCardHeading from '../../hooks/components/common/ContentCard/ContentCardHeading'
import ContentCardInner from '../../hooks/components/common/ContentCard/ContentCardInner'
import ContentCardWrapper from '../../hooks/components/common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../hooks/components/common/ContentMainTheme'
import ModalWindow from '../../hooks/components/common/ModalWindow/ModalWindow'
import UserListItem from '../../hooks/components/common/UserList/UserListItem'
import { RootState } from '../../redux/store'
import { sendTeamLeadTransferRequest, updateSquadList } from '../../utils/team'

const SelectNewTeamLeadPage = () => {
	const { squad } = useSelector((state: RootState) => state.myTeam)
	const { applications } = useSelector(
		(state: RootState) => state.NotifcationReducer
	)
	const [modalWindowContent, setModalWindowContent] =
		useState<React.ReactNode>(null)

	useEffect(() => {
		updateSquadList().catch(console.error)
	}, [])

	return (
		<>
			<ModalWindow visibility={!!modalWindowContent}>
				{modalWindowContent}
			</ModalWindow>
			<ContainerMain>
				<ContentMainTheme>Новый Team Lead</ContentMainTheme>
				<ContentCardWrapper>
					<ContentCard low>
						Выберите нового Team Lead из списка команды
					</ContentCard>
					<ContentCard>
						<ContentCardHeading topic='Список команды'></ContentCardHeading>
						<ContentCardInner>
							<div className='user-list'>
								{squad
									.filter(user => user.role?.id !== 1)
									.map((user, i) => (
										<UserListItem key={i} user={user}>
											{applications.find(
												item =>
													item.type === 'INVITE_TO_THE_TEAM' &&
													item?.recipient?.id === user.member.id
											) ? (
												<span>запрос отправлен</span>
											) : (
												<Button
													styling='text'
													onClick={() => {
														sendTeamLeadTransferRequest(user.member.id)
															.then(() =>
																setModalWindowContent(
																	<>
																		<p>Запрос успешно отправлен</p>
																		<ContentCardFooter>
																			<ButtonWrapper>
																				<Button
																					onClick={() =>
																						setModalWindowContent(null)
																					}
																				>
																					Назад
																				</Button>
																			</ButtonWrapper>
																		</ContentCardFooter>
																	</>
																)
															)
															.catch(err => {
																setModalWindowContent(
																	<>
																		<p>
																			{err.response.data.message ===
																			'Заявка уже существует'
																				? 'Вы уже подали заявку на предачу роли этому человеку.'
																				: 'Произошла ошибка, попробуйте позже.'}
																		</p>
																		<ContentCardFooter>
																			<ButtonWrapper>
																				<Button
																					onClick={() =>
																						setModalWindowContent(null)
																					}
																				>
																					Назад
																				</Button>
																			</ButtonWrapper>
																		</ContentCardFooter>
																	</>
																)
																console.error(err)
															})
													}}
												>
													отправить запрос
												</Button>
											)}
										</UserListItem>
									))}
							</div>
						</ContentCardInner>
						<ContentCardFooter>
							<ButtonWrapper>
								<Button action='link' to='../'>
									Назад
								</Button>
							</ButtonWrapper>
						</ContentCardFooter>
					</ContentCard>
				</ContentCardWrapper>
			</ContainerMain>
		</>
	)
}

export default SelectNewTeamLeadPage
