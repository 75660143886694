import jwt_decode from 'jwt-decode'
import { Link, useMatch } from 'react-router-dom'
import { getTokens, isTokenInLS } from '../../../../utils/token'

interface InformationCardActionPrompt {
	children?: React.ReactNode
	answerItem: string
	queItem: string
	onShow: any
	id: string
	isButtonClicked: any
}

const InformationCardAction: React.FC<InformationCardActionPrompt> = ({
	onShow,
	isButtonClicked,
	children,
	answerItem,
	queItem,
	id,
}) => {
	let admin: boolean = false
	let student: boolean = false
	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		const isAdmin = roles.indexOf('client-admin') > -1
		if (isAdmin) admin = isAdmin
		const isStudent = roles.indexOf('client-student') > -1
		if (isStudent) student = isStudent
	}
	return (
		<div className='information-card-action-wrapper'>
			{/* since the same component is used for faq questions and for questions 
            from users, the condition "queItem != null" I find out which button should be shown 
            to the admin: "expand" or "reply". The student does not see the "reply"*/}
			{queItem != null ? (
				<span className='information-card-show' onClick={onShow}>
					{isButtonClicked ? 'посмотреть' : 'свернуть'}
				</span>
			) : admin ? (
				<Link to='../answer-user' state={{ answerItem, queItem, id }}>
					<span className='information-card-show'>ответить</span>
				</Link>
			) : (
				<span className='information-mess' onClick={onShow}>
					К сожалению, пока нет ответа :{'('}
				</span>
			)}
			{/*Check our relative path. If the question is not 
            from the user, but from faq, then we show the "configure" 
            button on the admin*/}
			{useMatch('help/popular-question') ? (
				admin ? (
					<Link to='../question-set' state={{ answerItem, queItem, id }}>
						<span className='information-card-show2'>настроить</span>
					</Link>
				) : (
					''
				)
			) : (
				''
			)}
		</div>
	)
}

export default InformationCardAction
