import {
	GetImportantInfoDTO,
	advancedSearchStudents,
} from '../../../@types/dtos/GetImportantInfoDTO'
import Button from '../common/Button/Button'
import ContainerMain from '../common/ContainerMain/ContainerMain'
import ContentCard from '../common/ContentCard/ContentCard'
import ContentCardHeading from '../common/ContentCard/ContentCardHeading'
import ContentCardInner from '../common/ContentCard/ContentCardInner'
import ContentCardWrapper from '../common/ContentCard/ContentCardWrapper'

import React from 'react'
import { Degree } from '../../../@types/entities/Degree'
import {
	CREATE_COMMAND_MICROSERVICE_URL,
	sendAxiosWithTokens,
} from '../../../utils/team'
import MultiSelect from '../MultiSelect'
import SingleSelect from '../SingleSelect'
import ButtonWrapper from '../common/Button/ButtonWrapper'
import DoubleList from '../common/DoubleList/DoubleList'
import AllStudentsTable from './AllStudentsTable'
const rawSkills = [
	'AirFlow',
	'Atlassian stack',
	'Back-end разработка',
	'С',
	'С#',
	'С++',
	'СNN',
	'CSS',
	'Canva',
	'Computer Vision',
	'Confluence',
	'Data Engineering',
	'Data Science',
	'Deep Learning',
	'DevOps',
	'Django',
	'Docker',
	'ETL',
	'Excel',
	'Figma',
	'Front-end разработка',
	'Git',
	'Go',
	'HTML',
	'Hadoop',
	'Jira',
	'Jupyter',
	'Kafka',
	'Keras',
	'Kotlin',
	'Kubernetes',
	'Linux',
	'Machine Learning',
	'Marketing',
	'Matplotlib',
	'Microsoft Power BI',
	'NumPy',
	'ONNX Runtime',
	'OpenCV',
	'Pandas',
	'Photoshop',
	'PowerPoint',
	'PyTorch',
	'Python',
	'R',
	'React',
	'SMM',
	'SQL',
	'Scikit-Learn',
	'Seaborn',
	'TensorFlow',
	'Использование принципов Rest',
	'Математическая статистика',
	'Построение Rest API',
	'Работа с JSON',
	'Работа с XML',
	'Работа с YAML',
	'Разработка моделей данных',
	'СУБД GreenPulm',
	'СУБД MS SQL Server',
	'СУБД Oracle',
	'СУБД PostgreSQL',
	'Умение работать с API',
	'Управление проектами',
]

const AllStudents = () => {
	const [students, setStudents] = React.useState<GetImportantInfoDTO[]>([])
	const [extendedSearchIsOn, setExtendedSearchIsOn] = React.useState(false)
	const [isLoading, setIsLoading] = React.useState<boolean>(false)

	const [extendedSearchParams, setExtendedSearchParams] = React.useState<{
		programId: number | null
		DPPGroupId: number | null
		degree: string | null
		fio: string | null
		skills: string | null
	}>({
		programId: null,
		DPPGroupId: null,
		degree: null,
		fio: null,
		skills: null,
	})

	const skills = rawSkills.map((skill, index) => {
		return {
			value: skill,
			label: skill,
		}
	})

	const degrees = [
		{
			valueName: Degree.UNDERGRADUATE,
			label: 'Бакалавр',
			valueId: Degree.UNDERGRADUATE,
		},
		{
			valueName: Degree.MAGISTRACY,
			label: 'Магистратура',
			valueId: Degree.MAGISTRACY,
		},
		{
			valueName: Degree.SPACIALITY,
			label: 'Специалитет',
			valueID: Degree.SPACIALITY,
		},
	]

	const getExtendedSearch = async () => {
		setIsLoading(true)
		const body = {
			program_id: extendedSearchParams.programId,
			dpp_group_id: extendedSearchParams.DPPGroupId,
			degree: extendedSearchParams.degree,
			fio: extendedSearchParams.fio,
			skills: extendedSearchParams.skills,
		}
		if (extendedSearchIsOn) {
			const url = `${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/extend-search/students`
			await sendAxiosWithTokens(`post`, url, body)
				.then(r => {
					const newStudents: GetImportantInfoDTO[] = r.data.data.map(
						(studentData: advancedSearchStudents) => {
							return {
								userId: studentData?.owner?.id,
								fio: studentData?.owner?.fio,
								telegram_link: studentData?.owner?.telegram_link,
								email: studentData?.owner?.email,
								username: studentData?.owner?.username,
								birthDate: studentData?.birthDate,
								preferedRole: studentData?.preferedRole,
								degree: studentData?.academicGroup?.degree,
								dppGroup: studentData?.dppGroup,
								teamTitle: '',
								phoneNumber: studentData?.owner?.phoneNumber,
							}
						}
					)
					setStudents(newStudents)
					setIsLoading(false)
				})
				.catch(e => console.log(e))
		}
	}

	return (
		<ContainerMain>
			<ContentCardWrapper>
				<ContentCard low>
					<div className='text-mobile'>
						Для облегчения поиска мы решили сделать список рекомендаций, в
						котором каждый из вас сможет найти себе команду в зависимости от
						вашей предпочтительной роли, указанной в личном кабинете. Вы также
						можете воспользоваться расширенным поиском, который поможет найти
						любого пользователя и команду по названию или фильтрам
					</div>
					<div className='text-desktop'>
						Для облегчения поиска мы решили сделать список рекомендаций, в
						котором каждый из вас сможет найти себе команду в зависимости от
						вашей предпочтительной роли, указанной в личном кабинете. Вы также
						можете воспользоваться расширенным поиском, который поможет найти
						любого пользователя и команду по названию или фильтрам
					</div>
				</ContentCard>
				<ContentCard>
					<ContentCardHeading topic='Рекомендации'>
						<Button
							type={'button'}
							onClick={() => {
								if (extendedSearchIsOn) {
									// fetchDefaultStudents().then(setStudents).catch(console.error);
								}
								setExtendedSearchIsOn(!extendedSearchIsOn)
							}}
						>
							{extendedSearchIsOn
								? 'Скрыть расширенный поиск'
								: 'Расширенный поиск'}
						</Button>
					</ContentCardHeading>
					<ContentCardInner>
						{extendedSearchIsOn ? (
							<>
								<div className='extended-search'>
									<DoubleList
										listData={[
											{
												identifier: 'Поиск по необходимым навыкам в команде:',
												content: (
													<MultiSelect
														options={skills}
														infobefore={'ewew'}
														onSelectedValuesChange={(
															selectedSkills: string[]
														) =>
															setExtendedSearchParams({
																...extendedSearchParams,
																skills: selectedSkills.join(';'),
															})
														}
													/>
												),
											},
											{
												identifier: 'ФИО пользователя:',
												content: (
													<input
														className={'form-field-input '}
														//@ts-ignore
														value={extendedSearchParams.fio}
														onChange={e => {
															setExtendedSearchParams({
																...extendedSearchParams,
																fio: e.target.value,
															})
														}}
													/>
												),
											},
											{
												identifier: 'Степень:',
												content: (
													<SingleSelect
														options={degrees}
														infobefore={'ewew'}
														onChangeValue={(valueName: string) => {
															setExtendedSearchParams({
																...extendedSearchParams,
																degree: valueName,
															})
														}}
													/>
												),
											},
										]}
									/>
									<ButtonWrapper spacing>
										<Button onClick={() => getExtendedSearch()}>Найти</Button>
									</ButtonWrapper>
								</div>
							</>
						) : null}
						<div className='table-wrapper'>
							{isLoading ? null : <AllStudentsTable students={students} />}
						</div>
					</ContentCardInner>
				</ContentCard>
			</ContentCardWrapper>
		</ContainerMain>
	)
}

export default AllStudents
