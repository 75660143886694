import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import UpdatePassportDTO from '../../../../@types/dtos/UpdatePassportDTO'
import Button from '../../../../hooks/components/common/Button/Button'
import DoubleList from '../../../../hooks/components/common/DoubleList/DoubleList'
import { setFieldPasport } from '../../../../redux/slices/companyPasportSlice'
import { RootState } from '../../../../redux/store'
import {
	PASSPORT_MICROSERVICE,
	sendAxiosWithTokens,
} from '../../../../utils/team'

interface PropsTypes {
	toggle: Function
	caseId?: number
}

const PasportDescrEdit1of2 = ({ toggle, caseId }: PropsTypes) => {
	const { PasportCase } = useSelector(
		(state: RootState) => state.companyPasportSlice
	)
	const dispatch = useDispatch()

	const RequestForNewData = async (body: UpdatePassportDTO) => {
		await sendAxiosWithTokens(
			`post`,
			`${PASSPORT_MICROSERVICE}/api/v1/passport/${caseId}`,
			body
		)
			.then(r => {
				console.log('fdsf', r.data.data)
			})
			.catch(error => [console.log(error)])
	}

	const {
		register,
		getValues,
		formState: { errors, isValid },
	} = useForm<UpdatePassportDTO>({
		mode: 'onTouched',
	})

	const onSetNewData = () => {
		const value = getValues()
		const body: UpdatePassportDTO = {
			endUser: PasportCase?.endUser,
			projectDuration: PasportCase?.projectDuration,
			projectType: value['projectType'],
			projectGoal: value['projectGoal'],
			projectTasks: value['projectTasks'],
			projectResult: value['projectResult'],
			projectRestrictions: PasportCase?.projectRestrictions,
			projectResources: value['projectResources'],
			projectTaskTracker: PasportCase?.projectTaskTracker,
			projectCompitentions: PasportCase?.projectCompitentions,
			additionalInformation: PasportCase?.additionalInformation,
			projectMentor: PasportCase?.projectMentor,
			projectSupervisor: PasportCase?.projectSupervisor,
			example: PasportCase?.example,
		}
		dispatch(
			setFieldPasport({
				projectType: value['projectType'],
				projectGoal: value['projectGoal'],
				projectTasks: value['projectTasks'],
				projectResult: value['projectResult'],
				projectResources: value['projectResources'],
			})
		)

		RequestForNewData(body)
	}

	return (
		<>
			<DoubleList
				className='initials'
				listData={[
					{
						identifier:
							'Тип продукта (сервис, продукт, алгоритм, модель и т.п.',
						content: (
							<textarea
								className='company-info-input-forEdit'
								defaultValue={PasportCase?.projectType}
								{...register('projectType')}
								rows={4}
							/>
						),
					},
					{
						identifier: 'Цель проекта',
						content: (
							<textarea
								className='company-info-input-forEdit'
								defaultValue={PasportCase?.projectGoal}
								{...register('projectGoal')}
								rows={4}
							/>
						),
					},
					{
						identifier: 'Задачи проекта',
						content: (
							<textarea
								className='company-info-input-forEdit'
								defaultValue={PasportCase?.projectTasks}
								{...register('projectTasks')}
								rows={4}
							/>
						),
					},
					{
						identifier: 'Результат проекта',
						content: (
							<textarea
								className='company-info-input-forEdit'
								defaultValue={PasportCase?.projectResult}
								{...register('projectResult')}
								rows={4}
							/>
						),
					},
					{
						identifier:
							'Необходимые ресурсы для выполнения проекта (источник данных)',
						content: (
							<textarea
								className='company-info-input-forEdit'
								defaultValue={PasportCase?.projectResources}
								{...register('projectResources')}
								rows={4}
							/>
						),
					},
				]}
			/>
			<div className='link-wrapperPasport' onClick={onSetNewData}>
				<Button onClick={toggle}>Сохранить</Button>
			</div>
		</>
	)
}

export default PasportDescrEdit1of2
