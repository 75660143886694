import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import notifStyles from '../../../../assets/styles/components/notifStyle.module.scss'
import {
	TokenType,
	checkIsTokenExpired,
	getTokens,
	isTokenInLS,
} from '../../../../utils/token'
import Button from '../../common/Button/Button'

import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { useState } from 'react'
import GetUserMainInformationDTO from '../../../../@types/dtos/GetUserMainInformationDTO'
import { UserRole } from '../../../../@types/entities/UserRole'
import paths from '../../../../paths'
import {
	APPLICATION_MICROSERVICE_URL,
	sendAxiosWithTokens,
} from '../../../../utils/team'
import ButtonWrapper from '../../common/Button/ButtonWrapper'
import ModalWindow from '../../common/ModalWindow/ModalWindow'
import RightSidebarTopic from '../RightSidebar/RightSidebarTopic'
import ViewedUserCategories from './ViewedUserCategories'

export default function ViewedUserRightSidebar({
	setvisibility,
	username,
	userData,
}: {
	setvisibility: Function
	username: any
	userData?: GetUserMainInformationDTO
}) {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	let admin: boolean = false
	let student: boolean = false
	let teamlead: boolean = false

	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		admin = roles.indexOf('client-main-admin') > -1
		student = roles.indexOf('client-student') > -1
		teamlead = roles.indexOf('client-teamlead') > -1
	}
	const [badRequestMessage, setBedRequestMessage] = useState<string>('')
	const [goodResult, setGoodResult] = useState<string>('')
	const [goodUndoApprove, setGoodUndoApprove] = useState('')
	const [isVisibleModal, setVisibilityModal] = useState<boolean>(false)

	const SendRequestToAdminForApproveTeamLead = async (username: string) => {
		const tokens: TokenType = JSON.parse(window.localStorage.getItem('token')!)
		if (checkIsTokenExpired(tokens.access_token)) {
			if (checkIsTokenExpired(tokens.refresh_token)) {
				window.localStorage.removeItem('token')
				navigate('/')
			}
		}
		await axios
			.put(
				`${APPLICATION_MICROSERVICE_URL}/api/v1/requestsToAdmin/approveTeamLeadByUsername/${username}`,
				{},
				{
					headers: {
						Authorization: `Bearer ${tokens.access_token}`,
					},
				}
			)
			.then(r => {
				setGoodResult('Пользователь одобрен на роль Team Lead')
				setVisibilityModal(true)
			})
			.catch(error => {
				console.error(error)
				if (error.response.data.type === 'business') {
					setBedRequestMessage(error.response.data.message)
				}
				setVisibilityModal(true)
			})
	}

	const SendRequestToAdminForRejectTeamLead = async () => {
		await sendAxiosWithTokens(
			`put`,
			`${APPLICATION_MICROSERVICE_URL}/api/v1/requestsToAdmin/undoTeamLeadApprove/${username}`
		)
			.then(r => {
				setVisibilityModal(true)
				setGoodUndoApprove('Роль Team Lead снята')
			})
			.catch(error => {
				console.log(error)
				if (error.response.data.type === 'business') {
					setBedRequestMessage(error.response.data.message)
				}
				setVisibilityModal(true)
			})
	}

	return (
		<>
			<ModalWindow
				visibility={isVisibleModal}
				setVisibility={setVisibilityModal}
				autoClose
			>
				{badRequestMessage
					? badRequestMessage
					: goodResult
					? goodResult
					: goodUndoApprove
					? goodUndoApprove
					: 'Произошла ошибка'}
			</ModalWindow>
			<div className='personal-account-right-sidebar'>
				<div className='personal-account-right-sidebar__categories'>
					<br />
					{userData?.role !== UserRole.MAIN_ADMIN &&
					userData?.role !== UserRole.PROGRAM_CURATOR &&
					userData?.role != UserRole.CUSTOMER &&
					userData?.role !== UserRole.CURATOR ? (
						<>
							<RightSidebarTopic>Информация о пользователе</RightSidebarTopic>
							<ViewedUserCategories />
						</>
					) : null}
				</div>
				<div className='personal-account-right-sidebar__useful-links'>
					<div className={notifStyles.ForbuttonInPa}>
						<ButtonWrapper>
							<Button
								className='p-a-button'
								type='button'
								to={`/${paths.admin.base}/${paths.admin.users}`}
								action='link'
							>
								Перейти к списку пользователей
							</Button>
							{admin &&
								userData?.role !== UserRole.MAIN_ADMIN &&
								userData?.role !== UserRole.PROGRAM_CURATOR &&
								userData?.role != UserRole.CUSTOMER &&
								userData?.role !== UserRole.CURATOR && (
									<Button
										className='p-a-button'
										type='button'
										onClick={() =>
											SendRequestToAdminForApproveTeamLead(username)
										}
										disabled={userData?.isTeamLead}
									>
										{userData?.isTeamLead
											? 'Одобрен на роль Team Lead'
											: 'Одобрить на роль Team Lead'}
									</Button>
								)}
							{admin && userData?.isTeamLead ? (
								<Button onClick={SendRequestToAdminForRejectTeamLead}>
									Снять роль Team Lead
								</Button>
							) : null}
						</ButtonWrapper>
					</div>
				</div>
			</div>
		</>
	)
}
