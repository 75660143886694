import { useState } from 'react'
import Select from 'react-select'

export default function SingleSelect({ options, infobefore, onChangeValue }) {
	// Устанавливаем начальное значение как объект из options
	const [selectedOption, setSelectedOption] = useState(
		options?.find(opt => opt?.valueName === infobefore)
	)

	const onChange = newValue => {
		setSelectedOption(newValue) // устанавливаем selectedOption как выбранный объект
		if (onChangeValue) {
			onChangeValue(newValue.valueName, newValue.valueId)
		}
	}

	return (
		<div className='dropdown'>
			<Select
				placeholder={infobefore}
				classNamePrefix='custom-select'
				onChange={onChange}
				value={selectedOption} // передаем объект как значение
				options={options}
			/>
		</div>
	)
}
