import React from 'react'
import ContentCard from '../../common/ContentCard/ContentCard'
import ContentCardWrapper from '../../common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../common/ContentMainTheme'

import AboutMe from './AboutMe'
import AboutMeEditing from './AboutMeEditing'
import Role from './Role'
import RoleEditing from './RoleEditing'

import { useSelector } from 'react-redux'

import { RootState } from '../../../../redux/store'

export default function Skills() {
	const accountInformation = useSelector(
		(state: RootState) => state.personalAccount
	)
	const { profile } = useSelector((state: RootState) => state.personalAccount)

	// const { role, personalInformation } = profile;

	const [isRoleEditing, setIsRoleEditing] = React.useState(false)
	const [isAboutMeEditing, setIsAboutMeEditing] = React.useState(false)

	const AboutMePropsEdit = {
		status: isAboutMeEditing,
		toggle: () => setIsAboutMeEditing(!isAboutMeEditing),
		content: <AboutMeEditing About={profile.Skills} />,
	}
	const SkillsProps = {
		status: isRoleEditing,
		toggle: () => setIsRoleEditing(!isRoleEditing),
		content: <RoleEditing SkillsRole={profile.Skills} />,
	}

	return (
		<div className='personal-account-skills'>
			<ContentMainTheme>Навыки</ContentMainTheme>
			<ContentCardWrapper>
				<ContentCard topic='Роль и навыки' editing={SkillsProps}>
					<Role role={profile.Skills} />
				</ContentCard>

				<ContentCard topic='О себе' editing={AboutMePropsEdit}>
					<AboutMe about={profile.Skills} />
				</ContentCard>
			</ContentCardWrapper>
		</div>
	)
}
