import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { ApplicationType } from '../../@types/entities/ApplicationType'
import NotifWindow from '../../Pages/notifications/NotifWindow'
import Notification from '../../assets/img/icons/notification.svg'
import notifStyle from '../../assets/styles/components/notifStyle.module.scss'
import { setOpenOrCloseSideBar } from '../../redux/slices/OpenSideBarSlice'
import { setOpenWindow } from '../../redux/slices/notificationSlice'
import { RootState } from '../../redux/store'
import { isTokenInLS } from '../../utils/token'
import ModalWindow from './common/ModalWindow/ModalWindow'

function SignOut() {
	const { openwindow } = useSelector(
		(state: RootState) => state.NotifcationReducer
	)
	const amount = useSelector(
		(state: RootState) => state.NotifcationReducer.amount
	)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const ClickOpenWindow = () => {
		if (isTokenInLS()) {
			dispatch(setOpenWindow(!openwindow))
		}
	}

	const iconRef = useRef<HTMLDivElement | null>(null)
	const notificationRef = useRef<HTMLDivElement | null>(null)

	const handleClickOutside = (event: MouseEvent) => {
		if (
			notificationRef.current &&
			!notificationRef.current.contains(event.target as Node) &&
			iconRef.current &&
			!iconRef.current.contains(event.target as Node)
		) {
			dispatch(setOpenWindow(false))
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)

		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const onClick = () => {
		dispatch(setOpenWindow(false))
		dispatch(setOpenOrCloseSideBar(false))
		window.localStorage.removeItem('token')
		window.sessionStorage.removeItem('code_verifier')
		window.sessionStorage.removeItem('state')

		let id_token = window.localStorage.getItem('id_token')
		const domain =
			process.env.REACT_APP_KEYCLOACK_LOGOUT_URL ||
			`https://auth.${window.location.host}/realms/dpp-platform/protocol/openid-connect/logout`
		const KEYCLOACK_LOGOUT_URL = `${domain}?id_token_hint=${id_token}&post_logout_redirect_uri=${window.location.origin}/`

		window.location.assign(KEYCLOACK_LOGOUT_URL)
	}
	const [windowContent, setWindowContent] = useState<React.ReactNode>(null)
	const [type, setType] = useState<ApplicationType>()

	return (
		<>
			<ModalWindow visibility={!!windowContent} fixClass>
				{windowContent}
			</ModalWindow>

			<div className='sign-in'>
				<div
					ref={iconRef}
					className={`${notifStyle.NotifImg} ${
						openwindow ? notifStyle.activeNotifImg : ''
					}`}
					onClick={() => ClickOpenWindow()}
				>
					<Link to='/notifications/all'>
						<div className='mobile-notif'>
							<img src={Notification} alt='fdsf' />
						</div>
					</Link>

					{amount ? (
						<div
							className={`${notifStyle.amount} ${
								openwindow ? notifStyle.AmountBGIfActiveNotifImg : ''
							}`}
						>
							{amount ? `${amount}` : ''}
						</div>
					) : (
						''
					)}
				</div>

				{openwindow ? (
					<NotifWindow
						ref={notificationRef}
						setWindowContent={setWindowContent}
					/>
				) : null}
				<button className='sign-in__item__text' onClick={onClick}>
					Выйти
				</button>
			</div>
		</>
	)
}

export default SignOut
