import DoubleListItem from "./DoubleListItem";
import { DoubleListItemData } from "./DoubleListItem";
import styles from "./DoubleList.module.scss";
import SeparateLine from "../SeparateLine/SeparateLine";

interface DoubleListProps {
	listData: DoubleListItemData[];
	className?: string;
	nonUnderLine?: boolean;
}

const DoubleList = ({ listData, className = "", nonUnderLine }: DoubleListProps) => {
	// listData : array of { identifier: ReactNode; content: ReactNode; }
	return (
		<div className={"double-lsit-wrapper" + " " + className}>
			<ul className={styles["double-list"]}>
				{listData.map((itemData, i) => (
					<DoubleListItem
						nonUnderLine={nonUnderLine}
						identifier={itemData.identifier}
						content={itemData.content}
						key={i}
					/>
				))}
			</ul>
		</div>
	);
};

export default DoubleList;
