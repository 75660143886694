import axios from 'axios'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { PassportMyTeamDTO } from '../../../../@types/dtos/PassportMyTeamDTO'
import { SelectType } from '../../../../components/admin/tables/ExtendedSearchApi/ExtendedSearchApi'
import Button from '../../../../components/common/Button/Button'
import ContainerMain from '../../../../components/common/ContainerMain/ContainerMain'
import ContentCard from '../../../../components/common/ContentCard/ContentCard'
import ContentCardWrapper from '../../../../components/common/ContentCard/ContentCardWrapper'
import ContentMainTheme from '../../../../components/common/ContentMainTheme'
import SingleSelect from '../../../../hooks/components/SingleSelect'
import { PASSPORT_MICROSERVICE, fetchTeamInfo } from '../../../../utils/team'
import { TokenType, getTokens } from '../../../../utils/token'
import { fetchPassportMyTeam } from '../passport.data'
import PassportProjectCard from '../passportProjectCard/passportProjectCard'

const selectVars: SelectType[] = [
	{
		label: 'Страница 1',
		valueId: 1,
		valueName: 1,
	},
	{
		label: 'Страница 2',
		valueId: 2,
		valueName: 2,
	},
	{
		label: 'Страница 3',
		valueId: 3,
		valueName: 3,
	},
]

const CheckPassportForAdmin = () => {
	const location = useLocation()
	const teamId = location.state.teamId
	const [passportData, setPassportData] = useState<PassportMyTeamDTO>()
	const [slide, setSlide] = useState<number>(1)
	const [teamTitle, setTeamTitle] = useState<string>('')
	useEffect(() => {
		fetchTeamInfo(teamId)
			.then(r => {
				setTeamTitle(r.title)
			})
			.catch(e => console.log(e))
		if (teamId) {
			fetchPassportMyTeam(teamId)
				.then(r => {
					setPassportData(r)
				})
				.catch(error => {
					console.log(error)
				})
		}
	}, [])

	async function downloadFile(teamId: string) {
		let tokens: TokenType = getTokens()
		try {
			const response = await axios.get(
				`${PASSPORT_MICROSERVICE}/api/v1/passport/download/Commandpassport${teamId}.pdf`,
				{
					responseType: 'blob',
					headers: {
						Authorization: `Bearer ${tokens.access_token}`,
					},
				}
			)

			const url = window.URL.createObjectURL(new Blob([response.data]))

			const link = document.createElement('a')
			link.href = url

			let fileName = 'commandPassport.pdf'

			link.setAttribute('download', fileName)
			document.body.appendChild(link)
			link.click()
			link.remove()
		} catch (error) {
			console.error('Произошла ошибка при скачивании файла:', error)
		}
	}

	return (
		<ContainerMain>
			<ContentMainTheme>Паспорт команды "{teamTitle}" </ContentMainTheme>
			<ContentCardWrapper>
				<ContentCard>
					{passportData && (
						<div
							style={{
								display: 'grid',
								gridTemplateColumns: 'repeat(2, 1fr)',
								gap: '80px',
								alignItems: 'center',
							}}
						>
							<Button
								onClick={() => {
									downloadFile(teamId.toString())
								}}
							>
								Скачать паспорт проекта
							</Button>
							<SingleSelect
								options={selectVars}
								infobefore={`Страница ${slide}`}
								onChangeValue={(valueID: number) => {
									setSlide(valueID)
								}}
							/>
						</div>
					)}
					{passportData && (
						<PassportProjectCard slide={slide} passportData={passportData} />
					)}
					{!passportData && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								gap: '16px',
								marginBottom: '24px',
							}}
						>
							<div>У команды еще нет паспорта проекта...</div>
							<Button action='link' to={`/team/${teamId}`}>
								Вернуться назад
							</Button>
						</div>
					)}
				</ContentCard>
			</ContentCardWrapper>
		</ContainerMain>
	)
}

export default CheckPassportForAdmin
