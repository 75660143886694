import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import '../../assets/styles/components/Case/Info/Info.scss'
import Button from '../../hooks/components/common/Button/Button'
import CaseContentCard from '../../hooks/components/common/ContentCard/CaseContentCard'
import ContentCardWrapper from '../../hooks/components/common/ContentCard/ContentCardWrapper'
import LoadingText from '../../hooks/components/common/Loaders/LoadingText'
import { setCaseList } from '../../redux/slices/caseSlice'
import ErrorHandler from '../../utils/ErrorHandler'
import {
	CREATE_COMMAND_MICROSERVICE_URL,
	FILES_MICROSERVICE,
	MODERATION_MICROSERVICE_URL,
	PASSPORT_MICROSERVICE,
	sendAxiosWithTokens,
	sendAxiosWithTokensAndCustomHeaders,
} from '../../utils/team'
import {
	TokenType,
	getDecodedToken,
	getTokens,
	isTokenInLS,
} from '../../utils/token'
import caseStyles from '../Case/Case.module.scss'
import CasePassportListSideBar from './ActiveListPC/CasePassportListSideBar'
import ProjectPasport from './FramesCasePasport/PasportMain/ProjectPasport'

import MDEditor from '@uiw/react-md-editor'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import Select from 'react-select'
import { TopicDTO } from '../../@types/createTopic'
import { Program } from '../../@types/entities/Program'
import ButtonWrapper from '../../hooks/components/common/Button/ButtonWrapper'
import FileUploader from '../../hooks/components/common/FileUploader'
import FormLabel from '../../hooks/components/customer/FormLabel'
import { setPasportCase } from '../../redux/slices/companyPasportSlice'
import ExampleProject from './FramesCasePasport/ExampleProject/ExampleProject'
import PasportDescription1of2 from './FramesCasePasport/PasportDescr/PasportDescription1of2'
import PasportDescription2of2 from './FramesCasePasport/PasportDescr/PasportDescription2of2'

type SelectType = { valueName: string; label: string; value: number }

const Info = () => {
	const dispatch = useDispatch()
	const [data, setData] = useState<TopicDTO>()
	const [allPrograms, setAllPrograms] = useState<SelectType[]>()
	const [newProgramsId, setNewProgramsId] = useState<number[]>([])
	const [actualPrograms, setActualPrograms] = useState<number[]>([])
	const [isLoading, setLoading] = useState<boolean>(true)
	const { id } = useParams()
	const navigate = useNavigate()
	const [activePrograms, setActivePrograms] = useState<SelectType[]>([])
	const location = useLocation()

	const [hasPasport, setHasPasport] = useState<boolean>(false)

	const [selectedFile, setSelectedFile] = useState<File | null>(null)
	const fileUploader = new FileUploader()
	const [logoUrl, setLogoUrl] = useState('')

	const handleFileUpload = async () => {
		if (!selectedFile) {
			alert('Пожалуйста, выберите файл.')
			return
		}

		const maxFileSize = 3 * 1024 * 1024 // 3 МБ
		if (selectedFile.size > maxFileSize) {
			alert('Файл слишком большой. Размер файла должен быть менее 3 МБ.')
			return
		}
		// const response = await sendAxiosWithTokens(
		//     "get",
		//     `${FILES_MICROSERVICE}/api/v1/topic/${topicid}`
		// );
		// //@ts-ignore
		// const topicId = response.data.topic.id;

		const url = `${FILES_MICROSERVICE}/api/v1/topic/${id}`
		try {
			await fileUploader.uploadFile(url, selectedFile)
		} catch (error) {
			console.error('Ошибка при загрузке файла:', error)
		}
	}

	useEffect(() => {
		;(async () => {
			await sendAxiosWithTokens(
				'get',
				`${FILES_MICROSERVICE}/api/v1/topic/${id}`
			)
				.then(response => {
					setData(response.data.data)
					setMDValue(response.data.data?.description)
					const idps: number[] = []
					response.data.data.programs.forEach((item: Program) => {
						idps.push(item.id as number)
					})

					const activeProgramsServer: SelectType[] = []
					response.data.data.programs.forEach((item: Program) => {
						activeProgramsServer.push({
							valueName: item.title,
							label: item.title,
							value: item.id,
						} as SelectType)
					})
					setActivePrograms(activeProgramsServer)
					setActualPrograms(idps)
					setLoading(false)
				})
				.catch(error => {
					ErrorHandler(error, navigate)
				})
		})()
		;(async () => {
			await sendAxiosWithTokens(
				`get`,
				`${PASSPORT_MICROSERVICE}/api/v1/passport/${id}`
			)
				.then(resp => {
					dispatch(setPasportCase(resp.data.data))
					setHasPasport(true)
				})
				.catch(error => {
					setHasPasport(false)
				})
		})()
		;(async () => {
			await sendAxiosWithTokens(
				`get`,
				`${MODERATION_MICROSERVICE_URL}/api/v1/program/`
			)
				.then(r => {
					const dataToSaveInState: SelectType[] = []
					r.data.data.forEach((item: Program) => {
						dataToSaveInState.push({
							valueName: item.title,
							label: item.title,
							value: item.id,
						} as SelectType)
					})
					setAllPrograms(dataToSaveInState)
				})
				.catch(error => {
					console.log(error)
				})
		})()
	}, [])
	useEffect(() => {
		;(async () => {
			await sendAxiosWithTokens(
				`get`,
				`${PASSPORT_MICROSERVICE}/api/v1/passport/${id}`
			)
				.then(resp => {
					dispatch(setPasportCase(resp.data.data))
					setHasPasport(true)
				})
				.catch(error => {
					// console.log(error)
					setHasPasport(false)
				})
		})()
	}, [location.state.reload])

	interface EditingInfo {
		title: string
		descriprion: string
		companyId: 0
		delegateId: 0
		maxAmountOfTeams: 0
		customerId: 0
		programs: [0]
	}

	const {
		register,
		getValues,
		formState: { errors, isValid },
	} = useForm<EditingInfo>({
		mode: 'onTouched',
	})

	const editing = location.state?.editing
	const [actualPasportFrame, setActualPasportFrame] = useState<number>(0)
	const [pageOfDescr, setPageOfDescr] = useState<number>(1)

	const PutNewDataAboutCase = async (data: FormData) => {
		await sendAxiosWithTokensAndCustomHeaders(
			'put',
			`${FILES_MICROSERVICE}/api/v1/topic/${id}`,
			data,
			{
				'Content-Type': 'multipart/form-data',
			}
		)
			.then(response => {})
			.catch(error => {
				console.error(error)
			})
		await sendAxiosWithTokens(
			'get',
			`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/case`
		).then(resp => {
			dispatch(setCaseList(resp.data.data))
		})
	}

	const SaveChanges = () => {
		const value = getValues()
		const UpdateProgramsId =
			newProgramsId.length === 0 ? actualPrograms : newProgramsId

		const formData = new FormData()
		if (files) {
			for (let i = 0; i < files.length; i++) {
				formData.append('files', files[i])
			}
		}

		const maxAmountOfTeams =
			value['maxAmountOfTeams'] === null ? 100 : value['maxAmountOfTeams']
		formData.append('title', value['title'])
		formData.append('description', MDValue)
		formData.append('companyId', data?.company?.id?.toString())
		formData.append('delegateId', data?.delegate?.id?.toString() as string)
		formData.append('maxAmountOfTeams', maxAmountOfTeams?.toString())
		formData.append('customerId', data?.customer?.id?.toString() as string)
		formData.append('programs', UpdateProgramsId?.toString())
		// console.log('------------------')
		// console.log(formData.forEach(item => console.log(item.toString, item)))
		// console.log('------------------')
		PutNewDataAboutCase(formData)
	}
	let admin: boolean = false
	let customer: boolean = false

	if (isTokenInLS()) {
		const token: any = jwt_decode(getTokens().access_token)

		const roles = token.resource_access['spring-client'].roles
		const isAdmin = roles.indexOf('client-main-admin') > -1
		if (isAdmin) admin = isAdmin
		const isCustomer = roles.indexOf('client-customer') > -1
		if (isCustomer) customer = isCustomer
	}

	const GoToCreatePasport = () => {
		const dataToCreate = {
			caseTitle: data?.title,
			caseId: data?.id,
		}
		navigate('/createprojectpassport', { state: dataToCreate })
	}

	const roles = getDecodedToken()?.resource_access['spring-client'].roles

	const DeleteCase = async () => {
		await sendAxiosWithTokens(
			'delete',
			`${MODERATION_MICROSERVICE_URL}/api/v1/admin/topic/${data?.id}`
		).catch(error => {
			console.log(error)
		})
		await sendAxiosWithTokens(
			`get`,
			`${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/case`
		)
			.then(response => {
				dispatch(setCaseList(response.data.data))
			})
			.catch(error => {
				console.log(errors)
			})
		navigate('../')
	}
	const [MDValue, setMDValue] = useState(data?.description as string)
	const [files, setFiles] = useState<FileList | null>(null)
	const [editFiles, setEditFiles] = useState<boolean>(false)
	async function downloadFile(filename: string, fileOrigName: string) {
		let tokens: TokenType = getTokens()
		try {
			const response = await axios.get(
				`${FILES_MICROSERVICE}/api/v1/topic/download/${filename}`,
				{
					responseType: 'blob',
					headers: {
						Authorization: `Bearer ${tokens.access_token}`,
					},
				}
			)

			const url = window.URL.createObjectURL(new Blob([response.data]))

			const link = document.createElement('a')
			link.href = url

			let fileName = fileOrigName

			link.setAttribute('download', fileName)
			document.body.appendChild(link)
			link.click()
			link.remove()
		} catch (error) {
			console.error('Произошла ошибка при скачивании файла:', error)
		}
	}
	return (
		<>
			{isLoading ? (
				<LoadingText />
			) : (
				<div className='info-block'>
					<div className='info-block-content-wrapper'>
						<div className='info-block-theme info-icon'>
							Кейс{' '}
							{editing ? (
								<textarea
									className='info-block-textareaForEdit'
									{...register('title', { required: true })}
									defaultValue={data?.title}
									rows={3}
								/>
							) : (
								`"${data?.title}"`
							)}
						</div>
						<div className='info-block-title info-icon'>
							{/* {editing ? (
                                <>
                                    Загрузить иконку кейса:
                                    <input
                                        type='file'
                                        onChange={e => {
                                            if (e.target.files && e.target.files.length > 0) {
                                                setSelectedFile(e.target.files[0])
                                            }
                                        }}
                                        accept='image/*'
                                    ></input>
                                    <button onClick={handleFileUpload}>Загрузить</button>
                                    <Logo imageURL={logoUrl} />
                                </>
                            ) : null} */}
							{editing ? (
								<>
									<FormLabel required>Программа обучения:</FormLabel>
									<Select
										options={allPrograms}
										isMulti
										value={activePrograms}
										onChange={e => {
											//@ts-ignore
											setActivePrograms(e as SelectType[])
											const ids: number[] = []

											e.forEach((pair: SelectType) => {
												ids.push(pair.value)
											})
											setNewProgramsId(ids)
										}}
									/>
								</>
							) : (
								<>
									{' '}
									{(data?.programs?.length ?? 0) > 1
										? 'Программы'
										: 'Программа'}
									:{' '}
									<>
										{data?.programs
											?.map(program => `"${program?.title}"`)
											.join(', ')}
									</>
								</>
							)}
						</div>
						<div className='info-block-theme2'>
							Максимальное количество команд:{' '}
							{editing ? (
								<input
									className='info-block-input-forEdit'
									{...register('maxAmountOfTeams', {
										required: true,
									})}
									defaultValue={
										data?.maxAmountOfTeams === null
											? 100
											: data?.maxAmountOfTeams
									}
								></input>
							) : (
								`${data?.maxAmountOfTeams || 'Не указано'}  `
							)}
						</div>

						<ContentCardWrapper>
							<CaseContentCard>
								<div className='info-block-title'>
									Описание кейса:{' '}
									{editing ? (
										<>
											<br />{' '}
											<MDEditor
												value={MDValue}
												height={300}
												highlightEnable={true}
												// @ts-ignore
												onChange={setMDValue}
											/>
										</>
									) : (
										<MDEditor
											preview='preview'
											value={data?.description}
											height={300}
											highlightEnable={true}
											// @ts-ignore
										/>
									)}
								</div>
								<div className={caseStyles.trash}>
									<span>Файлы кейса:</span>

									{editing && (
										<div className={caseStyles.containerFileList}>
											{data?.files?.map(item => {
												return (
													<span className={caseStyles.containerFile}>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															height='24'
															viewBox='0 -960 960 960'
															width='24'
														>
															<path d='M240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z' />
														</svg>
														{item.originalFileName}
													</span>
												)
											})}
										</div>
									)}
									{editing && !editFiles && (
										<Button onClick={() => setEditFiles(true)}>
											Загрузить файлы заново
										</Button>
									)}
									{editing ? (
										editFiles ? (
											<div>
												<br />
												<input
													style={{ display: 'none' }}
													id='fileUpload'
													type='file'
													multiple
													onChange={e => setFiles(e.target?.files as FileList)}
												/>
												<label
													htmlFor='fileUpload'
													className={caseStyles.fileUploadLabel}
												>
													Выбрать файлы
												</label>
												{files ? (
													<Button
														onClick={() => {
															setEditFiles(!editFiles)
															setFiles(null)
														}}
													>
														Отменить загрузка файлов
													</Button>
												) : (
													<Button
														onClick={() => {
															setEditFiles(!editFiles)
														}}
													>
														Отмена
													</Button>
												)}

												{files && (
													<div>
														Чтобы увидеть новые файлы, сохраните изменения
													</div>
												)}
											</div>
										) : null
									) : (
										<div className={caseStyles.containerFileList}>
											{data?.files?.map(item => {
												return (
													<span className={caseStyles.containerFile}>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															height='24'
															viewBox='0 -960 960 960'
															width='24'
														>
															<path d='M240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z' />
														</svg>
														{item.originalFileName}
														<Button
															onClick={() =>
																downloadFile(
																	item.fileName,
																	item.originalFileName
																)
															}
														>
															Скачать
														</Button>
													</span>
												)
											})}
										</div>
									)}
								</div>
								<ButtonWrapper>
									<Link to='../'>
										{editing ? (
											<Button onClick={SaveChanges}>Сохранить изменения</Button>
										) : (
											<Button>Назад</Button>
										)}
									</Link>
									{roles?.includes('client-main-admin') ||
									roles.includes('client-program-curator') ||
									roles.includes('client-main-admin') ||
									roles.includes('client-customer') ? (
										<Button onClick={DeleteCase}>Удалить кейс</Button>
									) : null}
								</ButtonWrapper>
							</CaseContentCard>
						</ContentCardWrapper>
					</div>
					<div className='info-block-pasport'>
						<div className='info-block-theme3'>
							{hasPasport ? 'Паспорт проекта' : null}
						</div>
						<div className='info-block-title2'>
							{hasPasport
								? null
								: `У кейса "${data?.title}" еще нет паспорта проекта`}
							{!hasPasport && (admin || customer) ? (
								<Button onClick={GoToCreatePasport}>
									Создать паспорт проекта
								</Button>
							) : (
								''
							)}

							{hasPasport ? (
								<>
									{actualPasportFrame === 0 ? (
										<ProjectPasport caseId={data?.id} admin={admin} />
									) : actualPasportFrame === 1 ? (
										pageOfDescr == 1 ? (
											<PasportDescription1of2
												swapPage={setPageOfDescr}
												caseId={data?.id}
												admin={admin}
											/>
										) : pageOfDescr == 2 ? (
											<PasportDescription2of2
												swapPage={setPageOfDescr}
												caseId={data?.id}
												admin={admin}
											/>
										) : (
											''
										)
									) : actualPasportFrame === 2 ? (
										<ExampleProject caseId={data?.id} admin={admin} />
									) : (
										''
									)}
								</>
							) : (
								''
							)}
							{hasPasport ? (
								<CasePassportListSideBar
									caseId={data?.id}
									setActualPasportFrame={setActualPasportFrame}
									actualPasportFrame={actualPasportFrame}
								/>
							) : (
								''
							)}
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default Info
