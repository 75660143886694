import { useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import ContainerMain from './hooks/components/common/ContainerMain/ContainerMain'
import ContentCard from './hooks/components/common/ContentCard/ContentCard'
import ContentCardWrapper from './hooks/components/common/ContentCard/ContentCardWrapper'
import { MODERATION_MICROSERVICE_URL, sendAxiosWithTokens } from './utils/team'
function UploadTable() {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	//@ts-ignore
	const { register, handleSubmit, reset, errors } = useForm()

	const onSubmit = async (data: FieldValues) => {
		let formData = new FormData()
		formData.append('file', data.file[0])

		setIsLoading(true)

		await sendAxiosWithTokens(
			'post',
			`${MODERATION_MICROSERVICE_URL}/api/v1/users/create`,
			formData
		)
	}
	return (
		<>
			<div className='questionnare-page'>
				<ContainerMain size={'large'}>
					<ContentCardWrapper>
						<ContentCard>
							<div className='container mb-4'>
								<h1 className='text-center mt-5'>Загрузить файл</h1>

								<form
									onSubmit={handleSubmit(onSubmit)}
									className='upload-form mt-5'
									method='POST'
									encType='multipart/form-data'
								>
									<input
										type='file'
										{...register('file', {
											required: true,
										})}
										className='addfile'
									/>

									<div style={{ marginTop: '2rem' }}>
										<button
											type='submit'
											className='upload-btn'
											style={{
												borderRadius: '20px',
												padding: '1rem',
												border: '1px solid cyan',
												background: 'cyan',
												color: 'black',
												fontSize: '25px',
											}}
										>
											Загрузить
										</button>
									</div>
								</form>
							</div>
						</ContentCard>
					</ContentCardWrapper>
				</ContainerMain>
			</div>
		</>
	)
}

export default UploadTable
