import { Outlet, useMatch } from 'react-router-dom'
import Case from './Case'
const Caseoutlet = () => {
	return (
		<div className='case'>
			<div className='case-content-wrapper'>
				{useMatch('/Case') ? <Case /> : <Outlet />}
			</div>
		</div>
	)
}

export default Caseoutlet
