import { useState } from 'react'
import { TeamDTO } from '../../../../../@types/createCommand'
import paths from '../../../../../paths'
import Button from '../../../common/Button/Button'
import ContainerMain from '../../../common/ContainerMain/ContainerMain'
import ContentCard from '../../../common/ContentCard/ContentCard'
import ContentCardHeading from '../../../common/ContentCard/ContentCardHeading'
import ContentCardInner from '../../../common/ContentCard/ContentCardInner'
import ContentCardWrapper from '../../../common/ContentCard/ContentCardWrapper'
import AllAdminsTeamsTable from './AllAdminTeamsTable'
import ExtendedSearchTeams from './ExtendedSearchTeamt'

const AllAdminTeams = () => {
	const link = `/${paths.admin.base}/${paths.admin.createTeam}`
	const [isSearch, setIsSearch] = useState<boolean>(false)
	const [teamsFromExtendedSearch, setTeamsFromExtendedSearch] = useState<
		TeamDTO[]
	>([])

	return (
		<ContainerMain>
			<ContentCardWrapper>
				<ContentCard low>
					<div className='text-mobile'>
						Для облегчения поиска мы решили сделать список рекомендаций, в
						котором каждый из вас сможет найти себе команду в зависимости от
						вашей предпочтительной роли, указанной в личном кабинете. Вы также
						можете воспользоваться расширенным поиском, который поможет найти
						любого пользователя и команду по названию или фильтрам
					</div>
					<div className='text-desktop'>
						Для облегчения поиска мы решили сделать список рекомендаций, в
						котором каждый из вас сможет найти себе команду в зависимости от
						вашей предпочтительной роли, указанной в личном кабинете. Вы также
						можете воспользоваться расширенным поиском, который поможет найти
						любого пользователя и команду по названию или фильтрам
					</div>
				</ContentCard>
				<ContentCard>
					<ContentCardHeading topic='Рекомендации'>
						<Button styling='text' action={'link'} to={link}>
							Создать команду
						</Button>
						{teamsFromExtendedSearch.length == 0 && !isSearch ? (
							<Button onClick={() => setIsSearch(true)}>
								Расширенный поиск
							</Button>
						) : (
							<Button
								onClick={() => {
									setIsSearch(false)
									window.location.reload()
								}}
							>
								Вернуться назад
							</Button>
						)}
					</ContentCardHeading>
					<ContentCardInner>
						{isSearch ? (
							<ExtendedSearchTeams
								setIsSearch={setIsSearch}
								setTeamsFromExtendedSearch={setTeamsFromExtendedSearch}
							/>
						) : null}
						<div className='table-wrapper'>
							{isSearch ? null : (
								<AllAdminsTeamsTable
									teamsFromExtendedSearch={teamsFromExtendedSearch}
								/>
							)}
						</div>
					</ContentCardInner>
				</ContentCard>
			</ContentCardWrapper>
		</ContainerMain>
	)
}

export default AllAdminTeams
