import { useEffect, useState } from 'react'
import { TeamDTO } from '../../../../../@types/createCommand'
import {
	CREATE_COMMAND_MICROSERVICE_URL,
	sendAxiosWithTokens,
} from '../../../../../utils/team'
import SingleSelect from '../../../SingleSelect'
import Button from '../../../common/Button/Button'
import ButtonWrapper from '../../../common/Button/ButtonWrapper'
import DoubleList from '../../../common/DoubleList/DoubleList'
import {
	facultyList,
	getCompanies,
	getGroups,
} from '../ExtendedSearchApi/ExtendedSearchApi'
interface extendedSearchTeamsProps {
	setIsSearch: (value: boolean) => void
	setTeamsFromExtendedSearch: (value: TeamDTO[]) => void
}
type SelectType = { valueName: number; valueId: number; label: string }
export type defaultTypes = number | string | boolean
export function checkAllKyesIsEmpty(obj: {
	[key: string]: defaultTypes | null
}): boolean {
	for (let key in obj) {
		if (obj[key] !== null) {
			return false
		}
	}
	return true
}
const ExtendedSearchTeams = ({
	setIsSearch,
	setTeamsFromExtendedSearch,
}: extendedSearchTeamsProps) => {
	//state возможных выборов
	const [companiesList, setCompaniesList] = useState<SelectType[]>([])
	const [dppGroupList, setDppGroupList] = useState<SelectType[]>([])
	const [academicList, setAcademicList] = useState<SelectType[]>([])
	// state актуального выбора
	const [faculty, setFaculty] = useState<number | null>(null)
	const [company, setCompany] = useState<number | null>(null)
	const [dppGroup, setDppGroup] = useState<number | null>(null)
	const [academicGroup, setAcademicGroup] = useState<number | null>(null)

	const requestBody: {
		dppGroupId: number | null
		academicGroupId: number | null
		facultyNumber: number | null
		companyId: number | null
	} = {
		dppGroupId: dppGroup,
		academicGroupId: academicGroup,
		facultyNumber: faculty,
		companyId: company,
	}

	const getExtendedTeams = async () => {
		const url = `${CREATE_COMMAND_MICROSERVICE_URL}/api/v1/extend-search/teams`
		await sendAxiosWithTokens(`post`, url, requestBody)
			.then(res => {
				setTeamsFromExtendedSearch(res.data.data)
			})
			.catch(error => {
				console.log(error)
			})
	}
	useEffect(() => {
		const dataFetch = async () => {
			const companies = await getCompanies()
			const academicGroups = await getGroups('academic')
			const dppGroups = await getGroups('dpp')
			setCompaniesList(companies)
			setAcademicList(academicGroups)
			setDppGroupList(dppGroups)
		}
		dataFetch()
	}, [])

	return (
		<>
			<div className='extended-search'>
				<DoubleList
					listData={[
						{
							identifier: 'ДПП Группа:',
							content: (
								<SingleSelect
									options={dppGroupList}
									infobefore={'ДПП Группа'}
									onChangeValue={(valueID: number) => {
										setDppGroup(valueID)
									}}
								/>
							),
						},
						{
							identifier: 'Академическая группа:',
							content: (
								<SingleSelect
									options={academicList}
									infobefore={'Академическая группа'}
									onChangeValue={(valueID: number) => {
										setAcademicGroup(valueID)
									}}
								/>
							),
						},
						{
							identifier: 'Факультет:',
							content: (
								<SingleSelect
									options={facultyList}
									infobefore={'Факультет'}
									onChangeValue={(valueID: number) => {
										setFaculty(valueID)
									}}
								/>
							),
						},
						{
							identifier: 'Компания',
							content: (
								<SingleSelect
									options={companiesList}
									infobefore={'Компания'}
									onChangeValue={(valueId: number) => {
										setCompany(valueId)
									}}
								/>
							),
						},
					]}
				/>
				<ButtonWrapper spacing>
					{checkAllKyesIsEmpty(requestBody) ? null : (
						<Button
							onClick={() => {
								setIsSearch(false)
								getExtendedTeams()
							}}
						>
							Найти
						</Button>
					)}
				</ButtonWrapper>
			</div>
		</>
	)
}

export default ExtendedSearchTeams
