import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import UpdatePassportDTO from '../../../../@types/dtos/UpdatePassportDTO'
import Button from '../../../../hooks/components/common/Button/Button'
import { setFieldPasport } from '../../../../redux/slices/companyPasportSlice'
import { RootState } from '../../../../redux/store'
import {
	PASSPORT_MICROSERVICE,
	sendAxiosWithTokens,
} from '../../../../utils/team'

const ExampleProjEdit = ({
	caseId,
	toggle,
}: {
	caseId?: number
	toggle: Function
}) => {
	const { PasportCase } = useSelector(
		(state: RootState) => state.companyPasportSlice
	)

	const dispatch = useDispatch()

	const RequestForNewData = async (body: UpdatePassportDTO) => {
		await sendAxiosWithTokens(
			`post`,
			`${PASSPORT_MICROSERVICE}/api/v1/passport/${caseId}`,
			body
		)
			.then(r => {
				console.log('fdsf', r.data.data)
			})
			.catch(error => [console.log(error)])
	}

	const {
		register,
		getValues,
		formState: { errors, isValid },
	} = useForm<UpdatePassportDTO>({
		mode: 'onTouched',
	})

	const onSetNewData = () => {
		const value = getValues()
		const body: UpdatePassportDTO = {
			endUser: PasportCase?.endUser,
			projectDuration: PasportCase?.projectDuration,
			projectType: PasportCase?.projectType,
			projectGoal: PasportCase?.projectGoal,
			projectTasks: PasportCase?.projectTasks,
			projectResult: PasportCase?.projectResult,
			projectRestrictions: PasportCase?.projectRestrictions,
			projectResources: PasportCase?.projectResources,
			projectTaskTracker: PasportCase?.projectTaskTracker,
			projectCompitentions: PasportCase?.projectCompitentions,
			additionalInformation: PasportCase?.additionalInformation,
			projectMentor: PasportCase?.projectMentor,
			projectSupervisor: PasportCase?.projectSupervisor,
			example: value['example'],
		}
		dispatch(
			setFieldPasport({
				example: value['example'],
			})
		)

		RequestForNewData(body)
	}

	return (
		<>
			<textarea
				className='company-info-input-forEdit'
				defaultValue={PasportCase?.example}
				{...register('example')}
				rows={20}
			/>
			<div className='link-wrapperPasport' onClick={onSetNewData}>
				<Button onClick={toggle}>Сохранить</Button>
			</div>
		</>
	)
}

export default ExampleProjEdit
