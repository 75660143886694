import axios from 'axios'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CompanyUserDTO } from '../../../@types/dtos/CompanyUserDTO'
import { setCompanyInfo } from '../../../redux/slices/CompanyInfoSlice'
import { RootState } from '../../../redux/store'
import { PASSPORT_MICROSERVICE } from '../../../utils/team'
import {
	checkIsTokenExpired,
	getTokens,
	updateToken,
} from '../../../utils/token'
import Button from '../common/Button/Button'
import FormInput, { makeProps } from './FormInput'
import FormNavigateButtons from './FormNavigateButtons'
import FormPanel from './FormPanel'

const CustomerMainInfo = () => {
	const dispatch = useDispatch()
	const user = useSelector(
		(state: RootState) =>
			state.CompanyInfoReducer.profile.CompanyUserProfile.owner
	)
	const onChange = ({ target }: any) => {
		dispatch(setCompanyInfo({ name: target?.name, value: target?.value }))
	}
	const navigate = useNavigate()
	const [allFilled, setAllFilled] = useState(false)
	const canGoNext = () => {
		return [user.fio !== '', user.email !== '', user.phoneNumber !== ''].reduce(
			(result, current) => result && current
		)
	}
	const saveQuestionnare = async (body: CompanyUserDTO) => {
		let token = getTokens()

		if (checkIsTokenExpired(token.access_token)) {
			if (checkIsTokenExpired(token.refresh_token)) navigate('/')
			updateToken()
		}

		token = getTokens()

		const headers = {
			Authorization: `Bearer ${token.access_token}`,
		}

		const options = {
			headers,
		}
		const resultOfRequest = await axios
			.post(
				`${PASSPORT_MICROSERVICE}/api/v1/questionnaire/admin`,
				body,
				options
			)
			.catch(error => {
				if (error.response.status === 404) {
					navigate('*')
					console.log(error)
				} else {
					navigate('/AnyError')
					console.log(error)
				}
			})
		navigate('/PersonalAccount/MyData')
	}

	const finishFillingQueistionnare = () => {
		//@ts-ignore
		const copyUser = { ...user } as CompanyUserDTO

		saveQuestionnare(copyUser)
	}

	return (
		<FormPanel method='POST'>
			{/* <NameForm /> */}

			<FormInput
				required
				label='Имя'
				{...makeProps.forInput('name', user, onChange)}
			/>

			<FormInput
				required
				label='Фамилия'
				{...makeProps.forInput('surname', user, onChange)}
			/>

			<FormInput
				label='Отчество'
				{...makeProps.forInput('patronymic', user, onChange)}
			/>

			<FormInput
				required
				label='Почта'
				type='email'
				{...makeProps.forInput('email', user, onChange)}
				labelDescription='Можно будет скрыть в личном кабинете'
			/>

			<FormInput
				required
				label='Номер телефона'
				type='tel'
				{...makeProps.forInput('phoneNumber', user, onChange)}
				inputDescription='
                Номер вводится без +7 или 8. Пример: 9251234567'
				labelDescription='Скрыто по умолчанию'
			/>

			<FormInput
				label='Telegram'
				{...makeProps.forInput('telegram_link', user, onChange)}
				inputDescription='Ссылка на VK в формате https://vk.com/nickname'
				labelDescription='Можно будет скрыть в личном кабинете'
			/>

			<FormNavigateButtons>
				<Button
					action='link'
					to='../contact'
					spacing='8'
					disabled={!canGoNext()}
				>
					Далее
				</Button>
			</FormNavigateButtons>
		</FormPanel>
	)
}

export default CustomerMainInfo
