import React from "react";

interface FormLabelProps {
    children?: React.ReactNode;
    htmlFor?: string;
    required?: boolean;
    className?: string;
    description?: string;
}

const FormLabel = ({
    children,
    required,
    htmlFor,
    className = "",
    description,
}: FormLabelProps) => {
    return (
        <label
            htmlFor={htmlFor}
            className={"form-label " + (required ? "required " : "") + className}
        >
            {children}
            {description && <span className="form-label-description">{description}</span>}
        </label>
    );
};

export default FormLabel;
